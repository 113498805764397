import * as tslib_1 from "tslib";
import { InterfaceService } from '@app/interfaces';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "@app/core";
import { BehaviorSubject, Observable } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../core/settings/settings.service";
var SetupViewsService = /** @class */ (function (_super) {
    tslib_1.__extends(SetupViewsService, _super);
    function SetupViewsService(http, translate, jwtToken) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.translate = translate;
        _this.jwtToken = jwtToken;
        _this.shareWithInformation = new BehaviorSubject('');
        _this.shareWithInformation$ = _this.shareWithInformation.asObservable();
        _this.shareWithMe = new BehaviorSubject('');
        _this.shareWithMe$ = _this.shareWithMe.asObservable();
        _this.getViewsObjects = function (recordType) {
            return this.http.get(this.getViewApiUrl(recordType, "get_views_metadata"));
        };
        return _this;
    }
    SetupViewsService.prototype.getProgressPanelData = function (screen) {
        var progressPanelData = [
            {
                stepText: this.translate.instant('Basic details'),
                id: 'basic_details',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: true,
                isStepDisable: false,
                stepPermission: true,
                sequence: 1
            },
            {
                stepText: this.translate.instant('share.with'),
                id: 'share_with',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 2
            }
        ];
        if (screen != 'opportunityViews') {
            progressPanelData.push({
                stepText: this.translate.instant('Card design'),
                id: 'card_design',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 3
            });
            progressPanelData.push({
                stepText: this.translate.instant('Filters'),
                id: 'filters',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 4
            });
        }
        else {
            progressPanelData.push({
                stepText: this.translate.instant('Filters'),
                id: 'filters',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 3
            });
        }
        return progressPanelData;
    };
    SetupViewsService.prototype.setShareWithInformationDetails = function (data) {
        this.shareWithInformation.next(data);
    };
    SetupViewsService.prototype.getShareWithInformationDetails = function () {
        return this.shareWithInformation;
    };
    SetupViewsService.prototype.addNewViews = function (recordType, params) {
        return this.http.post(this.getViewApiUrl(recordType, "add_views"), JSON.stringify(params));
    };
    SetupViewsService.prototype.reorderList = function (data, type) {
        return this.http.put(this.getApiUrl("commonSettingsReorder?type=" + type), data);
    };
    SetupViewsService.prototype.updateViewDetails = function (recordType, viewId, params) {
        return this.http.put(this.getViewApiUrl(recordType, "update_views/" + viewId), JSON.stringify(params));
    };
    SetupViewsService.prototype.getViewsDetails = function (recordType, viewId) {
        return this.http.get(this.getViewApiUrl(recordType, "get_views/" + viewId + "?recordType=" + recordType));
    };
    SetupViewsService.prototype.previewViews = function (recordType, params) {
        if (recordType === 'opportunity') {
            return this.http.post(this.getApiUrl('get_preview_opportunities'), params);
        }
        else {
            return this.http.post(this.getJobApiUrl("preview_jobs"), JSON.stringify(params));
        }
    };
    SetupViewsService.prototype.deleteViews = function (recordType, params) {
        if (recordType === void 0) { recordType = 'job'; }
        return this.http.post(this.getViewApiUrl(recordType, "delete_views"), params);
    };
    SetupViewsService.prototype.checkViewTemplateExists = function (params) {
        return this.http.get(this.getApiUrl('checkViewTemplateExists', params));
    };
    SetupViewsService.prototype.getViewApiUrl = function (recordType, path) {
        if (recordType === 'opportunity') {
            return this.getApiUrl(path);
        }
        else {
            return this.getJobApiUrl(path);
        }
    };
    SetupViewsService.prototype.getSettingsRolesList = function (recordType, params) {
        if (recordType === 'opportunity') {
            return this.http.get(this.getApiUrl("getSettingsRolesList", params));
        }
        else {
            var httpOptions = new HttpHeaders().set('Authorization', params.v5Token);
            return this.http.get(this.getJobApiUrl("getSettingsRolesList", {}), { headers: httpOptions });
        }
    };
    SetupViewsService.prototype.setShareWithMe = function (data) {
        this.shareWithMe.next(data);
    };
    SetupViewsService.ngInjectableDef = i0.defineInjectable({ factory: function SetupViewsService_Factory() { return new SetupViewsService(i0.inject(i1.HttpClient), i0.inject(i2.TranslateService), i0.inject(i3.SettingsService)); }, token: SetupViewsService, providedIn: "root" });
    return SetupViewsService;
}(InterfaceService));
export { SetupViewsService };
