import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ScreenSpecificComponent } from './activity-log/screen-specific/screen-specific.component';
import { DiaryActivityComponent } from './activity-log/diary-activity/diary-activity.component';
import { CommunicationComponent } from './activity-log/communication/communication.component';
import { PortalActivityComponent } from './activity-log/portal-activity/portal-activity.component';
import { FileActivityComponent } from './activity-log/file-activity/file-activity.component';
import { CallActivityComponent } from './activity-log/communication/call-activity/call-activity.component';
import { EmailActivityComponent } from './activity-log/communication/email-activity/email-activity.component';
import { SmsActivityComponent } from './activity-log/communication/sms-activity/sms-activity.component';
import { NotesActivityComponent } from './activity-log/communication/notes-activity/notes-activity.component';
import { ViewAttachFilesComponent } from '@app/shared/cs-components/view-attach-files/view-attach-files.component';
import { ViewJobPartsComponent } from '@app/shared/cs-components/view-job-parts/view-job-parts.component';
import { CommunicationModalModule } from '../communication-modals/communication-modal.module';
import { FilterNavbarComponent } from './filter-navbar/filter-navbar.component';
import { PrintActivityComponent } from './activity-log/communication/print-activity/print-activity.component';
import { RunThroughActivityComponent } from './run-through-activity/run-through-activity.component';
import {WorkflowCommonModule} from "@app/workflow-common/workflow-common.module";
import {DiagramHistoryComponent} from "@app/workflow-common/diagram-history/diagram-history.component";
import {DagrunsComponent} from "@app/workflow-common/dagruns/dagruns.component";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {MxGraphService} from "@app/workflow-common/services/mx-graph.service";
import { ContractActivityComponent } from './activity-log/contract-activity/contract-activity.component';
import { ContractStatusComponent } from './activity-log/contract-activity/contract-status/contract-status.component';
import { AddressComponent } from './activity-log/contract-activity/address/address.component';
import { BoltOnComponent } from './activity-log/contract-activity/bolt-on/bolt-on.component';
import { InvoiceComponent } from './activity-log/contract-activity/invoice/invoice.component';
import { PaymentComponent } from './activity-log/contract-activity/payment/payment.component';
import { PpmActivityComponent } from './activity-log/contract-activity/ppm-activity/ppm-activity.component';
import {JobComponent} from "@app/shared/timeline/activity-log/contract-activity/job/job.component";
import { SharedModalModule } from '@app/shared-modal/shared-modal.module';
import {TranslateModule} from "@ngx-translate/core";
import { TooltipModule} from "ngx-bootstrap";
import {DirectivesModule} from "@app/directives";
import {PipesModule} from "@app/pipes";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    ScheduledActivitySliderComponent
} from "@app/shared/timeline/sliders/scheduled-activity-slider/scheduled-activity-slider.component";
import {
    ScheduledActivityComponent
} from "@app/shared/timeline/activity-log/scheduled-activity/scheduled-activity.component";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";
import { DiaryEventActivityComponent } from './activity-log/diary-event-activity/diary-event-activity.component';

@NgModule({
    declarations: [ActivityLogComponent, ScreenSpecificComponent, DiaryActivityComponent, CommunicationComponent, PortalActivityComponent, FileActivityComponent, CallActivityComponent, EmailActivityComponent, SmsActivityComponent, NotesActivityComponent,FilterNavbarComponent, PrintActivityComponent, RunThroughActivityComponent, ContractActivityComponent, ContractStatusComponent, AddressComponent, BoltOnComponent, InvoiceComponent, PaymentComponent,PpmActivityComponent, JobComponent,
        ScheduledActivitySliderComponent,
        ScheduledActivityComponent,
        DiaryEventActivityComponent],
    exports: [
        ActivityLogComponent,
        FilterNavbarComponent,
        RunThroughActivityComponent,
        NotesActivityComponent,
        ScheduledActivitySliderComponent,
        ScheduledActivityComponent,
        DiaryEventActivityComponent,
    ],
    imports: [
        CommonModule,
        CommunicationModalModule,
        WorkflowCommonModule,
        SharedModalModule,
        TranslateModule,
        TooltipModule,
        DirectivesModule,
        PipesModule,
        FormsModule,
        ReactiveFormsModule,
        CsComponentsModule
    ],
    entryComponents: [
        ViewAttachFilesComponent,
        ViewJobPartsComponent,
    ],
    providers: [
        DiagramHistoryComponent,
        WorkflowhttpService,
        MxGraphService,
    ]
})
export class TimelineModule { }
