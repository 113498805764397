import { OnInit, NgZone, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subject } from 'rxjs/index';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { AuthService } from '@app/core';
import { TimelineService } from '@app/shared/services/timeline.service';
var ImageBlocksComponent = /** @class */ (function () {
    function ImageBlocksComponent(bsModalRef, proposaltemplateservice, authService, ts, ngZone, cdRef, confirmationBoxHelper, ajsScope) {
        this.bsModalRef = bsModalRef;
        this.proposaltemplateservice = proposaltemplateservice;
        this.authService = authService;
        this.ts = ts;
        this.ngZone = ngZone;
        this.cdRef = cdRef;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.errorMsg = '';
        this.imgURL = '';
        this.templateImages = [];
        this.croppedImage = '';
        this.pageBackground = 'color';
        this.backgroundColorDisplay = '#ffffff';
        this.imageSelectStatus = false;
        this.saving = false;
        this.color_options = {
            type: 'component',
            showPalette: false,
            hideAfterPaletteSelect: true,
            showButtons: false,
            allowEmpty: false,
            preferredFormat: 'hex',
            replacerClassName: 'format-replace',
            containerClassName: 'container-palette'
        };
        this.filename = '';
        this.partsImageList = [];
        this.categoryImageList = [];
        this.partUrl = false;
        this.imageblock = false;
        this.disabled = false;
        this.restoreBgColor = '';
        this.previewImage = false;
        this.pageBackgroundColor = 'No background';
        this.editImageLoading = false;
        this.imageArrayList = [];
        this.isUploadPdfPage = false;
        this.selectedOption = 'uploadImage';
        this.customTagAccess = false;
        this.selectedImages = []; // Array to store selected image URLs
        this.showCropper = false;
        this.aspectRatioOptions = [
            { value: 'freeform', label: 'Freeform' },
            { value: 'originalRatio', label: 'Originalratio' },
            { value: 'Square', label: 'Square 1:1' },
            { value: 'Monitor', label: 'Monitor 4:3' },
        ];
        this.selectedAspectRatio = 'freeform'; // Variable to store the selected aspect ratio
        this.maintainAspectRatio = false;
        this.searchText = ''; // Variable to store the search text
        this.filteredActivityFiles = []; // Array to store filtered activity files
        this.filteredCustomerFiles = []; // Array to store filtered customer files
        this.filteredTemplateFiles = []; // Array to store filtered template files
        this.filteredPartList = []; // Array to store filtered template files
        this.filteredSurveyFiles = [];
        this.filteredCategoryImageList = [];
        this.lockAspectRatio = false;
        this.imgURLs = [];
        this.freeformRatio = false;
        this.cropper = {
            x1: 0,
            y1: 0,
            x2: 100,
            y2: 100,
        };
        this.adjustedWidth = 0; // New property for adjusted width
        this.adjustedHeight = 0;
        this.isCustomerImagesEmpty = true;
        this.multipleImageSelected = false;
        this.backgroundImage = false;
    }
    ImageBlocksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editImageLoading = true;
        this.customTagAccess = this.authService.permitted(['CustomTags.writeaccess']) === true;
        if (this.customTagAccess) {
            this.proposaltemplateservice.getSurveyTemplateList().subscribe(function (values) {
                if (values) {
                    _this.surveyQuestionsData = values['leaveJobQuestion'];
                    _this.surveyQuestionsData = _this.surveyQuestionsData.filter(function (template) { return template.JsonData !== null && (template.JsonData.includes('Photo') || template.JsonData.includes('Signature')); });
                    if (_this.surveyObject) {
                        _this.selectedOption = 'survey';
                        _this.selectedTemplateId = _this.surveyObject.templateId;
                        _this.onTemplateChange(_this.selectedTemplateId);
                        _this.selectedQuestionId = _this.surveyObject.questionId;
                        setTimeout(function () {
                            $(_this.select2TemplateElement.nativeElement).select2('val', _this.selectedTemplateId);
                            $(_this.select2QuestionElement.nativeElement).select2('val', _this.selectedQuestionId);
                        }, 200);
                    }
                }
            });
        }
        this.onClose = new Subject();
        this.uploader = new FileUploader({
            url: "",
            autoUpload: false,
            itemAlias: 'file',
            allowedMimeType: ['image/png', 'image/jpeg'],
            disableMultipart: true
        });
        if (this.option == 'pricingImage') {
            this.imagesCount = 3 - this.currentImageCount;
        }
        else {
            this.imagesCount = 1;
        }
        if (this.option == 'previewImage') {
            this.imgLocation = this.imgLocation;
            this.open = true;
            this.previewImage = true;
            this.imageArrayList = this.imageArrayList;
        }
        if (this.option == 'edit' || this.option == 'fitToBlock') {
            this.editImageLoading = true;
            var image = void 0;
            image = $('#cs_editor').contents().find('div.cs_page').find('.cs_selected').find('img');
            if (image) {
                this.imgURL = image.attr('src');
                this.croppedImage = this.imgURL;
                this.editImageLoading = false;
                this.selectedImages.push(this.imgURL);
                this.convertImagesToBase64();
            }
        }
        this.proposaltemplateservice.getPartsListImage(this.selectedTemplate, this.currentTemplateId, this.opportunityProposalTemplateId).subscribe(function (res) {
            if (res) {
                // this.partsImageList = [];
                _this.partsImageList = res['data'] ? res['data']['partsImages'] : [];
                _this.customerImages = res['data'] ? res['data']['files'] : {};
                _this.categoryImageList = res['data'] ? res['data']['categoryImages'] : [];
                // parsing incoming data to make filters
                _this.filteredPartList = _this.partsImageList ? _this.partsImageList : [];
                _this.filteredActivityFiles = _this.customerImages ? _this.customerImages['activityFiles'] : [];
                _this.filteredTemplateFiles = _this.customerImages ? _this.customerImages['templateFiles'] : [];
                _this.filteredCustomerFiles = _this.customerImages ? _this.customerImages['customerFiles'] : [];
                _this.filteredSurveyFiles = _this.customerImages ? _this.customerImages['surveyFiles'] : [];
                _this.filteredCategoryImageList = _this.categoryImageList ? _this.categoryImageList : [];
            }
            if (_this.customerImages && _this.customerImages['activityFiles'].length || _this.customerImages['templateFiles'].length || _this.customerImages['customerFiles'].length || _this.customerImages['surveyFiles'].length) {
                _this.isCustomerImagesEmpty = false;
            }
            _this.editImageLoading = false;
        });
        //to get the top height of the drag-zone and set the top to newly implemented drag zone
        setTimeout(function () {
            _this.dropZoneTopHeight = document.getElementById('drop-zone').offsetTop;
        }, 1500);
        $(':input[type=number]').on('wheel', function (e) { $(this).blur(); });
    };
    ImageBlocksComponent.prototype.addImageBlock = function () {
        var _this = this;
        $('#block-background-color').spectrum('destroy');
        if (this.option == 'pricingImage') {
            if (this.checked) {
                this.open = false;
                this.partUrl = false;
                this.onClose.next(this.croppedImage);
                this.bsModalRef.hide();
            }
            else {
                this.disabled = true;
                this.proposaltemplateservice.addPricingImages(this.imgURLs, this.pricingId).subscribe(function (res) {
                    if (res) {
                        // this.croppedImage = res['file']['location'];
                        var imagesUploadList = res[0]['file'];
                        var data = {
                            'imagesList': imagesUploadList,
                        };
                        _this.open = false;
                        _this.partUrl = false;
                        _this.onClose.next(data);
                        _this.disabled = true;
                        _this.bsModalRef.hide();
                    }
                });
            }
        }
        else {
            this.uploadImage();
        }
        $('#cs_editor').contents().find('div.cs_selected').find('.cs_margin').removeAttr('data-bgclr');
    };
    ImageBlocksComponent.prototype.uploadImage = function (option) {
        var _this = this;
        if (option === void 0) { option = null; }
        this.disabled = true;
        this.proposaltemplateservice.addPageImages(this.croppedImage, this.page_id).subscribe(function (res) {
            if (res) {
                _this.croppedImage = res['file']['location'];
                _this.open = false;
                if (option) {
                    _this.onClose.next({ 'image': _this.croppedImage });
                }
                else {
                    _this.onClose.next(_this.croppedImage);
                }
                _this.disabled = false;
                _this.bsModalRef.hide();
                _this.proposaltemplateservice.setEditorThumbnail('imageBg');
            }
        });
    };
    ImageBlocksComponent.prototype.backTo = function () {
        this.option = 'pricingImage';
        this.imgURL = '';
    };
    ImageBlocksComponent.prototype.cancel = function () {
        if (this.blockSettingModal) {
            this.blockSettingModal.cancel();
        }
        $('#block-background-color').spectrum('destroy');
        if (this.option == 'pageBackground' && (this.pageBackground == 'color' || this.pageBackground == 'image')) {
            var pageType = $('#cs_editor').contents().find("div[data_id=" + this.page_id + "]")[0];
            if (pageType.getAttribute('data-page-type') == 'EHP') {
                $('#cs_editor').contents().find('div.cs_selected').css('background-color', this.restoreBgColor);
            }
            else {
                $('#cs_editor').contents().find('div.cs_selected_border').css('background-color', this.restoreBgColor);
            }
            $('#cs_editor').contents().find('div.cs_selected').find('.cs_margin').removeAttr('old-bgclr');
        }
        this.bsModalRef.hide();
    };
    ImageBlocksComponent.prototype.detectChanges = function (data) {
        var that = this;
        this.myValue = '';
        setTimeout(function () {
            that.myValue = data;
        }, 50);
    };
    ImageBlocksComponent.prototype.keyEvent = function (event) {
        if (event.keyCode == 39) { // right key
            this.fileRightArrow();
        }
        else if (event.keyCode == 37) { // left key
            this.fileLeftArrow();
        }
    };
    ImageBlocksComponent.prototype.fileRightArrow = function () {
        if (this.imageArrayList.length > 0 && this.imageArrayList.length - 1 > this.imageIndex) {
            this.imageIndex++;
            this.selectedIndex(this.imageIndex);
        }
    };
    ImageBlocksComponent.prototype.fileLeftArrow = function () {
        if (this.imageArrayList.length > 0 && this.imageIndex > 0) {
            this.imageIndex--;
            this.selectedIndex(this.imageIndex);
        }
    };
    ImageBlocksComponent.prototype.selectedIndex = function (currentIndex) {
        if (this.imageArrayList[currentIndex]) {
            var findNextImage = this.imageArrayList[currentIndex];
            this.imgLocation = findNextImage;
            // this.clone['fileName'] = findNextImage;
        }
    };
    ImageBlocksComponent.prototype.onTemplateChange = function (template) {
        var _this = this;
        this.questionOptions = [];
        this.questionSelected = true;
        this.selectedTemplateId = template;
        this.selectedQuestionId = null;
        if (this.select2QuestionElement) {
            $(this.select2QuestionElement.nativeElement).select2('val', this.selectedQuestionId);
        }
        var index = this.surveyQuestionsData.findIndex(function (question) { return question.id == template; });
        if (index >= 0) {
            var jsonData = this.surveyQuestionsData[index]['JsonData'];
            var data = JSON.parse(jsonData);
            if (data) {
                data.pages.forEach(function (page) {
                    page.sections.forEach(function (section) {
                        section.fields.forEach(function (field) {
                            if (field.fieldAnswerType == 'Photo' || field.fieldAnswerType == 'Signature') {
                                _this.surveyOptionConfig = {
                                    'questionLabel': field.fieldLabel,
                                    'questionId': field.questionID,
                                    'answerType': field.fieldAnswerType,
                                    'file': true
                                };
                                _this.questionOptions.push(_this.surveyOptionConfig);
                            }
                        });
                    });
                });
            }
            this.questionData = this.questionOptions.find(function (dO) { return dO.questionId === _this.data && _this.data.questionId; }) || this.questionOptions[0];
        }
    };
    ImageBlocksComponent.prototype.onQuestionSelect = function (questionId) {
        this.questionData = this.questionOptions.find(function (dO) { return dO.questionId === questionId; });
    };
    ImageBlocksComponent.prototype.onOptionChange = function (option) {
        this.selectedOption = option;
    };
    ImageBlocksComponent.prototype.addSurveyImage = function () {
        var _this = this;
        this.tagdatas = {
            name: this.questionData.questionLabel ? this.questionData.questionLabel : null,
            tagCompletedBy: this.selectedOption,
            templateId: this.selectedTemplateId,
            questionId: this.questionData.questionId ? this.questionData.questionId : null,
            answerType: this.questionData.answerType ? this.questionData.answerType : null,
        };
        this.customTagData = {
            tablePkId: this.currentTemplateId,
            tagData: JSON.stringify(this.tagdatas),
            currentScreen: this.selectedTemplate
        };
        if (this.surveyObject) {
            this.customTagData.editId = this.surveyObject.id;
        }
        this.proposaltemplateservice.addOrEditCustomTags(this.customTagData).subscribe(function (data) {
            if (data) {
                _this.bsModalRef.hide();
                _this.proposaltemplateservice.getCustomTags(_this.selectedTemplateId, _this.selectedTemplate).subscribe(function (customTagDatas) {
                    if (customTagDatas) {
                        _this.allcustomTags = customTagDatas;
                        var modalCloseData = { id: data, allDatas: customTagDatas };
                        _this.onClose.next(modalCloseData);
                    }
                });
            }
        });
    };
    ImageBlocksComponent.prototype.imageNameParsing = function (filename, type) {
        if (type === 'part') {
            var firstUnderscoreIndex = filename.indexOf('_');
            if (firstUnderscoreIndex !== -1) {
                var parsedFileName = filename.substring(firstUnderscoreIndex + 1);
                return parsedFileName.split('.')[0];
            }
            else {
                return filename;
            }
        }
        else {
            if (filename.includes('.')) {
                return filename.split('.')[0];
            }
            else {
                return filename;
            }
        }
    };
    ImageBlocksComponent.prototype.selectImage = function (event, imageURL) {
        this.fileErrorMsg = false;
        this.uploading = false;
        this.uploadErrorMsg = false;
        var isCtrlPressed = event.ctrlKey || event.metaKey;
        if (!isCtrlPressed || this.currentImageCount === 0) {
            this.selectedImages = [];
            if (this.selectedImages.length < this.currentImageCount || this.currentImageCount === 0) {
                this.selectedImages.push(imageURL);
                this.imgURL = event.currentTarget.firstChild.src;
                this.croppedImage = this.imgURL;
                this.multipleImageSelected = false;
            }
        }
        else {
            var index = this.selectedImages.indexOf(imageURL);
            if (index !== -1) {
                if (this.selectedImages.length === 1 && imageURL === this.selectedImages[0]) {
                    this.multipleImageSelected = false;
                    return;
                }
                else {
                    this.selectedImages.splice(index, 1);
                    if (this.selectedImages.length > 1) {
                        this.multipleImageSelected = true;
                    }
                    else {
                        this.multipleImageSelected = false;
                    }
                }
            }
            else {
                if ((this.selectedImages.length + 1) <= this.currentImageCount) {
                    this.imgURL = event.currentTarget.firstChild.src;
                    this.croppedImage = this.imgURL;
                    this.selectedImages.push(imageURL);
                    if (this.selectedImages.length > 1) {
                        this.multipleImageSelected = true;
                    }
                    else {
                        this.multipleImageSelected = false;
                    }
                }
            }
        }
        this.imgURLs = this.selectedImages;
        this.convertImagesToBase64();
    };
    ImageBlocksComponent.prototype.convertImagesToBase64 = function () {
        var _this = this;
        var base64DataArray = [];
        var promises = this.selectedImages.map(function (url) {
            return new Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        var blob = xhr.response;
                        var pngBlob = new Blob([blob], { type: 'image/png' });
                        var reader_1 = new FileReader();
                        reader_1.readAsDataURL(pngBlob);
                        reader_1.onloadend = function () {
                            if (typeof reader_1.result === 'string') {
                                base64DataArray.push(reader_1.result);
                                resolve();
                            }
                            else {
                                reject(new Error('Failed to convert image to base64'));
                            }
                        };
                    }
                    else {
                        reject(new Error("Failed to fetch image from " + url));
                    }
                };
                xhr.onerror = function () {
                    reject(new Error("Failed to fetch image from " + url));
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            });
        });
        Promise.all(promises)
            .then(function () {
            _this.imgURLs = base64DataArray;
            _this.croppedImage = base64DataArray[0];
        })
            .catch(function (error) {
            console.error('Error:', error);
        });
    };
    ImageBlocksComponent.prototype.isSelected = function (imageURL) {
        return this.selectedImages.includes(imageURL);
    };
    ImageBlocksComponent.prototype.removeSelectedImage = function () {
        var that = this;
        this.confirmationBoxHelper.getConfirmation("Are you sure you want to remove this image ?", this.ajsScope)
            .then(function () {
            that.selectedImages = [];
            that.croppedImage = '';
            that.imgURLs = [];
            that.disabled = true;
            that.imgURL = '';
            setTimeout(function () {
                that.dropZoneTopHeight = document.getElementById('drop-zone').offsetTop;
            }, 1500);
        });
    };
    ImageBlocksComponent.prototype.showCrop = function (value) {
        var _this = this;
        this.showCropper = value;
        this.showCropperLoading = true;
        if (this.croppedImage.startsWith('data:image')) {
            var img_1 = new Image();
            img_1.onload = function () {
                _this.defaultHeight = img_1.height;
                _this.defaultWidth = img_1.width;
                _this.defaultAspectRatio = _this.defaultWidth / _this.defaultHeight;
                _this.adjustedWidth = _this.defaultWidth;
                _this.adjustedHeight = _this.defaultHeight;
                _this.cropperAspectRatio = _this.defaultAspectRatio;
                _this.imgURL = _this.croppedImage;
            };
            img_1.onerror = function (error) {
                console.error('Error loading image:', error);
            };
            img_1.src = this.croppedImage;
        }
        else {
            var img_2 = new Image();
            img_2.src = this.croppedImage;
            img_2.onload = function () {
                _this.defaultHeight = img_2.width;
                _this.defaultWidth = img_2.height;
                _this.defaultAspectRatio = _this.defaultWidth / _this.defaultHeight;
                _this.adjustedWidth = _this.defaultWidth;
                _this.adjustedHeight = _this.defaultHeight;
                _this.cropperAspectRatio = _this.defaultAspectRatio;
            };
            img_2.onerror = function (error) {
                console.error('Error loading image:', error);
            };
        }
    };
    ImageBlocksComponent.prototype.closeCropper = function () {
        this.showCropper = false;
        this.previewImageCropper = false;
    };
    ImageBlocksComponent.prototype.cancelCropper = function () {
        this.showCropper = false;
        this.previewImageCropper = false;
        this.imgURLs[0] = this.imgURL;
        this.croppedImage = this.imgURL;
        this.selectedAspectRatio = 'freeform';
    };
    ImageBlocksComponent.prototype.imageCropped = function (event) {
        this.partUrl = true;
        this.croppedImage = event.base64;
        this.adjustedWidth = event.width;
        this.adjustedHeight = event.height;
        this.cropperProperties = event.cropperPosition;
        this.cropperHeightDifference = parseFloat(((this.cropperProperties.y2 - this.cropperProperties.y1) / this.adjustedHeight).toFixed(4));
        this.cropperWidthDifference = parseFloat(((this.cropperProperties.x2 - this.cropperProperties.x1) / this.adjustedWidth).toFixed(4));
        this.currentAspectRatio = this.adjustedWidth / this.adjustedHeight;
        this.imgURLs[0] = this.croppedImage;
        if (this.aspectRatioChanged) {
            this.updateCropper();
        }
        this.showCropperLoading = false;
    };
    ImageBlocksComponent.prototype.onLockAspectRatioChange = function () {
        if (!this.lockAspectRatio && this.selectedAspectRatio != 'freefrom') {
            this.updateAspectRatio('freeform');
        }
        else {
            this.aspectRatioChanged = true;
            this.maintainAspectRatio = true;
            this.lockAspectRatio = true;
            this.cropperAspectRatio = this.adjustedWidth / this.adjustedHeight;
            if (this.cropperAspectRatio >= 1) {
                this.maxWidth = Math.round(this.defaultHeight * this.cropperAspectRatio);
                this.maxheight = Math.round(this.defaultWidth / this.cropperAspectRatio);
            }
            else {
                this.maxWidth = Math.round(this.defaultHeight / this.cropperAspectRatio);
                this.maxheight = Math.round(this.defaultWidth * this.cropperAspectRatio);
            }
        }
    };
    ImageBlocksComponent.prototype.updateAspectRatio = function (selectedAspectRatio) {
        this.aspectRatioChanged = true;
        this.selectedAspectRatio = selectedAspectRatio;
        this.maintainAspectRatio = true;
        this.lockAspectRatio = true;
        this.maxWidth = this.defaultWidth;
        if (selectedAspectRatio === 'freeform') {
            this.maintainAspectRatio = false;
            this.lockAspectRatio = false;
            this.adjustedWidth = this.defaultWidth;
            this.adjustedHeight = this.defaultHeight;
            this.maxheight = this.defaultHeight;
            this.updateCropper();
        }
        else if (selectedAspectRatio === 'Square') {
            this.cropperAspectRatio = 1 / 1;
            if (this.defaultWidth >= this.defaultHeight) {
                this.adjustedWidth = this.adjustedHeight = this.defaultHeight;
            }
            else {
                this.adjustedWidth = this.adjustedHeight = this.defaultWidth;
            }
            this.maxheight = this.maxWidth = this.adjustedWidth;
            this.updateCropper();
        }
        else if (selectedAspectRatio === 'Monitor') {
            this.cropperAspectRatio = 4 / 3;
            var maxWidth = Math.min(this.defaultWidth, (4 / 3) * this.defaultHeight);
            var maxHeight = Math.min(this.defaultHeight, (3 / 4) * this.defaultWidth);
            // Ensure that the calculated dimensions fit within the original image dimensions
            if (Math.round((maxWidth / 4) * 3) >= this.defaultHeight) {
                maxWidth = (4 / 3) * this.defaultHeight;
            }
            else {
                maxHeight = (4 / 3) * this.defaultWidth;
            }
            this.adjustedWidth = this.maxWidth = Math.round(maxWidth);
            this.adjustedHeight = this.maxheight = Math.round(maxHeight);
            this.updateCropper();
        }
        else if (selectedAspectRatio === 'originalRatio') {
            this.cropperAspectRatio = this.defaultAspectRatio;
            this.adjustedWidth = this.defaultWidth;
            this.adjustedHeight = this.defaultHeight;
            if (this.cropperAspectRatio >= 1) {
                this.maxheight = Math.round(this.adjustedWidth * this.cropperAspectRatio);
            }
            else {
                this.maxheight = Math.round(this.adjustedWidth / this.cropperAspectRatio);
            }
            if (this.adjustedHeight <= this.adjustedWidth) {
                this.onHeightChange();
            }
            else {
                this.onWidthChange();
            }
        }
        this.cdRef.detectChanges();
    };
    ImageBlocksComponent.prototype.onWidthChange = function () {
        if (this.lockAspectRatio) {
            if (this.selectedAspectRatio === 'Monitor') {
                this.adjustedHeight = Math.round((this.adjustedWidth / 4) * 3);
            }
            else if (this.selectedAspectRatio === 'Square') {
                if (this.adjustedWidth >= this.maxWidth) {
                    this.adjustedWidth = this.maxWidth;
                }
                this.adjustedHeight = this.adjustedWidth;
            }
            else {
                if (this.cropperAspectRatio <= 1 || this.adjustedWidth < this.maxheight) {
                    this.adjustedHeight = Math.round(this.adjustedWidth / this.cropperAspectRatio);
                }
                else {
                    this.adjustedHeight = Math.round(this.adjustedWidth * this.cropperAspectRatio);
                }
            }
            this.preventOverflow();
        }
        else {
            this.preventLimitsWidth();
        }
        this.cdRef.detectChanges();
    };
    ImageBlocksComponent.prototype.onHeightChange = function () {
        if (this.lockAspectRatio) {
            if (this.selectedAspectRatio == 'Monitor') {
                this.adjustedWidth = Math.round((this.adjustedHeight / 3) * 4);
            }
            else if (this.selectedAspectRatio == 'Square') {
                if (this.adjustedHeight >= this.maxheight) {
                    this.adjustedHeight = this.maxheight;
                }
                this.adjustedWidth = this.adjustedHeight;
            }
            else {
                if (this.cropperAspectRatio <= 1 || this.adjustedHeight < this.maxheight) {
                    this.adjustedWidth = Math.round(this.adjustedHeight * this.cropperAspectRatio);
                }
                else {
                    this.adjustedWidth = Math.round(this.adjustedHeight / this.cropperAspectRatio);
                }
            }
            this.preventOverflow();
        }
        else {
            this.preventLimitsHeight();
        }
        this.cdRef.detectChanges();
    };
    ImageBlocksComponent.prototype.preventOverflow = function () {
        if (this.adjustedWidth >= this.maxWidth) {
            this.adjustedWidth = this.maxWidth;
            this.adjustedHeight = this.maxheight;
        }
        else if (this.adjustedHeight > this.maxheight) {
            this.adjustedWidth = this.maxWidth;
            this.adjustedHeight = this.maxheight;
        }
        this.updateCropper();
    };
    ImageBlocksComponent.prototype.preventLimitsWidth = function () {
        if (this.adjustedWidth >= this.maxWidth) {
            this.adjustedWidth = this.maxWidth;
        }
        this.updateCropper();
    };
    ImageBlocksComponent.prototype.preventLimitsHeight = function () {
        if (this.adjustedHeight > this.maxheight) {
            this.adjustedHeight = this.maxheight;
        }
        this.updateCropper();
    };
    ImageBlocksComponent.prototype.updateCropper = function () {
        this.aspectRatioChanged = false;
        if (this.adjustedWidth >= 50) {
            this.adjustedWidth = this.adjustedWidth;
        }
        else {
            this.adjustedWidth = 50;
            this.onWidthChange();
            return;
        }
        if (this.adjustedHeight >= 50) {
            this.adjustedHeight = this.adjustedHeight;
        }
        else {
            this.adjustedHeight = 50;
            this.onHeightChange();
            return;
        }
        this.cropper = {
            x1: 0,
            y1: 0,
            x2: this.adjustedWidth * this.cropperWidthDifference,
            y2: this.adjustedHeight * this.cropperHeightDifference
        };
    };
    ImageBlocksComponent.prototype.validateWidth = function (event) {
        var _this = this;
        var input = event.target;
        if (!this.isValidInput(event) || input.value.length >= 10) {
            event.preventDefault();
        }
        clearTimeout(this.inputTimeout);
        setTimeout(function () {
            _this.adjustedWidth = parseInt(input.value, 10);
            // this.cdRef.detectChanges();  // Ensure Angular knows about the changes}, 0);
        });
    };
    ImageBlocksComponent.prototype.validateHeight = function (event) {
        var _this = this;
        var input = event.target;
        if (!this.isValidInput(event) || input.value.length >= 10) {
            event.preventDefault();
        }
        clearTimeout(this.inputTimeout);
        setTimeout(function () {
            _this.adjustedHeight = parseInt(input.value, 10);
            // this.cdRef.detectChanges();  // Ensure Angular knows about the changes}, 0);
        });
    };
    ImageBlocksComponent.prototype.onBlur = function (type) {
        if (type === 'width') {
            this.onWidthChange();
        }
        else if (type === 'height') {
            this.onHeightChange();
        }
        this.cdRef.detectChanges();
    };
    ImageBlocksComponent.prototype.isValidInput = function (event) {
        var allowedKeys = [
            'Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
            'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4',
            'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
        ];
        return allowedKeys.includes(event.key) || (event.key.length === 1 && event.key >= '0' && event.key <= '9');
    };
    ImageBlocksComponent.prototype.preventWheel = function (event) {
        event.preventDefault();
    };
    ImageBlocksComponent.prototype.searchFiles = function () {
        this.editImageLoading = true;
        var searchWords = this.searchText.toLowerCase().split(' ');
        if (this.searchText.trim() === '' || searchWords) {
            this.filteredActivityFiles = this.customerImages ? this.customerImages['activityFiles'] : [];
            this.filteredTemplateFiles = this.customerImages ? this.customerImages['templateFiles'] : [];
            this.filteredCustomerFiles = this.customerImages ? this.customerImages['customerFiles'] : [];
            this.filteredSurveyFiles = this.customerImages ? this.customerImages['surveyFiles'] : [];
            this.filteredPartList = this.partsImageList ? this.partsImageList : [];
            this.filteredCategoryImageList = this.categoryImageList ? this.categoryImageList : [];
            // this.editImageLoading = false;
        }
        if (this.filteredActivityFiles.length > 0) {
            this.filteredActivityFiles = this.customerImages['activityFiles'].filter(function (file) {
                return searchWords.every(function (word) { return file.name.toLowerCase().includes(word); });
            });
        }
        if (this.filteredCustomerFiles.length > 0) {
            this.filteredCustomerFiles = this.customerImages['customerFiles'].filter(function (file) {
                return searchWords.every(function (word) { return file.name.toLowerCase().includes(word); });
            });
        }
        if (this.filteredPartList.length > 0) {
            this.filteredPartList = this.partsImageList.filter(function (file) {
                return searchWords.every(function (word) { return file.name.toLowerCase().includes(word); });
            });
        }
        if (this.filteredSurveyFiles.length > 0) {
            this.filteredSurveyFiles = this.customerImages['surveyFiles'].filter(function (file) {
                return searchWords.every(function (word) { return file.name.toLowerCase().includes(word); });
            });
        }
        if (this.filteredTemplateFiles.length > 0) {
            this.filteredTemplateFiles = this.customerImages['templateFiles'].filter(function (file) {
                return searchWords.every(function (word) { return file.name.toLowerCase().includes(word); });
            });
        }
        if (this.filteredCategoryImageList.length > 0) {
            this.filteredCategoryImageList = this.categoryImageList.filter(function (file) {
                return searchWords.every(function (word) { return file.name.toLowerCase().includes(word); });
            });
        }
        this.editImageLoading = false;
    };
    // Drag and upload File
    ImageBlocksComponent.prototype.uploadFile = function (event) {
        var _this = this;
        this.selectedImages = [];
        var files = (event.type === 'change' && event.isTrusted) ? event.target.files : event;
        var originalFormatData = [];
        this.fileErrorMsg = false;
        this.uploadErrorMsg = false;
        this.correctSizeAndFormat = [];
        this.wrongSizeAndFormat = [];
        this.noOfImagesSelected = files.length;
        this.croppedImage = '';
        this.imgURLs = [];
        if (this.option == 'pricingImage') {
            this.maximumCount = 3 - this.imagesCount;
        }
        else {
            this.maximumCount = 1;
        }
        if (files.length > this.maximumCount) {
            this.uploadErrorMsg = true;
        }
        else {
            this.uploading = true;
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var checkMaxFileSize = (file.size / 1024 / 1024).toFixed(2);
                var fileType = this.ts.getFileGroup(file.name.substr(file.name.lastIndexOf('.') + 1));
                if (parseInt(checkMaxFileSize) <= 30 && fileType === 'image') {
                    originalFormatData.push(file);
                    this.correctSizeAndFormat.push({
                        'name': file.name,
                        'size': (file.size / 1024).toFixed(2),
                        'type': fileType
                    });
                }
                else {
                    this.wrongSizeAndFormat.push({
                        'name': file.name,
                        'size': (file.size / 1024).toFixed(2),
                        'type': fileType
                    });
                }
            }
            if (this.correctSizeAndFormat.length) {
                setTimeout(function () {
                    var _loop_1 = function (i) {
                        var file = originalFormatData[i];
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function (_event) {
                            var imgBase = reader.result;
                            _this.imgURLs.push(imgBase);
                            _this.croppedImage = _this.imgURLs[0];
                        };
                    };
                    for (var i = 0; i < originalFormatData.length; i++) {
                        _loop_1(i);
                    }
                    _this.croppedImage = _this.imgURLs;
                    _this.uploading = false;
                    _this.disabled = false;
                    _this.selectedImages = _this.imgURLs;
                    if (originalFormatData.length > 1) {
                        _this.multipleImageSelected = true;
                    }
                    else {
                        _this.multipleImageSelected = false;
                    }
                }, 3000);
            }
            else {
                if (this.wrongSizeAndFormat && this.wrongSizeAndFormat.length) {
                    this.fileErrorMsg = true;
                    this.backgroundImgfileSelected = false;
                    this.uploading = false;
                }
            }
        }
    };
    ImageBlocksComponent.prototype.imageLoaded = function () {
        var _this = this;
        setTimeout(function () {
            _this.cropper = {
                x1: 0,
                y1: 0,
                x2: _this.adjustedWidth,
                y2: _this.adjustedHeight,
            };
        }, 2);
        this.showCropperLoading = false;
    };
    ImageBlocksComponent.prototype.loadImageFailed = function () {
        this.fileErrorMsg = true;
    };
    ImageBlocksComponent.prototype.enableImagePreview = function () {
        if (this.croppedImagePreview) {
            this.croppedImagePreview = false;
        }
        else {
            this.croppedImagePreview = true;
        }
    };
    ImageBlocksComponent.prototype.handleDragEvents = function (element) {
        element.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
        element.on('dragover dragenter', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!element.hasClass('blur')) {
                element.find('.upload-popover').removeClass('display-none');
                element.find('.ano').addClass('blur');
            }
        });
        element.on('dragleave', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!element.has(e.relatedTarget).length) {
                element.find('.upload-popover').addClass('display-none');
                element.find('.ano').removeClass('blur');
            }
        });
        element.on('drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
            element.find('.pay-in-full').addClass('clickable');
            element.find('.upload-popover').addClass('display-none');
            element.find('.ano').removeClass('blur');
        });
    };
    ImageBlocksComponent.prototype.ngAfterViewInit = function () {
        this.handleDragEvents($('#drop-zone'));
    };
    return ImageBlocksComponent;
}());
export { ImageBlocksComponent };
