<div class="modal-header">
    <h4 class="modal-title pull-left">{{blockData['title']}}</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <form class="no-form-style" *ngIf="form" [formGroup]="form" novalidate>
        <div class="cs-d-flex flex-column h-100">
            <div class="cs-helper-section bg-white cs-border cs-border--bottom">
                <div class="cs-help-text-color p--15">{{blockData['description']}}</div>
            </div>
            <div class="cs-flex-1">
                <div class="cs-block-widget--form cs-d-flex flex-column h-100">
                    <div class="cs-block-widget--form-control">
                        <div class="cs-d-flex">
                            <label class="control-label required_field mr-auto" translate>Send sequence to:</label>
                        </div>
                        <div>
                            <select class="full-width-select2 cs-select2-wfe default-input-height"
                                    formControlName="contactId"
                                    csSelect2
                                    [select2Config]="{}">
                                <option value="" translate>Please.Choose</option>
                                <option *ngFor="let item of contactsList" [value]="item.id">{{item.text}}</option>
                            </select>
                        </div>
                    </div>

                    <hr style="float: none;" class="m-b--0">
                    <div class="cs-block-widget--form-control">
                        <div class="cs-d-flex">
                            <label class="control-label required_field mr-auto">{{blockData['inputLabelText']}}:</label>
                        </div>
                        <div>
                            <select class="full-width-select2 cs-select2-wfe default-input-height"
                                    (onChange)="onChangeSequence($event)"
                                    csSelect2
                                    [dropdownvalue]="inputModel.templateId"
                                    [select2Config]="{}">
                                <option value="" translate>Please.Choose</option>
                                <option *ngFor="let item of sequenceListData" [value]="item.id">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="cs-block-widget--form-control cs-flex-1">
                        <div>
                            <div class="cs-p3 height-100 p-t--0" *ngIf="activeDagSource.length || onChangeSequenceLoading; else emptyMessage">
                                <ng-container *ngIf="!onChangeSequenceLoading; else: sequenceLoading">
                                    <div class="cs-d-flex cs-py1 text-truncate" *ngFor="let block of activeDagSource">
                                        <div class="cs-mr2"><i class="{{displayBlocks[block.name].iconClass}}"></i></div>
                                        <div class="text-truncate">{{displayBlocks[block.name].text}}:  {{getDisplayText(block)}}</div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!isLoading else footerSection">
        <button *ngIf="showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="remove()" type="button">Delete </button>
        <button class="btn btn-link" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!form.valid || isFormSubmitted" (click)="save()">
            <span *ngIf="!isFormSubmitted" translate>Save</span>
            <span *ngIf="isFormSubmitted" translate>Saving</span>
        </button>
    </ng-container>
</div>

<ng-template #footerSection>
    <button class="btn btn-link" (click)="closeModal()">Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="true">
        <span translate>Save</span>
    </button>
</ng-template>

<ng-template #emptyMessage>
    <div class="empty-message-container m-t--20 cs-d-flex justify-content-center flex-column text-center cs-d-flex-wrap height-100 pos-rlt">
        <div class="empty-message-icon">
            <i class="fas fa-sitemap"></i>
        </div>
        <div class="empty-message-content">
            Choose a sequence template to preview what will get sent
        </div>
    </div>
</ng-template>

<ng-template #sequenceLoading>
    <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0">
        <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
            <div class="ph-row align-items-center">
                <div class="ph-col-8 mb3"></div>
                <div class="ph-col-10 mb3"></div>
                <div class="ph-col-6 mb3"></div>
                <div class="ph-col-12 mb3"></div>
                <div class="ph-col-8 mb3"></div>
                <div class="ph-col-6 mb3"></div>
                <div class="ph-col-8 mb3"></div>
            </div>
        </div>
    </div>
</ng-template>
