<div class="modal-header">
    <span class="title-heading" translate>File </span>
    <i class="font-size14 title-name-style" *ngIf="createdDetails">- {{ clone?.fileName.length >= 10 ? clone?.fileName.substring(0,10)+"..." :
            clone?.fileName }}</i>
    <i class="font-size14 title-name-style" *ngIf="!createdDetails">- {{ clone?.fileName.length >= 40 ? clone?.fileName.substring(0,40)+"..." :
        clone?.fileName }}</i>
    <span class="right-side font-size14 created-details-style" *ngIf="createdDetails">
        <strong translate>Added </strong>
        <span>{{clone.createdDateTime}}</span>
        <span (click)="attachFileDownload(clone?.fileLocation)">
            <i class="fa fa-download attach-download-icon-size icon-style" aria-hidden="true"></i>
        </span>
        <!-- <a href="{{ pdf_url }}?watermark=0" class="ss-download"  target="_blank"></a> -->
    </span>
</div>
<div class="modal-body" [ngSwitch]="clone.fileGroup">
	<span *ngSwitchCase="'image'">
		<img class="img-config" src="{{ clone?.fileLocation}}">
	</span>
    <span *ngSwitchCase="'pdf'">
		<iframe class="pdf-width" name="{{ clone?.fileName}}" [src]="Url"></iframe>
	</span>
    <span *ngSwitchCase="'audio'">
		<audio controls src="{{ clone?.fileLocation}}"></audio>
	</span>
    <span *ngSwitchCase="'video'">
		<video class="video-width" src="{{ clone?.fileLocation }}" controls autoplay
               loop></video>
	</span>
    <ng-container *ngSwitchDefault>
        <div class="warning-icon-wrap with-info-icon">
            <span class="ss-info icon fix-left"></span>
        </div>
        <div class="warning-message ng-binding text-align-center" translate>
            No.File.Preview.Available
        </div>
    </ng-container>
</div>
<!-- Footer -->
<div class="modal-footer">
    <a class="btn" (click)="bsModalRef.hide()">Cancel</a>
</div>
