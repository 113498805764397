import {Component, OnInit, ViewEncapsulation, NgZone, ChangeDetectorRef, ViewChild, HostListener, ElementRef, Inject} from '@angular/core';
import { Subject } from 'rxjs/index';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap';
import { ProposalTemplateService } from '@app/features/system-settings/proposal-template/services/proposal-template.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BlockSettingsModalComponent } from '../block-settings-modal/block-settings-modal.component';
import {AuthService} from '@app/core';
import {TimelineService} from '@app/shared/services/timeline.service';


declare var $: any;
@Component({
	selector: 'app-image-blocks',
	templateUrl: './image-blocks.component.html',
	styleUrls: ['./image-blocks.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ImageBlocksComponent implements OnInit {
	@ViewChild('select2Question') select2QuestionElement: ElementRef<HTMLElement>;
	@ViewChild('select2Template') select2TemplateElement: ElementRef<HTMLElement>;
	public onClose: Subject<any>;
	title: string;
	helpText: string;
	open: boolean;
	errorMsg = '';
	imagePath: any;
	imgURL:string = '';
	option: any;
	selectedTemplate: any;
	templateImages: any = [];
	customerImages: any;
	croppedImage: any = '';
	page_id: any;
	pricingId: any;
	partId: any;
	pageBackground: any = 'color';
	backgroundColor: any;
	backgroundColorDisplay: any = '#ffffff';
	backgroundImg: any;
	imageSelectStatus = false;
    saving = false;
	color_options = {
		type: 'component',
		showPalette: false,
		hideAfterPaletteSelect: true,
		showButtons: false,
		allowEmpty: false,
		preferredFormat: 'hex',
		replacerClassName: 'format-replace',
		containerClassName: 'container-palette'
	};
	filename: any = '';
	public uploader: FileUploader;
	partsImageList: any[] = [];
    categoryImageList: any[] = [];
	checked: any;
	partUrl = false;
	imageblock = false;
	disabled = false;
	restoreBgColor: any = '';
    previewImage = false;
    pageBackgroundColor: any = 'No background';
    myValue: any;
    editImageLoading = false;
    @ViewChild(BlockSettingsModalComponent) blockSettingModal;
    imageArrayList: any[] = [];
    imageIndex: any;
    imgLocation: any;
    isUploadPdfPage = false;
    pageType: any;
    selectedOption = 'uploadImage';
    selectedTemplateId: number;
    questionOptions: any[];
    questionSelected: boolean;
    formData: any;
    surveyQuestionsData: any;
    surveyOptionConfig: { questionLabel: any; questionId: any; answerType: any; file: boolean; };
    dropdownOptions: any;
    questionData: any;
    selectedQuestionId: any;
    data: any;
    customTagData: any;
    currentTemplateId: any;
    tagdatas: any;
    allcustomTags: any;
	surveyObject: any;
    customTagAccess = false;
    selectedImages: string[] = []; // Array to store selected image URLs
    currentImageCount: any;
    showCropper: boolean = false;
    defaultWidth: number;
    defaultHeight: number;
    defaultAspectRatio: number;
    opportunityProposalTemplateId: number;
    aspectRatioOptions: { value: string; label: string }[] = [
        { value: 'freeform', label: 'Freeform' },
        { value: 'originalRatio', label: 'Originalratio' },
        { value: 'Square', label: 'Square 1:1' },
        { value: 'Monitor', label: 'Monitor 4:3' },

    ];
    selectedAspectRatio = 'freeform'; // Variable to store the selected aspect ratio
    cropperAspectRatio: any;
    maintainAspectRatio: boolean = false;
    imagesCount: any;
    searchText = ''; // Variable to store the search text
    filteredActivityFiles: any[] = []; // Array to store filtered activity files
    filteredCustomerFiles: any[] = []; // Array to store filtered customer files
    filteredTemplateFiles: any[] = []; // Array to store filtered template files
    filteredPartList: any[] = []; // Array to store filtered template files
    filteredSurveyFiles: any[] = [];
    filteredCategoryImageList: any[] = [];
    lockAspectRatio: boolean = false;
    uploading: boolean;
    fileErrorMsg: boolean;
    wrongSizeAndFormat: any[];
    noOfImagesSelected: any;
    backgroundImgfileSelected: boolean;
    imgURLs: any[] = [];
    correctSizeAndFormat: any;
    previewImageCropper: boolean;
    freeformRatio = false;
    currentAspectRatio: number;
    cropper = {
        x1: 0,
        y1: 0,
        x2: 100,
        y2: 100,
    };
    @ViewChild('imageElement') imageElement: ElementRef;
    maxheight: number;
    maxWidth: number;
    croppedImagePreview: boolean;
    adjustedWidth: number = 0; // New property for adjusted width
    adjustedHeight: number = 0;
    showIcon: boolean;
    cropperProperties: any;
    cropperHeightDifference: number;
    cropperWidthDifference: number;
    preview: boolean;
    showCropperLoading: boolean;
    aspectRatioChanged: any;
    isCustomerImagesEmpty: boolean = true;
    dropZoneTopHeight: number;
    private inputTimeout: any;
    multipleImageSelected: boolean = false;
    maximumCount: number;
    uploadErrorMsg: boolean;
    backgroundImage: boolean = false;


    constructor(public bsModalRef: BsModalRef,
        public proposaltemplateservice: ProposalTemplateService,
        private authService: AuthService,
        private ts: TimelineService,
        private ngZone: NgZone,
        private cdRef: ChangeDetectorRef,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope) {

	}

    ngOnInit() {
        this.editImageLoading = true;
        this.customTagAccess = this.authService.permitted(['CustomTags.writeaccess']) === true;
        if (this.customTagAccess) {
            this.proposaltemplateservice.getSurveyTemplateList().subscribe(values => {
                if (values) {
                    this.surveyQuestionsData = values['leaveJobQuestion'];
                    this.surveyQuestionsData = this.surveyQuestionsData.filter((template) => template.JsonData !== null && (template.JsonData.includes('Photo') || template.JsonData.includes('Signature')));
                    if (this.surveyObject) {
                        this.selectedOption = 'survey';
                        this.selectedTemplateId = this.surveyObject.templateId;
                        this.onTemplateChange(this.selectedTemplateId);
                        this.selectedQuestionId = this.surveyObject.questionId;
                        setTimeout(() => {
                            $(this.select2TemplateElement.nativeElement).select2('val', this.selectedTemplateId);
                            $(this.select2QuestionElement.nativeElement).select2('val', this.selectedQuestionId);
                        }, 200);
                    }
                }
            });
        }
        this.onClose = new Subject();
        this.uploader = new FileUploader({
            url: ``,
            autoUpload: false,
            itemAlias: 'file',
            allowedMimeType: ['image/png', 'image/jpeg'],
            disableMultipart: true
        });
        if (this.option == 'pricingImage') {
            this.imagesCount = 3 - this.currentImageCount;
        } else {
            this.imagesCount = 1;
        }
        if (this.option == 'previewImage') {
            this.imgLocation = this.imgLocation;
            this.open = true;
            this.previewImage = true;
            this.imageArrayList = this.imageArrayList;
        }
        if (this.option == 'edit' || this.option == 'fitToBlock') {
            this.editImageLoading = true;
            let image;
            image = $('#cs_editor').contents().find('div.cs_page').find('.cs_selected').find('img');
            if (image) {
                this.imgURL = image.attr('src');
                this.croppedImage = this.imgURL;
                this.editImageLoading = false;
                this.selectedImages.push(this.imgURL);
                this.convertImagesToBase64();

            }
        }

        this.proposaltemplateservice.getPartsListImage(this.selectedTemplate, this.currentTemplateId, this.opportunityProposalTemplateId).subscribe(
            res => {
                if (res) {
                    // this.partsImageList = [];
                    this.partsImageList = res['data'] ? res['data']['partsImages'] : [];
                    this.customerImages = res['data'] ? res['data']['files'] : {};
                    this.categoryImageList = res['data'] ? res['data']['categoryImages'] : [];

                    // parsing incoming data to make filters
                    this.filteredPartList = this.partsImageList ? this.partsImageList : [];
                    this.filteredActivityFiles = this.customerImages ? this.customerImages['activityFiles'] : [];
                    this.filteredTemplateFiles = this.customerImages ? this.customerImages['templateFiles'] : [];
                    this.filteredCustomerFiles = this.customerImages ? this.customerImages['customerFiles'] : [];
                    this.filteredSurveyFiles = this.customerImages ? this.customerImages['surveyFiles'] : [];
                    this.filteredCategoryImageList = this.categoryImageList ? this.categoryImageList : [];
                }
                if (this.customerImages && this.customerImages['activityFiles'].length || this.customerImages['templateFiles'].length || this.customerImages['customerFiles'].length || this.customerImages['surveyFiles'].length) {
                    this.isCustomerImagesEmpty = false;
                }
                this.editImageLoading = false;
            }
        );

        //to get the top height of the drag-zone and set the top to newly implemented drag zone
        setTimeout(() => {
            this.dropZoneTopHeight = document.getElementById('drop-zone').offsetTop;
        }, 1500);

        $(':input[type=number]').on('wheel', function(e) { $(this).blur(); });
    }
	addImageBlock() {
		$('#block-background-color').spectrum('destroy');
		if (this.option == 'pricingImage') {
			if (this.checked) {
				this.open = false;
				this.partUrl = false;
				this.onClose.next(this.croppedImage);
				this.bsModalRef.hide();
			} else {
                this.disabled = true;
				this.proposaltemplateservice.addPricingImages(this.imgURLs, this.pricingId).subscribe(
					res => {
						if (res) {
							// this.croppedImage = res['file']['location'];
                            const imagesUploadList = res[0]['file'];
							const data = {
								'imagesList' : imagesUploadList,
							};
							this.open = false;
							this.partUrl = false;
							this.onClose.next(data);
                            this.disabled = true;
							this.bsModalRef.hide();
						}
					}
				);
			}
		}
        else {
			this.uploadImage();
		}
		$('#cs_editor').contents().find('div.cs_selected').find('.cs_margin').removeAttr('data-bgclr');
	}
	uploadImage(option = null) {
        this.disabled = true;
		this.proposaltemplateservice.addPageImages(this.croppedImage, this.page_id).subscribe(
			res => {
				if (res) {
					this.croppedImage = res['file']['location'];
					this.open = false;
					if (option) { this.onClose.next({ 'image': this.croppedImage }); } else { this.onClose.next(this.croppedImage); }
                    this.disabled = false;
					this.bsModalRef.hide();
                    this.proposaltemplateservice.setEditorThumbnail('imageBg');
				}
			}
		);
	}

	backTo() {
		this.option = 'pricingImage';
		this.imgURL = '';
	}

	cancel() {
		if (this.blockSettingModal) {
            this.blockSettingModal.cancel();
        }
        $('#block-background-color').spectrum('destroy');
		if (this.option == 'pageBackground' && (this.pageBackground == 'color' || this.pageBackground == 'image')) {
            const pageType = $('#cs_editor').contents().find(`div[data_id=` + this.page_id + `]`)[0];
            if (pageType.getAttribute('data-page-type') == 'EHP') {
                $('#cs_editor').contents().find('div.cs_selected').css('background-color', this.restoreBgColor);
            } else {
                $('#cs_editor').contents().find('div.cs_selected_border').css('background-color', this.restoreBgColor);
            }
			$('#cs_editor').contents().find('div.cs_selected').find('.cs_margin').removeAttr('old-bgclr');
		}
        this.bsModalRef.hide();
    }

    detectChanges(data) {
        const that = this;
        this.myValue = '';
        setTimeout(() => {
        that.myValue = data;
        }, 50);
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode == 39) { // right key
            this.fileRightArrow();
        } else if (event.keyCode == 37) { // left key
            this.fileLeftArrow();
        }
    }

    fileRightArrow() {
        if (this.imageArrayList.length > 0 && this.imageArrayList.length - 1 > this.imageIndex) {
            this.imageIndex++;
            this.selectedIndex(this.imageIndex);
        }
    }

    fileLeftArrow() {
        if (this.imageArrayList.length > 0 && this.imageIndex > 0) {
            this.imageIndex--;
            this.selectedIndex(this.imageIndex);
        }
    }

    selectedIndex(currentIndex) {
        if (this.imageArrayList[currentIndex]) {
            const findNextImage = this.imageArrayList[currentIndex];
            this.imgLocation = findNextImage;
            // this.clone['fileName'] = findNextImage;
        }
    }

    onTemplateChange(template: number) {
        this.questionOptions = [];
        this.questionSelected = true;
        this.selectedTemplateId = template;
		this.selectedQuestionId = null;
		if (this.select2QuestionElement) {
			$(this.select2QuestionElement.nativeElement).select2('val', this.selectedQuestionId);
		}
        const index = this.surveyQuestionsData.findIndex(question => question.id == template);
		if (index >= 0) {
			const jsonData = this.surveyQuestionsData[index]['JsonData'];
			const data = JSON.parse(jsonData);
			if (data) {
				data.pages.forEach(page => {
					page.sections.forEach(section => {
						section.fields.forEach(field => {
							if (field.fieldAnswerType == 'Photo' || field.fieldAnswerType == 'Signature') {
								this.surveyOptionConfig = {
									'questionLabel': field.fieldLabel,
									'questionId': field.questionID,
									'answerType': field.fieldAnswerType,
									'file': true
								};
								this.questionOptions.push(this.surveyOptionConfig);
							}
						});
					});
				});
			}
			this.questionData = this.questionOptions.find(dO => dO.questionId === this.data && this.data.questionId) || this.questionOptions[0];
		}
    }


    onQuestionSelect(questionId) {
        this.questionData = this.questionOptions.find(dO => dO.questionId === questionId);
    }

    onOptionChange(option: string) {
        this.selectedOption = option;
    }

    addSurveyImage() {
        this.tagdatas = {
            name : this.questionData.questionLabel ? this.questionData.questionLabel : null,
            tagCompletedBy : this.selectedOption,
            templateId : this.selectedTemplateId,
            questionId : this.questionData.questionId ? this.questionData.questionId : null,
            answerType : this.questionData.answerType ? this.questionData.answerType : null,
        };

        this.customTagData = {
            tablePkId : this.currentTemplateId,
            tagData : JSON.stringify(this.tagdatas),
            currentScreen: this.selectedTemplate
        };
        if (this.surveyObject) {
            this.customTagData.editId = this.surveyObject.id;
        }
        this.proposaltemplateservice.addOrEditCustomTags(this.customTagData).subscribe(data => {
            if (data) {
                this.bsModalRef.hide();
                this.proposaltemplateservice.getCustomTags(this.selectedTemplateId, this.selectedTemplate).subscribe(customTagDatas => {
                    if (customTagDatas) {
                        this.allcustomTags = customTagDatas;
                        const modalCloseData: any = {id: data, allDatas: customTagDatas};
                        this.onClose.next(modalCloseData);
                    }
                });
            }
        });
    }

    imageNameParsing(filename: string, type: string ): string {
           if (type === 'part') {
               const firstUnderscoreIndex = filename.indexOf('_');
               if (firstUnderscoreIndex !== -1) {
                      const parsedFileName = filename.substring(firstUnderscoreIndex + 1);
                      return parsedFileName.split('.')[0];
               } else {
                      return filename;
               }
           } else {
              if (filename.includes('.')) {
                      return filename.split('.')[0];
              } else {
                      return filename;
              }
           }
    }

    selectImage(event: any, imageURL: string): void {
      this.fileErrorMsg = false;
      this.uploading = false;
      this.uploadErrorMsg = false;
      const isCtrlPressed = event.ctrlKey || event.metaKey;
      if ( !isCtrlPressed || this.currentImageCount === 0 ) {
            this.selectedImages = [];
            if (this.selectedImages.length < this.currentImageCount || this.currentImageCount === 0) {
              this.selectedImages.push(imageURL);
              this.imgURL = event.currentTarget.firstChild.src;
              this.croppedImage = this.imgURL;
              this.multipleImageSelected = false;
            }
      } else {
          const index = this.selectedImages.indexOf(imageURL);
          if (index !== -1) {
              if (this.selectedImages.length === 1 && imageURL === this.selectedImages[0]) {
                  this.multipleImageSelected = false;
                  return;
              } else {
                  this.selectedImages.splice(index, 1);
                  if (this.selectedImages.length > 1) {
                      this.multipleImageSelected = true;
                  } else {
                      this.multipleImageSelected = false;
                  }
              }
          } else {
              if ((this.selectedImages.length + 1) <= this.currentImageCount) {
                  this.imgURL = event.currentTarget.firstChild.src;
                  this.croppedImage = this.imgURL;
                  this.selectedImages.push(imageURL);
                  if (this.selectedImages.length > 1) {
                      this.multipleImageSelected = true;
                  } else {
                      this.multipleImageSelected = false;
                  }
              }
          }
      }
      this.imgURLs = this.selectedImages;
      this.convertImagesToBase64();
    }

    convertImagesToBase64(): void {
        const base64DataArray: string[] = [];
        const promises = this.selectedImages.map(url => {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const blob = xhr.response as Blob;
                        const pngBlob = new Blob([blob], { type: 'image/png' });
                        const reader = new FileReader();
                        reader.readAsDataURL(pngBlob);
                        reader.onloadend = () => {
                            if (typeof reader.result === 'string') {
                                base64DataArray.push(reader.result);
                                resolve();
                            } else {
                                reject(new Error('Failed to convert image to base64'));
                            }
                        };
                    } else {
                        reject(new Error(`Failed to fetch image from ${url}`));
                    }
                };
                xhr.onerror = function () {
                    reject(new Error(`Failed to fetch image from ${url}`));
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            });
        });

        Promise.all(promises)
            .then(() => {
                this.imgURLs = base64DataArray;
                this.croppedImage = base64DataArray[0];
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    isSelected(imageURL: string): boolean {
               return this.selectedImages.includes(imageURL);
    }

    removeSelectedImage() {
        const that = this;
        this.confirmationBoxHelper.getConfirmation(`Are you sure you want to remove this image ?`, this.ajsScope)
            .then(function () {
                that.selectedImages = [];
                that.croppedImage = '';
                that.imgURLs = [];
                that.disabled = true;
                that.imgURL = '';
                setTimeout(() => {
                    that.dropZoneTopHeight = document.getElementById('drop-zone').offsetTop;
                }, 1500);
            });
    }

    showCrop(value) {
        this.showCropper = value;
        this.showCropperLoading = true;
        if (this.croppedImage.startsWith('data:image')) {
            const img = new Image();
            img.onload = () => {
                this.defaultHeight = img.height;
                this.defaultWidth = img.width;
                this.defaultAspectRatio = this.defaultWidth / this.defaultHeight;
                this.adjustedWidth = this.defaultWidth;
                this.adjustedHeight = this.defaultHeight;
                this.cropperAspectRatio = this.defaultAspectRatio;
                this.imgURL = this.croppedImage;
            };
            img.onerror = (error) => {
                console.error('Error loading image:', error);
            };
            img.src = this.croppedImage;
        } else {
            const img = new Image();
            img.src = this.croppedImage;
            img.onload = () => {
                this.defaultHeight = img.width;
                this.defaultWidth = img.height;
                this.defaultAspectRatio = this.defaultWidth / this.defaultHeight;
                this.adjustedWidth = this.defaultWidth;
                this.adjustedHeight = this.defaultHeight;
                this.cropperAspectRatio = this.defaultAspectRatio;
            };
            img.onerror = (error) => {
                console.error('Error loading image:', error);
            };
        }
    }

    closeCropper() {
        this.showCropper = false;
        this.previewImageCropper = false;

    }

    cancelCropper() {
        this.showCropper = false;
        this.previewImageCropper = false;
        this.imgURLs[0] = this.imgURL;
        this.croppedImage = this.imgURL;
        this.selectedAspectRatio = 'freeform'
    }

    imageCropped(event: ImageCroppedEvent) {
        this.partUrl = true;
        this.croppedImage = event.base64;
        this.adjustedWidth = event.width;
        this.adjustedHeight = event.height;
        this.cropperProperties = event.cropperPosition;
        this.cropperHeightDifference = parseFloat(((this.cropperProperties.y2 - this.cropperProperties.y1) / this.adjustedHeight).toFixed(4));
        this.cropperWidthDifference = parseFloat(((this.cropperProperties.x2 - this.cropperProperties.x1) / this.adjustedWidth).toFixed(4));
        this.currentAspectRatio = this.adjustedWidth / this.adjustedHeight;
        this.imgURLs[0] = this.croppedImage;
        if (this.aspectRatioChanged) {
            this.updateCropper();
        }
        this.showCropperLoading = false;
    }

    onLockAspectRatioChange() {
        if (!this.lockAspectRatio && this.selectedAspectRatio != 'freefrom') {
            this.updateAspectRatio('freeform');
        } else {
            this.aspectRatioChanged = true;
            this.maintainAspectRatio = true;
            this.lockAspectRatio = true;
            this.cropperAspectRatio = this.adjustedWidth / this.adjustedHeight;
            if (this.cropperAspectRatio >= 1) {
                this.maxWidth = Math.round(this.defaultHeight * this.cropperAspectRatio);
                this.maxheight = Math.round(this.defaultWidth / this.cropperAspectRatio);
            } else {
                this.maxWidth = Math.round(this.defaultHeight / this.cropperAspectRatio);
                this.maxheight = Math.round(this.defaultWidth * this.cropperAspectRatio);
            }
        }
    }

    updateAspectRatio(selectedAspectRatio) {
        this.aspectRatioChanged = true;
        this.selectedAspectRatio = selectedAspectRatio;
        this.maintainAspectRatio = true;
        this.lockAspectRatio = true;
        this.maxWidth = this.defaultWidth;
        if (selectedAspectRatio === 'freeform') {
            this.maintainAspectRatio = false;
            this.lockAspectRatio = false;
            this.adjustedWidth = this.defaultWidth;
            this.adjustedHeight = this.defaultHeight;
            this.maxheight = this.defaultHeight;
            this.updateCropper();
        } else if (selectedAspectRatio === 'Square') {
            this.cropperAspectRatio = 1 / 1;
            if (this.defaultWidth >= this.defaultHeight) {
                this.adjustedWidth = this.adjustedHeight =  this.defaultHeight;
            } else {
                this.adjustedWidth = this.adjustedHeight = this.defaultWidth;
            }
            this.maxheight = this.maxWidth = this.adjustedWidth;
            this.updateCropper();
        } else if (selectedAspectRatio === 'Monitor') {
            this.cropperAspectRatio = 4 / 3;
            let maxWidth = Math.min(this.defaultWidth, (4 / 3) * this.defaultHeight);
            let maxHeight = Math.min(this.defaultHeight, (3 / 4) * this.defaultWidth);
            // Ensure that the calculated dimensions fit within the original image dimensions
            if (Math.round((maxWidth / 4) * 3) >= this.defaultHeight) {
                maxWidth = (4 / 3) * this.defaultHeight;
            } else {
                maxHeight = (4 / 3) * this.defaultWidth;
            }
            this.adjustedWidth = this.maxWidth = Math.round(maxWidth);
            this.adjustedHeight = this.maxheight = Math.round(maxHeight);
            this.updateCropper();
        } else if (selectedAspectRatio === 'originalRatio') {
            this.cropperAspectRatio = this.defaultAspectRatio;
            this.adjustedWidth = this.defaultWidth;
            this.adjustedHeight = this.defaultHeight;
            if (this.cropperAspectRatio >= 1) {
                this.maxheight = Math.round(this.adjustedWidth * this.cropperAspectRatio);
            } else {
                this.maxheight = Math.round(this.adjustedWidth / this.cropperAspectRatio);
            }
            if (this.adjustedHeight <= this.adjustedWidth) {
                this.onHeightChange();
            } else {
                this.onWidthChange();
            }
        }
        this.cdRef.detectChanges();
    }

    onWidthChange() {
        if (this.lockAspectRatio) {
            if (this.selectedAspectRatio === 'Monitor') {
                this.adjustedHeight = Math.round((this.adjustedWidth  / 4) * 3);
            } else if (this.selectedAspectRatio === 'Square') {
                if (this.adjustedWidth >= this.maxWidth) {
                    this.adjustedWidth = this.maxWidth;
                }
                this.adjustedHeight = this.adjustedWidth;
            } else {
                if (this.cropperAspectRatio <= 1 || this.adjustedWidth < this.maxheight) {
                    this.adjustedHeight = Math.round(this.adjustedWidth / this.cropperAspectRatio);
                } else {
                    this.adjustedHeight = Math.round(this.adjustedWidth * this.cropperAspectRatio);
                }
            }
            this.preventOverflow()
        } else {
            this.preventLimitsWidth();
        }
        this.cdRef.detectChanges();
    }

    onHeightChange() {
        if (this.lockAspectRatio) {
            if (this.selectedAspectRatio == 'Monitor') {
                this.adjustedWidth = Math.round((this.adjustedHeight / 3) * 4 );
            } else if (this.selectedAspectRatio == 'Square') {
                if (this.adjustedHeight >= this.maxheight) {
                    this.adjustedHeight = this.maxheight;
                }
                this.adjustedWidth = this.adjustedHeight;
            } else {
                if (this.cropperAspectRatio <= 1 || this.adjustedHeight < this.maxheight) {
                    this.adjustedWidth = Math.round(this.adjustedHeight * this.cropperAspectRatio);
                } else {
                    this.adjustedWidth = Math.round(this.adjustedHeight / this.cropperAspectRatio);
                }
            }
            this.preventOverflow();
        } else {
            this.preventLimitsHeight();
        }
        this.cdRef.detectChanges();
    }

    preventOverflow() {
        if (this.adjustedWidth >= this.maxWidth) {
            this.adjustedWidth = this.maxWidth;
            this.adjustedHeight = this.maxheight;
        } else if (this.adjustedHeight > this.maxheight) {
            this.adjustedWidth = this.maxWidth;
            this.adjustedHeight = this.maxheight;
        }
        this.updateCropper();
    }

    preventLimitsWidth() {
        if (this.adjustedWidth >= this.maxWidth) {
            this.adjustedWidth = this.maxWidth;
        }
        this.updateCropper();
    }

    preventLimitsHeight() {
        if (this.adjustedHeight > this.maxheight) {
            this.adjustedHeight = this.maxheight;
        }
        this.updateCropper();
    }

    updateCropper() {
            this.aspectRatioChanged = false;
            if (this.adjustedWidth >= 50) {
                this.adjustedWidth = this.adjustedWidth;
            } else {
                this.adjustedWidth = 50;
                this.onWidthChange();
                return;
            }
            if (this.adjustedHeight >= 50) {
                this.adjustedHeight = this.adjustedHeight;
            } else {
                this.adjustedHeight = 50;
                this.onHeightChange();
                return;
            }
            this.cropper = {
                x1: 0,
                y1: 0,
                x2: this.adjustedWidth * this.cropperWidthDifference,
                y2: this.adjustedHeight * this.cropperHeightDifference
            };
    }

    validateWidth(event) {
        const input = event.target as HTMLInputElement;
        if (!this.isValidInput(event) || input.value.length >= 10) {
            event.preventDefault();
        }
        clearTimeout(this.inputTimeout);
            setTimeout(() => {
                this.adjustedWidth = parseInt(input.value, 10);
                // this.cdRef.detectChanges();  // Ensure Angular knows about the changes}, 0);
        });
    }

    validateHeight(event) {
        const input = event.target as HTMLInputElement;
        if (!this.isValidInput(event) || input.value.length >= 10) {
            event.preventDefault();
        }
        clearTimeout(this.inputTimeout);
            setTimeout(() => {
                this.adjustedHeight = parseInt(input.value, 10);
                // this.cdRef.detectChanges();  // Ensure Angular knows about the changes}, 0);
        });
    }

    onBlur(type){
        if (type === 'width') {
            this.onWidthChange();
        } else if (type === 'height') {
            this.onHeightChange();
        }
        this.cdRef.detectChanges();
    }


    isValidInput(event): boolean {
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
            'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4',
            'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
        ];
        return allowedKeys.includes(event.key) || (event.key.length === 1 && event.key >= '0' && event.key <= '9');
    }

    preventWheel(event: WheelEvent) {
        event.preventDefault();
    }


    searchFiles() {
        this.editImageLoading = true;
        const searchWords = this.searchText.toLowerCase().split(' ');
        if (this.searchText.trim() === '' || searchWords ) {
            this.filteredActivityFiles = this.customerImages ? this.customerImages['activityFiles'] : [];
            this.filteredTemplateFiles = this.customerImages ? this.customerImages['templateFiles'] : [];
            this.filteredCustomerFiles = this.customerImages ? this.customerImages['customerFiles'] : [];
            this.filteredSurveyFiles = this.customerImages ? this.customerImages['surveyFiles'] : [];
            this.filteredPartList = this.partsImageList ? this.partsImageList : [];
            this.filteredCategoryImageList = this.categoryImageList ? this.categoryImageList : [];
            // this.editImageLoading = false;
        }
        if (this.filteredActivityFiles.length > 0) {
            this.filteredActivityFiles = this.customerImages['activityFiles'].filter(file =>
                searchWords.every(word => file.name.toLowerCase().includes(word))
            );
        }
        if (this.filteredCustomerFiles.length > 0) {
            this.filteredCustomerFiles = this.customerImages['customerFiles'].filter(file =>
                searchWords.every(word => file.name.toLowerCase().includes(word))
            );
        }
        if (this.filteredPartList.length > 0) {
            this.filteredPartList = this.partsImageList.filter(file =>
                searchWords.every(word => file.name.toLowerCase().includes(word))
            );
        }
        if (this.filteredSurveyFiles.length > 0) {
            this.filteredSurveyFiles = this.customerImages['surveyFiles'].filter(file =>
                searchWords.every(word => file.name.toLowerCase().includes(word))
            );
        }
        if (this.filteredTemplateFiles.length > 0) {
            this.filteredTemplateFiles = this.customerImages['templateFiles'].filter(file =>
                searchWords.every(word => file.name.toLowerCase().includes(word))
            );
        }
        if (this.filteredCategoryImageList.length > 0) {
            this.filteredCategoryImageList = this.categoryImageList.filter(file =>
                searchWords.every(word => file.name.toLowerCase().includes(word))
            );
        }
        this.editImageLoading = false;
    }

    // Drag and upload File
    uploadFile(event) {
        this.selectedImages = [];
        const files: any = (event.type === 'change' && event.isTrusted) ? (event.target as HTMLInputElement).files : event;
        const originalFormatData: File[] = [];
        this.fileErrorMsg = false;
        this.uploadErrorMsg = false;
        this.correctSizeAndFormat = [];
        this.wrongSizeAndFormat = [];
        this.noOfImagesSelected = files.length;
        this.croppedImage = '';
        this.imgURLs = [];
        if (this.option == 'pricingImage') {
            this.maximumCount = 3 - this.imagesCount;
        } else {
            this.maximumCount = 1;
        }
        if (files.length > this.maximumCount) {
            this.uploadErrorMsg = true;
        } else {
            this.uploading = true;
            for (let i = 0;  i < files.length; i++) {
                const file = files[i];
                const checkMaxFileSize = (file.size / 1024 / 1024).toFixed(2);
                const fileType = this.ts.getFileGroup(file.name.substr(file.name.lastIndexOf('.') + 1));
                if (parseInt(checkMaxFileSize) <= 30 && fileType === 'image') {
                    originalFormatData.push(file);
                    this.correctSizeAndFormat.push({
                        'name': file.name,
                        'size': (file.size / 1024).toFixed(2),
                        'type': fileType
                    });
                } else {
                    this.wrongSizeAndFormat.push({
                        'name': file.name,
                        'size': (file.size / 1024).toFixed(2),
                        'type': fileType
                    });
                }
             }
             if (this.correctSizeAndFormat.length) {
                 setTimeout(() => {
                         for (let i = 0; i < originalFormatData.length; i++) {
                              const file = originalFormatData[i];
                              const reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = (_event) => {
                                       const imgBase = reader.result as string;
                                      this.imgURLs.push(imgBase);
                                      this.croppedImage = this.imgURLs[0];
                              };
                         }
                            this.croppedImage = this.imgURLs;
                            this.uploading = false;
                            this.disabled = false;
                            this.selectedImages = this.imgURLs;
                            if (originalFormatData.length > 1) {
                                this.multipleImageSelected = true;
                            } else {
                                this.multipleImageSelected = false;
                            }
                 }, 3000);

            } else {
                if (this.wrongSizeAndFormat && this.wrongSizeAndFormat.length) {
                    this.fileErrorMsg = true;
                    this.backgroundImgfileSelected = false;
                    this.uploading = false;
                }
            }
        }

        }

    imageLoaded() {
            setTimeout(() => {
            this.cropper = {
                x1: 0,
                y1: 0,
                x2: this.adjustedWidth,
                y2: this.adjustedHeight,
            };
        }, 2);
            this.showCropperLoading = false;
    }

    loadImageFailed() {
        this.fileErrorMsg = true;
    }
    enableImagePreview() {
        if (this.croppedImagePreview) {
            this.croppedImagePreview = false;
        } else {
            this.croppedImagePreview = true;
        }
    }

    handleDragEvents(element: any) {
        element.on('drag dragstart dragend dragover dragenter dragleave drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
        });

        element.on('dragover dragenter', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            if (!element.hasClass('blur')) {
                element.find('.upload-popover').removeClass('display-none');
                element.find('.ano').addClass('blur');
            }

        });

        element.on('dragleave', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            if (!element.has(e.relatedTarget).length) {
                element.find('.upload-popover').addClass('display-none');
                element.find('.ano').removeClass('blur');
            }
        });

        element.on('drop', function (e: any) {
            e.preventDefault();
            e.stopPropagation();
            element.find('.pay-in-full').addClass('clickable');
            element.find('.upload-popover').addClass('display-none');
            element.find('.ano').removeClass('blur');
        });
    }

    ngAfterViewInit() {
        this.handleDragEvents($('#drop-zone'));

    }
  }
