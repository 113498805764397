import { NgZone, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CsToastBoxService } from '@app/services';
import { CsTaggingService } from '@app/directives/material-directives/cs-tagging/cs-tagging.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { FolderService } from '@app/shared/common-folder-structure/service/folder.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "@app/core";
import { OpportunityTemplateService } from "@app/features/system-settings/opportunity-template/services/opportunity-template.services";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
var TemplateModalComponent = /** @class */ (function () {
    function TemplateModalComponent(fb, templateService, router, route, toastBox, tagService, bsModalRef, ctModalRef, translate, opportunityTemplateService, ajsScope, confirmationBoxHelper, zone, authService, sidePanelModalService) {
        this.fb = fb;
        this.templateService = templateService;
        this.router = router;
        this.route = route;
        this.toastBox = toastBox;
        this.tagService = tagService;
        this.bsModalRef = bsModalRef;
        this.ctModalRef = ctModalRef;
        this.translate = translate;
        this.opportunityTemplateService = opportunityTemplateService;
        this.ajsScope = ajsScope;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.zone = zone;
        this.authService = authService;
        this.sidePanelModalService = sidePanelModalService;
        this.page = 'add';
        this.templateData = {};
        this.action = 'save';
        this.showSidepanelTagging = false;
        this.taggingNotificationName = 'Use tagging';
        this.uniqueName = false;
        this.tagsList = [];
        this.customTagsList = [];
        this.title = "";
        this.folderId = null;
        this.templateId = null;
        this.screenType = 'email_template';
        this.templateName = "-- Select folder --";
        this.isFolderSelectable = true;
        this.treeData = [];
        this.type = 'folder';
        this.showSubject = false;
        this.folderParentIds = [];
        this.helpText = 'Create a new template here and fill in all fields with valid information. Use tagging when necessary.';
        this.editFormData = false;
        this.enableSave = false;
        this.contentPage = false;
        this.coverPage = false;
        this.layout = '';
        this.freemiumAccess = false;
        this.jobDescriptionAccess = false;
        this.opportunityFormData = {
            id: '',
            templateName: '',
            jobDescription: '',
            expiry: '',
            proposal: '',
            pipeline: '',
            expiryOption: '0'
        };
        this.expiryDays = [{ 'value': '', 'checked': false, 'showError': false }, { 'value': '', 'checked': false, 'showError': false }, { 'value': '', 'checked': false, 'showError': false }];
        this.saveDisabled = false;
        this.loading = true;
        this.expiryDayDisable = true;
        this.jobDescriptionList = [];
        this.proposalList = [];
        this.showexpiryInput = true;
        this.pipelineList = [];
        this.dynamicDayValue = "N";
        this.addon = false;
        this.isFreemium = false;
        this.customerOptionName = '';
        this.disabled = false;
        this.pipelineReadAccess = false;
        if (this.route.snapshot.params.id) {
            this.page = 'edit';
        }
        else {
            this.page = 'add';
        }
        this.jobDescriptionAccess = this.authService.permitted(['Setupjobdescriptions.writeaccess']) === true;
        this.pipelineReadAccess = this.authService.permitted(['SetupPipelines.readaccess']) === true;
        // this.templateService.getUserId().subscribe(value => {
        //     this.isFreemium = value['salesClient']
        // });
    }
    TemplateModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if (!proposalEditor) {
            this.freemiumAccess = true;
            this.isFreemium = true;
        }
        else {
            this.freemiumAccess = false;
            this.isFreemium = false;
        }
        this.buildTemplateForm();
        this.onClose = new Subject();
        if (this.page == 'edit') {
            this.updateForm(this.templateData);
        }
        else if (this.editFormData) {
            this.page = 'edit';
            this.updateForm(this.editFormData);
            this.templateId = this.editFormData.templateId;
        }
        this.tagService.getTags().then(function (tags) {
            _this.tagsList = tags;
        });
        this.templateForm.controls['subject'].valueChanges.subscribe(function (value) {
            if (!$(value).text()) {
                _this.templateForm.controls['subject'].setErrors({ customError: true });
            }
        });
        this.templateForm.controls['message'].valueChanges.subscribe(function (value) {
            if (!$(value).text()) {
                _this.templateForm.controls['message'].setErrors({ customError: true });
            }
        });
        if (this.pageType && this.pageType == "EDITOR_CONTENT_PAGE") {
            this.contentPage = true;
        }
        else if (this.pageType && this.pageType == "EDITOR_HEADER_PAGE") {
            this.coverPage = true;
        }
        if (this.screenType == 'content_library') {
            if (this.page == 'edit') {
                this.helpText = 'edit.content.library.helptext';
            }
            else {
                this.helpText = 'add.content.library.helptext';
            }
        }
        if (this.screenType == 'email_template') {
            if (this.page == 'edit') {
                this.helpText = 'edit.email.template.helptext';
            }
            else {
                this.helpText = 'add.email.template.helptext';
            }
        }
        if (this.templateId && this.screenType == 'opportunity_template') {
            this.opportunityTemplateService.opportunityTemplateLinkedOpportunity(this.templateId).subscribe(function (resp) {
                if (resp['message'] == 'success') {
                    _this.templateForm.controls['pipeline'].disable();
                    _this.templateForm.controls['proposal'].disable();
                    _this.disabled = true;
                }
            });
        }
        if (this.expiryDays[1].value != '' && this.expiryDays[1].value != null) {
            this.expiryDayDisable = false;
        }
    };
    TemplateModalComponent.prototype.ngAfterViewInit = function () {
    };
    // update form
    TemplateModalComponent.prototype.updateForm = function (data) {
        this.templateForm.patchValue({
            id: data['id'],
            templateName: data['templateName'],
            subject: data['subject'] ? data['subject'] : '',
            message: data['message'],
            customerOptionName: this.customerOptionName ? this.customerOptionName : data['templateName'],
            jobDescription: data['jobDescription'] ? data['jobDescription'] : '',
            proposal: data['proposal'] ? data['proposal'] : '',
            pipeline: data['pipeline'] ? data['pipeline'] : ''
        });
        this.templateForm.updateValueAndValidity();
        this.customTagsList = this.templateData && this.templateData.customTags && this.templateData.customTags.map(function (tag) {
            var tagData = {
                id: tag.name,
                value: tag.name,
                denotationChar: '{'
            };
            if (tag.options && tag.options.length) {
                tagData['options'] = tag.options;
            }
            return tagData;
        }) || [];
    };
    //Form Build
    TemplateModalComponent.prototype.buildTemplateForm = function () {
        this.templateForm = this.fb.group({
            'subject': [''],
            'templateName': ['', Validators.required],
            'message': [''],
            'customerOptionName': [''],
            'jobDescription': [''],
            'proposal': [''],
            'pipeline': ['']
        });
        if (this.screenType == 'email_template') {
            this.showSubject = true;
            this.templateForm.get('subject').setValidators([
                Validators.required,
            ]);
        }
        if (this.screenType == 'email_template' || this.screenType == 'sms_template' || this.screenType == 'letter_template' || this.screenType == 'call_note_template') {
            this.templateForm.get('message').setValidators([
                Validators.required,
            ]);
        }
        if (this.screenType == 'opportunity_template') {
            if (this.jobDescriptionAccess) {
                this.templateForm.get('jobDescription').setValidators([
                    Validators.required,
                ]);
            }
            this.templateForm.get('proposal').setValidators([
                Validators.required,
            ]);
            this.templateForm.get('pipeline').setValidators([
                Validators.required,
            ]);
            if (!this.pipelineReadAccess) {
                this.templateForm.controls['pipeline'].setValue(this.pipelineList[0]['id']);
            }
        }
    };
    TemplateModalComponent.prototype.stripTags = function (content) {
        var regex = /<\/?span[^>]*>/g;
        return content.replace(regex, '');
    };
    TemplateModalComponent.prototype.replaceQuotes = function (replaceString) {
        if (replaceString == '') {
            return '';
        }
        var find = ["&quot;Trebuchet MS&quot;", "&quot;Times New Roman&quot;", "&quot;Courier New&quot;"];
        var replace = ['Trebuchet MS', 'Times New Roman', 'Courier New'];
        var regex;
        for (var i = 0; i < find.length; i++) {
            regex = new RegExp(find[i], "g");
            replaceString = replaceString.replace(regex, replace[i]);
        }
        return replaceString;
    };
    // Save Data
    TemplateModalComponent.prototype.saveTemplate = function () {
        var _this = this;
        if (this.templateForm.valid && this.screenType != 'content_library' && this.screenType != 'option_template' && this.screenType != 'proposal_template' && this.screenType != 'opportunity_template' && this.screenType != 'call_note_template') {
            this.action = 'saving';
            var value_1 = {};
            value_1['message'] = this.replaceQuotes(this.templateForm.controls['message'].value);
            value_1['subject'] = this.stripTags(this.templateForm.controls['subject'].value);
            value_1['templateName'] = this.templateForm.controls['templateName'].value;
            value_1['folderId'] = this.folderId;
            value_1['templateId'] = this.templateId;
            value_1['screen'] = this.screenType;
            if (this.templateData && this.templateData.id) {
                value_1['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value_1).subscribe(function (data) {
                _this.templateData.id = data['templateId'];
                _this.action = 'save';
                if (_this.page == 'edit') {
                    _this.toastBox.show('Updated');
                }
                else {
                    _this.toastBox.show('Saved');
                }
                value_1['templateId'] = data['templateId'];
                value_1['parentIds'] = _this.folderParentIds;
                _this.onClose.next(value_1);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log(error);
            });
        }
        else if (this.screenType == 'content_library') {
            this.action = 'saving';
            var value_2 = {};
            value_2['templateName'] = this.templateForm.controls['templateName'].value;
            value_2['folderId'] = this.folderId;
            value_2['templateId'] = this.templateId;
            value_2['screen'] = this.screenType;
            value_2['pageType'] = 'contentPage';
            // if(this.coverPage){
            //     value['pageType'] = 'coverPage'
            // } else if(this.contentPage) {
            // }
            if (this.templateData && this.templateData.id) {
                value_2['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value_2).subscribe(function (data) {
                _this.templateData.id = data['templateId'];
                _this.action = 'save';
                if (_this.page == 'edit') {
                    _this.toastBox.show('Updated');
                }
                else {
                    _this.toastBox.show('Saved');
                }
                value_2['templateId'] = data['templateId'];
                value_2['parentIds'] = _this.folderParentIds;
                _this.onClose.next(value_2);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log(error);
            });
        }
        else if (this.screenType == 'option_template') {
            this.action = 'saving';
            var setupOption = {
                'customerOptionName': this.templateForm.controls['customerOptionName'].value,
                'optionName': this.templateForm.controls['templateName'].value,
                'id': this.templateId,
                'addOn': this.addon
            };
            var value_3 = {};
            value_3['templateName'] = this.templateForm.controls['templateName'].value;
            value_3['folderId'] = this.folderId;
            value_3['templateId'] = this.templateId;
            value_3['screen'] = this.screenType;
            value_3['customerOptionName'] = this.templateForm.controls['customerOptionName'].value;
            value_3['setupOption'] = setupOption;
            value_3['isFreemium'] = this.isFreemium;
            value_3['layout'] = this.layout ? this.layout : '';
            value_3['addon'] = this.addon;
            if (this.templateData && this.templateData.id) {
                value_3['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value_3).subscribe(function (data) {
                _this.templateData.id = data['templateId'];
                _this.action = 'save';
                if (_this.page == 'edit') {
                    _this.toastBox.show('Updated');
                }
                else {
                    _this.toastBox.show('Saved');
                }
                value_3['templateId'] = data['templateId'];
                value_3['parentIds'] = _this.folderParentIds;
                _this.onClose.next(value_3);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log(error);
            });
        }
        else if (this.screenType == 'proposal_template') {
            this.action = 'saving';
            var value_4 = {};
            value_4['templateName'] = this.templateForm.controls['templateName'].value;
            value_4['folderId'] = this.folderId;
            value_4['templateId'] = this.templateId;
            value_4['screen'] = this.screenType;
            value_4['freebee'] = this.freemiumAccess;
            if (this.templateData && this.templateData.id) {
                value_4['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value_4).subscribe(function (data) {
                _this.templateData.id = data['templateId'];
                _this.action = 'save';
                if (_this.page == 'edit') {
                    _this.toastBox.show('Updated');
                }
                else {
                    _this.toastBox.show('Saved');
                }
                value_4['templateId'] = data['templateId'];
                value_4['parentIds'] = _this.folderParentIds;
                _this.onClose.next(value_4);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log(error);
            });
        }
        else if (this.screenType == 'opportunity_template') {
            this.action = 'saving';
            var value_5 = {};
            value_5['templateName'] = this.templateForm.controls['templateName'].value;
            value_5['folderId'] = this.folderId;
            value_5['templateId'] = this.templateId;
            value_5['screen'] = this.screenType;
            value_5['freebee'] = this.freemiumAccess;
            if (this.templateData && this.templateData.id) {
                value_5['id'] = this.templateData.id;
            }
            var opportunityForm = {
                "id": this.templateId,
                "templateName": this.templateForm.controls['templateName'].value,
                "jobDescription": this.templateForm.controls['jobDescription'].value,
                "expiry": JSON.stringify(this.expiryDays),
                "expiryOption": "0",
                "proposal": this.templateForm.controls['proposal'].value,
                "pipeline": this.templateForm.controls['pipeline'].value,
                "isFreemium": this.isFreemium
            };
            value_5['opportunityForm'] = opportunityForm;
            this.templateService.addEditEmailTemplate(value_5).subscribe(function (data) {
                _this.templateData.id = data['templateId'];
                _this.action = 'save';
                if (_this.page == 'edit') {
                    _this.toastBox.show('Updated');
                }
                else {
                    _this.toastBox.show('Saved');
                }
                value_5['templateId'] = data['templateId'];
                value_5['parentIds'] = _this.folderParentIds;
                _this.onClose.next(value_5);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log(error);
            });
        }
        else if (this.screenType === 'call_note_template') {
            this.action = 'saving';
            var value_6 = {};
            value_6['templateName'] = this.templateForm.controls['templateName'].value;
            value_6['folderId'] = this.folderId;
            value_6['message'] = this.templateForm.controls['message'].value;
            value_6['templateId'] = this.templateId;
            value_6['screen'] = this.screenType;
            var customTags_1 = [];
            if (this.customTagsList.length) {
                this.customTagsList.forEach(function (tag) {
                    customTags_1.push({
                        name: tag.value,
                        fieldType: tag.fieldType,
                        options: tag.options,
                        id: tag.id
                    });
                });
                value_6['customTags'] = JSON.stringify(customTags_1);
            }
            if (this.templateData && this.templateData.id) {
                value_6['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value_6).subscribe(function (data) {
                _this.templateData.id = data['templateId'];
                _this.action = 'save';
                if (_this.page == 'edit') {
                    _this.toastBox.show('Updated');
                }
                else {
                    _this.toastBox.show('Saved');
                }
                value_6['templateId'] = data['templateId'];
                value_6['parentIds'] = _this.folderParentIds;
                _this.onClose.next(value_6);
                _this.bsModalRef.hide();
            }, function (error) {
                console.log(error);
            });
        }
    };
    Object.defineProperty(TemplateModalComponent.prototype, "f", {
        get: function () {
            return this.templateForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    TemplateModalComponent.prototype.selectedFolder = function (folderDetails) {
        if (folderDetails.hasOwnProperty('parentIds')) {
            this.folderParentIds = folderDetails.parentIds;
        }
        this.folderId = folderDetails.id;
    };
    // open tags panel
    TemplateModalComponent.prototype.openSidePanelNotificationTagging = function () {
        this.showSidepanelTagging = !this.showSidepanelTagging;
        if (this.showSidepanelTagging) {
            this.taggingNotificationName = 'Close tagging';
        }
        else {
            this.taggingNotificationName = 'Use tagging';
        }
    };
    TemplateModalComponent.prototype.customTagCall = function (data) {
        this.openCustomTagModal(data);
    };
    TemplateModalComponent.prototype.openCustomTagModal = function (data) {
        /*this.ctModalRef = this.sidePanelModalService.show(
            CustomTagModalComponent,
            {
                initialState: data,
            }
        );
        this.ctModalRef.content.onClose.subscribe((result) => {
            data['fieldType'] = result['fieldType'];
            if(result['options']) {
                data['options'] = result['options']
            }
            data['new'] = true;
            const ctList = [...this.customTagsList];
            ctList.push(data);
            this.customTagsList = ctList;
        });*/
    };
    TemplateModalComponent.prototype.checkForm = function () {
        if (this.screenType == 'content_library' || this.screenType == 'proposal_template' || this.screenType == 'opportunity_template') {
            if (this.templateForm.controls['templateName'].value != '') {
                this.enableSave = true;
            }
            else {
                this.enableSave = false;
            }
        }
    };
    TemplateModalComponent.prototype.onlyOne = function (checkbox) {
        if (checkbox == 'content-page') {
            this.contentPage = true;
            this.coverPage = false;
        }
        else {
            this.contentPage = false;
            this.coverPage = true;
        }
        this.checkForm();
    };
    TemplateModalComponent.prototype.selectLayout = function (type, selectedClass) {
        $(document).ready(function () {
            $('.layout').click(function () {
                $('.layout').removeClass('selected');
                $(this).addClass('selected');
            });
        });
        this.layout = type;
        this.checkForm();
    };
    TemplateModalComponent.prototype.checkValidDays = function () {
        this.expiryDays.forEach(function (val, key) {
            if (val.checked && !val.value) {
                val.showError = true;
            }
        });
        if (this.expiryDays[0].showError || this.expiryDays[1].showError || this.expiryDays[2].showError) {
            this.saveDisabled = true;
        }
    };
    TemplateModalComponent.prototype.checkboxChecked = function (index) {
        this.expiryDays[index].checked = !this.expiryDays[index].checked;
        if (!this.expiryDays[index].checked) {
            this.expiryDays[index].value = '';
            this.expiryDays[index].showError = false;
            if (index == 1) {
                this.expiryDays[2].value = '';
                this.expiryDays[2].showError = false;
                this.expiryDays[2].checked = false;
                this.expiryDayDisable = true;
            }
            if (!this.expiryDays[0].showError && !this.expiryDays[1].showError && !this.expiryDays[2].showError) {
                this.saveDisabled = false;
            }
        }
        this.expiryDayCheckBox();
        this.checkValidDays();
    };
    TemplateModalComponent.prototype.expiryDayCheckBox = function () {
        if (this.expiryDays[1].checked == true && !this.expiryDays[1].showError && this.expiryDays[1].value != '') {
            this.expiryDayDisable = false;
        }
        else {
            this.expiryDayDisable = true;
            this.expiryDays[2].value = '';
            this.expiryDays[2].checked = false;
        }
    };
    TemplateModalComponent.prototype.validateNumber = function (e, index) {
        var value = parseFloat(e.target.value);
        if (value == 0 || isNaN(value)) {
            this.expiryDays[index].invalid = true;
        }
        else {
            this.expiryDays[index].invalid = false;
        }
        if (!value && this.expiryDays[index].checked == true || isNaN(value) || !$.isNumeric(e.target.value)) {
            this.expiryDays[index].showError = true;
            this.saveDisabled = true;
            e.preventDefault();
            this.expiryDayCheckBox();
            return false;
        }
        else {
            this.expiryDays[index].showError = false;
            if (!this.expiryDays[0].showError && !this.expiryDays[1].showError && !this.expiryDays[2].showError) {
                this.saveDisabled = false;
            }
            this.expiryDays[index].value = e.target.value.slice(0, 10);
            this.checkValidDays();
            this.expiryDayCheckBox();
            return true;
        }
    };
    TemplateModalComponent.prototype.checkUniqueName = function (value) {
        var _this = this;
        if (this.screenType == 'content_library' || this.screenType == 'call_note_template') {
            var text = value;
            var data = {
                title: text,
                screen: this.screenType,
            };
            var checkName = false;
            if (this.page == 'edit') {
                if (this.templateData.templateName == value) {
                    checkName = false;
                }
                else {
                    checkName = true;
                }
            }
            else {
                checkName = true;
            }
            if (checkName) {
                this.templateService.checkUniqueTemplateName(data).subscribe(function (resp) {
                    _this.uniqueName = resp;
                });
            }
        }
        else if (this.screenType == 'option_template') {
            var params = {
                optionName: value,
                isAddonTemplate: this.addon
            };
            var checkName = false;
            if (this.page == 'edit') {
                params.selectedId = this.templateId;
                if (this.templateData.templateName === value.trim()) {
                    checkName = false;
                }
                else {
                    checkName = true;
                }
            }
            else {
                checkName = true;
            }
            if (checkName) {
                this.templateService.checkTemplateName(params).subscribe(function (resp) {
                    if (resp == true) {
                        _this.uniqueName = true;
                    }
                    else {
                        _this.uniqueName = false;
                    }
                });
            }
            else {
                this.uniqueName = false;
            }
        }
        else if (this.screenType == 'proposal_template') {
            var params = {
                template: value
            };
            var checkName = false;
            if (this.page == 'edit') {
                params.selectedId = this.templateId;
                if (this.templateData.templateName === value.trim()) {
                    checkName = false;
                }
                else {
                    checkName = true;
                }
            }
            else {
                checkName = true;
            }
            if (checkName) {
                this.templateService.checkProposalTemplateExists(params).subscribe(function (resp) {
                    if (resp == true) {
                        _this.uniqueName = true;
                    }
                    else {
                        _this.uniqueName = false;
                    }
                });
            }
            else {
                this.uniqueName = false;
            }
        }
        else if (this.screenType == 'opportunity_template') {
            var params = {
                template: value,
            };
            var checkName = false;
            if (this.page == 'edit') {
                params.selectedId = this.templateId;
                if (this.templateData.templateName === value.trim()) {
                    checkName = false;
                }
                else {
                    checkName = true;
                }
            }
            else {
                checkName = true;
            }
            if (checkName && value.trim() != '') {
                this.opportunityTemplateService.checkTemplateName(params).subscribe(function (resp) {
                    if (resp == true) {
                        _this.uniqueName = true;
                    }
                    else {
                        _this.uniqueName = false;
                    }
                });
            }
            else {
                this.uniqueName = false;
            }
        }
    };
    TemplateModalComponent.prototype.templateSelected = function (event) {
        if (event.isFolder === '0') {
            this.templateForm.controls['proposal'].setValue(event.templateId);
            this.selectedTemplateId = event.templateId;
        }
    };
    return TemplateModalComponent;
}());
export { TemplateModalComponent };
