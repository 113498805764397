import { InjectorInstance } from "@app/app.module";
import { HttpClient } from "@angular/common/http";
import { GetApiUrl } from "@app/utils";
var CommonDataSource = /** @class */ (function () {
    function CommonDataSource() {
    }
    CommonDataSource.prototype.static = function () {
        console.log('CommonDataSource initialization');
    };
    CommonDataSource.editorCommonConfig = function (onselect, source, mentionDenotationChars, toolbar) {
        if (mentionDenotationChars === void 0) { mentionDenotationChars = ["@", "#", "["]; }
        if (toolbar === void 0) { toolbar = false; }
        return {
            mention: {
                mentionListClass: "ql-mention-list mat-elevation-z8",
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                showDenotationChar: true,
                spaceAfterInsert: true,
                onSelect: onselect,
                mentionDenotationChars: mentionDenotationChars,
                source: source,
            },
            toolbar: false
        };
    };
    CommonDataSource.getEmailCommunicationData = function () {
        return new Promise(function (resolve, reject) {
            if (CommonDataSource.emailCommunicationData) {
                resolve(CommonDataSource.emailCommunicationData);
            }
            else {
                var httpClient = InjectorInstance.get(HttpClient);
                httpClient.get(GetApiUrl("workflow/communication/template/config"))
                    .toPromise()
                    .then(function (resp) {
                    if (resp.hasOwnProperty('data')) {
                        CommonDataSource.emailCommunicationData = resp['data'];
                        resolve(resp['data']);
                    }
                    else {
                        reject({ error: true });
                    }
                }, function (err) {
                    console.error(err);
                    reject({ error: true });
                });
            }
        });
    };
    CommonDataSource.getUserRoles = function () {
        return new Promise(function (resolve, reject) {
            if (CommonDataSource.userRoles.length > 0) {
                resolve(CommonDataSource.userRoles);
            }
            else {
                var httpClient = InjectorInstance.get(HttpClient);
                httpClient.get(GetApiUrl("listSecurityRoles", { limit: 100 }))
                    .toPromise()
                    .then(function (resp) {
                    if (resp['roles']) {
                        CommonDataSource.userRoles = resp['roles'];
                        resolve(resp);
                    }
                    else {
                        reject({ error: true });
                    }
                }, function (err) {
                    console.error(err);
                    reject({ error: true });
                });
            }
        });
    };
    CommonDataSource.getUserData = function () {
        return new Promise(function (resolve, reject) {
            if (CommonDataSource.userData) {
                resolve(CommonDataSource.userData);
            }
            else {
                var httpClient = InjectorInstance.get(HttpClient);
                httpClient.get(GetApiUrl("get_clientUsers"))
                    .toPromise()
                    .then(function (resp) {
                    if (resp['userRecords']) {
                        CommonDataSource.userData = resp;
                        resolve(resp);
                    }
                    else {
                        reject({ error: true });
                    }
                }, function (err) {
                    console.error(err);
                    reject({ error: true });
                });
            }
        });
    };
    CommonDataSource.getUserDetail = function () {
        return new Promise(function (resolve, reject) {
            if (CommonDataSource.userDetail) {
                resolve(CommonDataSource.userDetail);
                console.log(CommonDataSource.userDetail, '1');
            }
            else {
                var httpClient = InjectorInstance.get(HttpClient);
                httpClient.get(GetApiUrl("get_client_users"))
                    .toPromise()
                    .then(function (resp) {
                    if (resp) {
                        CommonDataSource.userDetail = resp;
                        console.log(CommonDataSource.userDetail, '2');
                        resolve(resp);
                    }
                    else {
                        reject({ error: true });
                    }
                }, function (err) {
                    console.error(err);
                    reject({ error: true });
                });
            }
        });
    };
    CommonDataSource.formattingSmsAddress = function (values, smsTagsList, usersList) {
        if (smsTagsList === void 0) { smsTagsList = []; }
        if (usersList === void 0) { usersList = []; }
        if (!values) {
            return undefined;
        }
        var smsMobileVal = values;
        var newItemList = [];
        smsMobileVal.forEach(function (value) {
            if (value.indexOf('t_') === 0) {
                var matchedItem = smsTagsList.find(function (t) { return t.id == value; });
                if (matchedItem) {
                    var displayName = matchedItem['displayText'] ? matchedItem['displayText'] : matchedItem['text'];
                    newItemList.push({ type: "tag", value: value, displayName: displayName, tagName: matchedItem['text'] });
                }
            }
            else if (value.indexOf('(Create New)') > -1) {
                newItemList.push({ type: "normal", value: value.replace(" (Create New)", ''), displayName: value.replace(" (Create New)", '') });
            }
            else if (!isNaN(value)) {
                var matchedItem = usersList.find(function (t) { return t.id == value; });
                if (matchedItem) {
                    newItemList.push({ type: "user", value: value, displayName: matchedItem.username });
                }
            }
        });
        return newItemList;
    };
    CommonDataSource.formattingEmailAddress = function (value, emailTagsList, usersList) {
        if (emailTagsList === void 0) { emailTagsList = []; }
        if (usersList === void 0) { usersList = []; }
        var values = value;
        if (!values) {
            return undefined;
        }
        var emailAddressVal = values;
        var newItemList = [];
        emailAddressVal.forEach(function (value) {
            if (value.indexOf('t_') === 0) {
                //tags
                var matchedItem = emailTagsList.find(function (t) { return t.id == value; });
                if (matchedItem) {
                    var displayName = matchedItem['displayText'] ? matchedItem['displayText'] : matchedItem['text'];
                    newItemList.push({ type: "tag", value: value, displayName: displayName, tagName: matchedItem['text'] });
                }
            }
            else if (value.indexOf('@') > -1) {
                //custom email
                newItemList.push({ type: "normal", value: value.replace(" (Create New)", ''), displayName: value.replace(" (Create New)", '') });
            }
            else if (!isNaN(value)) {
                // user email
                var matchedItem = usersList.find(function (t) { return t.id == value; });
                if (matchedItem) {
                    newItemList.push({ type: "user", value: value, displayName: matchedItem.username });
                }
            }
        });
        return newItemList;
    };
    CommonDataSource.getClientDetails = function () {
        return new Promise(function (resolve, reject) {
            if (CommonDataSource.clientDetails) {
                resolve(CommonDataSource.clientDetails);
            }
            else {
                var httpClient = InjectorInstance.get(HttpClient);
                httpClient.get(GetApiUrl("get_client_details"))
                    .toPromise()
                    .then(function (resp) {
                    if (resp['clientDetails']) {
                        CommonDataSource.clientDetails = resp['clientDetails'];
                        resolve(resp['clientDetails']);
                    }
                    else {
                        reject({ error: true });
                    }
                }, function (err) {
                    console.error(err);
                    reject({ error: true });
                });
            }
        });
    };
    CommonDataSource.makeEmailAddressDropdownData = function (data) {
        var _inputs = [];
        var customList = [];
        if (data && data.length > 0) {
            data.forEach(function (item) {
                _inputs.push(item['value']);
                if (item['value'].indexOf('@') > 0) {
                    customList.push({
                        id: item['value'],
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                }
            });
        }
        return {
            inputValue: _inputs,
            customList: customList
        };
    };
    CommonDataSource.makeUserDropdownData = function (data) {
        var _inputs = [];
        var customList = [];
        if (data && data.length > 0) {
            data.forEach(function (item) {
                _inputs.push(item['value']);
                if (!isNaN(item['value'])) {
                    customList.push({
                        id: item['value'],
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                }
            });
        }
        return {
            inputValue: _inputs,
            customList: customList
        };
    };
    CommonDataSource.makeSmsAddressDropdownData = function (data) {
        var _inputs = [];
        var customList = [];
        if (data && data.length > 0) {
            data.forEach(function (item) {
                _inputs.push(item['value']);
                if (item['type'] == 'normal') {
                    customList.push({
                        id: item['value'] + ' (Create New)',
                        text: item['value'],
                        name: 'CUSTOM'
                    });
                }
            });
        }
        return {
            inputValue: _inputs,
            customList: customList
        };
    };
    CommonDataSource.userRoles = [];
    CommonDataSource.allTagsList = undefined;
    CommonDataSource.stagesList = [];
    CommonDataSource.activityTypes = [];
    CommonDataSource.userData = undefined;
    CommonDataSource.userDetail = undefined;
    CommonDataSource.emailCommunicationData = undefined;
    CommonDataSource.contactsList = [
        { id: 'customer_contact', text: 'Customer contact' },
        { id: 'opportunity_contact', text: 'Opportunity contact' },
        { id: 'opportunity_site_contact', text: 'Opportunity site contact' }
    ];
    CommonDataSource.select2EmailDefaultConfig = {
        width: '100%',
        tags: true,
        createSearchChoice: function (term, data) {
            var exists = false;
            data.forEach(function (idText) {
                if (idText.text == term)
                    exists = true;
            });
            var regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'g');
            return term.indexOf(' ') >= 0 || exists || !term.match(regex) ? null : {
                id: term + " (Create New)",
                text: term + " (Create New)"
            };
        },
        formatSelection: function (item) {
            return item.text.replace(" (Create New)", '');
        },
        formatResult: function (item) {
            return item.text;
        }
    };
    CommonDataSource.clientDetails = undefined;
    return CommonDataSource;
}());
export { CommonDataSource };
