import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";
import {from, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailBlockModel} from "@app/workflow-common/services/models/EmailBlockModel";
import {SetSalesPersonBlockModel} from "@app/workflow-common/services/models/SetSalesPersonBlockModel";

@Component({
  selector: 'app-set-sales-person-block-widget',
  templateUrl: './set-sales-person-block-widget.component.html',
  styles: []
})
export class SetSalesPersonBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();
  constructor(
      private formBuilder: FormBuilder,
  ) { }
    form: FormGroup;
    blockData: any;
    isLoading: boolean = true;
    isFormSubmitted: boolean = false;
    inputModel: any;
    salesPersonUsers: any;
    select2Config: any;
    selectedUserInputs:{} = {};

    showDeleteBtn: boolean = false;
  async ngOnInit() {
      this.blockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
      await CommonDataSource.getUserDetail().then((userData) => {
          const salesPersonUserList: any[] = [];
          const userRecords = [];
          userRecords['userRecords'] = userData ? userData : [];
          userRecords['userRecords'].forEach((user: any) => {
              let type: string = '';
              if (user.salesRoleId != null && (user.salesRoleId > 10 || user.salesRoleId == 9)) {
                  salesPersonUserList.push({
                      id: user.id,
                      text: user.text,
                  })
              }
          });
          this.salesPersonUsers = salesPersonUserList;

      });
      const blockObject = {...this.blockObject};
      const modelData = blockObject['model'] ? blockObject['model'] : undefined;
      if(modelData) {
          this.showDeleteBtn=true;
      }
      this.inputModel = new SetSalesPersonBlockModel(modelData);
      this.makeEmailAddressDropdownData();
      this.form = this.formBuilder.group({
              assignTo: new FormControl(this.selectedUserInputs['assignTo'], [Validators.required]),
              displayValue: new FormControl(this.selectedUserInputs['displayValue']),
      });
      this.isLoading = false;

  }
    closeModal(){
        this.close.emit({
            action: 'close',
            data: null
        });
    }
    remove(){
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }
    selectedUsers(event:any = null) {
      this.form.get('assignTo').setValue(event);
      if(event){
          this.form.get('displayValue').setValue(this.getDisplayValue(event))
      }
    }
    // makeGroupUserList() {
    //     const allEmailTag = [];
    //     const newItems: any[] = [];
    //         newItems.push(this.salesPersonUsers);
    //         newItems.push(this.otherUsers);
    //
    //     const source = from(newItems);
    //     const tags = source.pipe(
    //         map(groupItems => {
    //             const _item: any = {text: '', children: []};
    //             let cnt: number = 0;
    //             groupItems.forEach( group => {
    //                 if (cnt === 0) {
    //                     _item.text = group.name;
    //                 }
    //                 _item.children.push({
    //                     id: group.id,
    //                     text: group.text
    //                 });
    //                 cnt++;
    //             });
    //             return _item;
    //         })
    //     );
    //     const subscribe:Subscription = tags.subscribe(val => allEmailTag.push(val));
    //     subscribe.unsubscribe();
    //     console.log(allEmailTag,'allEmailTag')
    //     return allEmailTag;
    // }
    makeEmailAddressDropdownData(){
        this.selectedUserInputs['assignTo'] = this.inputModel.assignTo;
        if(this.inputModel.assignTo){
            this.selectedUserInputs['displayValue'] = this.getDisplayValue(this.inputModel.assignTo);
        }

    }
    save(){
        this.isFormSubmitted=true;

        const blockObject = {...this.blockObject};
        blockObject.model = new SetSalesPersonBlockModel(this.form.value);
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        })

    }
    getDisplayValue(userId){
        const selectedUserData = this.salesPersonUsers.find(x => x.id == userId);
        return selectedUserData['text'];
    }

}
