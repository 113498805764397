import { Component, OnInit, Input, Output, EventEmitter, Inject, HostListener, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var jQuery: any;

@Component({
  selector: 'cs-sidepanel',
  templateUrl: './cs-sidepanel.component.html',
  styles: [`.cs-sidepanel-parentcontainer {
                display: flex;
                flex-direction: column;
                height: 100%;
          }`]
})
export class CsSidepanelComponent implements OnInit {
  @Input('name') _panelName: string;
  @Input('autoCloseConfirmation') autoCloseWarning: boolean = true;
  @Input('panelId') sidePanelId: string = 'side-panel';
  @Input('panelClass') sidePanelClass : string = 'narrow-side-panel';

  _showPanel: boolean = false;
  private sidePanelIsShowing: boolean = false;
  @Output('close') panelCloseEvent: EventEmitter<any> = new EventEmitter();
  escapeElements: string[] = ['bs-datepicker-container'];

  constructor(
    @Inject('$scope') private ajsScope,
    @Inject('confirmationBoxHelper') private confirmationBoxHelper,
    private translate: TranslateService,
    private hostRef: ElementRef
  ) { }

  ngOnInit() {
  }

  @Input('show')
  set showSidePanel(value: boolean) {
    this._showPanel = value;

    if (value) {
      this.onPanelOpen();
      setTimeout(() => {
        this.sidePanelIsShowing = true;
      }, 100);
    }
    else {
      this.sidePanelIsShowing = false;
      this.onPanelClose();
    }
  }

  // Listening for click on outside panel
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    if (!this.hostRef || !this.sidePanelIsShowing) return;
    if(event.target['id']!='page-overlay'){ return; }
    let canBeEscaped = (): boolean => {
      if ((<HTMLElement>this.hostRef.nativeElement).querySelector('#'+this.sidePanelId).contains(event.target)) return true;

      for (let i = 0; i < this.escapeElements.length; i++) {
        if (jQuery(event.target).parents(this.escapeElements[i]).length) {
          return true;
          break;
        }
      }

        if((this.hostRef.nativeElement).querySelector('.select2-container')){
            let select2EscapeElements = ['select2-input', 'select2-focusser'];
            let activeElement = event.currentTarget.activeElement;
            if(activeElement) {
                let classValue = $(activeElement).attr('class');
                if (classValue) {
                    let classes = classValue.split(' ');
                    for (let j = 0; j < classes.length; j++) {
                        if (classes[j] == select2EscapeElements[0] || classes[j] == select2EscapeElements[1]) {
                            return true;
                        }
                    }
                }
            }
        }
      if(jQuery(event.target)[0].classList.value=='clear-link-container'){
          return true;
      }

      return false;

    };

    if (!canBeEscaped()) {
      this.showPanelCloseWarning();
    }
  }

  // Listening for 'escape' key press
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (!this.hostRef || !this.sidePanelIsShowing) return;

    if (!this.hostRef.nativeElement.contains(event.target)) {
      this.showPanelCloseWarning();
    }
  }

  // Get confirmation from user to close sidepanel
  showPanelCloseWarning() {
    if(this.sidePanelId === "more-details-panel"){
      this.showSidePanel = false;
      this.panelCloseEvent.emit();
    }
    else if (this.autoCloseWarning) this.confirmationBoxHelper.getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
      .then(() => {
        this.showSidePanel = false;
        this.panelCloseEvent.emit();
      });

    else {
      this.showSidePanel = false;
      this.panelCloseEvent.emit();
    }
  }

  // Publish events on panel open
  onPanelOpen() {
    this.ajsScope.$emit('sidepanel:open', this._panelName);
  }

  // Publish events on panel close
  onPanelClose() {
    this.ajsScope.$emit(`'sidepanel:${this._panelName}:closed`);
    this.ajsScope.$emit('sidepanel:closed');
  }
}
