<form *ngIf="statusForm" [formGroup]="statusForm">
    <!-- Header -->
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>
    <!-- Content -->
    <div class="modal-body">
        <div class="help_section" *ngIf="this.screen == 'opportunity'">
            <div class="help-text" *ngIf="isEdit" translate>
                Stages.opp.configure.text.edit
            </div>
            <div class="help-text" *ngIf="!isEdit" translate>
                Stages.opp.configure.text.add
            </div>
        </div>
        <div class="help_section" *ngIf="this.screen == 'job' || this.screen == 'stage'">
            <div class="help-text" *ngIf="isEdit" translate>
                Stages.job.configure.text.edit
            </div>
            <div class="help-text" *ngIf="!isEdit" translate>
                Stages.job.configure.text.add
            </div>
        </div>
        <div class="body-content-section">
            <div class="row-fluid">
                <div class="span12">

                    <div class="control-group clearfix bottom-space">
                        <label class="control-label required_field" translate>Status.name</label>
                        <div class="controls">
                            <input type="text" class="control-input status-name-input" formControlName="name" appDebounceTimer required>
                        </div>
                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
							<span *ngIf="f.name.errors.required" class="control-error-message show">
								<span translate>Please.enter.valid.stage.name</span>
							</span>
                        </div>
<!--                        <span *ngIf="uniqueName && !f.name.valid" class="control-error-message show">-->
<!--							<span translate>This.status.name.already.exists</span>-->
<!--						</span>-->
                    </div>

                    <div class="control-group clearfix color-bottom-space">
                        <label class="control-label required_field" translate>Colour</label>
                        <div class="width-inherit">
                            <div class="cs-color-palatte dropdown">
                                <ng-container>
                                    <span class="color-box color-box-top-align" data-toggle="dropdown" [style.background]="changedColor"></span>
                                </ng-container>
                                <input class="picker-color" readonly autocomplete="off" formControlName="color" data-toggle="dropdown"  [(ngModel)]="changedColor">
                                <input class="cs-transparent" readonly autocomplete="off" data-toggle="dropdown"
                                       [value]="getTransparencyLevel(changedColor)">
                                <div class="dropdown-menu dropdown-height">
                                    <color-palatte [data]="myValue" (colorValue)="getColor($event)"></color-palatte>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="control-group" *ngIf="this.screen == 'stage'">
                        <label class="control-label required_field view-iocn-label mb-8" translate>View icon</label>
                        <div class="controls">
                            <div class="drop-down-wrapper icon-section flex-basis-70 flex-grow-1">
                                <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                                    <a href="javascript: void(0)" id="button-basic" dropdownToggle
                                       class="btn btn-primary dropdown-toggle icon-select label-padding-0"
                                       *ngIf="!selectedIcon" translate>
                                        Con.Click.Select.Icon
                                    </a>

                                    <div class="selected-icon d-flex gap-16-pxl" *ngIf="selectedIcon">
                                        <div class="preview-icon">
                                            <div class="d-flex" *ngIf="selectedIconType">
                                                <img *ngIf="selectedIconType === 'image'" [src]="selectedIcon" alt="">
                                                <i *ngIf="selectedIconType === 'FA'"
                                                   [class]="'icon fas '+selectedIcon"></i>
                                            </div>
                                        </div>
                                        <div class="action-wrapper">
                                            <div class="change-remove-action d-flex gap-16-pxl">
                                                <a href="javascript: void(0)" dropdownToggle
                                                   class="btn btn-primary dropdown-toggle button-space icon-select"
                                                   translate>
                                                    Con.Change.Icon
                                                </a>

                                                <a href="javascript: void(0)" id="remove-icon"
                                                   class="btn btn-primary icon-select" translate (click)="removeIcon()">
                                                    Con.Remove.Icon
                                                </a>
                                            </div>
                                            <p class="help-text mt-8 icon-help-text" translate>
                                                This icon will appear on the jobs view.</p>
                                        </div>
                                    </div>
                                    <div id="dropdown-list-icon" class="dropdown-menu"
                                         (click)="stopEventPropagation($event)">
                                        <app-icon-list *ngIf="defaultIconsList.length"
                                                       [style.visibility]="dropdown.isOpen ? 'visible' : 'hidden'"
                                                       [dropdownInstance]='dropdown'
                                                       [defaultIconsUrlList]="defaultIconsList"
                                                       [uploadedIconsUrlList]="uploadedIconsListUrls"
                                                       (selectedIcon)="setSelectedIcon($event)">
                                        </app-icon-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="control-group clearfix" *ngIf="this.screen == 'job' || this.screen == 'stage' ">
                        <label class="control-label bold-label" translate>Rules</label>
                        <div class="expiry-block">
                            <div class="rules-checkbox-div">
                                <div>
                                    <input type="checkbox" class="input-checkbox" [checked]="isJobComplete != 'false'" (click)="stageCompleteChecked()" id="complete"
                                           value="0">
                                </div>
                                <div>
                                    <label for="complete" translate>enable.job.complete.on.stage</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="control-group clearfix" *ngIf="this.screen == 'opportunity'">
                        <label class="control-label" translate>Rotting.days</label>
                        <div class="controls">
                            <input type="text" class="control-input rotting-input" onkeypress="return /[0-9]/i.test(event.key)" maxlength="10" formControlName="rotting" appDebounceTimer>
                        </div>
                        <!--<div *ngIf="f.rotting.invalid && (f.rotting.dirty || f.rotting.touched)">
							<span *ngIf="f.rotting.errors.required" class="control-error-message show">
								<span translate>Please.enter.valid.rotting.days</span>
							</span>
                        </div>-->
                    </div>

                    <div class="control-group clearfix" *ngIf="this.screen == 'opportunity'">
                        <label class="control-label bold-label" translate>Rules</label>
                        <div class="expiry-block">
                            <div class="rules-checkbox-div">
                                <div>
                                <input type="checkbox" class="input-checkbox" [checked]="stageRules[0]?.checked" (click)="checkboxChecked(0)" id="rules0" name="rules"
                                       value="0">
                                </div>
                                <div>
                                <label for="rules0" translate>
                                    enable.rules.to.stop.users.from.moving.opportunity.stage</label>
                                </div>
                            </div>
                            <div class="control-group clearfix mb-2" *ngIf="stageRules[0]?.checked">
                                <div class="controls span6">
                                    <div class="radio-input-position">
                                        <div class="check-box-block checkbox-text-container option-margin">
                                            <label class="radio curser-pointer">
                                                <input class="form-check-input custom-radio-rules" type="radio"
                                                       name="rolesList"  (change)="optionChange(0,0)" [checked]="this.stageRules[0]['radioOptions'][0].selected" />
                                                <span></span>
                                                <div class="rules-text-area">
                                                    <span>No one</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="check-box-block checkbox-text-container option-margin">
                                            <label class="radio curser-pointer">
                                                <input class="form-check-input custom-radio-rules" type="radio"
                                                       name="rolesList"  (change)="optionChange(0,1)" [checked]="this.stageRules[0]['radioOptions'][1].selected" />
                                                <span></span>
                                                <div class="rules-text-area">
                                                    <span>Roles</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="check-box-block checkbox-text-container option-margin">
                                            <label class="radio curser-pointer">
                                                <input class="form-check-input custom-radio-rules" type="radio"
                                                       name="rolesList" (change)="optionChange(0,2)" [checked]="this.stageRules[0]['radioOptions'][2].selected"/>
                                                <span></span>
                                                <div class="rules-text-area">
                                                    <span>Users</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="control-group mb-2 clearfix" *ngIf="showInput">
                                            <div class="controls span6">
                                                <div class="user-input-holder">
                                                    <label *ngIf="this.stageRules[0]['radioOptions'][1].selected" class="control-label required_field">Roles</label>
                                                    <label *ngIf="this.stageRules[0]['radioOptions'][2].selected" class="control-label required_field">Users</label>
                                                    <div  *ngIf="this.stageRules[0]['radioOptions'][1].selected" class="controls select2-form-container input-padding">
                                                        <select  csSelect2 class="full-width-select2 outRoles"
                                                                [select2Config]="select2rolesConfig"
                                                                [multiple]="true"
                                                                (selected)="validateInput($event,0,1)">
                                                            <option value=""  [disabled]='true' translate>Please.Choose</option>
                                                            <option *ngFor="let role of roles" value="{{role?.id}}">{{role.description}}</option>
                                                        </select>
                                                    </div>
                                                    <div  *ngIf="this.stageRules[0]['radioOptions'][2].selected" class="controls select2-form-container input-padding">
                                                        <select  csSelect2 class="full-width-select2 outUsers"
                                                                 [select2Config]="select2usersConfig"
                                                                 [multiple]=true
                                                                 (selected)="validateInput($event,0,2)">
                                                            <option value=""  [disabled]='true' translate>Please.Choose</option>
                                                            <option *ngFor="let user of users" [value]="user.id">{{user.username}}</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="rules-checkbox-div" >
                                <div>
                                <input type="checkbox" class="input-checkbox" [checked]="stageRules[1]?.checked" (click)="checkboxChecked(1)" id="rules1" name="rules"
                                       value="1">
                                </div>
                                <div>
                                <label for="rules1" translate>
                                    enable.rules.to.stop.users.from.moving.opportunity.into.stage</label>
                                </div>
                            </div>
                            <div class="control-group clearfix mb-2" *ngIf="stageRules[1]?.checked">
                                <div class="controls span6">
                                    <div class="radio-input-position">
                                        <div class="check-box-block checkbox-text-container option-margin">
                                            <label class="radio curser-pointer">
                                                <input class="form-check-input custom-radio-rules" type="radio"
                                                       name="rolesList2"  (change)="optionChange(1,0)" [checked]="this.stageRules[1]['radioOptions'][0].selected" />
                                                <span></span>
                                                <div class="rules-text-area">
                                                    <span>No one</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="check-box-block checkbox-text-container option-margin">
                                            <label class="radio curser-pointer">
                                                <input class="form-check-input custom-radio-rules" type="radio"
                                                       name="rolesList2"  (change)="optionChange(1,1)" [checked]="this.stageRules[1]['radioOptions'][1].selected" />
                                                <span></span>
                                                <div class="rules-text-area">
                                                    <span>Roles</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="check-box-block checkbox-text-container option-margin">
                                            <label class="radio curser-pointer">
                                                <input class="form-check-input custom-radio-rules" type="radio"
                                                       name="rolesList2" (change)="optionChange(1,2)" [checked]="this.stageRules[1]['radioOptions'][2].selected"/>
                                                <span></span>
                                                <div class="rules-text-area">
                                                    <span>Users</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div class="control-group clearfix mb-2" *ngIf="showInStageInput">
                                            <div class="controls span6">
                                                <div class="user-input-holder">
                                                    <label *ngIf="this.stageRules[1]['radioOptions'][1].selected" class="control-label required_field">Roles</label>
<!--                                                    <input *ngIf="this.stageRules[1]['radio'][1].selected" type="text" class="control-input" [value]="this.stageRules[1]['radio'][1]?.value" (input)="validateInput($event,1,1)" >-->
                                                    <label *ngIf="this.stageRules[1]['radioOptions'][2].selected" class="control-label required_field">Users</label>
<!--                                                    <input *ngIf="this.stageRules[1]['radio'][2].selected" type="text" class="control-input" [value]="this.stageRules[1]['radio'][2]?.value" (input)="validateInput($event,1,2)" >-->
                                                    <div  *ngIf="this.stageRules[1]['radioOptions'][1].selected" class="controls select2-form-container input-padding">
                                                        <select  csSelect2 class="full-width-select2 inRoles"
                                                                 [select2Config]="select2rolesConfig"
                                                                 [multiple]="true"
                                                                 (selected)="validateInput($event,1,1)">
                                                            <option value=""  [disabled]='true' translate>Please.Choose</option>
                                                            <option *ngFor="let role of roles" value="{{role.id}}">{{role.description}}</option>
                                                        </select>
                                                    </div>
                                                    <div  *ngIf="this.stageRules[1]['radioOptions'][2].selected" class="controls select2-form-container input-padding">
                                                        <select  csSelect2 class="full-width-select2 inUsers"
                                                                 [select2Config]="select2usersConfig"
                                                                 [multiple]="true"
                                                                 (selected)="validateInput($event,1,2)">
                                                            <option value=""  [disabled]='true' translate>Please.Choose</option>
                                                            <option *ngFor="let user of users" value="{{user.id}}">{{user.username}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="rules-checkbox-div">
                                <div>
                                <input type="checkbox" class="input-checkbox" [disabled]="previousStageEnable == true"
                                    [checked]="stageRules[2]?.checked" (click)="checkboxChecked(2)" id="rules2" name="rules"
                                       value="2">
                                </div>
                                <div>
                                <label for="rules2" translate>
                                    enable.rules.to.stop.users.from.moving.opportunity.into.previous.stage</label>
                                </div>
                            </div>
                            <div class="control-group clearfix"  *ngIf="stageRules[2]?.checked">
                                <div class="controls span6">
                                        <div class="input-holder input-checkbox3">
                                            <label class="control-label required_field">Which stage must opportunity be
                                                in to be moved to this stage? </label>
                                            <div class="controls">
                                                <select
                                                    class="chosen-select full-width-select controls_input control-input"
                                                    id="cs-select-previous-stage" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="selectedStageId"
                                                    (selected)="onStageSelect($event)" csSelect2
                                                    [select2Config]="{width: '100%'}">
                                                <option value="" [disabled]="true" translate="">Please.Choose</option>
                                                <option *ngFor="let report of stageData" [value]="report.id">
                                                    {{ report?.name }}
                                                </option>
                                            </select>
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <div class="rules-checkbox-div" [ngClass]="stageRules[3]?.checked  ? '': 'mb-16'">
                                <div>
                                <input type="checkbox" class="input-checkbox" [checked]="stageRules[3]?.checked" (click)="checkboxChecked(3)" id="rules3" name="rules"
                                       value="3">
                                </div>
                                <div>
                                <label for="rules3" translate>
                                    enable.rules.to.block.users.from.sending.proposals.to.customer.opportunity.in.stage</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="modal-footer">
        <a class="btn" (click)="bsModalRef.hide()"><span id="cancel-styles">Cancel</span></a>
        <button class="btn btn-primary" [ngClass]="{'disabled-btn': !statusForm.valid || hideSave || outStageCheckBox || inStageCheckBok || saving || previousStageCheck }" (click)="savePipelineStatus()">
            <span translate>Save</span>
        </button>
    </div>
</form>
