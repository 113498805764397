import { Component, EventEmitter, HostListener, Inject, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AddOpportunityService } from "@app/features/customers/add-opportunity/service/add-opportunity.service";
import { FolderService } from '@app/shared/common-folder-structure/service/folder.service';
import { CsActivityLogEventService } from "@app/shared/cs-activity-log/cs-activity-log-event.service";
import { SharedModalService } from '../service/shared-modal.service';
import { CsToastBoxService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
import { CommonFilterService } from "@app/shared-modal/email-modal/common-filter.service";
import {ICsActivityLogFilterParams} from "@app/shared/cs-components/cs-components.interface";
import {PropertyViewService} from "@app/features/customers/customer/property-view/service/property-view.service";
import {CommonService} from "@app/services";
import { ViewVehicleService } from '@app/features/fleet-management/add-vehicle/services/view-vehicle-service';
import {TimelineService} from "@app/shared/services/timeline.service";
import {AuthService} from "@app/core";
import {EmailListenerService} from "@app/shared-modal/email-modal/email-listener.service";

declare var $: any;
declare var tinymce: any;
@Component({
    selector: 'app-email-modal',
    templateUrl: './email-modal.component.html',
    styleUrls: ['../../shared/communication-modals/communication-modal.scss', './email-modal.component.scss']
})
export class EmailModalComponent implements OnInit {
    emailForm: FormGroup;
    templateForm: FormGroup;
    attachedFiles: any = [];
    attachmentSearchValues: any = [];
    fromAddress: any = [];
    modalBack: boolean = false;
    attachNotFound: boolean = false;
    attachments: any = [];
    attachmentFiles: any = [];
    selectedFiles: any = [];
    showEmailError: boolean = false;
    emailErrorMessage: string = '';
    emailActivityShow: boolean = false;
    completed: boolean = true;
    select2Config = {};
    toAddresses: any = '';
    ccAddresses: any = '';
    customerContacts: any = [];
    ccContacts: any = [];
    notFound: boolean = false;
    @Input() scheduled: boolean = false;
    templateList: any = [];
    templateSearchList: any = [];
    templateData: any = {};
    editor: any;
    attached_files_view_limit: number = 3;
    emailDraft: boolean = false;
    scheduledData: any;
    subscriptions: any = {};
    activityIsScheduled: Array<any> = [];
    todayActivityId: any;
    totalSizeofAttachment: any = 0;
    sendButtonDisabled: boolean = true;
    pdfFiles: any = [];
    sendPdf: boolean = false;
    footerMessage: string = '';
    tinyMCEIntervel: any;
    interval: any;
    attachmentSize: any;
    isLargeFileSize: boolean = false;
    isGetEmailTemplates: boolean = true;
    showCc: boolean = false;
    showAttachfileButton: boolean = false;
    @Input() customerId: number;
    @Input() opportunityId: number;
    @Input() opportunityTemplateId: number;
    @Input() activityList: any;
    @Input() checkTodayActivity: any;
    @Input() showEmailModal: boolean = false;
    @Input() currentUserEmail: string = '';
    @Input() selectedActivity: any;
    @Input() pdfData: object;
    @Output() onSave: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input() isCommonRoute: boolean = false;
    @Input() tablePKId: number;
    @Input() screenType: string;
    @Input() selectedToMail: string;
    todayEmailActivity: any = [];
    @Input() screen: string = "email_template";
    screenLabel: string = "Email template";
    showTemplateList: boolean = false;
    showAddNewTemplate: boolean = true;
    @Input() isCallFromCsActivityLog = false; // Note: This is used for modification according to `cs-activity-log.component`.
    searchValue: any = '';
    parentId: any;
    timeDelay: any = "";
    filterParams: any = {
        screen: this.screen,
        parentId: '',
        search: ''
    };
    isFromJs: boolean = false;
    defaultMessage: string = '';
    defaultSelectedAttachments: any;
    tableType: any;
    scheduledActivityParams: any;
    isInitialied: boolean = false;
    proposal: string ='';
    showAttachment: boolean = false;
    afterSave: () => void;
    primaryData: any;
    completeTodayActivity: boolean = false;
    folderStructureActive: boolean = false;
    fileActive: boolean = false;
    @HostListener(' :click', ['$event'])
    clickout(event) {
        if ($(event.srcElement).closest('.dropdown').length == 0) {
            $('#drop-down-emails').removeClass("open");
        }
    }
    emailHandleTagData: any = {};
    emailModalData:any;
    scheduledActivityAccess: boolean = true;
    classAdded: boolean = false;
    restrictUnwanted:boolean = true;
    appliedFilterCondition :any;

    constructor(private sharedModalService: SharedModalService,
        private fb: FormBuilder,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private opportunityService: AddOpportunityService,
        private zone: NgZone,
        private folderService: FolderService,
        private alEvent: CsActivityLogEventService,
        private toast: CsToastBoxService,
        private translate: TranslateService,
        private commonFilterService: CommonFilterService,
        public propertyService: PropertyViewService,
        private commonService: CommonService,
        private viewVehicleService: ViewVehicleService,
        private ts:TimelineService,
        private authService: AuthService,
        private _emailEventService: EmailListenerService
    ) {
    }

    ngOnInit() {
        this.scheduledActivityAccess = this.authService.permitted(['ScheduleActivity.writeaccess']) === true;
        let addTemplatePermission = this.authService.permitted(['SetupEmailTemplates.readaccess']) === true;
        if(!addTemplatePermission){
            this.showAddNewTemplate = false;
        }
        this.sharedModalService.emailModalDetails$.subscribe((data: any) => {
            if (data) {
                if (data.showModal == true) {
                    if (this.templateData) {
                        this.templateData['message'] = '';
                        this.templateData['subject'] = '';
                    }
                    this.sendButtonDisabled = true;
                    this.emailHandleTagData = this.commonFilterService.filterData(data);
                    this.isFromJs = data.isFromJs;
                    this.showEmailModal = data.showModal;
                    this.isCallFromCsActivityLog = data['isCallFromCsActivityLog'] || false;
                    this.afterSave = data['afterSave'] || null;
                    this.loadEmailDetails(data);
                } else {
                    this.showEmailModal = data.showModal;
                }
            }
        });
        this.buildForm();
        this.getDefaultEmailFooter();
    }

    async loadEmailDetails(data) {
        this.emailModalData = data
        try {
            // Retrieve attachments, from email list, and to email list in parallel
            const attachmentUrl = this.validateURL(data.attachments_url);
            const fromEmailUrl = this.validateURL(data.from_addresses_url);
            const toEmailUrl = this.validateURL(data.recipient_addresses_url);

            let [attachmentData, fromAddress, customerContacts] = await Promise.all([
                attachmentUrl ? this.getEmailDetails(attachmentUrl) : [],
                fromEmailUrl ? this.getEmailDetails(fromEmailUrl) : [],
                toEmailUrl ? this.getEmailDetails(toEmailUrl) : []
            ]);

            this.scheduled = false;
            const attachmentKeys = { file_name: "name", file_size: "size" };
            if (data['attached_files'] && data['attached_files'].length) {
                data['attached_files'].forEach(value => {
                    value.selected = true;
                    value.attached = true;
                    value.isRemovable = false;
                    attachmentData.push(value);
                });
            }
            this.attachmentSearchValues = this.attachmentFiles = this.attachments = this.formatAttachmentData(attachmentData, attachmentKeys);
            this.fromAddress = fromAddress;
            this.currentUserEmail = (fromAddress.length > 0) ? fromAddress[0].description : '';
            this.customerContacts = JSON.parse(JSON.stringify(customerContacts));
            this.ccContacts = JSON.parse(JSON.stringify(customerContacts));
            this.defaultMessage = data['message'];
            this.selectedToMail = data.selectedToEmail || '';
            this.tablePKId = data.relatedObjectId || '';
            this.tableType = data.relatedObjectType || '';
            this.screenType = data.screenType || '';
            this.customerId = data.customerId || '';
            this.scheduledActivityParams = data.scheduledActivityParams || '';
            this.todayEmailActivity = data.todayEmailActivity || '';
            this.proposal = data.proposal || '';
            this.pdfData = data.pdfData || '';
            if(this.pdfData['pdfData']){
                this.pdfData['pdfData']['size']= parseFloat(this.pdfData['pdfData']['size'])/1024;
                this.attachments.push(this.pdfData['pdfData']);
            }
            if(data.completeTodayActivity){
                let activityParentId = (['opportunity', 'contract', 'jobs'].includes(this.screenType)) ? this.tablePKId : this.customerId;
                this.primaryData = data;
                this.propertyService.getTodayScheduleActivity(activityParentId,'Email', this.screenType).subscribe(
                    (data: any) => {
                        if (data) {
                            this.todayEmailActivity = data;
                        }
                        this.loadEmailRelated(this.primaryData)

                    });
            }else{
                this.loadEmailRelated(data)
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    loadEmailRelated(data){
        this.resetFormValues();
        this.setFooterMessage();
        this.openEmailModal();
        this.getSelectedAttachments('save');
        this.setAttachedFileSize();
        this.setDefaultValues(data);
        setTimeout(()=>{
            this.updateTinyMCEValues();
        },500);
    }

    resetFormValues() {
        $('.email-receipient').val('').trigger('change');
        $('#emailCc').val('').trigger('change');
        let defaultMessage: string = this.footerMessage;
        if (this.defaultMessage) {
            defaultMessage = this.defaultMessage + this.footerMessage;
        }
        if (this.ccAddresses) {
            this.ccAddresses = '';
        }
        if (this.toAddresses) {
            this.toAddresses = '';
        }
        this.emailForm.get('toAddresses').setValue('');
        this.emailForm.get('ccAddresses').setValue('');
        this.emailForm.get('content').setValue(defaultMessage);
        this.emailForm.get('subject').setValue('');
        this.showCc = false;
    }

    setDefaultValues(data) {
        if (data && data.hasOwnProperty('subject')) {
            this.emailForm.get('subject').setValue(data['subject']);
        }
        this.interval = setInterval(() => {
            this.setChangeDetector();
        }, 50);

        if (this.selectedToMail) {
            this.scheduled = false;
            let index = this.customerContacts.findIndex(x => x.description == this.selectedToMail);
            if (index === -1) {
                setTimeout(() => {
                    this.addEmailAddress(this.selectedToMail);
                }, 500);
            }
            else {
                setTimeout(() => {
                    this.checkMailAddressStatus(this.customerContacts[index].id)
                }, 300);
            }
        }

        if(data.screenType == 'opportunity' && data.proposal != 'proposal'){
            this.scheduled = true;
        }
        this.showAttachment = (data.proposal == 'proposal') ? true : false;

        if (this.todayEmailActivity.length > 0) {
            this.emailActivityShow = true;
        }
    }

    checkMailAddressStatus(id) {
        $('.email-receipient option').each(function (i, element) {
            let optionValue = element.value;
            let value = optionValue.split(': ').pop();
            if (value == id) {
                $('.email-receipient').val(optionValue).trigger('change');
            }
        });
        this.getToAddress(true);
    }

    validateURL(url: string) {
        if (url && url.startsWith('/')) {
            return url.substring(1);
        }
        return url;
    }

    async getEmailDetails(url): Promise<any> {
        try {
            if(this.emailModalData.screenType === 'vehicle' && this.emailModalData.recipient_addresses_url === url){
               const data = this.viewVehicleService.getEmailAddressList(url).toPromise();
               if (data) {
                    return data;
                } else {
                    throw new Error('No data received');
                }
            }
            else{
                const data = await this.sharedModalService.getEmailModalDetails(url).toPromise();
                if (data) {
                    return data;
                } else {
                    throw new Error('No data received');
                }
            }
        } catch (error) {
            console.error('Error fetching email details:', error);
            throw error; // rethrow the error to be caught by the caller
        }
    }

    formatAttachmentData(attachmentData, attachmentKeys) {
        let data = attachmentData.map(function (obj) {
            var newObj = {};
            Object.keys(obj).forEach(function (key) {
                newObj[attachmentKeys[key] || key] = obj[key];
            });
            return newObj;
        });
        return data;
    }

    openEmailModal() {
        this.emailDraft = false;
        setTimeout(() => {
            $(".modal-header").css("background", "#5E7F7D");
            $('.emailModal').modal('show');
            $(".modal-dialog").addClass("modal-dialog-open").css("display", "block");
        }, 500);
    }

    setFooterMessage() {
        // this.tinyMCEIntervel = setInterval(() => {
        this.updateTinyMCEValues();
        // }, 50);
        let signatureMessage = this.footerMessage;
        if (this.defaultMessage) {
            signatureMessage = this.defaultMessage + this.footerMessage;
        }
        this.emailForm.get('content').setValue(signatureMessage);
    }

    setChangeDetector() {
        let self = this;
        if ($('.email-receipient .select2-input').length) {
            $('.email-receipient .select2-input').on("input", function (e) {
                let inputtedValue = e.target.value;
                let inputtedValueWithoutTrailingCharacter = inputtedValue.substring(0, inputtedValue.length - 1);
                // Check if the input ends in a space
                let lastChar = inputtedValue.slice(-1);
                if ([" ", ","].indexOf(lastChar) > -1) {
                    self.addEmailAddress(inputtedValueWithoutTrailingCharacter);
                }
            });
            $('.email-receipient .select2-input').on("keyup", function (e) {
                if (e.keyCode == 13) {
                    var inputtedValue = e.target.value;
                    self.addEmailAddress(inputtedValue);
                }
            });
            if (this.interval) clearInterval(this.interval);
        }
    }

    addNewRecipient(emailAddress, contactList, selectElement, addressGetter) {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmailAddress = emailRegex.test(emailAddress);
        const emailAddressAlreadyExists = contactList.find((contact) => contact.description === emailAddress);

        if (isEmailAddress && !emailAddressAlreadyExists) {
            const newRecipient = {
                id: contactList.length,
                description: emailAddress
            };
            contactList.push(newRecipient);
            const selectedVal = $(selectElement).select2('val');

            setTimeout(() => {
                const newVal = $(selectElement).find('option:last').val();
                selectedVal.push(newVal);
                $(selectElement).select2('val', selectedVal);
                addressGetter(true);
            }, 200);
        }
    }

    addNewCcAddress(emailAddress) {
        this.addNewRecipient(emailAddress, this.ccContacts, '#emailCc', this.getCcAddress.bind(this));
    }

    addEmailAddress(emailAddress) {
        setTimeout(() =>{
            $('.email-receipient').click().trigger('change')
        },200)
        this.addNewRecipient(emailAddress, this.customerContacts, '#emailTo', this.getToAddress.bind(this));
    }

    buildForm() {
        if (this.emailForm) {
            this.emailForm.reset();
        }
        this.emailForm = this.fb.group({
            "toAddresses": [""],
            "fromAddress": [""],
            "ccAddresses": [""],
            "subject": ["", Validators.required],
            "activityType": [""],
            "content": [""],
            "attachments": [""]
        });
        this.templateForm = this.fb.group({
            subject: [''],
            message: ['']
        });
    }

    showCcOption() {
        setTimeout(() => {
            const ccInput = $('.cc-mail .select2-input');
            if (ccInput.length) {
                ccInput.on("input", (e) => this.handleCcInput(e));
                ccInput.on("keyup", (e) => this.handleCcKeyUp(e));
                clearInterval(this.interval);
            }
        }, 300);
        this.showCc = true;
    }

    handleCcInput(event) {
        const inputtedValue = event.target.value.trim();
        const lastChar = inputtedValue.slice(-1);
        if ([" ", ","].includes(lastChar)) {
            const inputtedValueWithoutTrailingCharacter = inputtedValue.substring(0, inputtedValue.length - 1);
            this.addNewCcAddress(inputtedValueWithoutTrailingCharacter);
        }
    }

    handleCcKeyUp(event) {
        if (event.keyCode === 13) {
            const inputtedValue = event.target.value.trim();
            this.addNewCcAddress(inputtedValue);
        }
    }


    removeCcOption() {
        this.showCc = false;
        this.ccAddresses = '';
    }

    closeModal(emitEvent = true) {
        if (this.templateData) {
            this.templateData['message'] = '';
            this.templateData['subject'] = '';
        }
        this.updateTinyMCEValues();
        $('.emailModal').modal('hide');
        $("#email-modal-dialog").removeClass("modal-dialog-open");
        $(".template-wrapper").removeClass("template-open");
        $(".attachment-container").removeClass("attachment-open");
        $("#emailcontent").removeClass("overlay");
        this.showTemplateList = false;
        this.activityIsScheduled = [];
        this.modalBack = false;
        this.emailDraft = false;
        this.emailActivityShow = false;
        this.pdfFiles = [];
        this.pdfData = null;
        this.showEmailModal = false;
        this.sharedModalService.setEmailEmittedDetails(false);
        this.sharedModalService.setCommonModalEmittedValue(false);
        this.commonService.setMailModalCase(true);
        this.sharedModalService.updateModalStatus('others', 'email', this.showEmailModal)
        if (emitEvent)
            this.onClose.emit(false);

    }

    emailDraft_(event) {
        if (!$(event.srcElement).closest('#email-close').length) {
            if ($("#email-modal-dialog").hasClass("modal-dialog-open")) {
                this.emailDraft = !this.emailDraft;
            }
        } else {
            this.closeModalConfirmation()
        }
    }

    openFromAddress(event) {
        event.stopPropagation();
        this.toggleDropdown();
        // $('#fromEmailDropdown').dropdown('toggle');
    }

    toggleDropdown() {
        if ($('#drop-down-emails').hasClass("open")) {
            $('#drop-down-emails').removeClass("open");
        } else {
            $('#drop-down-emails').addClass("open");
        }
    }

    getFromAddress() {
        this.opportunityService.getFromEmailAddress().subscribe(
            (data: any) => {
                if (data) {
                    data.forEach(email => {
                        this.fromAddress.push(email);
                    });
                    if (this.isCommonRoute) {
                        this.currentUserEmail = (this.fromAddress.length > 0) ? this.fromAddress[0].description : ''
                    }
                }
            }
        );

        if (this.isCallFromCsActivityLog) {
            this.alEvent.onTimelinePreloadDataEvent$.subscribe(data => {
                this.attachmentSearchValues = this.attachmentFiles = this.attachments = JSON.parse(JSON.stringify(data.attachedFiles));
            });
        } else {
            this.opportunityService.$attachedFilesList.subscribe(data => {
                if (data) {
                    this.attachmentSearchValues = this.attachmentFiles = this.attachments = JSON.parse(JSON.stringify(data));
                }
            })
        }
    }

    getDefaultEmailFooter() {
        this.opportunityService.getDefaultEmailFooter().subscribe(data => {
            if (data['message']) {
                this.footerMessage = '<br />' + data['message'];
                this.setFooterMessage();
            }
        });
    }

    getSelectedFromAddress(selectedEmail, event) {
        event.stopPropagation();
        this.currentUserEmail = selectedEmail.description;
        this.toggleDropdown();
    }

    cancelAttachments() {
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.searchFiles();
        this.getSelectedAttachments('cancel')
        this.setAttachedFileSize();
        $("#emailcontent").removeClass("overlay");
        this.modalBack = false;
        $(".attachment-container").removeClass("attachment-open");
        this.fileActive = false;
    }

    searchFiles() {
        let attachmentValues = [];
        this.attachmentSearchValues.filter(value => {
            if (value["name"].toLowerCase().includes(this.filterParams.search.toLowerCase())) {
                attachmentValues.push(value);
                this.attachments = attachmentValues;
            }
            if (attachmentValues.length == 0) {
                this.attachNotFound = true
            } else {
                this.attachNotFound = false
            }
        })
    }

    onNativeChange(event) {
        let selectedAttachment = this.attachments.find(data => data.id == event.target.value);
        let attachmentIndex = this.attachments.findIndex(data => data.id == event.target.value);
        if (selectedAttachment) {
            let size = this.attachments[attachmentIndex].size;
            if (selectedAttachment.hasOwnProperty('selected')) {
                if (selectedAttachment['selected'] == true) {
                    this.attachments[attachmentIndex]['selected'] = false;
                    this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) - size).toFixed(2);
                    this.totalSizeofAttachment = (this.totalSizeofAttachment > 0) ? this.totalSizeofAttachment : 0;
                } else {
                    this.attachments[attachmentIndex]['selected'] = true;
                    this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) + size).toFixed(2);
                }
            } else {
                this.attachments[attachmentIndex].selected = true;
                this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) + size).toFixed(2);
            }
        }
        this.getSelectedAttachments()
    }

    showAttachedFiles() {
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.searchFiles();
        this.getSelectedAttachments('save')
        $(".attachment-container").removeClass("attachment-open");
        $("#emailcontent").removeClass("overlay");
        this.modalBack = false;
        this.fileActive = false;
    }

    getSelectedAttachments(status = '') {
        if (status == 'save') {
            this.attachedFiles = [];
            this.attachments.forEach((val, key) => {
                if (val.selected) {
                    this.attachedFiles.push(val);
                }
                else {
                    this.attachments[key].attached = false;
                }
            });
        }
        else if (status == 'cancel') {
            this.attachments.forEach((val, key) => {
                if (val.attached == true && val.selected == false) {
                    this.attachments[key].selected = true;
                }
                if (val.selected && (val.attached == false || typeof val.attached == 'undefined')) {
                    this.attachments[key].selected = false;
                    this.attachments[key].attached = false;
                }
            });
        }
        if (this.attachments.findIndex(x => x.selected == true) !== -1) {
            this.showAttachfileButton = true;
        }
        else {
            this.showAttachfileButton = false;
        }
        if(this.attachedFiles.length > 0 && !this.classAdded){
            $("#email-template-message_ifr").addClass("bottom-space-mail");
            this.classAdded = true;
        }
        if(this.attachedFiles.length == 0  && this.classAdded){
            $("#email-template-message_ifr").removeClass("bottom-space-mail");
            this.classAdded = false;
        }
    }

    returnEmail() {
        $(".email-error-message").removeClass("message-open");
        this.showEmailError = false;
        this.emailErrorMessage = '';
    }

    completeActivity(event) {
        this.completed = event.target.checked;
    }

    getToAddress(toAddresses) {
        if (toAddresses) {
            this.setAddresses('#emailTo', 'toAddresses');
        }
    }

    getCcAddress(toAddresses) {
        if (toAddresses) {
            this.setAddresses('#emailCc', 'ccAddresses');
        }
    }

    setAddresses(elementId, addressType) {
        this[addressType] = '';
        let selectedData = $(elementId).select2('data');
        if (selectedData && selectedData.length) {
            this[addressType] = selectedData.map(data => data.text.trim()).join(',');
            addressType == 'toAddresses' ? this.sendButtonDisabled = false : '';
        } else {
            addressType == 'toAddresses' ? this.sendButtonDisabled = true : ''
        }
    }


    deleteAttachedFile(selectedFile) {
        let index = this.attachments.findIndex(x => x.id == selectedFile.id);
        if (index !== -1) {
            this.attachments[index].selected = false;
            this.attachments[index].attached = false;
        }
        this.setAttachedFileSize();
        this.getSelectedAttachments('save')
    }

    setAttachedFileSize() {
        this.totalSizeofAttachment = 0;
        this.attachedFiles.forEach((val, key) => {
            if (val.selected == true) {
                this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) + val.size).toFixed(2);
            }
        });
    }

    showTemplateOptions() {
        this.searchValue = '';
        this.notFound = false;
        this.modalBack = true;
        $(".template-wrapper").addClass("template-open");
        this.showTemplateList = true;
        $("#emailcontent").addClass("overlay");
        this.templateList = [];
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.folderStructureActive = true;
    }

    showAttachments() {
        this.attachments.forEach(data => {
            this.attachmentSize = parseInt(this.totalSizeofAttachment) + data.size;
            if (this.attachmentSize > 16) {
                this.isLargeFileSize = true;
            }
            else {
                this.isLargeFileSize = false;
            }
        })
        this.attachNotFound = false;
        // this.selectedFiles = [];
        if (this.attachedFiles.length != 0) {
            for (let i = 0; i < this.attachedFiles.length; i++) {
                this.attachedFiles[i].attached = true;
            }
        }
        $("#attachmentId").val('');
        this.modalBack = true;
        $(".attachment-container").addClass("attachment-open");
        $("#emailcontent").addClass("overlay");
        this.fileActive = true;
    }

    isScheduledActivity(event) {
        this.scheduled = event.target.checked
    }

    searchTemplates(event) {
        let templateValues = [];
        this.templateSearchList.filter(value => {
            if (value["templateName"].toLowerCase().includes(event.target.value.toLowerCase())) {
                templateValues.push(value);
                this.templateList = templateValues
            }
            this.notFound = templateValues.length == 0;
        });
    }

    selectedTemplate(id) {
        let selectedTemplate = this.templateList.find(data => data.id == id);
        this.setTemplateDetails(selectedTemplate);
    }

    setTemplateDetails(selectedTemplate) {
        this.emailHandleTagData['message'] = selectedTemplate['message'];
        this.emailHandleTagData['subject'] = selectedTemplate['subject'];
        this.folderService.getReplacedMessage(this.emailHandleTagData).subscribe((data: any) => {
            if (data) {
                this.emailForm.get('subject').setValue(data['subject']);
                data['message'] = data['message'] + this.footerMessage;
                this.emailForm.get('content').setValue(data['message']);
                this.templateData = JSON.parse(JSON.stringify(data));
                this.cancelTemplate();
                this.updateTinyMCEValues();
            }
        })
        $(".template-wrapper").removeClass("template-open")
        $("#emailcontent").removeClass("overlay");
        this.showTemplateList = false;
        this.modalBack = false;
        this.folderStructureActive = false;
    }

    beginCloseWidget: boolean = false;
    sendEmail() {
        // Validate email modal
        this.emailForm.get('toAddresses').setValue(this.toAddresses);
        this.emailForm.get('ccAddresses').setValue(this.ccAddresses);
        if (!this.emailForm.value['toAddresses']) {
            $(".email-error-message").addClass("message-open");
            this.showEmailError = true;
            this.emailErrorMessage = 'Please choose a recipient';
        } else if (!this.emailForm.value['subject']) {
            $(".email-error-message").addClass("message-open");
            this.showEmailError = true;
            this.emailErrorMessage = 'You have not written a subject'
        } else {
            this._emailEventService.send(this);
            setTimeout(() => {
                this.beginCloseWidget = true;
                setTimeout(() => {
                    this.beginCloseWidget = false;
                    this.closeModal(true);
                }, 300)
            }, 0)
        }
    }

    processEmail() {
        this._emailEventService.beforeSend('hi before');
        this.sendButtonDisabled = true;
            this.showEmailError = false;
            let emailData;
            this.emailForm.controls['activityType'].setValue("Email");
            let attachedArray = [];
            for (let i = 0; i < this.attachedFiles.length; i++) {
                attachedArray.push(this.attachedFiles[i]['id']);
            }
            this.emailForm.controls['attachments'].setValue(attachedArray.join());
            this.emailForm.controls['fromAddress'].setValue(this.currentUserEmail);
            this.emailForm.controls['toAddresses'].setValue(this.toAddresses);
            emailData = this.emailForm.value;
            if (this.completed) {
                emailData = this.emailForm.value
                let activityId = []
                if (this.activityIsScheduled.length != 0) {
                    for (var i = 0; i < this.activityIsScheduled.length; i++) {
                        activityId.push(this.activityIsScheduled[i]['activityId'])
                    }
                } else {
                    if (this.scheduledData) {
                        if (this.scheduledData.hasOwnProperty('activity_id')) {
                            activityId.push(this.scheduledData.activity_id)
                        } else {
                            activityId.push(this.scheduledData.activityId)
                        }
                    }
                }
                emailData.activityIds = activityId;
                if (this.todayActivityId) {
                    emailData.activityIds = this.todayActivityId;
                }
            }
            let customerContact = (this.customerContacts.length > 0) ? this.customerContacts[0].id : '';
            let subject = this.emailForm.get('subject').value;
            let body = this.emailForm.get('content').value;
            const formData: any = new FormData();
            formData.append('contactId', customerContact);
            formData.append('tableType', this.tableType);
            formData.append('screenType', this.screenType);
            formData.append('tablePKID', this.tablePKId);
            formData.append('toAddresses', this.toAddresses);
            formData.append('fromAddress', this.currentUserEmail);
            if (this.ccAddresses) {
                formData.append('ccAddress', this.ccAddresses);
            }
            formData.append('body', body);
            formData.append('subject', subject);
            formData.append('attachments', attachedArray.join());
            formData.append('proposal', this.proposal);
            if(this.emailModalData.screenType === 'vehicle'){
                this.viewVehicleService.onSaveEmail(formData).subscribe(data => {
                    if(data){
                        // this.closeModal(true);
                        this.showEmailModal = false;
                        this.ts.loadVehicleTimelineData();
                        this.emailForm.reset();
                    }
                })
            }
            else{

                this.sharedModalService.saveEmail(formData, {
                    '___noPageloading': '1'
                }).subscribe(data => {
                    if (data) {
                        if (this.isCallFromCsActivityLog) {
                            if (this.afterSave) this.afterSave();
                        }

                        if (this.isFromJs) {
                            this.ajsScope.$broadcast('email:email_sent');
                            let data = { 'id': this.tablePKId, 'type': this.tableType };
                            this.ajsScope.$broadcast('event:sentStatus', data);
                        }
                        else {
                            this.afterMailSend();
                        }
                        // this.closeModal(true);
                        // this.closeEmailWidget(true);
                        // this.showEmailModal = false;
                        if(this.screenType == 'customer'){
                            this.propertyService.setRefreshSliderScheduleActivity(true);
                        }
                        if (this.screenType == 'opportunity') {
                            let refreshData: any;
                            if (this.proposal == 'proposal') {
                                refreshData = {
                                    data: {
                                        screen: 'addEmail',
                                        type: 'add',
                                        values: null,
                                        id: 'latest',
                                        proposal: 'sendProposal'
                                    }
                                }
                            } else {
                                this.opportunityService.setRefreshActivityList(true);
                                refreshData = {
                                    data: {
                                        screen: 'addEmail',
                                        type: 'add',
                                        values: null,
                                        id: 'latest'
                                    }
                                }
                            }
                            this.propertyService.setTimelineRefresh(refreshData);
                        }

                    }
                });
            }

        this.sharedModalService.updateModalStatus('others', 'email', false)
    }

    afterMailSend() {
        $("#emailTo").val(null).trigger("change");
        let responseData: any = true;
        if (this.completed && this.todayEmailActivity.length) {
            let activityData = { "activityIds": this.todayEmailActivity,'screen': this.screenType};
            this.opportunityService.completeScheduledActivity(activityData).subscribe(
                data => {
                    let dataSet = JSON.parse(JSON.stringify(this.filterParams));
                    dataSet.page = 1;
                    dataSet.data = data;
                    dataSet.isSearch = true;
                    if (this.isCallFromCsActivityLog) {
                        this.alEvent.notifyScheduledActivityCompletion();
                    } else {
                        this.restrictUnwanted = false;
                        this.propertyService.setTimelineRefresh(dataSet);
                        this.propertyService.setRefreshSliderScheduleActivity(true);
                    }
                }, (error: any) => {
                    console.error('error', error);
                });
        }
        if ( this.scheduled && this.scheduledActivityAccess ) {
            this.sharedModalService.setScheduledActivityDetails(this.scheduledActivityParams);
        }
        this.propertyService.timelineRefresh$.subscribe((refreshData: any) => {
            let  scheduleList, filterActivityList = [];
            let  userId,dateType= '';
            if (this.filterParams && this.filterParams.hasOwnProperty('dateType'))
                dateType = this.filterParams.dateType;
            if (this.filterParams && this.filterParams.hasOwnProperty('scheduleList'))
                scheduleList = this.filterParams.scheduleList;
            if (this.filterParams && this.filterParams.hasOwnProperty('filterActivityList'))
                filterActivityList = this.filterParams.filterActivityList;
            if (this.filterParams && this.filterParams.hasOwnProperty('userId'))
                userId = this.filterParams.userId;
            if(scheduleList != undefined || filterActivityList != undefined ||  dateType != '' || (userId != undefined && userId != '')){
                this.appliedFilterCondition = refreshData;
            }
        });
        setTimeout(()=>{
            if(this.restrictUnwanted){
                this.sharedModalService.setEmailSaveAction(responseData)
                this.restrictUnwanted = true;
            }else {
                let newDataSet = JSON.parse(JSON.stringify(this.filterParams));
                newDataSet.page = 1;
                newDataSet.isSearch = true;
                this.restrictUnwanted = true;
                if(this.completed && this.todayEmailActivity.length && this.screenType == 'customer'){

                }else if(this.appliedFilterCondition){
                    newDataSet = this.appliedFilterCondition;
                }
                this.propertyService.setTimelineRefresh(newDataSet);
            }
        },1500);
        let notificationMsg = this.translate.instant("Communication.mail.send");
        this.toast.show(notificationMsg, 1500);
        this.sendButtonDisabled = false;
        if (this.scheduledData) {
            if (this.scheduledData.hasOwnProperty('activityId')) {
                this.opportunityService.refreshCompleteActivity('complete')
            }
        }
    }

    todayActivity() {
        if (!this.isCommonRoute) {
            this.opportunityService.getScheduledEmail(this.opportunityId, 'Email').subscribe(resp => {
                if (resp) {
                    if (resp == false) {
                        this.emailActivityShow = false;
                        this.completed = false;
                    }
                    else {
                        this.emailActivityShow = true;
                        this.completed = true;
                        this.todayActivityId = resp;
                    }
                }
            })
        }
        else {
            if (this.todayEmailActivity.length > 0) {
                this.emailActivityShow = true;
            }
            else {
                this.emailActivityShow = false;
            }
        }
    }

    // for p tag remove for email template
    updateTinyMCEValues() {
        if ($('#email-template-message').length) {
            tinymce.init(
                {
                    selector: "#email-template-message",
                    menubar: false,
                    toolbar: false,
                    plugins: "paste,textcolor,autolink",
                    placeholder: 'Subject',
                    paste_as_text: true,
                    browser_spellcheck: true,
                    paste_block_drop: false,
                    relative_urls: false,
                    remove_script_host: false,
                    content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
                    setup: editor => {
                        editor.on('LoadContent', () => {
                            if (this.templateData && this.templateData['message'])
                                editor.setContent(this.templateData['message']);
                            else {
                                if (this.emailForm) {
                                    editor.setContent(this.emailForm.get('content').value);
                                }
                            }
                        });
                        this.editor = editor;
                        editor.on('keyup', () => {
                            const content = editor.getContent();
                            this.zone.run(() => this.onChange(content))
                        });
                        editor.on("click", () => {
                            $('#drop-down-emails').removeClass("open");
                        });
                    }
                });
            tinymce.init(
                {
                    selector: "#email-template-subject",
                    menubar: false,
                    toolbar: false,
                    statusbar: false,
                    plugins: "paste",
                    browser_spellcheck: true,
                    paste_as_text: true,
                    paste_block_drop: false,
                    relative_urls: false,
                    remove_script_host: false,
                    convert_urls: true,
                    content_style: "p { white-space: nowrap; } body {margin-top: 14px;}",
                    setup: editor => {
                        editor.on('init', function () {
                            if (editor.getContent() == '') {
                                editor.setContent("<p class='sub-placeholder' style='color:#999;'>Subject</p>");
                            }
                        }),
                            editor.on('LoadContent', () => {
                                if (this.templateData && this.templateData['subject'])
                                    editor.setContent(this.templateData['subject']);
                            });
                        this.editor = editor;
                        editor.on('keyup', () => {
                            const content = editor.getContent();
                            this.zone.run(() => this.onChange(content))
                        });
                        editor.on("click", () => {
                            $('#drop-down-emails').removeClass("open");
                        });
                        editor.on("blur", () => {
                            if (editor.getContent() == '') {
                                editor.setContent("<p class='sub-placeholder' style='color:#999;'>Subject</p>");
                            }
                        });
                        editor.on("focus", () => {
                            $('#email-template-subject_ifr').contents().find('.sub-placeholder').remove();
                        });
                    }
                });
            if (this.tinyMCEIntervel) clearInterval(this.tinyMCEIntervel);
        }
    }

    onChange = (_: any) => {
        this.templateForm.patchValue({
            subject: tinymce.get('email-template-subject').getContent(),
            message: tinymce.get('email-template-message').getContent(),
        });
        this.emailForm.get('content').setValue(this.templateForm.get('message').value);
        this.emailForm.get('subject').setValue(this.templateForm.get('subject').value);
        this.emailForm.updateValueAndValidity();
        this.sendButtonDisabled = false;
        this.templateForm.updateValueAndValidity();
    };

    closeModalConfirmation() {
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to discard the email?', this.ajsScope)
            .then(() => {
                this.closeModal(true);
            }).catch(() => { });
    }

    sendProposalPdf() {
        this.sendButtonDisabled = true;
        let emailData = this.emailForm.value;
        let messageData = {
            "body": emailData['content'],
            "subject": emailData['subject'],
            "attachments": this.pdfData['url'],
            "contactId": this.toAddresses['id'],
            "toAddresses": this.toAddresses,
            "fromAddress": this.currentUserEmail,
            "ccAddress": "",
            "tablePKID": this.opportunityId,
        }

        this.opportunityService.sendProposalEmailToCustomer(messageData, this.opportunityId)
            .subscribe(resp => {
                // this.onSave.emit(this.scheduled);
                this.closeModal(true);
                this.sendButtonDisabled = false;
                this.sendPdf = false;
                this.pdfFiles = [];
            }, error => {
                console.log('err', error);
            }
            );
    }
    showAttachmentName(name, size) {
        let imageSize = '(' + (size).toFixed(2) + 'MB' + ')';
        return name.concat(" ", imageSize);
    }

    closeDropdown() {
        $('#drop-down-emails').removeClass("open");
    }
    cancelTemplate() {
        $("#emailcontent").removeClass("overlay");
        this.modalBack = false;
        $(".template-wrapper").removeClass("template-open");
        this.showTemplateList = false;
        this.folderStructureActive = false;
    }
}
