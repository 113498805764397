import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable()

export class VehicleModelService extends InterfaceService {
    private screenType = new BehaviorSubject('');
    screenType$ = this.screenType.asObservable()

    private closeModal = new BehaviorSubject('');
    closeModal$ = this.closeModal.asObservable()

    private saveData = new BehaviorSubject('');
    saveData$ = this.saveData.asObservable()

    constructor(private http: HttpClient) {
        super();
    }
    // Add/Edit vehicle model
    addOrEditVehicleModel(data: object): Observable<any> {
        return this.http.post(this.getFleetApiUrl(`/addOrEditVehicleModels`), data);
    }

    // Delete vehicle model
    deleteVehicleModel(id:number,params?: any): Observable<any> {
        return this.http.delete(this.getFleetApiUrl(`/${id}/deleteVehicleModel`, params));
    }

    // Check vehiclemodel already exists
    checkVehicleModelName(params?: Object): Observable<any> {
        return this.http.get(this.getFleetApiUrl(`/is_model_exists`, params));
    }

    reorderList(data: object, type: any): Observable<any> {
        return this.http.put(this.getFleetApiUrl(`/reorderVehicleModels`), data);
    }

    getVehicleMake(type,id) {
        return this.http.get(this.getFleetApiUrl(`/getVehicleMakesDropdown?type=${type}&id=${id}`));
    }
    getModelsFilter(): Observable<any> {
        return this.http.get(this.getFleetApiUrl(`/getVehicleModelsFilters`));
    }

    checkVehicleModel(id){
        return this.http.get(this.getFleetApiUrl(`/${id}` + `/is_vehicle_model_deletable`));
    }
}
