import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { AddOpportunityService } from "@app/features/customers/add-opportunity/service/add-opportunity.service";
import * as moment from "moment";
import { Subject } from 'rxjs';
import { SharedModalService } from '../service/shared-modal.service';
import { switchMap } from 'rxjs/operators';
import { PropertyViewService } from '@app/features/customers/customer/property-view/service/property-view.service';
import { Observable } from 'rxjs';
import { CsToastBoxService } from '@app/services';
import { SettingsService } from "@app/core";
import { CsActivityLogEventService } from "@app/shared/cs-activity-log/cs-activity-log-event.service";
var ScheduledActivityModalComponent = /** @class */ (function () {
    function ScheduledActivityModalComponent(sharedModalService, fb, opportunityService, confirmationBoxHelper, propertyService, toast, ajsScope, settingsService, _alEvent) {
        this.sharedModalService = sharedModalService;
        this.fb = fb;
        this.opportunityService = opportunityService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.propertyService = propertyService;
        this.toast = toast;
        this.ajsScope = ajsScope;
        this.settingsService = settingsService;
        this._alEvent = _alEvent;
        this.selectedActivityType = 1;
        this.selectedDate = 'Today';
        this.customDate = [];
        this.select2Config = { width: '100%' };
        //   assigneeList: any = [];
        //   scheduledData: any;
        this.deleted_array = [];
        this.subscriptions = {};
        this.completed = true;
        this.dateValuesChanged = false;
        this.scheduleButtonDisabled = false;
        this.minDate = new Date();
        this.editDateTime = '';
        this.showActivityModal = false;
        this.onSave = new EventEmitter();
        this.onClose = new EventEmitter();
        this.isCommonRoute = false;
        this.isCallFromCsActivityLog = false; // Note: This is used for modification according to `cs-activity-log.component`
        this.timepickerChangeEvent = new Subject();
    }
    ScheduledActivityModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initCalender();
        this.sharedModalService.scheduledActivityDetails$.subscribe(function (activityData) {
            if (activityData) {
                _this.sharedModalService.updateModalStatus('others', 'activity', activityData.showActivityModal);
                if (activityData.showActivityModal == false) {
                    _this.closeModalWithoutConfirmation();
                }
                else {
                    $(".modal-dialog").removeClass("modal-dialog-open");
                }
                _this.isCommonRoute = activityData.isCommonRoute || false;
                _this.showActivityModal = activityData.showActivityModal || '';
                _this.listActivity = activityData.listActivity || '';
                _this.assigneeList = activityData.assigneeList || '';
                _this.scheduledData = activityData.scheduledData || null;
                _this.screenType = activityData.screenType || '';
                _this.tablePkId = activityData.tablePkId || '';
                _this.opportunityId = activityData.opportunityId || '';
                _this.isCallFromCsActivityLog = activityData['isCallFromCsActivityLog'] || false;
                _this.afterSave = activityData['afterSave'] || null;
                _this.openActivityModal();
            }
        });
    };
    ScheduledActivityModalComponent.prototype.openModal = function () {
        setTimeout(function () {
            $(".modal-header").css("background", "#5E7F7D");
            $('.activityModal__').modal('show');
            $(".modal-dialog").addClass("modal-dialog-open");
        }, 500);
    };
    ScheduledActivityModalComponent.prototype.openActivityModal = function () {
        var _this = this;
        this.recordBasedAssigneeList = this.assigneeList;
        if (this.showActivityModal) {
            this.countryDetails = this.settingsService.getCountryCode();
            if (!this.scheduledData || this.scheduledData == 'reschedule') {
                this.buildForm();
                this.openModal();
            }
            else {
                if (this.scheduledData.checked) {
                    if (this.scheduledData.activityCompleted) {
                        this.completed = false;
                    }
                    else {
                        this.completed = true;
                    }
                }
                if (this.scheduledData.isScheduled) {
                    var selectedActivity = null;
                    this.buildForm();
                    this.openModal();
                    if (this.listActivity) {
                        selectedActivity = this.listActivity.find(function (item) { return item.name == _this.scheduledData.activityType; });
                        this.selectedActivityType = selectedActivity.id;
                    }
                    if (selectedActivity) {
                        this.scheduleForm.controls['activityType'].setValue(selectedActivity.id);
                    }
                    this.scheduleForm.controls['description'].setValue(this.scheduledData.description);
                    this.scheduleForm.controls['notes'].setValue(this.scheduledData.notes);
                    if (this.scheduledData.recordType == 'Opportunities') {
                        this.recordBasedAssigneeList = this.recordBasedAssigneeList.filter(function (item) { return item.salesroleid !== '8' && item.salesroleid !== null; });
                    }
                    else {
                        //sales dispatcher license role is 8, we are restricting lite user to assign scheduled activity
                        this.recordBasedAssigneeList = this.recordBasedAssigneeList.filter(function (item) { return !(item.salesroleid === '8' && item.jmsroleid === null); });
                    }
                    if (this.scheduledData.assignees.length != 0) {
                        var assignedUserId_1 = [];
                        for (var i = 0; i < this.scheduledData.assignees.length; i++) {
                            assignedUserId_1.push(this.scheduledData.assignees[i].id);
                        }
                        // below set of code to handle select plugin issue while restricting record based assignee
                        setTimeout(function () {
                            var dataSet = [];
                            $('#assignTo').find('option').each(function (key, val) {
                                var rowData = $(val).val();
                                var assigneeArray = rowData.split(': ').pop();
                                var checkKey = assignedUserId_1.includes(parseInt(assigneeArray, 10));
                                if (checkKey) {
                                    dataSet.push(rowData);
                                }
                            });
                            $('#assignTo').val(dataSet).trigger('change');
                        }, 500);
                    }
                    var date = new Date(this.scheduledData.dateToShow);
                    var scheduledDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                    this.activityDateEdit = scheduledDate;
                    if (this.activityDateEdit) {
                        $('#scheduledDate').addClass('input-width');
                        $('#scheduledDate').removeClass('input-date-width');
                        $('#scheduledDate').removeClass('date-width');
                    }
                    if (this.countryDetails['code'] == 44) {
                        this.selectedDate = moment(this.scheduledData.dateToShow).format('DD/MM/YYYY');
                    }
                    else if (this.countryDetails['code'] == 1) {
                        this.selectedDate = moment(this.scheduledData.dateToShow).format('MM/DD/YYYY');
                    }
                    this.scheduleEditDate = this.selectedDate;
                    this.scheduleForm.controls['scheduledDate'].setValue(this.selectedDate);
                    var editDate = new Date(moment(this.scheduledData.dateToShow).format('YYYY-MM-DD HH:mm:ss'));
                    this.scheduleForm.controls['scheduleTime'].setValue(editDate);
                    this.editDateTime = editDate;
                    this.scheduleForm.updateValueAndValidity();
                    $(".modal-header").css("background", "#5E7F7D");
                    $('.activityModal__').modal('show');
                    $(".modal-dialog").addClass("modal-dialog-open");
                }
                else {
                    this.closeModalWithoutConfirmation();
                }
            }
        }
        else {
            this.closeModalWithoutConfirmation(false);
        }
    };
    ScheduledActivityModalComponent.prototype.buildForm = function () {
        var _this = this;
        this.dateValuesChanged = true;
        this.scheduleForm = this.fb.group({
            'assigneeIds': [''],
            'notes': [''],
            'description': ['', Validators.required],
            'scheduledDate': [''],
            'scheduleTime': [''],
            'activityType': [1]
        });
        this.scheduleForm.get('scheduledDate').setValue(this.selectedDate);
        this.selectedActivityType = 1;
        this.scheduleForm.get('activityType').setValue(this.selectedActivityType);
        var momentTime = moment();
        var reminder = 15 - (momentTime.minute() % 15);
        if (reminder == 15) {
            reminder = 0;
        }
        var roundTime = moment(momentTime).add(reminder, "minutes").format();
        var time = new Date(roundTime);
        this.scheduleForm.controls['scheduleTime'].setValue(time);
        this.scheduleForm.controls['scheduledDate']
            .valueChanges.subscribe(function (value) {
            if (_this.dateValuesChanged) {
                var momentCurrentTime = moment();
                var reminder_1 = 0;
                if (value == 'Today') {
                    reminder_1 = 15 - (momentCurrentTime.minute() % 15);
                    if (reminder_1 == 15) {
                        reminder_1 = 0;
                    }
                }
                else if (!_this.editDateTime) {
                    momentCurrentTime = moment().toDate();
                    momentCurrentTime.setHours(8);
                    momentCurrentTime.setMinutes(0);
                }
                else {
                    momentCurrentTime = _this.editDateTime;
                }
                var roundTime_1 = moment(momentCurrentTime).add(reminder_1, "minutes").format();
                var time_1 = new Date(roundTime_1);
                _this.scheduleForm.controls['scheduleTime'].setValue(time_1);
                _this.editDateTime = '';
            }
        });
        // }
    };
    ScheduledActivityModalComponent.prototype.initCalender = function () {
        var date = Date.now();
        var momentBusinessDays = require("moment-business-days");
        var tomorrow = '(' + momentBusinessDays(date).businessAdd(2).format("dddd") + ')';
        var business2days = '(' + momentBusinessDays(date).businessAdd(3).format("dddd") + ')';
        var nextmonday = '(' + moment(date).add(1, 'week').day(1).format("MMMM DD") + ')';
        var oneweek = '(' + moment(date).add(7, 'days').format("MMMM DD") + ')';
        var twoweeks = '(' + moment(date).add(14, 'days').format("MMMM DD") + ')';
        var onemonth = '(' + moment(date).add(1, 'M').format("MMMM DD") + ')';
        var threemonths = '(' + moment(date).add(3, 'M').format("MMMM DD") + ')';
        var sixmonths = '(' + moment(date).add(6, 'M').format("MMMM DD") + ')';
        this.customDate = [{ 'id': 1, 'text': "Today", 'view_text': 'Today', 'name': '' },
            { 'id': 2, 'text': 'Tomorrow', 'view_text': 'Tomorrow', 'name': '' },
            { 'id': 3, 'text': 'in.business.days', 'text_days': '2', 'view_text': 'in.2.business.days', 'name': tomorrow },
            { 'id': 4, 'text': 'in.business.days', 'view_text': 'in.3.business.days', 'text_days': '3', 'name': business2days },
            { 'id': 5, 'text': 'next.monday', 'view_text': 'next.monday', 'text_days': 'Next', 'name': nextmonday },
            { 'id': 6, 'text': 'in.1.week', 'view_text': 'in.1.week', 'text_days': '1', 'name': oneweek },
            { 'id': 7, 'text': 'in.weeks', 'view_text': 'in.2.weeks', 'text_days': '2', 'name': twoweeks },
            { 'id': 8, 'text': 'in.1.month', 'view_text': 'in.1.month', 'text_days': '1', 'name': onemonth },
            { 'id': 9, 'text': 'in.months', 'view_text': 'in.3.months', 'text_days': '3', 'name': threemonths },
            { 'id': 10, 'text': 'in.months', 'view_text': 'in.6.months', 'text_days': '6', 'name': sixmonths },
            { 'id': 10, 'text': 'Custom date', 'view_text': 'Custom_date' }];
    };
    ScheduledActivityModalComponent.prototype.scheduleTime = function (time) {
        if (time instanceof Date && time.toString() != 'Invalid Date') {
            return this.scheduleForm.value.scheduleTime.toLocaleTimeString(['en-GB'], { timeStyle: 'short' });
        }
        else if (this.scheduledTime) {
            return this.scheduledTime;
        }
        else {
            return time;
        }
    };
    ScheduledActivityModalComponent.prototype.selectedType = function (type) {
        this.selectedActivityType = type.id;
        this.scheduleForm.controls['activityType'].setValue(type.id);
    };
    ScheduledActivityModalComponent.prototype.closeModal = function (emitEvent) {
        var _this = this;
        if (emitEvent === void 0) { emitEvent = true; }
        // this.buildForm()
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to discard the schedule activity?', this.ajsScope)
            .then(function () {
            _this.closeModalWithoutConfirmation(emitEvent);
            _this.sharedModalService.updateModalStatus('others', 'activity', false);
        }).catch(function () { });
    };
    ScheduledActivityModalComponent.prototype.closeModalWithoutConfirmation = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = true; }
        this.dateValuesChanged = false;
        this.assignee = undefined;
        $("#assignTo").val(null).trigger("change");
        if (this.scheduleForm) {
            this.scheduleForm.controls['assigneeIds'].setValue('');
            this.scheduleForm.controls['scheduledDate'].setValue('');
            this.scheduleForm.controls['scheduleTime'].setValue('');
            $('.activityModal__').modal('hide');
            $(".modal-dialog").removeClass("modal-dialog-open");
        }
        this.completed = false;
        this.editId = null;
        this.scheduledData = undefined;
        this.activityDateEdit = '';
        this.selectedDate = 'Today';
        this.sharedModalService.setCommonModalEmittedValue(false);
        if (emitEvent)
            this.onClose.emit(false);
        if (this.isCallFromCsActivityLog) {
            this._alEvent.resetActivityEventData();
        }
    };
    ScheduledActivityModalComponent.prototype.getFilterByDate = function (id, text, name) {
        $('#dropdownd1').removeClass('dropdown');
        this.selectedId = id;
        if (this.selectedId == '1' || this.selectedId == '2') {
            $('#scheduledDate').addClass('input-width');
            $('#scheduledDate').removeClass('input-date-width');
            $('#scheduledDate').removeClass('date-width');
            this.selectedDate = text;
            this.selectedText = text;
            $('#caret-up').addClass('up-caret-icon');
        }
        else {
            var inputText = text.charAt(0).toUpperCase() + text.slice(1);
            this.selectedText = inputText.split('.').join('');
            this.selectedDate = inputText.split('.').join(' ') + " " + name;
            if (this.selectedId == '3' || this.selectedId == '4') {
                $('#caret-up').addClass('up-caret-icon');
                $('#caret-up').removeClass('dropdown-list-caret-icon');
            }
            else {
                $('#scheduledDate').addClass('input-date-width');
                $('#scheduledDate').removeClass('date-width');
                $('#scheduledDate').removeClass('input-width');
            }
        }
    };
    ScheduledActivityModalComponent.prototype.selectedCustom = function () {
        $('#dropdownd1').addClass('dropdown');
    };
    ScheduledActivityModalComponent.prototype.dateCreated = function ($event) {
        this.selectedDate = 'Custom date';
        this.selectedText = 'CustomDate';
        $('#caret-up').addClass('up-caret-icon');
        this.selectedCustomDate = $event;
    };
    ScheduledActivityModalComponent.prototype.getAssignee = function (data) {
        if (data) {
            var assigneeArray = [];
            for (var i = 0; i < data.length; i++) {
                assigneeArray.push(data[i].split(':')[1].trim().replace(/['"]+/g, ''));
            }
            this.assignee = assigneeArray;
        }
    };
    ScheduledActivityModalComponent.prototype.scheduledActivity = function () {
        var _this = this;
        var scheduledData;
        this.dateValuesChanged = false;
        this.scheduleButtonDisabled = true;
        if (this.selectedText == 'CustomDate') {
            this.scheduleForm.value['scheduledDate'] = this.selectedCustomDate;
        }
        else {
            this.dateConversion(this.selectedText);
            this.selectedText = '';
        }
        if (this.scheduleForm.value['scheduledDate']) {
            var date = this.scheduleForm.value['scheduledDate'].toLocaleDateString('en-GB');
            this.scheduleForm.controls['scheduledDate'].setValue(date.replaceAll("/", "/"));
        }
        if (this.scheduleForm.value['scheduleTime']) {
            var time = this.scheduleForm.value['scheduleTime'].toLocaleTimeString('en-GB');
            var arr = time.split(':').slice(0, -1).join(":");
            time = arr + "";
            this.scheduledTime = time;
            this.scheduleForm.controls['scheduleTime'].setValue(time);
        }
        else {
            this.scheduleForm.controls['scheduleTime'].setValue("00:00:00");
        }
        this.deleted_array = [];
        var url;
        this.scheduledData = this.scheduledData ? this.scheduledData : null;
        if (this.scheduledData != null && this.scheduledData != 'reschedule') {
            if (this.scheduledData.hasOwnProperty('isScheduled')) {
                if (this.scheduledData.assignees.length != 0) {
                    var selectedAsignees_1 = [];
                    for (var i = 0; i < this.scheduledData.assignees.length; i++) {
                        selectedAsignees_1.push(this.scheduledData.assignees[i]['id']);
                    }
                    var new_array = void 0;
                    if (this.assignee && selectedAsignees_1) {
                        var assignee_1 = this.assignee.map(Number);
                        new_array = assignee_1.filter(function (f) { return !selectedAsignees_1.includes(f); });
                        this.deleted_array = selectedAsignees_1.filter(function (f) { return !assignee_1.includes(f); });
                    }
                    if (new_array) {
                        this.scheduleForm.controls['assigneeIds'].setValue(new_array);
                    }
                    else {
                        this.scheduleForm.controls['assigneeIds'].setValue([]);
                    }
                }
                else {
                    this.scheduleForm.controls['assigneeIds'].setValue(this.assignee);
                }
                scheduledData = this.scheduleForm.value;
                if (this.deleted_array) {
                    scheduledData.deletedAssigneeIds = this.deleted_array;
                }
                else {
                    scheduledData.deletedAssigneeIds = [];
                }
                url = "sales/editScheduleActivity/" + this.scheduledData.activityId;
            }
        }
        else {
            this.scheduleForm.controls['assigneeIds'].setValue(this.assignee);
            scheduledData = this.scheduleForm.value;
            url = "sales/scheduleOpportunityActivity/" + this.opportunityId;
        }
        var logId = null;
        if (this.scheduledData) {
            logId = this.scheduledData.logId;
        }
        scheduledData = this.scheduleForm.value;
        // Date format change
        var scheduledDate = moment(scheduledData['scheduledDate'], "DD-MM-YYYY").format("MM-DD-YYYY");
        scheduledDate = scheduledDate + " " + scheduledData['scheduleTime'];
        var new_date = new Date(scheduledDate);
        scheduledData['scheduled_date'] = new_date;
        if (this.isCommonRoute) {
            this.scheduleButtonDisabled = false;
            if (this.scheduledData != null && this.scheduledData != 'reschedule') {
                scheduledData.scheduleId = this.scheduledData.activityId;
            }
            scheduledData.logId = logId;
            scheduledData.screenType = this.screenType;
            scheduledData.tablePkId = this.tablePkId;
            this.onActivityScheduleSave(scheduledData);
        }
        else {
            this.opportunityService.createScheduledActivity(scheduledData, url).subscribe(function (res) {
                _this.onSave.emit(_this.scheduledData);
                _this.closeModalWithoutConfirmation();
                _this.scheduleButtonDisabled = false;
                if (_this.scheduledData) {
                    if (_this.scheduledData.hasOwnProperty('edit')) {
                        _this.opportunityService.refreshCompleteActivity('update');
                    }
                }
            }, function (error1) {
                console.log(error1);
            });
            this.sharedModalService.updateModalStatus('others', 'activity', false);
        }
    };
    ScheduledActivityModalComponent.prototype.onActivityScheduleSave = function (data) {
        var _this = this;
        var saveObservable = data.hasOwnProperty('scheduleId') ?
            this.propertyService.saveEditScheduledActivity(data, data['scheduleId']) :
            this.propertyService.saveScheduledActivity(data);
        this.propertyService.timelineRefresh$.subscribe(function (refreshData) {
            _this.newRefreshData = refreshData;
        });
        saveObservable.pipe(switchMap(function (savedData) {
            var refreshData = {
                fromDate: "",
                isSearch: false,
                object: "",
                page: 1,
                search: "",
                toDate: "",
                userId: "",
                screen: _this.screenType,
            };
            refreshData['data'] = {
                screen: data.hasOwnProperty('logId') && data.logId ? 'editActivity' : 'addScheduleActivity',
                type: data.hasOwnProperty('scheduleId') ? 'update' : 'add',
                values: null,
                id: data.hasOwnProperty('logId') && data.logId ? data.logId : 'latest'
            };
            if (_this.isCallFromCsActivityLog) {
                if (_this.afterSave)
                    _this.afterSave(data);
            }
            else {
                _this.propertyService.setRefreshSliderScheduleActivity(true);
                if (_this.screenType == 'jobs' || _this.screenType == 'jobs_tab') {
                    _this.propertyService.setTimelineRefresh(_this.newRefreshData);
                }
                else {
                    _this.propertyService.setTimelineRefresh(refreshData);
                }
            }
            _this.sharedModalService.updateModalStatus('others', 'activity', false);
            _this.showToast(data.hasOwnProperty('scheduleId') ? 'Scheduled activity updated' : 'Scheduled activity added');
            // Return an observable to satisfy switchMap
            return new Observable(function (observer) {
                observer.next();
                observer.complete();
            });
        })).subscribe(function (res) {
            _this.closeModalWithoutConfirmation();
        });
    };
    ScheduledActivityModalComponent.prototype.showToast = function (msg) {
        this.toast.show(msg, 1500);
    };
    ScheduledActivityModalComponent.prototype.dateConversion = function (data) {
        var momentBusinessDays = require("moment-business-days");
        var today = new Date();
        if (data == 'Today') {
            var date = moment(today);
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'Tomorrow') {
            var date = momentBusinessDays(today).businessAdd(1, 'days');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In2businessdays') {
            var date = momentBusinessDays(today).businessAdd(2, 'days');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In3businessdays') {
            var date = momentBusinessDays(today).businessAdd(3, 'days');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'Nextmonday') {
            var date = moment(today).add(1, 'week').day(1);
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In1week') {
            var date = moment(today).add(7, 'days');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In2weeks') {
            var date = moment(today).add(14, 'days');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In1month') {
            var date = moment(today).add(1, 'M');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In3months') {
            var date = moment(today).add(3, 'M');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else if (data == 'In6months') {
            var date = moment(today).add(6, 'M');
            this.scheduleForm.value['scheduledDate'] = date['_d'];
        }
        else {
            if (this.selectedDate == 'Today') {
                var today_1 = new Date();
                var date = moment(today_1);
                this.scheduleForm.value['scheduledDate'] = date['_d'];
                $('#caret-up').addClass('up-caret-icon');
            }
            else {
                this.scheduleForm.value['scheduledDate'] = this.activityDateEdit;
            }
        }
    };
    ScheduledActivityModalComponent.prototype.omitSpecialChar = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        else {
            return true;
        }
    };
    ScheduledActivityModalComponent.prototype.timepickerIncrement = function (event) {
        if (event == 'time') {
            var element = $('.hour-min').first();
            var hour = element.find('option:selected');
            var newHour = '';
            if (!hour.next().length) {
                newHour = '00';
                element.find('option').first().prop('selected', 'selected');
            }
            else {
                newHour = hour.next().val();
                hour.next().prop('selected', 'selected');
            }
            var changeObject = { hour: newHour };
            this.timepickerChangeEvent.next(changeObject);
        }
        else if (event == 'min') {
            var element = $('.hour-min').last();
            var min = element.find('option:selected');
            var newMin = '';
            if (!min.next().length) {
                newMin = '00';
                element.find('option').first().prop('selected', 'selected');
            }
            else {
                newMin = min.next().val();
                min.next().prop('selected', 'selected');
            }
            var changeObject = { min: newMin };
            this.timepickerChangeEvent.next(changeObject);
        }
    };
    ScheduledActivityModalComponent.prototype.timepickerDecrement = function (event) {
        if (event == 'time') {
            var element = $('.hour-min').first();
            var hour = element.find('option:selected');
            var newHour = '';
            if (!hour.prev().length) {
                newHour = '23';
                element.find('option').first().prop('selected', 'selected');
            }
            else {
                newHour = hour.prev().val();
                hour.prev().prop('selected', 'selected');
            }
            var changeObject = { hour: newHour };
            this.timepickerChangeEvent.next(changeObject);
        }
        else if (event == 'min') {
            var element = $('.hour-min').last();
            var min = element.find('option:selected');
            var newMin = '';
            if (!min.prev().length) {
                newMin = '45';
                element.find('option').first().prop('selected', 'selected');
            }
            else {
                newMin = min.prev().val();
                min.prev().prop('selected', 'selected');
            }
            var changeObject = { min: newMin };
            this.timepickerChangeEvent.next(changeObject);
        }
    };
    return ScheduledActivityModalComponent;
}());
export { ScheduledActivityModalComponent };
