import {
    Component,
    ElementRef, OnInit,
    Renderer2,
    HostListener,
    Inject,
    ViewEncapsulation
} from '@angular/core';
import {ModalContainerComponent} from "ngx-bootstrap/modal";
import {SidepanelOptions} from "@app/sidepanel/services/sidepanel-options";
import { TranslateService } from '@ngx-translate/core';
import {SidepanelService} from "@app/sidepanel/services/sidepanel.service";

// noinspection JSAnnotator
@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'sidepanel-modal-container',
    template: `
    <div [class]="'modal-dialog' + (config.class ? ' ' + config.class : '')" role="document">
      <div class="modal-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
   host: {
    class: 'modal cs-sidepanel-v2',
    role: 'dialog',
    tabindex: '-1',
    '[attr.aria-modal]': 'true',
    '[attr.aria-labelledby]': 'config.ariaLabelledBy',
    '[attr.aria-describedby]': 'config.ariaDescribedby'
   },
    styleUrls: ['./sidepanel.component.scss']
})

export class SidepanelComponent extends ModalContainerComponent implements OnInit{

    config: SidepanelOptions;

    private clickStartedInContent = false;
    private modalscount = 0;
    private allowConfirmationModal: boolean = true;

    constructor(options: SidepanelOptions,
      protected element: ElementRef,
      @Inject('confirmationBoxHelper') private confirmationBoxHelper,
      @Inject('$scope') private ajsScope,
      private translate: TranslateService,
      private sidepanelService: SidepanelService,
      private renderer: Renderer2) {
        super(options, element, renderer);
    }

    ngOnInit() {
        this.modalscount =this.bsModalService.getModalsCount();
        if(this.modalscount == 2){
            let parentInstance = this.bsModalService['loaders'][this.modalscount-2].instance;
            let childInstance = this.bsModalService['loaders'][this.modalscount-1].instance;
            let parentElem = parentInstance.element.nativeElement;
            let childElem = childInstance.element.nativeElement;
            let offset = (childElem.offsetWidth/2);

            if(parentInstance.config.size == 'sm' && childInstance.config.size == 'xl'){
                offset = (childElem.offsetWidth - parentElem.offsetWidth/2) + 140;
            }

            let parentElemStyle = parentElem.style;
            setTimeout( () => {
                parentElemStyle.transform = 'translate3d(-'+offset+'px, 0, 0)' ;
            }, 100)

        }else if(this.modalscount == 1){
            let childInstance = this.bsModalService['loaders'][0].instance;
            if('allowConfirmationModal' in childInstance.initialState && childInstance.initialState){
                this.allowConfirmationModal = childInstance.initialState.allowConfirmationModal;
            }
        }
        super.ngOnInit();
        if(this.config.size != '')
            this.renderer.addClass(this.element.nativeElement, this.config.size);
        if(this.modalscount) {
            this.renderer.addClass(this.element.nativeElement, 'modal-level-' + this.modalscount);
        }
    }

    hide() {
        if(this.modalscount === 2) {
            let prevElem = this.bsModalService['loaders'][this.modalscount-2].instance['element'].nativeElement;
            let prevElemStyle = prevElem.style;
            prevElemStyle.transform = '';
        }
        super.hide();
    }
    @HostListener('body:click', ['$event'])
    onClickStarted(event: any): void {
        if(this.allowConfirmationModal) {
            this.clickStartedInContent = event.target !== this.element.nativeElement;
            if (this.modalscount === this.bsModalService.getModalsCount() && (event.target.tagName === 'BS-MODAL-BACKDROP' || event.target.tagName === 'CS-MODAL-BACKDROP')) {
                if (this.config.closeConfirmation) {
                    this.confirmationBoxHelper
                        .getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
                        .then(() => {
                            if ($('app-table-formatter-options').length > 0) {
                                let msg = {type: 'refreshTable', id: true}
                                window.top.postMessage(msg, '*')
                            }
                            $('#cancel-styles').trigger('click');
                            this.hide();
                            // emit modal hide event
                            this.sidepanelService.OpportunityModalClose();
                            this.sidepanelService.closePagePropertiesSidePanel();
                            this.sidepanelService.onCloseSidePanelModal();
                        });
                } else {
                    $('#cancel-styles').trigger('click');
                    this.hide();
                    // emit modal hide event
                    this.sidepanelService.OpportunityModalClose();
                }
            }
        }
        else{
            this.hide()
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEsc(event: KeyboardEvent): void {
        if (this.allowConfirmationModal) {
            if (event.keyCode === 27 || event.key === 'Escape') {
                event.preventDefault();
            }
            if (this.modalscount === this.bsModalService.getModalsCount() && this.isShown) {
                this.confirmationBoxHelper
                    .getConfirmation(this.translate.instant('Are.you.sure.you.want.to.close.side.panel?'), this.ajsScope)
                    .then(() => {
                        this.hide();
                        // emit modal hide event
                        this.sidepanelService.OpportunityModalClose();
                    });
            }
        }
        else{
            this.hide()
        }
    }
}
