import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from "rxjs/index";
import { BsModalRef } from 'ngx-bootstrap';
import { VehicleModelService } from '@app/features/system-settings/fleet-management/vehicle-models/services/vehicle-model.service';

export interface vehicleModelFormData {
	id: any;
	modelName: any;
	makes: any;
}

@Component({
	selector: 'app-vehicle-model-panel',
	styleUrls: ['./vehicle-model-panel.component.scss'],
	templateUrl: './vehicle-model-panel.component.html',
	styles: []
})
export class VehicleModelPanelComponent implements OnInit {
	public onClose: Subject<any>;
	vehicleModelForm: FormGroup;
	title: string;
	data: any;
	isEdit: boolean = false;
	action: string = 'save';
	showexpiryInput: boolean = true;
	makeList: any[] = [];
	vehicleModelFormData = {
		id: '',
		modelName: '',
		makes: ''
	}
	uniqueName: boolean = false;
	saveDisabled: boolean = false;
	loading: boolean = true;
	language: string;
	selectedVehicleMakeId: any;
	selectedVehicleModalName: any;
	exist: boolean;

	constructor(
		private fb: FormBuilder,
		public bsModalRef: BsModalRef,
		private typeService: VehicleModelService,
	) {
	}

	ngOnInit() {
		this.onClose = new Subject();
        let editId;
        if(this.data){
            editId = this.data.id;
        }else{
            editId = null;
        }
		this.typeService.getVehicleMake( this.data ? 'edit' : 'add' , editId ).subscribe(res => {
			this.makeList = res['vehicleMakes'];
		})

		if (this.selectedVehicleMakeId || this.selectedVehicleModalName) {
			this.vehicleModelFormData.makes = this.selectedVehicleMakeId;
			this.vehicleModelFormData.modelName = this.selectedVehicleModalName;
		}

		if (this.data) {
			// Set values for edit screen
			this.isEdit = true;
			this.loading = false;
			this.vehicleModelFormData.id = this.data.id;
			this.vehicleModelFormData.modelName = this.data.modelName;
			this.vehicleModelFormData.makes = this.data.makes.id;
		}

		this.title = this.isEdit == true ? 'Fleet.edit.vehicle.model' : 'Fleet.add.new.model';
		this.vehicleModelForm = this.fb.group({
			'id': [this.vehicleModelFormData.id ? this.vehicleModelFormData.id : ''],
			'modelName': [this.vehicleModelFormData.modelName ? this.vehicleModelFormData.modelName : '', [Validators.required, Validators.minLength(1)]],
			'makes': [this.vehicleModelFormData.makes ? this.vehicleModelFormData.makes : '', Validators.required]
		});
	}


	// Getting control values for validation check
	get f() {
		return this.vehicleModelForm.controls;
	}

	save() {
		this.saveDisabled = true;
		let formData = this.vehicleModelForm.getRawValue();
		this.typeService.addOrEditVehicleModel(formData)
			.subscribe(resp => {
				if (resp.hasOwnProperty('id')) {
					this.uniqueName = false;
					let addVehicleData = {
						makeId: formData['makes'],
						modalId: resp['id'],
						modalName: formData['modelName'],
					}
					this.onClose.next(addVehicleData);
					this.bsModalRef.hide();
				} else {
					this.uniqueName = true;
				}

			}, error => {
				console.log('err', error);
			});
	}

	// Check vehicle type name exists
	checkUniqueVehicleType(value) {

		let data = {
			modelName: value,
		}
		if (this.isEdit) {
			data['id'] = this.data.id;
		}
		let checkName: boolean = false;
		if (this.vehicleModelForm.get('modelName').value === value) {
			checkName = true;
		} else {
			checkName = false;
		}
		if (checkName) {
			this.typeService.checkVehicleModelName(data).subscribe(resp => {
				this.uniqueName = resp
			})
		}

	}

	closePanel() {
		this.bsModalRef.hide();
		this.onClose.next('close');
	}
}
