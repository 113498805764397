import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from 'moment';
import { FileUploader } from "ng2-file-upload";
import { FormParserServicesService } from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/services/form-parser-services.service";
import { CommonService, CsToastBoxService } from "@app/services";
import { DomSanitizer } from '@angular/platform-browser';
import { AppValidators } from "@app/directives";
import { environment } from "@app/utils/get-api-url";
var URL = environment.apiBasePath + 'uploadcertificateimage';
var DynamicCertificateFormParserComponent = /** @class */ (function () {
    function DynamicCertificateFormParserComponent(formBuilder, toastBox, _DomSanitizer, confirmationBoxHelper, ajsScope, document, formParserServicesService, commonService) {
        this.formBuilder = formBuilder;
        this.toastBox = toastBox;
        this._DomSanitizer = _DomSanitizer;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.document = document;
        this.formParserServicesService = formParserServicesService;
        this.commonService = commonService;
        this.select2Config = {
            width: "270px",
        };
        this.bsConfig = {
            adaptivePosition: true
        };
        // Time
        this.hoursArray = [];
        this.minutesArray = [];
        this.meridiemArray = ['AM', 'PM'];
        this.numberFields = [];
        this.decimalFields = [];
        this.dateFields = [];
        this.photoFields = [];
        this.timeFields = {};
        this.signatureFields = [];
        this.checkboxGroupFields = {};
        this.pageWiseFieldIds = {};
        this.uploaderArray = {};
        this.photoFileInvalidStatus = [];
        this.currentPhotoFormControl = '';
        this.select2LabelConfig = {};
        this.format = function (item) {
            return item.text;
        };
        // list page
        this.searchText = '';
        this.appliances = [];
        this.limit = 50;
        this.showListFormSidepanel = false;
        this.listItemCount = 0;
        this.numberFieldsList = [];
        this.decimalFieldsList = [];
        this.dateFieldsList = [];
        this.photoFieldsList = [];
        this.timeFieldsList = {};
        this.checkboxGroupFieldsList = {};
        this.singleCheckBoxFieldsList = [];
        this.uploaderArrayList = {};
        this.disableSave = false;
        this.listItemValueArray = {};
        this.listFormLabels = {};
        this.currentListIndex = -1;
        this.type = 'ADD'; // 'ADD' or 'EDIT
        this.listFormIndexAndPageIndex = {};
        this.formValue = {};
        this.needFetchApplianceData = false;
        this.applianceGroupTypes = [];
        this.typeId = '';
        this.loading = false;
        this.assetData = {};
        this.assetGroupObjectKey = '';
        this.keyLabelArray = {};
        // Signature
        this.showSignatureSidePanel = false;
        this.currentSignatureFormControl = '';
        // Input with selection choices list
        this.select2LabelConfigList = {};
        // Measurements
        this.measurements = {};
        this.measurementsValues = {};
        // Dynamic fields
        this.defaultFieldOptions = {};
        // Service job date
        this.enableServiceJobDate = false;
        this.serviceReminderData = [];
        this.currentServiceJobDateId = '';
        this.serviceJobDate = '';
        this.listItemFieldOrderArray = {};
        this.photoFieldValues = {};
        this.userFieldInListForm = {};
        this.pageTitle = '';
        this.currentPageNumber = 0;
        this.draftData = {};
        this.customTemplateScreen = 0; //0-Default
        this.formStatusChanges = new EventEmitter();
        this.formValueChanges = new EventEmitter();
        this.isDowngrade = false;
        this.calculationField = [];
    }
    DynamicCertificateFormParserComponent.prototype.ngOnInit = function () {
        for (var i = 0; i < 60; i++) {
            var value = this.pad(i);
            if (i != 0 && i <= 12) {
                this.hoursArray.push(value);
            }
            this.minutesArray.push(value);
        }
        this.listItemForm = this.formBuilder.array([]);
    };
    DynamicCertificateFormParserComponent.prototype.ngAfterViewInit = function () {
        if (this.isDowngrade) {
            this.document.body.classList.add('dc-form-parser');
        }
    };
    DynamicCertificateFormParserComponent.prototype.ngOnDestroy = function () {
        if (this.isDowngrade) {
            this.document.body.classList.remove('dc-form-parser');
        }
    };
    DynamicCertificateFormParserComponent.prototype.onShownDatepicker = function (text) {
        var _this = this;
        if (this.isDowngrade) {
            this.document.body.classList.remove('dc-shown-datepicker');
            setTimeout(function () {
                if (text == 'shown') {
                    _this.document.body.classList.add('dc-shown-datepicker');
                }
            }, 601);
        }
    };
    DynamicCertificateFormParserComponent.prototype.ngOnChanges = function (changes) {
        if (changes['draftData']) {
            this.formValue = this.draftData;
            if (this.listItemForm && this.listItemForm.length)
                if (this.currentPageNumber && this.pages && this.pages[this.currentPageNumber]['pageType'] == 'list')
                    this.setListFormAndApplyPatch();
            if (this.form)
                this.setFormDraftData();
        }
        if (changes['customerId']) {
            if (this.needFetchApplianceData)
                this.fetchApplianceData();
        }
        if (changes['certificateId']) {
            if (this.certificateId) {
                this.setDynamicFieldValues();
            }
        }
        if (changes['jobId']) {
            if (this.jobId) {
                this.fetchServiceReminderData();
            }
        }
        if (changes['formData']) {
            this.setFormData(this.formData);
        }
    };
    DynamicCertificateFormParserComponent.prototype.pad = function (value) {
        return (value.toString().length < 2) ? '0' + value : value;
    };
    /**=========================================================================================
         Handle switching switching page in parent component
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.selectPage = function (index) {
        var _this = this;
        this.currentPageNumber = index;
        this.formStatusChangeEmit();
        var currentPage = this.pages[index];
        this.formParserServicesService.checkDependantFields(currentPage, this.formValue, this.currentPageNumber).then(function (value) {
            _this.formStatusChangeEmit();
        });
        if (currentPage && currentPage['pageType'] == 'list') {
            this.formStatusChanges.emit(true);
            if (this.listItemForm && this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber])) {
                var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
                this.listItemCount = formArray.length;
                this.currentListIndex = this.listItemCount - 1;
            }
            var intervel_1 = setInterval(function () {
                if (_this.hasAssetModuleAccess !== undefined) {
                    _this.setListFormAndApplyPatch();
                    clearInterval(intervel_1);
                }
            }, 100);
        }
        this.setMeasurementValueOnPageChange();
    };
    /**=========================================================================================
         Emits form status changes to the parent component
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.formStatusChangeEmit = function () {
        var currentPageFields = this.pageWiseFieldIds[this.currentPageNumber];
        var currentPageDependantFields = this.formParserServicesService.pageWiseDependantFields[this.currentPageNumber];
        if (currentPageFields) {
            var formValid = true;
            var _loop_1 = function (i) {
                var controlName = currentPageFields[i];
                var controlObject = void 0;
                if (currentPageDependantFields) {
                    var index = currentPageDependantFields.findIndex(function (value) {
                        return value['id'] == controlName;
                    });
                    if (index != -1) {
                        controlObject = currentPageDependantFields[index];
                    }
                }
                if (this_1.form.get(controlName) && this_1.form.get(controlName).invalid) {
                    formValid = false;
                    if (controlObject && !controlObject['required'])
                        formValid = true;
                    if (!formValid)
                        return "break";
                }
            };
            var this_1 = this;
            for (var i = 0; i < currentPageFields.length; i++) {
                var state_1 = _loop_1(i);
                if (state_1 === "break")
                    break;
            }
            for (var h = 0; h < this.singleCheckBoxFieldsList.length; h++) {
                var controlNameChk = this.singleCheckBoxFieldsList[h];
                if (this.form.get(controlNameChk).value == false) {
                    formValid = false;
                    break;
                }
            }
            if (this.customFieldsConfigId != '' && this.customFieldsConfigId != undefined) {
                this.formStatusChanges.emit({ 'ID': this.customFieldsConfigId, 'formValidStatus': formValid });
            }
            else {
                this.formStatusChanges.emit(formValid);
            }
        }
    };
    /**=========================================================================================
         Emits form value changes to the parent component
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.formSValueChangeEmit = function (formValue) {
        var _this = this;
        if (this.decimalFields) {
            this.decimalFields.forEach(function (questionId) {
                if (formValue[questionId]) {
                    if (_this.measurements && _this.measurements[questionId]) {
                        formValue[questionId] = _this.splitValueFromMeasurement(formValue[questionId], questionId);
                    }
                    if (!_this.isNumber(formValue[questionId]))
                        formValue[questionId] = "";
                }
            });
        }
        if (this.numberFields) {
            this.numberFields.forEach(function (questionId) {
                if (formValue[questionId]) {
                    if (_this.measurements && _this.measurements[questionId]) {
                        formValue[questionId] = _this.splitValueFromMeasurement(formValue[questionId], questionId);
                    }
                    if (!_this.isNumber(formValue[questionId]))
                        formValue[questionId] = "";
                }
            });
        }
        if (this.dateFields) {
            this.dateFields.forEach(function (questionId) {
                if (formValue[questionId]) {
                    if (_this.customFieldsConfigId && _this.customFieldsConfigId != undefined) {
                        var formatDate = moment(formValue[questionId]).format('YYYY-MM-D');
                        formValue[questionId] = formatDate;
                    }
                    else {
                        var formatDate = moment(formValue[questionId]).format('ddd D MMM YYYY');
                        formValue[questionId] = formatDate;
                    }
                }
            });
        }
        // List
        if (this.decimalFieldsList) {
            this.decimalFieldsList.forEach(function (questionId) {
                if (formValue[questionId]) {
                    if (_this.measurements && _this.measurements[questionId]) {
                        formValue[questionId] = _this.splitValueFromMeasurement(formValue[questionId], questionId);
                    }
                    if (!_this.isNumber(formValue[questionId]))
                        formValue[questionId] = "";
                }
            });
        }
        if (this.numberFieldsList) {
            this.numberFieldsList.forEach(function (questionId) {
                if (formValue[questionId]) {
                    if (_this.measurements && _this.measurements[questionId]) {
                        formValue[questionId] = _this.splitValueFromMeasurement(formValue[questionId], questionId);
                    }
                    if (!_this.isNumber(formValue[questionId]))
                        formValue[questionId] = "";
                }
            });
        }
        if (this.dateFieldsList) {
            this.dateFieldsList.forEach(function (questionId) {
                if (formValue[questionId]) {
                    var formatDate = moment(formValue[questionId]).format('ddd D MMM YYYY');
                    formValue[questionId] = formatDate;
                }
            });
        }
        if (this.signatureFields) {
            this.signatureFields.forEach(function (questionId) {
                if (formValue[questionId]) {
                    if (formValue[questionId].includes('data:image/png;base64')) {
                        var formattedBase64 = formValue[questionId].substring(22);
                        formValue[questionId] = formattedBase64;
                    }
                }
            });
        }
        // Append measurement at the end of the value
        if (this.measurements) {
            Object.keys(this.measurements).forEach(function (key) {
                if (_this.measurements[key] !== undefined && _this.measurements[key] != ''
                    && formValue && key in formValue && formValue[key] !== undefined && formValue[key] != '') {
                    if (!formValue[key].includes(_this.measurements[key]))
                        formValue[key] = formValue[key] + " " + _this.measurements[key];
                }
            });
        }
        this.formValue = tslib_1.__assign({}, this.formValue, formValue);
        this.formParserServicesService.checkDependantFields(this.pages[this.currentPageNumber], this.formValue, this.currentPageNumber).then(function (value) {
            _this.formStatusChangeEmit();
            var page = _this.pages[_this.currentPageNumber];
            if (page && page['pageType'] != 'list') {
                page['sections'].forEach(function (section) {
                    section['fields'].forEach(function (field) {
                        if ((field.hasOwnProperty('previousShowStatus') && !field.previousShowStatus && field.show)) {
                            if (_this.checkboxGroupFields[field['questionID']] && _this.checkboxGroupFields[field['questionID']].length) {
                                _this.checkboxGroupFields[field['questionID']].forEach(function (row) {
                                    row.formValue = '';
                                });
                            }
                        }
                        if ((section['hasDependency'] && !section['show']) || (field['hasDependency'] && !field['show'])) {
                            if (_this.form.get(field['questionID'])) {
                                _this.form.get(field['questionID']).setValue(undefined, { emitEvent: false });
                            }
                        }
                    });
                });
                // this.formValue = {...this.formValue, ...this.form.value};
                _this.formValueChanges.emit(_this.formValue);
            }
        });
        // Updated photo field values in the main form
        this.updatePhotoFieldValues();
        this.formValueChanges.emit(this.formValue);
    };
    DynamicCertificateFormParserComponent.prototype.isNumber = function (value) {
        return Number(value) !== NaN && isFinite(value);
    };
    /**=========================================================================================
         If the current page is list page, emit the form status as valid
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.checkListPage = function () {
        if (this.pages) {
            var currentPage = this.pages[this.currentPageNumber];
            if (currentPage && currentPage['pageType'] == 'list') {
                this.formStatusChanges.emit(true);
            }
        }
    };
    /**=========================================================================================
       Set the dynamic form using the dynamic form data coming from parent component
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setFormData = function (formData) {
        var _this = this;
        if (formData) {
            this.numberFields = [];
            this.decimalFields = [];
            this.dateFields = [];
            this.pages = formData['pages'];
            this.form = this.formBuilder.group({});
            this.enableServiceJobDate = false;
            if (this.pages && this.pages.length) {
                this.pages.forEach(function (page, pageIndex) {
                    if (page['pageType'] == 'list') {
                        if (page['pageSelectAssetFirst']) {
                            _this.needFetchApplianceData = true;
                        }
                        _this.listItemForm.push(new FormArray([]));
                        _this.listFormIndexAndPageIndex[pageIndex] = _this.listItemForm.length - 1;
                    }
                    else {
                        _this.pageWiseFieldIds[pageIndex] = [];
                        if (page.fields) {
                            page.fields.forEach(function (field) {
                                _this.formParserServicesService.setDependantFields(field);
                                if (field['fieldAnswerType'] == 'Date' &&
                                    (field['defaults'] &&
                                        field['defaults']['defaultTitle']))
                                    _this.enableServiceJobDate = true;
                                _this.addFormControl(field, pageIndex);
                            });
                        }
                        page.sections.forEach(function (section) {
                            _this.formParserServicesService.setDependantSections(section);
                            section.fields.forEach(function (field) {
                                _this.formParserServicesService.setDependantFields(field);
                                if (field['fieldAnswerType'] == 'Date'
                                    && (field['isServicedJobDT'] ||
                                        (field['defaults'] && field['defaults']['defaultTitle'])))
                                    _this.enableServiceJobDate = true;
                                _this.addFormControl(field, pageIndex);
                            });
                        });
                    }
                });
                if (!this.needFetchApplianceData && this.hasAssetModuleAccess == undefined) {
                    this.hasAssetModuleAccess = false;
                }
            }
            this.form.valueChanges.subscribe(function (value) {
                _this.formStatusChangeEmit();
                _this.formSValueChangeEmit(value);
            });
            this.setFormDraftData();
            this.checkListPage();
            this.selectPage(this.currentPageNumber);
            if (this.enableServiceJobDate)
                this.fetchServiceReminderData();
        }
    };
    /**=========================================================================================
        Apply the patch value coming from parent to the form
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setFormDraftData = function () {
        var _this = this;
        this.setValueAndMeasurements();
        // Convert formatted date to date string to support bs datePicker
        this.dateFields.forEach(function (questionId) {
            if (_this.draftData[questionId]) {
                _this.draftData[questionId] = new Date(_this.draftData[questionId]);
            }
        });
        // Apply the draft value to the form
        this.form.patchValue(this.draftData);
        // Set the time value models with draft value
        this.setTimeModelDraft(this.timeFields);
        // Set the checkbox value models with draft value
        this.setCheckboxModelModelDraft(this.checkboxGroupFields);
        // Set input with selection choices value
        this.setSelect2Draft(this.select2LabelConfig);
        this.formParserServicesService.checkDependantFields(this.pages[this.currentPageNumber], this.formValue, this.currentPageNumber).then(function (value) {
            _this.formStatusChangeEmit();
        });
    };
    /**=========================================================================================
       Add form control and validations based on the fields
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.addFormControl = function (field, pageIndex) {
        var formControl = new FormControl();
        formControl.setValue(undefined);
        if (field.required) {
            if (this.customTemplateScreen == 0 || (field.showInScreen && ((this.customTemplateScreen == 1 && (field.showInScreen == '1' || field.showInScreen == '3')) || (this.customTemplateScreen == 2 && (field.showInScreen == '2' || field.showInScreen == '3'))) && field.showInScreen != '4')) {
                formControl.setValidators(Validators.required);
            }
        }
        if (field.defaultValue !== undefined) {
            if (field.fieldAnswerType !== 'Date' && field.fieldAnswerType !== 'User')
                formControl.setValue(field.defaultValue);
            else {
                var date = new Date();
                var year = date.getFullYear();
                date.setFullYear(year + 1);
                formControl.setValue(date);
            }
        }
        if (field.fieldAnswerType == 'Non-editable Input') {
            if (field['defaultID'] == '[user]')
                if (this.userName)
                    formControl.setValue(this.userName);
                else {
                    this.getUserData(formControl);
                }
            formControl.disable();
        }
        if (field['fieldAnswerType'] == 'Custom calculation') {
            this.calculationField[field['questionID']] = { fields: field['calculationField'], field_data: field['field_data'] };
            formControl.disable();
        }
        if (field['fieldValidations'] && field['fieldValidations'].length) {
            this.addFieldValidations(field, formControl);
        }
        if (field['fieldAnswerType'] == 'Single Checkbox' && field['required'])
            this.singleCheckBoxFieldsList.push(field['questionID']);
        this.form.addControl(field['questionID'], formControl);
        if (field['fieldAnswerType'] != 'Photo')
            this.form.addControl(field['questionID'], formControl);
        if (field['fieldAnswerType'] == 'Number')
            this.numberFields.push(field['questionID']);
        if (field['fieldAnswerType'] == 'Decimal')
            this.decimalFields.push(field['questionID']);
        if (field['fieldAnswerType'] == 'Date')
            this.dateFields.push(field['questionID']);
        if (field['fieldAnswerType'] == 'Photo') {
            this.photoFields.push(field['questionID']);
            for (var i = 1; i <= field['max']; i++) {
                var formControlName = field['questionID'] + "_image" + i;
                this.form.addControl(formControlName, formControl);
                this.setFileUploader(formControlName, 'STANDARD');
                this.pageWiseFieldIds[pageIndex].push(field['questionID']);
                this.photoFileInvalidStatus[formControlName] = false;
            }
        }
        if (field['fieldAnswerType'] == 'Time') {
            var today = moment();
            var timeObject = {};
            timeObject['hour'] = today.format('hh');
            timeObject['minutes'] = today.format('mm');
            timeObject['meridiem'] = today.format('A');
            this.timeFields[field['questionID']] = timeObject;
            this.timeChanged(field['questionID']);
        }
        if (field['fieldAnswerType'] == 'Checkbox') {
            var checkboxArray_1 = [];
            if (field['fieldAnswerOptions'] && field['fieldAnswerOptions'].length) {
                field['fieldAnswerOptions'].forEach(function (option) {
                    var optionObject = { optionValue: option['optionValue'], formValue: '', answerID: option['answerID'] };
                    checkboxArray_1.push(optionObject);
                });
                this.checkboxGroupFields[field['questionID']] = checkboxArray_1;
            }
        }
        if (field['fieldAnswerType'] != 'Photo')
            this.pageWiseFieldIds[pageIndex].push(field['questionID']);
        if (field['fieldAnswerType'] == 'Input with Selection Choices') {
            this.addSelect2Configuration(field, field['questionID'], field['fieldAnswerOptions']);
        }
        if (field['fieldAnswerType'] == 'Signature') {
            this.signatureFields.push(field['questionID']);
        }
        if (field['allMeasurements'] && field['allMeasurements'].length) {
            this.measurements[field['questionID']] = '';
            this.measurementsValues[field['questionID']] = field['allMeasurements'];
        }
    };
    /**=========================================================================================
       Add select2 configuration for list/standard pages
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.addSelect2Configuration = function (field, questionId, fieldAnswerOptions, type) {
        if (type === void 0) { type = 'STANDARD'; }
        var labelIdTextArray = [];
        if (fieldAnswerOptions) {
            fieldAnswerOptions.forEach(function (options) {
                var data = { id: options['answerID'], text: options['optionValue'] };
                labelIdTextArray.push(data);
            });
        }
        var select2Config = {
            width: '100%',
            data: labelIdTextArray,
            tags: true,
            createSearchChoice: function (term, data) {
                var exists = false;
                data.forEach(function (idText) {
                    if (idText.text == term)
                        exists = true;
                });
                return term == ' ' || exists ? null :
                    { id: term, text: term };
            },
            formatSelection: this.format,
            formatResult: this.format,
            multiple: true
        };
        if (type == 'STANDARD')
            this.select2LabelConfig[questionId] = select2Config;
        else
            this.select2LabelConfigList[questionId] = select2Config;
    };
    /**=========================================================================================
       Creates the file uploader component for every photo field
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setFileUploader = function (questionID, type) {
        var _this = this;
        var uploadURL = URL;
        if (this.customFieldsConfigId != '' && this.customFieldsConfigId != undefined) {
            uploadURL = URL + '?uploadFrom=customfields';
        }
        var uploader = new FileUploader({
            url: uploadURL,
            itemAlias: 'file',
            autoUpload: true,
            allowedMimeType: ['image/png', 'image/jpeg']
        });
        uploader.onCompleteAll = function () {
            console.log('File upload completed');
        };
        uploader.onWhenAddingFileFailed = function (fileItem, filter) {
            console.log('File upload failed', fileItem, filter);
            if (filter.name = "mimeType") {
                _this.photoFileInvalidStatus[_this.currentPhotoFormControl] = true;
            }
        };
        uploader.onCompleteItem = function (item, response, status, headers) {
            response = JSON.parse(response);
            if (response.error == false) {
                _this.photoFileInvalidStatus[_this.currentPhotoFormControl] = false;
                _this.photoFieldValues[_this.currentPhotoFormControl] = response.message;
                if (_this.form.get(_this.currentPhotoFormControl)) {
                    _this.form.get(_this.currentPhotoFormControl).setValue(response.message, { emitEvent: false });
                }
                else {
                    var pageFormArray = _this.listItemForm.at(_this.listFormIndexAndPageIndex[_this.currentPageNumber]);
                    var myForm = pageFormArray.controls[_this.currentListIndex];
                    if (myForm.get(_this.currentPhotoFormControl)) {
                        myForm.get(_this.currentPhotoFormControl).setValue(response.message, { emitEvent: false });
                    }
                }
                _this.formSValueChangeEmit({});
            }
        };
        if (type == 'LIST')
            this.uploaderArrayList[questionID] = uploader;
        else
            this.uploaderArray[questionID] = uploader;
        if (this.draftData && this.draftData.hasOwnProperty(questionID)) {
            this.photoFieldValues[questionID] = this.draftData[questionID];
        }
    };
    DynamicCertificateFormParserComponent.prototype.getPhotoArray = function (maxNumber, questionID) {
        var array = [], formControlName = '';
        for (var i = 1; i <= maxNumber; i++) {
            formControlName = questionID + "_image" + i;
            array.push(formControlName);
        }
        return array;
    };
    DynamicCertificateFormParserComponent.prototype.getPhotoListArray = function (maxNumber, questionID) {
        var array = [], formControlName = '';
        for (var i = 1; i <= maxNumber; i++) {
            formControlName = questionID + "_image" + i + "_page" + (this.currentPageNumber + 1) + "_" + (this.currentListIndex + 1);
            array.push(formControlName);
        }
        return array;
    };
    /**==========================================================================================
      If the type is number or decimal, set the filters for the input to support number/decimal
    ===========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setInputFilter = function (textbox, inputFilter) {
        if (!textbox)
            return;
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function (event) {
            textbox.addEventListener(event, function () {
                if (inputFilter(this.value)) {
                    this.oldValue = this.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                }
                else if (this.hasOwnProperty("oldValue")) {
                    this.value = this.oldValue;
                    this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
                else {
                    this.value = "";
                }
            });
        });
    };
    DynamicCertificateFormParserComponent.prototype.onFileSelected = function (questionId) {
        this.currentPhotoFormControl = questionId;
    };
    /**=========================================================================================
      Sets the formatted time value in the form in standard page
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.timeChanged = function (questionId) {
        if (this.timeFields[questionId]) {
            var timeObject = this.timeFields[questionId];
            var time = timeObject.hour + ":" + timeObject.minutes + " " + timeObject.meridiem;
            var timeValue = this.timeConvert12T24(time);
            if (this.form.get(questionId)) {
                this.form.get(questionId).setValue(timeValue);
            }
        }
    };
    /**=========================================================================================
        Sets the formatted time value in the form in list page
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.timeChangedList = function (questionId) {
        if (this.timeFieldsList[questionId]) {
            var timeObject = this.timeFieldsList[questionId];
            var time = timeObject.hour + ":" + timeObject.minutes + " " + timeObject.meridiem;
            var timeValue = this.timeConvert12T24(time);
            var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
            var formGroup = formArray.at(this.currentListIndex);
            if (formGroup.get(questionId)) {
                formGroup.get(questionId).setValue(timeValue);
            }
        }
    };
    /**=========================================================================================
       Sets the time value in the models from the draft data coming from parent component
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setTimeModelDraft = function (timeFields) {
        for (var _i = 0, _a = Object.entries(timeFields); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (this.draftData[key]) {
                var timeFormat = this.draftData[key];
                var timeValue = this.timeConvert24T12(timeFormat);
                var hour = timeValue.substring(0, 2);
                var minutes = timeValue.substring(3, 5);
                var meridiem = timeValue.substring(5, 8);
                value['hour'] = hour;
                value['minutes'] = minutes;
                value['meridiem'] = meridiem;
            }
        }
    };
    /**=========================================================================================
      Sets the check box value as comma separated strings in form {Standard page}
     ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.checkboxChanged = function (questionId) {
        var _this = this;
        setTimeout(function () {
            if (_this.checkboxGroupFields[questionId]) {
                var value_1 = '';
                _this.checkboxGroupFields[questionId].forEach(function (option) {
                    if (option['formValue']) {
                        if (value_1)
                            value_1 += ',' + option['optionValue'];
                        else
                            value_1 = option['optionValue'];
                    }
                });
                if (_this.form.get(questionId)) {
                    _this.form.get(questionId).setValue(value_1);
                }
            }
        }, 10);
    };
    /**=========================================================================================
       Sets the check box value as comma separated strings in form {List page}
     ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.checkboxChangedList = function (questionId) {
        var _this = this;
        setTimeout(function () {
            if (_this.checkboxGroupFieldsList[questionId]) {
                var value_2 = '';
                _this.checkboxGroupFieldsList[questionId].forEach(function (option) {
                    if (option['formValue']) {
                        if (value_2)
                            value_2 += ',' + option['optionValue'];
                        else
                            value_2 = option['optionValue'];
                    }
                });
                var formArray = _this.listItemForm.at(_this.listFormIndexAndPageIndex[_this.currentPageNumber]);
                var formGroup = formArray.at(_this.currentListIndex);
                if (formGroup.get(questionId)) {
                    formGroup.get(questionId).setValue(value_2);
                }
            }
        }, 10);
    };
    /**=========================================================================================
     Sets the check box models from the draft data coming from parent component
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setCheckboxModelModelDraft = function (checkboxGroupFields) {
        var _loop_2 = function (key, value) {
            if (this_2.draftData[key]) {
                var checkValue = this_2.draftData[key];
                var valueArray_1 = checkValue.split(',');
                if (valueArray_1.length) {
                    checkboxGroupFields[key].forEach(function (option) {
                        valueArray_1.forEach(function (value) {
                            if (option['optionValue'] == value) {
                                option['formValue'] = true;
                            }
                        });
                    });
                }
            }
        };
        var this_2 = this;
        for (var _i = 0, _a = Object.entries(checkboxGroupFields); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            _loop_2(key, value);
        }
    };
    DynamicCertificateFormParserComponent.prototype.setSelect2Draft = function (select2Config) {
        var _this = this;
        setTimeout(function () {
            var _loop_3 = function (key, value) {
                if (key in _this.formValue) {
                    var data = value['data'];
                    var index = data.findIndex(function (idText) {
                        return idText['text'] == _this.formValue[key];
                    });
                    var selector = "#" + key;
                    if (index != -1) {
                        $(selector).select2('data', data[index]);
                    }
                    else {
                        if (_this.formValue[key])
                            $(selector).select2('data', { id: _this.formValue[key], text: _this.formValue[key] });
                    }
                }
            };
            for (var _i = 0, _a = Object.entries(select2Config); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                _loop_3(key, value);
            }
        }, 100);
    };
    /**========================================================================================
       Handles the input with selection choices changes
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.onSelectInputWithSelection = function (event, questionId, type) {
        if (type === void 0) { type = 'STANDARD'; }
        var data;
        var form;
        if (type == 'STANDARD') {
            data = this.select2LabelConfig[questionId]['data'];
            form = this.form;
            form.get(questionId).setValue('');
        }
        else {
            data = this.select2LabelConfigList[questionId]['data'];
            var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
            form = formArray.at(this.currentListIndex);
            form.get(questionId).setValue('');
        }
        if (event.length && event.length != 1) {
            var index = data.findIndex(function (idText) {
                return idText['id'] == event[1];
            });
            var selector = "#" + questionId;
            if (index != -1) {
                var idText = data[index];
                $(selector).select2('data', idText);
                form.get(questionId).setValue(idText['text']);
            }
            else {
                $(selector).select2('data', { id: event[1], text: event[1] });
                form.get(questionId).setValue(event[1]);
            }
        }
        else if (event.length == 1) {
            var index = data.findIndex(function (idText) {
                return idText['id'] == event[0];
            });
            if (index != -1) {
                var idText = data[index];
                form.get(questionId).setValue(idText['text']);
            }
        }
    };
    /**========================================================================================
      Selects the appliance
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.addAppliance = function (value, assetGroupObjectKey) {
        var _this = this;
        if (assetGroupObjectKey === void 0) { assetGroupObjectKey = ''; }
        /** Timeout is set to remove the unwanted close toast from side panel */
        setTimeout(function () {
            _this.currentlySelectedAppliance = value;
            _this.assetGroupObjectKey = assetGroupObjectKey;
        }, 10);
    };
    DynamicCertificateFormParserComponent.prototype.checkListPageHasFields = function () {
        var noFields = true;
        var page = this.pages[this.currentPageNumber];
        if (page) {
            var sections = page['sections'];
            if (sections) {
                sections.forEach(function (section) {
                    if (section['fields'] && section['fields'].length)
                        noFields = false;
                });
            }
            var fields = page['fields'];
            if (fields && fields.length)
                noFields = false;
        }
        return noFields;
    };
    DynamicCertificateFormParserComponent.prototype.fetchApplianceData = function () {
        var _this = this;
        this.loading = true;
        var params = {};
        params['searchText'] = this.searchText.trim();
        if (this.typeId)
            params['type'] = this.typeId;
        if (this.limit)
            params['limit'] = this.limit;
        this.formParserServicesService.getCustomerAppliances(this.customerId, params).subscribe(function (response) {
            if (response) {
                _this.hasAssetModuleAccess = false;
                if (response['hasAssetModuleAccess']) {
                    _this.assetData = response['data'];
                    _this.hasAssetModuleAccess = true;
                    _this.keyLabelArray = response['keyLabelArray'];
                }
                else {
                    _this.appliances = response['data'];
                }
                _this.applianceGroupTypes = response['applianceGroupType'];
                if (_this.searchText == '')
                    _this.limit = response['count'];
                _this.loading = false;
                _this.setListFormAndApplyPatch();
            }
        }, function (error1) {
            _this.loading = false;
        });
    };
    DynamicCertificateFormParserComponent.prototype.typeFilterChanged = function (event) {
        if (event)
            this.typeId = event;
        else
            this.typeId = '';
        this.fetchApplianceData();
    };
    DynamicCertificateFormParserComponent.prototype.openListPageSidePanel = function (pageIndex, draftData, open, applyPatch) {
        var _this = this;
        if (open === void 0) { open = true; }
        if (applyPatch === void 0) { applyPatch = false; }
        // Show warning message when max number reached
        if (open && this.pages[pageIndex] && this.pages[pageIndex]['pageMaximumNumberOfListItems']) {
            if (this.listItemCount == this.pages[pageIndex]['pageMaximumNumberOfListItems']) {
                this.toastBox.show('You have reached maximum number of list items!', 3000);
                return;
            }
        }
        this.type = 'ADD';
        this.currentListIndex = this.listItemCount;
        this.listItemCount++;
        this.disableSave = false;
        var listItemForm = this.formBuilder.group({});
        if (this.pages[pageIndex]) {
            var page = this.pages[pageIndex];
            if (page.fields) {
                page.fields.forEach(function (field) {
                    listItemForm = _this.addListFormControl(field, pageIndex, listItemForm);
                });
            }
            page.sections.forEach(function (section) {
                _this.formParserServicesService.setDependantSections(section);
                section.fields.forEach(function (field) {
                    listItemForm = _this.addListFormControl(field, pageIndex, listItemForm);
                });
            });
            if (page['pageSelectAssetFirst']) {
                if (!this.hasAssetModuleAccess) {
                    var result = this.formParserServicesService.setApplianceForm(listItemForm, this.currentPageNumber + 1, this.listItemCount, this.listItemFieldOrderArray);
                    this.listFormLabels = tslib_1.__assign({}, this.listFormLabels, result.formLabels);
                    listItemForm = result.listItemForm;
                }
                else {
                    var result = this.formParserServicesService.setAssetFormWithKeyLabel(listItemForm, this.keyLabelArray, this.currentPageNumber + 1, this.listItemCount, this.draftData, this.listItemFieldOrderArray);
                    this.listFormLabels = tslib_1.__assign({}, this.listFormLabels, result.formLabels);
                    listItemForm = result.formGroup;
                }
            }
        }
        if (open)
            this.showListFormSidepanel = true;
        var pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[pageIndex]);
        /** Check dependencies for the list page */
        listItemForm.valueChanges.subscribe(function (value) {
            var appendString = "_page" + (pageIndex + 1) + "_" + (_this.currentListIndex + 1);
            _this.formParserServicesService.checkDependantFields(_this.pages[pageIndex], value, _this.currentPageNumber, 'LIST', appendString).then(function (value) {
                _this.setListItemFormValidity(listItemForm, appendString);
            })
                .finally(function () {
                var currentFb = _this.listItemForm.get((_this.listFormIndexAndPageIndex[_this.currentPageNumber]) + '.' + (_this.currentListIndex));
                var currentListValue = currentFb.getRawValue();
                _this.formValue = tslib_1.__assign({}, _this.formValue, currentListValue);
            });
        });
        if (applyPatch) {
            // Convert formatted date to date string to support bs datePicker
            this.dateFieldsList.forEach(function (questionId) {
                if (_this.draftData[questionId]) {
                    _this.draftData[questionId] = new Date(_this.draftData[questionId]);
                }
            });
            this.setValueAndMeasurements();
            listItemForm.patchValue(draftData);
            // Set the time value models with draft value
            this.setTimeModelDraft(this.timeFieldsList);
            // Set the checkbox value models with draft value
            this.setCheckboxModelModelDraft(this.checkboxGroupFieldsList);
            if (this.listItemValueArray[this.currentPageNumber]) {
                this.listItemValueArray[this.currentPageNumber].push(listItemForm.value);
            }
            else {
                this.listItemValueArray[this.currentPageNumber] = [listItemForm.value];
            }
            this.updateUserNameFromId(listItemForm.value);
        }
        pageFormArray.push(listItemForm);
        /** Check dependencies for the list page */
        var formValues = tslib_1.__assign({}, listItemForm.value, draftData);
        var appendString = "_page" + (pageIndex + 1) + "_" + (this.currentListIndex + 1);
        this.formParserServicesService.checkDependantFields(this.pages[pageIndex], formValues, this.currentPageNumber, 'LIST', appendString).then(function (value) {
            _this.setListItemFormValidity(listItemForm, appendString);
        });
    };
    /**=========================================================================================
       Add form control and validations based on the fields in the list page
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.addListFormControl = function (field, pageIndex, listItemForm) {
        var formControl = new FormControl();
        formControl.setValue(undefined);
        var questionId = field['questionID'] + "_page" + (this.currentPageNumber + 1) + "_" + this.listItemCount;
        if (field.required) {
            formControl.setValidators(Validators.required);
        }
        if (field.defaultValue !== undefined) {
            if (field.fieldAnswerType !== 'Date')
                formControl.setValue(field.defaultValue);
            else {
                var date = new Date();
                var year = date.getFullYear();
                date.setFullYear(year + 1);
                formControl.setValue(date);
            }
        }
        if (field.fieldAnswerType == 'Non-editable Input') {
            if (field['defaultID'] == '[user]')
                if (this.userName)
                    formControl.setValue(this.userName);
                else {
                    this.getUserData(formControl);
                }
            formControl.disable();
        }
        if (field['fieldValidations'] && field['fieldValidations'].length) {
            this.addFieldValidations(field, formControl);
        }
        if (field['fieldAnswerType'] != 'Photo')
            listItemForm.addControl(questionId, formControl);
        if (field['fieldAnswerType'] == 'Number')
            this.numberFieldsList.push(questionId);
        if (field['fieldAnswerType'] == 'Decimal')
            this.decimalFieldsList.push(questionId);
        if (field['fieldAnswerType'] == 'Date')
            this.dateFieldsList.push(questionId);
        if (field['fieldAnswerType'] == 'User') {
            formControl.setValue(field['defaultID']);
            this.userFieldInListForm[questionId] = { value: '', show: false };
        }
        if (field['fieldAnswerType'] == 'Photo') {
            this.photoFieldsList.push(questionId);
            for (var i = 1; i <= field['max']; i++) {
                // let formControlName = `${questionId}_image${i}`;
                var formControlName = field['questionID'] + "_image" + i + "_page" + (this.currentPageNumber + 1) + "_" + this.listItemCount;
                listItemForm.addControl(formControlName, formControl);
                this.setFileUploader(formControlName, 'LIST');
                this.photoFileInvalidStatus[formControlName] = false;
            }
        }
        if (field['fieldAnswerType'] == 'Custom calculation') {
            this.calculationField[questionId] = { fields: field['calculationField'], field_data: field['field_data'] };
            formControl.disable();
        }
        if (field['fieldAnswerType'] == 'Time') {
            var today = moment();
            var timeObject = {};
            timeObject['hour'] = today.format('hh');
            timeObject['minutes'] = today.format('mm');
            timeObject['meridiem'] = today.format('A');
            this.timeFieldsList[questionId] = timeObject;
            if (listItemForm.get(questionId)) {
                var time = timeObject['hour'] + ":" + timeObject['minutes'] + " " + timeObject['meridiem'];
                var timeValue = this.timeConvert12T24(time);
                listItemForm.get(questionId).setValue(timeValue);
            }
        }
        if (field['fieldAnswerType'] == 'Checkbox') {
            var checkboxArray_2 = [];
            if (field['fieldAnswerOptions'] && field['fieldAnswerOptions'].length) {
                field['fieldAnswerOptions'].forEach(function (option) {
                    var optionObject = { optionValue: option['optionValue'], formValue: '', answerID: option['answerID'] };
                    checkboxArray_2.push(optionObject);
                });
                this.checkboxGroupFieldsList[questionId] = checkboxArray_2;
            }
        }
        if (field['fieldAnswerType'] == 'Input with Selection Choices') {
            this.addSelect2Configuration(field, questionId, field['fieldAnswerOptions'], 'LIST');
        }
        if (field['allMeasurements'] && field['allMeasurements'].length) {
            this.measurements[questionId] = '';
            this.measurementsValues[questionId] = field['allMeasurements'];
        }
        // Dynamic input for old structure
        if (field['fieldAnswerType'] == 'Dynamic Input' || field['fieldAnswerType'] == 'Dynamic Input with Selection Choices') {
            if (this.defaultFieldOptions && this.defaultFieldOptions[field['dynamicFieldID']])
                this.addSelect2Configuration(field, questionId, this.defaultFieldOptions[field['dynamicFieldID']], 'LIST');
        }
        this.listFormLabels[questionId] = { label: field['fieldLabel'], visibleOnList: field['visibleOnList'], type: field['fieldAnswerType'] };
        this.formParserServicesService.setDependantFields(field);
        // Set order of list item
        if (this.listItemFieldOrderArray[this.currentPageNumber]) {
            if (this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex]) {
                var index = this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex].findIndex(function (v) {
                    return v == questionId;
                });
                if (index == -1)
                    this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex].push(questionId);
            }
            else {
                this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex] = [questionId];
            }
        }
        else {
            this.listItemFieldOrderArray[this.currentPageNumber] = {};
            this.listItemFieldOrderArray[this.currentPageNumber][this.currentListIndex] = [questionId];
        }
        return listItemForm;
    };
    DynamicCertificateFormParserComponent.prototype.onSidepanelClose = function () {
        this.cancelForm();
    };
    /**=========================================================================================
       Handles the cancel/close of list page side panel
    ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.cancelForm = function () {
        if (this.type == 'ADD') {
            var pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
            if (pageFormArray.at(this.currentListIndex)) {
                pageFormArray.removeAt(this.currentListIndex);
            }
            this.listItemCount--;
            this.currentListIndex--;
        }
        this.currentlySelectedAppliance = null;
        this.showListFormSidepanel = false;
        this.searchText = '';
        this.typeId = '';
    };
    /**=========================================================================================
       Handles the form submission on list page side panel
     ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.onFormSubmit = function () {
        this.disableSave = true;
        this.showListFormSidepanel = false;
        var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
        var formGroup = formArray.at(this.currentListIndex);
        var allFormValue = formGroup.value;
        if (this.listItemValueArray[this.currentPageNumber]) {
            /** If the page is appliance/asset list page, add the selected appliance/asset to the form */
            if (this.pages[this.currentPageNumber] && this.pages[this.currentPageNumber]['pageSelectAssetFirst']) {
                var applianceFormValue = this.getAssetOrApplianceFormValue();
                if (this.hasAssetModuleAccess) {
                    var result = this.formParserServicesService.
                        setAssetForm(formGroup, this.currentlySelectedAppliance, this.currentPageNumber + 1, this.listItemCount);
                    formGroup = result.formGroup;
                    this.listFormLabels = tslib_1.__assign({}, this.listFormLabels, result.formLabels);
                    /** Get the asset value and set to the form  */
                    allFormValue = tslib_1.__assign({}, applianceFormValue, formGroup.value);
                }
                else {
                    /** Get the appliance value and set to the form  */
                    allFormValue = tslib_1.__assign({}, formGroup.value, applianceFormValue);
                }
                this.listItemValueArray[this.currentPageNumber][this.currentListIndex] = allFormValue;
                this.currentlySelectedAppliance = null;
            }
            else {
                if (this.listItemValueArray[this.currentPageNumber][this.currentListIndex])
                    this.listItemValueArray[this.currentPageNumber][this.currentListIndex] = formGroup.value;
                else
                    this.listItemValueArray[this.currentPageNumber].push(formGroup.value);
            }
        }
        else {
            /** If the page is appliance/asset list page, add the selected appliance/asset to the form */
            if (this.pages[this.currentPageNumber] && this.pages[this.currentPageNumber]['pageSelectAssetFirst']) {
                var applianceFormValue = this.getAssetOrApplianceFormValue();
                if (this.hasAssetModuleAccess) {
                    var result = this.formParserServicesService.
                        setAssetForm(formGroup, this.currentlySelectedAppliance, this.currentPageNumber + 1, this.listItemCount);
                    formGroup = result.formGroup;
                    this.listFormLabels = tslib_1.__assign({}, this.listFormLabels, result.formLabels);
                }
                /** Get the appliance/asset value and set to the form  */
                allFormValue = tslib_1.__assign({}, formGroup.value, applianceFormValue);
                formGroup.patchValue(allFormValue);
                this.listItemValueArray[this.currentPageNumber] = [allFormValue];
                this.currentlySelectedAppliance = null;
            }
            else {
                this.listItemValueArray[this.currentPageNumber] = [formGroup.value];
            }
        }
        var currentListValue = this.listItemValueArray[this.currentPageNumber][this.currentListIndex];
        this.updateUserNameFromId(currentListValue);
        // Emit the form value with list item
        this.formSValueChangeEmit(allFormValue);
        this.searchText = '';
        this.typeId = '';
    };
    DynamicCertificateFormParserComponent.prototype.getAssetOrApplianceFormValue = function () {
        var applianceFormValue = {};
        if (this.hasAssetModuleAccess) {
            applianceFormValue = this.formParserServicesService.
                getAssetFormValue(this.currentlySelectedAppliance, this.currentPageNumber + 1, this.listItemCount);
        }
        else {
            applianceFormValue = this.formParserServicesService.
                getApplianceFormValue(this.currentlySelectedAppliance, this.currentPageNumber + 1, this.listItemCount);
        }
        return applianceFormValue;
    };
    DynamicCertificateFormParserComponent.prototype.editListItem = function (listItem, listIndex) {
        var _this = this;
        var noFields = this.checkListPageHasFields();
        if (noFields) {
            this.toastBox.show('There are no extra fields to edit.', 3000);
            return;
        }
        this.type = 'EDIT';
        this.disableSave = false;
        this.currentListIndex = listIndex;
        var page = this.pages[this.currentPageNumber];
        if (page && page['pageSelectAssetFirst']) {
            // Get the selected appliance
            if (this.hasAssetModuleAccess) {
                var result = this.formParserServicesService.
                    getCurrentlySelectedAsset(listItem, this.listFormLabels);
                this.currentlySelectedAppliance = result.asset;
                this.assetGroupObjectKey = result.assetGroupObjectKey;
            }
            else {
                this.currentlySelectedAppliance = this.formParserServicesService.
                    getCurrentlySelectedAppliance(listItem, this.listFormLabels);
            }
        }
        /** Check dependencies for the list page */
        var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
        var formGroup = formArray.at(this.currentListIndex);
        var formValues = formGroup.value;
        var appendString = "_page" + (this.currentPageNumber + 1) + "_" + (this.currentListIndex + 1);
        this.formParserServicesService.checkDependantFields(page, formValues, this.currentPageNumber, 'LIST', appendString).then(function (value) {
            _this.setListItemFormValidity(formGroup, appendString);
        });
        this.showListFormSidepanel = true;
        // Set input with selection choices value
        this.setSelect2Draft(this.select2LabelConfigList);
    };
    DynamicCertificateFormParserComponent.prototype.deleteListItem = function (listItem, listIndex) {
        var _this = this;
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to delete this item?', this.ajsScope)
            .then(function () {
            _this.listItemValueArray[_this.currentPageNumber].splice(listIndex, 1);
            var formArray = _this.listItemForm.at(_this.listFormIndexAndPageIndex[_this.currentPageNumber]);
            var formValue = formArray.at(listIndex).value;
            var deleteIdPageNo;
            for (var _i = 0, _a = Object.entries(formValue); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                if (key in _this.formValue) {
                    delete _this.formValue[key];
                    var parts = key.split('_'); // Split the string by underscores
                    deleteIdPageNo = '_' + parts.slice(-2).join('_');
                }
            }
            // to missed values in the related page no
            for (var a in _this.formValue) {
                if (a.includes(deleteIdPageNo)) {
                    delete _this.formValue[a];
                }
            }
            var formArrayLength = formArray.length;
            formArray.removeAt(listIndex);
            if (listIndex == formArrayLength - 1) {
                _this.listItemCount = formArray.length;
                _this.currentListIndex = _this.listItemCount - 1;
                delete _this.listItemFieldOrderArray[_this.currentPageNumber][listIndex];
                _this.formSValueChangeEmit({});
            }
            else {
                var currentPage = _this.pages[_this.currentPageNumber];
                if (currentPage) {
                    var _loop_4 = function (i) {
                        var questionIdArray = [];
                        var listItemForm = new FormGroup({});
                        _this.currentListIndex = i;
                        _this.listItemCount = i + 1;
                        if (currentPage.fields) {
                            questionIdArray.push('!assetId'); // added to push asset id
                            currentPage.fields.forEach(function (field) {
                                questionIdArray.push(field['questionID']);
                                listItemForm = _this.addListFormControl(field, _this.currentPageNumber, listItemForm);
                            });
                        }
                        currentPage.sections.forEach(function (section) {
                            section.fields.forEach(function (field) {
                                questionIdArray.push(field['questionID']);
                                listItemForm = _this.addListFormControl(field, _this.currentPageNumber, listItemForm);
                            });
                        });
                        if (currentPage['pageSelectAssetFirst']) {
                            if (_this.hasAssetModuleAccess) {
                                var assetQuestionIds = _this.formParserServicesService.getAssetQuestionIds();
                                questionIdArray = questionIdArray.concat(assetQuestionIds);
                                listItemForm = _this.formParserServicesService.setAssetFormWithKeys(listItemForm, _this.currentPageNumber, i);
                            }
                            else {
                                questionIdArray = _this.formParserServicesService.getApplianceQuestionIds(questionIdArray);
                                listItemForm = _this.formParserServicesService.setApplianceFormWithKeys(listItemForm, _this.currentPageNumber, i);
                            }
                        }
                        delete _this.listItemFieldOrderArray[_this.currentPageNumber][i + 1];
                        _this.listItemFieldOrderArray[_this.currentPageNumber][i] = [];
                        questionIdArray.forEach(function (questionId) {
                            var oldTag = questionId + "_page" + (_this.currentPageNumber + 1) + "_" + (_this.listItemCount + 1);
                            var newTag = questionId + "_page" + (_this.currentPageNumber + 1) + "_" + _this.listItemCount;
                            var value = _this.formValue[oldTag];
                            if (listItemForm.get(newTag)) {
                                listItemForm.get(newTag).setValue(value);
                                _this.listItemFieldOrderArray[_this.currentPageNumber][i].push(newTag);
                                _this.listItemValueArray[_this.currentPageNumber][i][newTag] = value;
                            }
                            if (oldTag in _this.formValue)
                                delete _this.formValue[oldTag];
                        });
                        formArray.setControl(i, listItemForm);
                        _this.listItemValueArray[_this.currentPageNumber][i] = listItemForm.value;
                        _this.formSValueChangeEmit(listItemForm.value);
                    };
                    for (var i = listIndex; i < formArrayLength - 1; i++) {
                        _loop_4(i);
                    }
                }
            }
        }).catch(function () { });
    };
    DynamicCertificateFormParserComponent.prototype.setListFormAndApplyPatch = function () {
        var _this = this;
        var listPageKeysArray = Object.keys(this.draftData);
        var listItemCount = 0;
        var lastItemInt = 0;
        listPageKeysArray.forEach(function (key) {
            if (key.includes('_page' + (_this.currentPageNumber + 1))) {
                var splitArray = key.split('_');
                var lastItem = splitArray[splitArray.length - 1];
                lastItemInt = parseInt(lastItem);
                if (lastItemInt > listItemCount) {
                    listItemCount = lastItemInt;
                }
            }
        });
        for (var i = 0; i < listItemCount; i++) {
            var pageFormArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
            if (!pageFormArray.at(i))
                this.openListPageSidePanel(this.currentPageNumber, this.draftData, false, true);
        }
    };
    /** Signature handle section ================================================================*/
    DynamicCertificateFormParserComponent.prototype.openSignatureSidePanel = function (questionId) {
        this.showSignatureSidePanel = true;
        this.currentSignatureFormControl = questionId;
    };
    DynamicCertificateFormParserComponent.prototype.onSignatureSidePanelClose = function () {
        this.showSignatureSidePanel = false;
    };
    DynamicCertificateFormParserComponent.prototype.getSignatureBase64 = function (base64Image) {
        var page = this.pages[this.currentPageNumber];
        if (page) {
            if (page['pageType'] !== 'list') {
                if (this.form.get(this.currentSignatureFormControl)) {
                    if (base64Image.includes('data:image/png;base64')) {
                        base64Image = base64Image.substring(22);
                    }
                    this.form.get(this.currentSignatureFormControl).setValue(base64Image);
                }
            }
            else {
                var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
                var formGroup = formArray.at(this.currentListIndex);
                if (formGroup.get(this.currentSignatureFormControl)) {
                    if (base64Image.includes('data:image/png;base64')) {
                        base64Image = base64Image.substring(22);
                    }
                    formGroup.get(this.currentSignatureFormControl).setValue(base64Image);
                    // this.formSValueChangeEmit(formGroup.value);
                }
            }
            this.showSignatureSidePanel = false;
        }
    };
    /** =========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.measurementChanged = function (questionID) {
        if (this.formValue && questionID in this.formValue) {
            if (this.form.get(questionID)) {
                var val = {};
                val[questionID] = this.form.get(questionID).value;
                this.formSValueChangeEmit(val);
            }
        }
    };
    /**=========================================================================================
       Handles splitting measurement value and unit from draft data
     ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.setValueAndMeasurements = function () {
        var _this = this;
        if (this.measurements) {
            Object.keys(this.measurements).forEach(function (key) {
                if (_this.measurements[key] == '') {
                    if (key in _this.draftData) {
                        var value = _this.draftData[key];
                        if (value != '' && typeof value == "string") {
                            var splitArray = value.split(' ');
                            if (splitArray && splitArray.length > 1) {
                                var measurementValue = splitArray[splitArray.length - 1];
                                if (_this.measurementsValues[key].includes(measurementValue)) {
                                    _this.measurements[key] = measurementValue;
                                }
                                var newValue = '';
                                for (var i = 0; i <= splitArray.length - 1; ++i) {
                                    if (i == 0)
                                        newValue = splitArray[i];
                                    else
                                        newValue += " " + splitArray[i];
                                }
                                _this.draftData[key] = newValue;
                            }
                        }
                    }
                }
            });
        }
    };
    DynamicCertificateFormParserComponent.prototype.setDynamicFieldValues = function () {
        var _this = this;
        if (this.pages && this.pages.length) {
            this.formParserServicesService.getCertificatesFieldOptions(this.certificateId).subscribe(function (res) {
                _this.defaultFieldOptions = res;
                _this.pages.forEach(function (page, pageIndex) {
                    if (page.fields) {
                        page.fields.forEach(function (field) {
                            if (field['fieldAnswerType'] == 'Dynamic Input' || field['fieldAnswerType'] == 'Dynamic Input with Selection Choices') {
                                if (page['pageType'] !== 'list') {
                                    _this.addSelect2Configuration(field, field['questionID'], res[field['dynamicFieldID']]);
                                }
                            }
                        });
                        page.sections.forEach(function (section) {
                            _this.formParserServicesService.setDependantSections(section);
                            section.fields.forEach(function (field) {
                                if (field['fieldAnswerType'] == 'Dynamic Input' || field['fieldAnswerType'] == 'Dynamic Input with Selection Choices') {
                                    if (page['pageType'] !== 'list') {
                                        _this.addSelect2Configuration(field, field['questionID'], res[field['dynamicFieldID']]);
                                    }
                                }
                            });
                        });
                    }
                });
                _this.setSelect2Draft(_this.select2LabelConfig);
            }, function (error1) {
                console.log(error1);
            });
        }
    };
    /**=========================================================================================
     Handles extra validation for fields in the form data
     ==========================================================================================*/
    DynamicCertificateFormParserComponent.prototype.addFieldValidations = function (field, formControl) {
        var validation = field['fieldValidations'][0];
        if (validation['fieldValidationName'] == 'Email address') {
            formControl.setValidators(AppValidators.emailValidator);
            field['validationType'] = 'email';
        }
        if (validation['fieldValidationName'] == 'Date') {
            formControl.setValidators(Validators.pattern('^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$'));
            field['validationType'] = 'date';
        }
        if (validation['fieldValidationName'] == 'Custom') {
            formControl.setValidators(Validators.pattern(validation['fieldValidation']));
        }
    };
    /** Fetch service reminder info if the job is a service job */
    DynamicCertificateFormParserComponent.prototype.fetchServiceReminderData = function () {
        var _this = this;
        if (this.jobId && this.enableServiceJobDate) {
            this.formParserServicesService.getJobServiceReminders(this.jobId).subscribe(function (res) {
                _this.serviceReminderData = res['serviceReminders'];
            }, function (error1) {
                _this.serviceReminderData = [];
            });
        }
    };
    DynamicCertificateFormParserComponent.prototype.showServiceJobDropDown = function (questionID) {
        this.currentServiceJobDateId = questionID;
        this.serviceJobDate = '';
    };
    DynamicCertificateFormParserComponent.prototype.serviceJobDateChanged = function (type) {
        if (type === void 0) { type = 'STD'; }
        if (type == 'STD' && this.form && this.form.get(this.currentServiceJobDateId)) {
            this.form.get(this.currentServiceJobDateId).setValue(new Date(this.serviceJobDate));
        }
        if (type == 'LIST') {
            var formArray = this.listItemForm.at(this.listFormIndexAndPageIndex[this.currentPageNumber]);
            var formGroup = formArray.at(this.currentListIndex);
            if (formGroup.get(this.currentServiceJobDateId)) {
                formGroup.get(this.currentServiceJobDateId).setValue(new Date(this.serviceJobDate));
            }
        }
    };
    /** Update list item form validity based on the dependencies */
    DynamicCertificateFormParserComponent.prototype.setListItemFormValidity = function (listItemForm, appendString) {
        var currentPageDependantFields = this.formParserServicesService.pageWiseDependantFields[this.currentPageNumber];
        if (currentPageDependantFields) {
            currentPageDependantFields.forEach(function (value) {
                var controlName = value['id'] + appendString;
                if (listItemForm.get(controlName)) {
                    if (!value['required']) {
                        listItemForm.get(controlName).clearValidators();
                    }
                    else {
                        listItemForm.get(controlName).setValidators(Validators.required);
                    }
                    listItemForm.get(controlName).updateValueAndValidity({ emitEvent: false });
                }
            });
        }
        var page = this.pages[this.currentPageNumber];
        if (page && page['pageType'] == 'list') {
            page['sections'].forEach(function (section) {
                section['fields'].forEach(function (field) {
                    if ((section['hasDependency'] && !section['show']) || (field['hasDependency'] && !field['show'])) {
                        var controlName = field['questionID'] + appendString;
                        if (listItemForm.get(controlName)) {
                            listItemForm.get(controlName).setValue(undefined, { emitEvent: false });
                            listItemForm.get(controlName).updateValueAndValidity({ emitEvent: false });
                        }
                    }
                });
            });
        }
        listItemForm.updateValueAndValidity({ emitEvent: false });
    };
    /** Get user details and set in formControl */
    DynamicCertificateFormParserComponent.prototype.getUserData = function (formControl) {
        var _this = this;
        var interval = setInterval(function () {
            if (_this.jobId) {
                _this.formParserServicesService.getUserData(_this.jobId).subscribe(function (userData) {
                    _this.userName = userData['userName'];
                    formControl.setValue(_this.userName);
                    clearInterval(interval);
                }, function (error1) {
                    console.log(error1);
                    clearInterval(interval);
                });
                clearInterval(interval);
            }
            if (_this.opportunityId || _this.opportunityId == undefined) {
                var type = "existing";
                if (_this.opportunityId == undefined) {
                    _this.opportunityId = null;
                    type = "initial";
                }
                _this.formParserServicesService.getOpportunityUserData(_this.opportunityId, type).subscribe(function (userData) {
                    _this.userName = userData['userName'];
                    formControl.setValue(_this.userName);
                    formControl.disable();
                    clearInterval(interval);
                }, function (error1) {
                    console.log(error1);
                    clearInterval(interval);
                });
                clearInterval(interval);
            }
        }, 500);
    };
    /** Set form fields with measurements back to draft data ( ie. without measurement value) */
    DynamicCertificateFormParserComponent.prototype.setMeasurementValueOnPageChange = function () {
        var _this = this;
        if (this.measurements && Object.keys(this.measurements)) {
            Object.keys(this.measurements).forEach(function (questionId) {
                if (_this.form && _this.form.get(questionId)) {
                    var value = _this.form.value[questionId];
                    if (value && typeof value == "string") {
                        var splitArray = value.split(' ');
                        if (splitArray && splitArray.length > 1) {
                            var measurementValue = splitArray[splitArray.length - 1];
                            if (_this.measurementsValues[questionId].includes(measurementValue)) {
                                var newValue = '';
                                for (var i = 0; i < splitArray.length - 1; ++i) {
                                    if (i == 0)
                                        newValue = splitArray[i];
                                    else
                                        newValue += " " + splitArray[i];
                                }
                                _this.form.get(questionId).setValue(newValue, { emitEvent: false });
                            }
                        }
                    }
                }
            });
        }
    };
    DynamicCertificateFormParserComponent.prototype.splitValueFromMeasurement = function (value, questionId) {
        if (value) {
            // Value is a number if it comes from the web
            if (typeof (value) == 'number') {
                value = value.toString();
            }
            var splitArray = value.split(' ');
            if (splitArray && splitArray.length > 1) {
                var measurementValue = splitArray[splitArray.length - 1];
                if (this.measurementsValues[questionId].includes(measurementValue)) {
                    var newValue = '';
                    for (var i = 0; i < splitArray.length - 1; ++i) {
                        if (i == 0)
                            newValue = splitArray[i];
                        else
                            newValue += " " + splitArray[i];
                    }
                    return newValue;
                }
                else {
                    return "";
                }
            }
            else
                return value;
        }
        else {
            return "";
        }
    };
    DynamicCertificateFormParserComponent.prototype.updatePhotoFieldValues = function () {
        var _this = this;
        Object.keys(this.photoFieldValues).forEach(function (key) {
            if (_this.formValue.hasOwnProperty(key)) {
                _this.formValue[key] = _this.photoFieldValues[key];
            }
        });
    };
    /** Convert time format from 24 hours to 12 hours */
    DynamicCertificateFormParserComponent.prototype.timeConvert24T12 = function (time) {
        var ts = time;
        var H = +ts.substr(0, 2);
        var h = (H % 12) || 12;
        var hr = (h < 10) ? ("0" + h) : h; // leading 0 at the left for 1 digit hours
        var ampm = H < 12 ? 'AM' : 'PM';
        ts = hr + ts.substr(2, 3) + ampm;
        return ts;
    };
    /** Convert time format from 12 hours to 24 hours */
    DynamicCertificateFormParserComponent.prototype.timeConvert12T24 = function (time) {
        var timeRes = time;
        var timeValue;
        var hours = Number(timeRes.match(/^(\d+)/)[1]);
        var minutes = Number(timeRes.match(/:(\d+)/)[1]);
        var AMPM = timeRes.match(/\s(.*)$/)[1];
        if (AMPM == "PM" && hours < 12)
            hours = hours + 12;
        if (AMPM == "AM" && hours == 12)
            hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10)
            sHours = "0" + sHours;
        if (minutes < 10)
            sMinutes = "0" + sMinutes;
        timeValue = sHours + ":" + sMinutes;
        return timeValue;
    };
    DynamicCertificateFormParserComponent.prototype.updateCalculationFieldForm = function (result) {
        this.formValue = tslib_1.__assign({}, this.formValue, result);
        this.formValueChanges.emit(this.formValue);
    };
    DynamicCertificateFormParserComponent.prototype.updateUserNameFromId = function (valueList) {
        var _this = this;
        Object.keys(this.userFieldInListForm).forEach(function (objUserField) {
            if (valueList && valueList.hasOwnProperty(objUserField)) {
                _this.checkUserIsActive(objUserField, valueList[objUserField]);
            }
        });
    };
    DynamicCertificateFormParserComponent.prototype.checkUserIsActive = function (questionId, userId) {
        var _this = this;
        this.commonService.getIsUserActive(userId)
            .toPromise()
            .then(function (data) {
            if (data && data.hasOwnProperty('name')) {
                _this.userFieldInListForm[questionId].value = data.name;
                _this.userFieldInListForm[questionId].show = true;
            }
        }, function (err) {
        });
    };
    return DynamicCertificateFormParserComponent;
}());
export { DynamicCertificateFormParserComponent };
