import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import { FormGroup } from '@angular/forms';
import Quill from 'quill';
import 'quill-mention';
import { CsTaggingService } from "@app/directives/material-directives/cs-tagging/cs-tagging.service";
var FontStyle = Quill.import('attributors/style/font');
FontStyle.whitelist = [
    'Arial, sans-serif',
    'Courier New, monospace',
    'Georgia, serif',
    'Tahoma, sans-serif',
    'Times New Roman, serif',
    'Trebuchet MS, sans-serif',
    'Verdana, sans-serif'
];
Quill.register(FontStyle, true);
var Size = Quill.import('attributors/style/size');
Size.whitelist = [false, '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'];
Quill.register(Size, true);
var setDefaultCssProperties = function () {
    var Block = Quill.import("blots/block");
    var Pblock = /** @class */ (function (_super) {
        tslib_1.__extends(Pblock, _super);
        function Pblock() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        Pblock.create = function (value) {
            var node = _super.create.call(this);
            node.setAttribute("style", "margin-top:0;margin-right:0;margin-bottom:0;margin-left:0;");
            return node;
        };
        return Pblock;
    }(Block));
    Quill.register(Pblock, true);
};
var ɵ0 = setDefaultCssProperties;
setDefaultCssProperties();
/*
let Delta = Quill.import('delta');
let Break:any = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

const lineBreakMatcher = () => {
    let newDelta = new Delta();
    newDelta.insert({ break: "" });
    return newDelta;
};
class SmartBreak extends Break {
    length () {
        return 0
    }
    value () {
        return '\n'
    }
    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref)
    }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';
Quill.register(SmartBreak)
*/
var QuillEditorCustomComponent = /** @class */ (function () {
    function QuillEditorCustomComponent(tagService) {
        var _this = this;
        this.tagService = tagService;
        this.contentChanged = new EventEmitter();
        this._tagsList = [];
        this.csTaggingList = [];
        this.initialLoad = false;
        this.module = {
            mentionListClass: 'ql-mention-list mat-elevation-z8',
            defaultMenuOrientation: 'bottom',
            allowedChars: /^[A-Za-z\sÅÄÖåäö\_]*$/,
            fixMentionsToQuill: false,
            showDenotationChar: false,
            spaceAfterInsert: false,
            isolateCharacter: false,
            renderItem: function (data) {
                if (data.disabled) {
                    return "<div style=\"background-color:#ddd;margin:0 -14px;padding:2px 8px; font-weight: bold; pointer-events: none; color:#999;\">" + data.value + "</div>";
                }
                return data.value;
            },
            onSelect: function (item, insertItem) {
                if (item.denotationChar === '[') {
                    item['value'] = '[' + item['value'] + ']';
                }
                insertItem(item, false);
                setTimeout(function () {
                    var $mentionSpan = $('span.mention > span');
                    var $mentionWrap = $('span.mention');
                    _this.editors.quillEditor.insertText(_this.editors.quillEditor.getSelection(true), item.value + ' ');
                    if ($mentionSpan.length > 0) {
                        // const $content = $.trim($mentionSpan.html());
                        //$mentionWrap.parent().append($content)
                        $mentionWrap.remove();
                        setTimeout(function () {
                            var curPos = _this.editors.quillEditor.getSelection(true);
                            _this.editors.quillEditor.setSelection(curPos, -1);
                        }, 0);
                    }
                }, 300);
            },
            renderLoading: function () {
                return "Loading...";
            },
            mentionDenotationChars: ['['],
            source: function (searchTerm, renderList, mentionChar) {
                var values = [];
                if (searchTerm.length === 0) {
                    values = _this._tagsList;
                }
                else {
                    var matches = [];
                    values = _this._tagsList;
                    for (var i = 0; i < values.length; i++) {
                        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                            matches.push(values[i]);
                        }
                    }
                    values = matches;
                    if (matches.length === 0) {
                        values.push({
                            id: 0,
                            value: "No tag found...",
                            disabled: true
                        });
                    }
                }
                if (values.length) {
                    renderList(values, searchTerm);
                }
            }
        };
        this.modulesMessage = {
            mention: this.module,
            /*clipboard: {
                 matchers: [
                     ['BR', lineBreakMatcher]
                 ],
                // matchVisual: true,
             },
             keyboard: {
                 bindings: {
                     linebreak: {
                         key: 13,
                         shiftKey: true,
     
                         handler: function (range) {
                             let currentLeaf = this.quill.getLeaf(range.index)[0]
                             let nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                             this.quill.insertEmbed(range.index, "break", true, "user");
                             if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                                 this.quill.insertEmbed(range.index, "break", true, "user");
                             }
                             // Now that we've inserted a line break, move the cursor forward
                             this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                         }
                     }
                 }
             },*/
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    [{ font: [
                                'Arial, sans-serif',
                                'Courier New, monospace',
                                'Georgia, serif',
                                'Tahoma, sans-serif',
                                'Times New Roman, serif',
                                'Trebuchet MS, sans-serif',
                                'Verdana, sans-serif'
                            ] }],
                    [{ size: [false, '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px'] }],
                    ['link']
                ]
            }
        };
        this.modulesSubject = {
            mention: this.module,
            toolbar: false
        };
    }
    Object.defineProperty(QuillEditorCustomComponent.prototype, "tagsList", {
        set: function (tags) {
            if (tags.length) {
                this._tagsList = tags;
                if ($('.ql-mention-loading').length > 0) {
                    this.editors.quillEditor.getModule('mention').openMenu("");
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    QuillEditorCustomComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.uploadCommunication) {
            this.uploadCommunication.subscribe(function (data) {
                if (data === 'sms' || _this.lableName === 'subject') {
                    _this.initialLoad = true;
                }
                _this.communicatioType = data;
            });
        }
    };
    QuillEditorCustomComponent.prototype.blurHandler = function ($event) {
        var el = document.querySelector('.ql-tooltip [data-link]');
        if (!el) {
            return;
        }
        el.setAttribute('placeholder', 'http(s)://example.com');
        el.setAttribute('data-link', '');
    };
    QuillEditorCustomComponent.prototype.onContentChanged = function (event) {
        this.contentChanged.emit(event);
    };
    return QuillEditorCustomComponent;
}());
export { QuillEditorCustomComponent };
export { ɵ0 };
