import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap";
import { SetupPipelineService } from "@app/features/system-settings/set-up-pipelines/services/set-up-pipeline.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { PipelineSideMenuModalService } from "@app/features/system-settings/set-up-pipelines/services/pipeline-side-menu-modal.service";
import { ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { SetupViewsService } from "@app/shared/views-layouts/services/setup-views.service";
var PipelineStagesModalComponent = /** @class */ (function () {
    function PipelineStagesModalComponent(formBuilder, bsModalRef, httpService, route, router, configService, cdr, sanitizer, viewsService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.bsModalRef = bsModalRef;
        this.httpService = httpService;
        this.route = route;
        this.router = router;
        this.configService = configService;
        this.cdr = cdr;
        this.sanitizer = sanitizer;
        this.viewsService = viewsService;
        this.statusFormData = {
            name: '',
            color: '',
            rotting: '',
            rules: '',
            outRoles: '',
            outUsers: '',
            inRoles: '',
            inUsers: '',
            isJobComplete: '',
            viewIcon: '',
            iconName: '',
            iconId: '',
            iconType: ''
        };
        this.uniqueName = false;
        this.myValue = '#C5A638';
        this.changedColor = '#C5A638';
        this.stageRules = [{ 'value': '', 'checked': false, 'radioOptions': [{ 'value': '', 'selected': false }, { 'value': '', 'selected': false }, { 'value': '', 'selected': false }] }, { 'value': '', 'checked': false, 'radioOptions': [{ 'value': '', 'selected': false }, { 'value': '', 'selected': false }, { 'value': '', 'selected': false }] }, { 'value': '', 'checked': false, 'radioOptions': [{}] }, { 'value': '', 'checked': false, 'radioOptions': [{}] }];
        this.showInput = false;
        this.showInStageInput = false;
        this.isEdit = false;
        this.outSeletectedRoles = [];
        this.outSelectedUsers = [];
        this.inSeletectedRoles = [];
        this.inSelectedUsers = [];
        this.hideSave = false;
        this.screen = 'opportunity';
        this.outStageCheckBox = false;
        this.inStageCheckBok = false;
        this.previousStageCheck = false;
        this.sendCustomerCheck = false;
        this.selectedStageId = "";
        this.previousStageEnable = false;
        this.saving = false;
        this.isJobComplete = false;
        this.selectedIcon = '';
        this.selectedIconType = '';
        this.iconName = '';
        this.iconId = true;
        this.uploadedIconsListUrls = [];
        this.defaultIconsListUrls = [];
        this.defaultIconsList = [];
        this.route.params.subscribe(function (params) {
            _this.pipelineId = _this.router.url.split('/')[3];
        });
    }
    PipelineStagesModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.screen = this.configService.screenType;
        this.select2rolesConfig = {
            width: '100%'
        };
        this.select2usersConfig = {
            width: '100%'
        };
        this.onClose = new Subject();
        /*if(this.data){
            this.stageDropDownData = this.getPreviousStageById(this.data.id);
            console.log(this.stageDropDownData);
        } else {
            this.stageDropDownData = this.stageData;
        }*/
        this.select2stagesConfig = {
            width: '100%',
            data: this.stageData
        };
        if (this.stageData) {
            if ((!this.data && this.stageData.length < 1) || (this.data && this.stageData.length < 2)) {
                this.previousStageEnable = true;
            }
            else {
                this.previousStageEnable = false;
            }
        }
        if (this.data) {
            this.isEdit = true;
            this.statusFormData.name = this.data.hasOwnProperty('name') ? this.data.name : '';
            this.statusFormData.color = this.data.hasOwnProperty('color') ? this.data.color : '#FFFFFF';
            this.statusFormData.rotting = this.data.hasOwnProperty('rotting') ? this.data.rotting : '';
            this.statusFormData.rules = this.data.hasOwnProperty('rules') && this.data.rules ? JSON.parse(this.data.rules) : this.stageRules;
            this.statusFormData.isJobComplete = this.data.hasOwnProperty('isJobComplete') ? this.data.isJobComplete : '';
            this.statusFormData.viewIcon = this.data.hasOwnProperty('viewIcon') ? this.data.viewIcon : '';
            this.statusFormData.iconType = this.data.hasOwnProperty('iconType') ? this.data.iconType : '';
            this.myValue = this.statusFormData.color;
            this.changedColor = this.myValue;
            this.stageRules = this.statusFormData.rules;
            this.isJobComplete = this.statusFormData.isJobComplete;
            this.selectedIcon = this.data.viewIcon;
            this.selectedIconType = this.data.iconType || 'FA';
            this.stageData = this.stageData.filter(function (item) { return item.id !== _this.data.id; });
            if (this.stageData) {
                if (this.stageData.length < 1) {
                    this.previousStageEnable = true;
                }
                else {
                    this.previousStageEnable = false;
                }
            }
            if (this.stageRules) {
                var stageUsersOptions = (this.stageRules[1] && this.stageRules[1]['radioOptions']) || null;
                var stageRolesOptions = (this.stageRules[0] && this.stageRules[0]['radioOptions']) || null;
                if (stageUsersOptions && (stageUsersOptions[1] && stageUsersOptions[1]['selected'] || stageUsersOptions[2] && stageUsersOptions[2]['selected'])) {
                    this.showInStageInput = true;
                    if (stageUsersOptions[1] && stageUsersOptions[1]['selected']) {
                        var inRoleValues = stageUsersOptions[1]['value'];
                        if (Array.isArray(inRoleValues)) {
                            inRoleValues.forEach(function (element) {
                                _this.inSeletectedRoles.push(parseInt(element, 10));
                            });
                            this.statusFormData.inRoles = this.inSeletectedRoles;
                            stageUsersOptions[1]['value'] = this.inSeletectedRoles;
                        }
                    }
                    if (stageUsersOptions[2] && stageUsersOptions[2]['selected']) {
                        var inUserValues = stageUsersOptions[2]['value'];
                        if (Array.isArray(inUserValues)) {
                            inUserValues.forEach(function (element1) {
                                _this.inSelectedUsers.push(parseInt(element1, 10));
                            });
                            this.statusFormData.inUsers = this.inSelectedUsers;
                            stageUsersOptions[2]['value'] = this.inSelectedUsers;
                        }
                    }
                }
                if (stageRolesOptions && (stageRolesOptions[1] && stageRolesOptions[1]['selected'] || stageRolesOptions[2] && stageRolesOptions[2]['selected'])) {
                    this.showInput = true;
                    if (stageRolesOptions[1] && stageRolesOptions[1]['selected']) {
                        var outRoleValues = stageRolesOptions[1]['value'];
                        if (Array.isArray(outRoleValues)) {
                            outRoleValues.forEach(function (element) {
                                _this.outSeletectedRoles.push(parseInt(element, 10));
                            });
                            this.statusFormData.outRoles = this.outSeletectedRoles;
                            stageRolesOptions[1]['value'] = this.outSeletectedRoles;
                        }
                    }
                    if (stageRolesOptions[2] && Array.isArray(stageRolesOptions[2]['value'])) {
                        stageRolesOptions[2]['value'].forEach(function (element1) {
                            _this.outSelectedUsers.push(parseInt(element1, 10));
                        });
                        this.statusFormData.outUsers = this.outSelectedUsers;
                        stageRolesOptions[2]['value'] = this.outSelectedUsers;
                    }
                }
                if (this.stageRules[2] && this.stageRules[2].value && this.stageData) {
                    var selectedStage = this.stageData.find(function (stage) { return stage.id === +_this.stageRules[2].value; });
                    if (selectedStage) {
                        this.previousStageCheck = false;
                        this.selectedStageId = selectedStage.id;
                        this.selectedStageName = selectedStage.name;
                        setTimeout(function () {
                            $('#cs-select-previous-stage').select2('data', {
                                id: _this.selectedStageId,
                                text: _this.selectedStageName
                            });
                        }, 300);
                    }
                }
            }
        }
        if (this.statusFormData.isJobComplete == '') {
            this.statusFormData.isJobComplete = 'false';
            this.isJobComplete = 'false';
        }
        this.buildForm();
        if (this.screen == 'stage') {
            this.loadIcons();
        }
    };
    PipelineStagesModalComponent.prototype.buildForm = function () {
        var _this = this;
        this.statusForm = this.formBuilder.group({
            'name': [this.statusFormData.name, Validators.required],
            'color': [this.statusFormData.color, Validators.required],
            'rotting': [this.statusFormData.rotting],
            'rules': [this.statusFormData.rules],
            'outRoles': [this.statusFormData.outRoles],
            'outUsers': [this.statusFormData.outUsers],
            'inRoles': [this.statusFormData.inRoles],
            'inUsers': [this.statusFormData.inUsers],
            'isJobComplete': [this.statusFormData.isJobComplete],
            'viewIcon': [this.statusFormData.viewIcon, this.screen == 'stage' ? Validators.required : ''],
            'iconName': [this.statusFormData.iconName],
            'iconType': [this.statusFormData.iconType],
            'iconId': [this.statusFormData.iconId],
        });
        // Use setTimeout to delay the updates to ensure elements are loaded before triggering changes.
        // After values are set, trigger validation manually
        this.statusForm.controls['name'].updateValueAndValidity();
        this.statusForm.controls['color'].updateValueAndValidity();
        this.statusForm.controls['rotting'].updateValueAndValidity();
        this.statusForm.controls['rules'].updateValueAndValidity();
        this.statusForm.controls['outRoles'].updateValueAndValidity();
        this.statusForm.controls['outUsers'].updateValueAndValidity();
        this.statusForm.controls['inRoles'].updateValueAndValidity();
        this.statusForm.controls['inUsers'].updateValueAndValidity();
        this.statusForm.controls['viewIcon'].updateValueAndValidity();
        this.statusForm.controls['iconName'].updateValueAndValidity();
        this.statusForm.controls['iconId'].updateValueAndValidity();
        this.statusForm.controls['iconType'].updateValueAndValidity();
        setTimeout(function () {
            if (_this.statusFormData.outUsers) {
                $('.outUsers').val(_this.outSelectedUsers).trigger('change');
            }
            if (_this.statusFormData.outRoles) {
                $('.outRoles').val(_this.outSeletectedRoles).trigger('change');
            }
            if (_this.statusFormData.inRoles) {
                $('.inRoles').val(_this.inSeletectedRoles).trigger('change');
            }
            if (_this.statusFormData.inUsers) {
                $('.inUsers').val(_this.inSelectedUsers).trigger('change');
            }
        }, 100);
    };
    Object.defineProperty(PipelineStagesModalComponent.prototype, "f", {
        get: function () {
            return this.statusForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    PipelineStagesModalComponent.prototype.getColor = function (data) {
        this.changedColor = data;
    };
    PipelineStagesModalComponent.prototype.checkboxChecked = function (index) {
        var _this = this;
        this.updateStageCheck(index);
        this.stageRules[index].checked = !this.stageRules[index].checked;
        if (!this.stageRules[index].checked) {
            this.stageRules[index]['radioOptions'].forEach(function (element, ind) {
                if (element.selected) {
                    if (index == 0) {
                        _this.showInput = false;
                        _this.outStageCheckBox = false;
                    }
                    if (index == 1) {
                        _this.showInStageInput = false;
                        _this.inStageCheckBok = false;
                    }
                    _this.stageRules[index]['radioOptions'][ind]['selected'] = false;
                    _this.stageRules[index]['radioOptions'][ind]['value'] = '';
                }
            });
            if (index == 2) {
                this.previousStageCheck = false;
                this.selectedStageId = '';
            }
            else if (index == 1) {
                this.outStageCheckBox = false;
            }
            else if (index == 0) {
                this.inStageCheckBok = false;
            }
        }
        else {
            this.optionChange(index, 0);
        }
    };
    PipelineStagesModalComponent.prototype.optionChange = function (i, j) {
        this.stageRules[i]['radioOptions'][j].selected = !this.stageRules[i]['radioOptions'][j].selected;
        if (i == 0 && (j == 1 || j == 2)) {
            this.showInput = true;
            this.inStageCheckBok = true;
            if (j == 1) {
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            }
            else {
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            }
        }
        else if (i == 1 && (j == 1 || j == 2)) {
            this.showInStageInput = true;
            this.outStageCheckBox = true;
            if (j == 1) {
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            }
            else {
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            }
        }
        else {
            if (i == 0) {
                this.inStageCheckBok = false;
                this.showInput = false;
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][2].value = '';
                this.stageRules[i]['radioOptions'][1].value = '';
            }
            else if (i == 1) {
                // this.showInStageInput = false;
                this.outStageCheckBox = false;
                this.showInStageInput = false;
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][2].value = '';
                this.stageRules[i]['radioOptions'][1].value = '';
            }
        }
    };
    PipelineStagesModalComponent.prototype.validateInput = function (event, i, j) {
        if (event) {
            var data = [];
            this.selectedOutUsers = event;
            this.hideSave = false;
            data = event;
            this.stageRules[i]['radioOptions'][j].value = event;
            if (i == 0) {
                if (event && event.length > 0) {
                    this.inStageCheckBok = false;
                }
                else {
                    this.inStageCheckBok = true;
                }
            }
            else if (i == 1) {
                if (event && event.length > 0) {
                    this.outStageCheckBox = false;
                }
                else {
                    this.outStageCheckBox = true;
                }
            }
        }
    };
    PipelineStagesModalComponent.prototype.savePipelineStatus = function () {
        var _this = this;
        this.saving = true;
        this.statusForm.get('rules').setValue(JSON.stringify(this.stageRules));
        var pipelineStatus = this.statusForm.value;
        pipelineStatus['pipelineId'] = this.pipelineId;
        if (this.data && this.data.hasOwnProperty('rotting')) {
            pipelineStatus['rotting'] = this.statusForm.get('rotting').value;
        }
        if (this.isEdit) {
            pipelineStatus['id'] = this.data['id'];
        }
        this.statusForm.updateValueAndValidity();
        if (this.screen == 'job' || this.screen == 'stage') {
            pipelineStatus['screenType'] = this.screen;
            pipelineStatus['isJobComplete'] = JSON.parse(this.isJobComplete.toLowerCase());
        }
        this.cdr.detectChanges();
        this.httpService.addEditStages(pipelineStatus, this.screen)
            .subscribe(function (resp) {
            if (resp) {
                _this.onClose.next(true);
                _this.bsModalRef.hide();
                setTimeout(function () {
                    _this.saving = false;
                }, 200);
            }
        });
    };
    PipelineStagesModalComponent.prototype.nextStage = function (event) {
        this.stageRules[2].value = event.target.value;
    };
    PipelineStagesModalComponent.prototype.updateStageCheck = function (index) {
        switch (index) {
            case 0:
                this.inStageCheckBok = !this.inStageCheckBok;
                if (!this.inStageCheckBok) {
                    this.showInput = false;
                }
                break;
            case 1:
                this.outStageCheckBox = !this.outStageCheckBox;
                if (!this.outStageCheckBox) {
                    this.showInStageInput = false;
                }
                break;
            case 2:
                this.previousStageCheck = !this.previousStageCheck;
                break;
            default:
                break;
        }
    };
    PipelineStagesModalComponent.prototype.onStageSelect = function (value) {
        var selectedStage = this.stageData.find(function (stage) { return stage.id === +value; });
        if (selectedStage) {
            this.selectedStageId = selectedStage.id;
            this.selectedStageName = selectedStage.name;
            // Update the corresponding value in stageRules
            if (this.stageRules[2]) {
                this.stageRules[2].value = this.selectedStageId;
                this.previousStageCheck = false;
            }
        }
    };
    PipelineStagesModalComponent.prototype.stageCompleteChecked = function () {
        this.isJobComplete = this.isJobComplete == 'true' ? 'false' : 'true';
    };
    PipelineStagesModalComponent.prototype.getTransparencyLevel = function (val) {
        if (val) {
            var transparencyLevel = val.slice(7);
            if (transparencyLevel != '' && transparencyLevel.length < 3) {
                var decimal = parseInt(val.slice(7), 16);
                var percentage = Math.round(decimal * (100 / 255));
                return percentage + '%';
            }
        }
        return '100%';
    };
    PipelineStagesModalComponent.prototype.removeIcon = function () {
        this.selectedIcon = '';
        this.iconName = '';
        this.statusForm.controls['viewIcon'].setValue('');
    };
    PipelineStagesModalComponent.prototype.stopEventPropagation = function (eve) {
        eve.stopPropagation();
    };
    PipelineStagesModalComponent.prototype.setSelectedIcon = function (iconData) {
        var _this = this;
        this.selectedIconType = null;
        setTimeout(function () {
            if (iconData.category === 'FA') {
                _this.selectedIconType = iconData.category;
                _this.selectedIcon = iconData.icon.className;
                _this.statusForm.controls['viewIcon'].setValue(iconData.icon.className);
                _this.iconName = iconData.icon.label;
                _this.iconId = true;
            }
            else if (iconData.category === 'image') {
                _this.selectedIconType = iconData.category;
                if (typeof iconData.icon === 'string') {
                    _this.selectedIcon = _this.sanitizer.bypassSecurityTrustUrl(iconData.icon);
                    var iconUrl = _this.selectedIcon['changingThisBreaksApplicationSecurity'] ? _this.selectedIcon['changingThisBreaksApplicationSecurity'] : _this.selectedIcon;
                    _this.statusForm.controls['viewIcon'].setValue(iconUrl);
                    // if (element[1]) this.iconName = element[1];
                    _this.iconId = (_this.uploadedIconsListUrls.includes(iconUrl) || _this.defaultIconsListUrls.includes(iconUrl)) ? true : false;
                }
                else {
                    _this.selectedIcon = _this.sanitizer.bypassSecurityTrustUrl(iconData.icon.url);
                    var iconUrl = _this.selectedIcon['changingThisBreaksApplicationSecurity'] ? _this.selectedIcon['changingThisBreaksApplicationSecurity'] : _this.selectedIcon;
                    _this.statusForm.controls['viewIcon'].setValue(iconUrl);
                    _this.iconName = iconData.icon.name;
                    _this.iconId = (_this.uploadedIconsListUrls.includes(iconUrl) || _this.defaultIconsListUrls.includes(iconUrl)) ? true : false;
                }
            }
            _this.statusForm.controls['iconType'].setValue(_this.selectedIconType);
            _this.statusForm.controls['iconName'].setValue(_this.iconName);
            _this.statusForm.controls['iconId'].setValue(_this.iconId);
        }, 200);
    };
    PipelineStagesModalComponent.prototype.loadIcons = function () {
        var _this = this;
        this.viewsService.getViewsObjects('job').subscribe(function (data) {
            if (data['defaultIconsList']) {
                _this.defaultIconsList = data['defaultIconsList'];
            }
            if (data['uploadedIconList']) {
                var uploadedIconsList = data['uploadedIconList'];
                _this.uploadedIconsListUrls = uploadedIconsList.map(function (iconData) { return iconData['iconUrl']; });
            }
        }, function (error) {
            console.error('error', error);
        });
    };
    return PipelineStagesModalComponent;
}());
export { PipelineStagesModalComponent };
