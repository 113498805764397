<div class="trips-container">
    <div class="trips-wrapper">
        <div class="trips-side-bar" *ngIf="showRoute">
            <div class="trips-header">
                <div class="control-group">
                    <div class="controls">
                        <div class="input-append datepicker-append">
                            <div class="controls input-group flex-display">
                                <div class="input-append datepicker-append trip-datepicker">
                                    <cs-datepicker [type]="'default'" [placeholder]="'Please choose'"
                                        [maxDate]="maxDate" [defaultValue]="selectedCustomDate"
                                        [minDate]="minDate"
                                        [isHideWeekNumbers]="true" [isReadOnly]="true"
                                        [container]="'.datepicker-container'" [isDpDisabled]="false" (selectedDate)="dateCreated($event)">
                                    </cs-datepicker>
                                </div>
                                <!-- <div class="datepicker-container"></div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="arrow-box pointer-cursor" (click)="changeDate('before')" [ngStyle]="{'pointer-events': movePrevious == true ? '' : 'none'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M4.54163 7.39485C4.21436 7.72955 4.21436 8.27312 4.54163 8.60783L9.56852 13.749C9.89579 14.0837 10.4273 14.0837 10.7545 13.749C11.0818 13.4143 11.0818 12.8707 10.7545 12.536L6.31937 8L10.7519 3.46402C11.0792 3.12931 11.0792 2.58574 10.7519 2.25103C10.4247 1.91632 9.89317 1.91632 9.5659 2.25103L4.53901 7.39217L4.54163 7.39485Z"
                            fill="#505B65" />
                    </svg>
                </div>
                <div class="arrow-box pointer-cursor" (click)="changeDate('after')" [ngStyle]="{'pointer-events': moveNext == true ? 'none' : ''}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M11.4584 7.39485C11.7856 7.72955 11.7856 8.27312 11.4584 8.60783L6.43148 13.749C6.10421 14.0837 5.57272 14.0837 5.24545 13.749C4.91818 13.4143 4.91818 12.8707 5.24545 12.536L9.68063 8L5.24807 3.46402C4.9208 3.12931 4.9208 2.58574 5.24807 2.25103C5.57534 1.91632 6.10683 1.91632 6.4341 2.25103L11.461 7.39217L11.4584 7.39485Z"
                            fill="#505B65" />
                    </svg>
                </div>
            </div>
            <div class="trips-body">
            <div class="activity-container" *ngIf="trackingDetails.length != 0 && !loading">
                <div class="activity-info">
                    <div>
                        <span>Activity</span>&nbsp;<span>{{ summary['activityTime'] }}</span>
                    </div>
                    <div class="incidents-info cursor-pointer dropdown">
                        <div class="dropdown-toggle incidents-info-text" role="button" id="dropdownIncidentsInfo"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span class="incident-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8ZM8 2.3125C6.49158 2.3125 5.04494 2.91172 3.97833 3.97833C2.91172 5.04494 2.3125 6.49158 2.3125 8C2.3125 9.50842 2.91172 10.9551 3.97833 12.0217C5.04494 13.0883 6.49158 13.6875 8 13.6875C9.50842 13.6875 10.9551 13.0883 12.0217 12.0217C13.0883 10.9551 13.6875 9.50842 13.6875 8C13.6875 6.49158 13.0883 5.04494 12.0217 3.97833C10.9551 2.91172 9.50842 2.3125 8 2.3125ZM6.6875 7.78125C6.6875 7.6072 6.75664 7.44028 6.87971 7.31721C7.00278 7.19414 7.1697 7.125 7.34375 7.125H8.21875C8.3928 7.125 8.55972 7.19414 8.68279 7.31721C8.80586 7.44028 8.875 7.6072 8.875 7.78125V10.1875H9.09375C9.2678 10.1875 9.43472 10.2566 9.55779 10.3797C9.68086 10.5028 9.75 10.6697 9.75 10.8438C9.75 11.0178 9.68086 11.1847 9.55779 11.3078C9.43472 11.4309 9.2678 11.5 9.09375 11.5H7.34375C7.1697 11.5 7.00278 11.4309 6.87971 11.3078C6.75664 11.1847 6.6875 11.0178 6.6875 10.8438C6.6875 10.6697 6.75664 10.5028 6.87971 10.3797C7.00278 10.2566 7.1697 10.1875 7.34375 10.1875H7.5625V8.4375H7.34375C7.1697 8.4375 7.00278 8.36836 6.87971 8.24529C6.75664 8.12222 6.6875 7.9553 6.6875 7.78125ZM8 6.25C7.76794 6.25 7.54538 6.15781 7.38128 5.99372C7.21719 5.82962 7.125 5.60706 7.125 5.375C7.125 5.14294 7.21719 4.92038 7.38128 4.75628C7.54538 4.59219 7.76794 4.5 8 4.5C8.23206 4.5 8.45462 4.59219 8.61872 4.75628C8.78281 4.92038 8.875 5.14294 8.875 5.375C8.875 5.60706 8.78281 5.82962 8.61872 5.99372C8.45462 6.15781 8.23206 6.25 8 6.25Z"
                                        fill="#E59502" />
                                </svg>
                            </span>
                            <span class="incident-count-info">{{ summary['totalIncidents'] }} safety incidents</span>
                        </div>
                        <div class="incidents-info-dropdownmenu dropdown-menu" aria-labelledby="dropdownIncidentsInfo" >
                            <ul class="incidents-info-list">
                                
                                <li *ngIf="summary['incidentTypesCount']['hardAcceleration']">
                                    <span class="incident-icon">
                                        <svg _ngcontent-c4="" fill="none" height="16" viewBox="0 0 16 16" width="16"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g _ngcontent-c4="" clip-path="url(#clip0_3122_8813)">
                                                <path _ngcontent-c4=""
                                                    d="M12.631 4.67042C12.631 4.18705 12.2385 3.79455 11.7552 3.79455L5.56798 3.79455C5.08461 3.79455 4.69211 4.18705 4.69211 4.67042C4.69211 5.1538 5.08461 5.54629 5.56798 5.54629L9.64572 5.54436L3.71376 11.4763C3.37153 11.8186 3.37153 12.3715 3.71376 12.7138C4.05599 13.056 4.60897 13.056 4.9512 12.7138L10.8812 6.78373L10.8832 10.8576C10.8832 11.341 11.2757 11.7335 11.759 11.7335C12.2424 11.7335 12.6349 11.341 12.6349 10.8576L12.6349 4.67042L12.631 4.67042Z"
                                                    fill="#505B65"></path>
                                            </g>
                                            <defs _ngcontent-c4="">
                                                <clipPath _ngcontent-c4="" id="clip0_3122_8813">
                                                    <rect _ngcontent-c4="" fill="white" height="16" width="16"></rect>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span>{{ summary['incidentTypesCount']['hardAcceleration'] }} acceleration
                                        incidents</span>
                                </li>
                                <li *ngIf="summary['incidentTypesCount']['overspeed']">
                                    <span class="incident-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M3.75734 12.9094C2.91823 12.0702 2.34679 11.0011 2.11529 9.83726C1.88378 8.67338 2.0026 7.46698 2.45673 6.37063C2.91086 5.27428 3.6799 4.33721 4.66659 3.67793C5.65328 3.01864 6.81332 2.66675 8 2.66675C9.18669 2.66675 10.3467 3.01864 11.3334 3.67793C12.3201 4.33721 13.0891 5.27428 13.5433 6.37063C13.9974 7.46698 14.1162 8.67338 13.8847 9.83726C13.6532 11.0011 13.0818 12.0702 12.2427 12.9094M10.6667 6.00003L8 8.6667"
                                                stroke="#505B65" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span>{{ summary['incidentTypesCount']['overspeed'] }} speeding incidents</span>
                                </li>
                                <li *ngIf="summary['incidentTypesCount']['hardCornering']">
                                    <span class="incident-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M8.72 1.72007C8.86063 1.57962 9.05125 1.50073 9.25 1.50073C9.44875 1.50073 9.63937 1.57962 9.78 1.72007L13.28 5.22007C13.4205 5.3607 13.4993 5.55132 13.4993 5.75007C13.4993 5.94882 13.4205 6.13945 13.28 6.28007L9.78 9.78007C9.63783 9.91255 9.44978 9.98468 9.25548 9.98125C9.06118 9.97782 8.87579 9.89911 8.73838 9.76169C8.60097 9.62428 8.52225 9.4389 8.51882 9.24459C8.5154 9.05029 8.58752 8.86225 8.72 8.72007L10.94 6.50007H5.75C5.06 6.50007 4.5 7.06007 4.5 7.75007V13.2501C4.5 13.449 4.42098 13.6397 4.28033 13.7804C4.13968 13.9211 3.94891 14.0001 3.75 14.0001C3.55109 14.0001 3.36032 13.9211 3.21967 13.7804C3.07902 13.6397 3 13.449 3 13.2501V7.75007C3 7.02073 3.28973 6.32125 3.80546 5.80553C4.32118 5.2898 5.02065 5.00007 5.75 5.00007H10.94L8.72 2.78007C8.57955 2.63945 8.50066 2.44882 8.50066 2.25007C8.50066 2.05132 8.57955 1.8607 8.72 1.72007Z"
                                                fill="#505B65" />
                                        </svg>
                                    </span>
                                    <span>{{ summary['incidentTypesCount']['hardCornering'] }} cornering
                                        incidents</span>
                                </li>
                                <li *ngIf="summary['incidentTypesCount']['hardBraking']">
                                    <span class="incident-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M8 6V8.66667M8 10.6667V10.6733M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2C7.21207 2 6.43185 2.15519 5.7039 2.45672C4.97595 2.75825 4.31451 3.20021 3.75736 3.75736C3.20021 4.31451 2.75825 4.97595 2.45672 5.7039C2.15519 6.43185 2 7.21207 2 8Z"
                                                stroke="#505B65" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span>{{ summary['incidentTypesCount']['hardBraking'] }} braking incidents</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="info-message">
                    <div>
                        <span><b>Distance</b> {{ summary['totalDistance'] }}</span>
                    </div>
                    <span>.</span>
                    <div>
                        <span><b>Time</b> {{ summary['totalTime'] }}</span>
                    </div>
                </div>
                <div class="info-message">
                    <div class="info-message">
                        <div class="moving-info"></div>
                        <span><b>Moving</b> {{ summary['totalMoving'] }}</span>
                    </div>
                    <div class="info-message">
                        <div class="stopped-info"></div>
                        <span><b>Stopped</b> {{ summary['totalStop'] }}</span>
                    </div>
                    <!-- <div class="info-message">
                        <div class="idling-info"></div>
                        <span><b>Idling</b> {{ summary['totalIdle'] }}</span>
                    </div> -->
                </div>
            </div>
            <div class="route" *ngIf="!loading">
                <div *ngFor="let track of trackingDetails; let i=index;"
                    [ngClass]="{'selected':track['elementIndex'] == selectedIndex}" (click)="setRoute(track)">
                    <ng-container *ngIf="track['type'] == 'trip'">
                        <div class="activity-steps pointer-cursor">
                            <div class="activity-layout line">
                                <div class="status-wrapper-left">
                                    <div class="status-icon mtn-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28"
                                            viewBox="0 0 28 28" fill="none">
                                            <rect x="1" y="1" width="26" height="26" rx="13" fill="white" />
                                            <rect x="1" y="1" width="26" height="26" rx="13" stroke="white"
                                                stroke-width="2" />
                                            <path
                                                d="M14 24.5C16.7848 24.5 19.4555 23.3938 21.4246 21.4246C23.3938 19.4555 24.5 16.7848 24.5 14C24.5 11.2152 23.3938 8.54451 21.4246 6.57538C19.4555 4.60625 16.7848 3.5 14 3.5C11.2152 3.5 8.54451 4.60625 6.57538 6.57538C4.60625 8.54451 3.5 11.2152 3.5 14C3.5 16.7848 4.60625 19.4555 6.57538 21.4246C8.54451 23.3938 11.2152 24.5 14 24.5ZM11.375 10.0625H16.625C17.351 10.0625 17.9375 10.649 17.9375 11.375V16.625C17.9375 17.351 17.351 17.9375 16.625 17.9375H11.375C10.649 17.9375 10.0625 17.351 10.0625 16.625V11.375C10.0625 10.649 10.649 10.0625 11.375 10.0625Z"
                                                fill="#BE6D6D" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="status-wrapper-right ">
                                    <div class="status-wrapper-stopped">
                                        <p class="status-message"
                                            *ngIf="['office', 'home'].indexOf(track['stoppedAt']) != -1">Stopped at
                                            {{track['stoppedAt']}}</p>
                                        <p class="status-message" *ngIf="['other'].indexOf(track['stoppedAt']) != -1">
                                            Stopped at "{{track['stoppedAtDetails']['name']}}"</p>
                                        <p class="status-message"
                                            *ngIf="['office', 'home', 'other'].indexOf(track['stoppedAt']) == -1">
                                            Stopped</p>
                                        <p class="spot-time">{{track['endTime'] | date:'h mm a'}}</p>
                                    </div>
                                    <p class="sub-data" *ngIf="track['endAddress']">{{track['endAddress']}}</p>
                                    <div class="duration-time">
                                        <p>{{ track['durationToShow'] }} </p>
                                        <p>&nbsp;driving</p>
                                        <span *ngIf="track['incidentCount'] > 0" class="little-dot"></span>
                                        <div *ngIf="track['incidentCount'] > 0" class="dropdown" id="incidentCount" >
                                            <!-- open -->
                                            <div class="dropdown-toggle incidents-info-text incident-count" role="button"
                                                id="dropdownIncidentCount" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false"  (mouseenter)="hoveredIndex = i" 
                                                (mouseleave)="hoveredIndex = null">
                                                <span>{{ track['incidentCount'] }} safety incidents</span>
                                                <i class="fa-thin fa-circle-info icon-left incident-icon"></i>
                                            </div>
                                            <div class="incidents-info-dropdown dropdown-menu m-0"
                                                aria-labelledby="dropdownIncidentCount"  [ngClass]="{'show': hoveredIndex === i}"  >
                                                <ul class="incidents-info-list">
                                                   
                                                    <li *ngIf="track['incidentTypesCount']['hardAcceleration']">
                                                        <span class="incident-icon">
                                                            <svg _ngcontent-c4="" fill="none" height="16"
                                                                viewBox="0 0 16 16" width="16"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g _ngcontent-c4="" clip-path="url(#clip0_3122_8813)">
                                                                    <path _ngcontent-c4=""
                                                                        d="M12.631 4.67042C12.631 4.18705 12.2385 3.79455 11.7552 3.79455L5.56798 3.79455C5.08461 3.79455 4.69211 4.18705 4.69211 4.67042C4.69211 5.1538 5.08461 5.54629 5.56798 5.54629L9.64572 5.54436L3.71376 11.4763C3.37153 11.8186 3.37153 12.3715 3.71376 12.7138C4.05599 13.056 4.60897 13.056 4.9512 12.7138L10.8812 6.78373L10.8832 10.8576C10.8832 11.341 11.2757 11.7335 11.759 11.7335C12.2424 11.7335 12.6349 11.341 12.6349 10.8576L12.6349 4.67042L12.631 4.67042Z"
                                                                        fill="#505B65"></path>
                                                                </g>
                                                                <defs _ngcontent-c4="">
                                                                    <clipPath _ngcontent-c4="" id="clip0_3122_8813">
                                                                        <rect _ngcontent-c4="" fill="white" height="16"
                                                                            width="16"></rect>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        <span>{{ track['incidentTypesCount']['hardAcceleration'] }}
                                                            acceleration incidents</span>
                                                    </li>
                                                    <li *ngIf="track['incidentTypesCount']['overspeed']">
                                                        <span class="incident-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                viewBox="0 0 16 16" fill="none">
                                                                <path
                                                                    d="M3.75734 12.9094C2.91823 12.0702 2.34679 11.0011 2.11529 9.83726C1.88378 8.67338 2.0026 7.46698 2.45673 6.37063C2.91086 5.27428 3.6799 4.33721 4.66659 3.67793C5.65328 3.01864 6.81332 2.66675 8 2.66675C9.18669 2.66675 10.3467 3.01864 11.3334 3.67793C12.3201 4.33721 13.0891 5.27428 13.5433 6.37063C13.9974 7.46698 14.1162 8.67338 13.8847 9.83726C13.6532 11.0011 13.0818 12.0702 12.2427 12.9094M10.6667 6.00003L8 8.6667"
                                                                    stroke="#505B65" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span>{{ track['incidentTypesCount']['overspeed'] }} speeding
                                                            incidents</span>
                                                    </li>
                                                    <li *ngIf="track['incidentTypesCount']['hardCornering']">
                                                        <span class="incident-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                viewBox="0 0 16 16" fill="none">
                                                                <path
                                                                    d="M8.72 1.72007C8.86063 1.57962 9.05125 1.50073 9.25 1.50073C9.44875 1.50073 9.63937 1.57962 9.78 1.72007L13.28 5.22007C13.4205 5.3607 13.4993 5.55132 13.4993 5.75007C13.4993 5.94882 13.4205 6.13945 13.28 6.28007L9.78 9.78007C9.63783 9.91255 9.44978 9.98468 9.25548 9.98125C9.06118 9.97782 8.87579 9.89911 8.73838 9.76169C8.60097 9.62428 8.52225 9.4389 8.51882 9.24459C8.5154 9.05029 8.58752 8.86225 8.72 8.72007L10.94 6.50007H5.75C5.06 6.50007 4.5 7.06007 4.5 7.75007V13.2501C4.5 13.449 4.42098 13.6397 4.28033 13.7804C4.13968 13.9211 3.94891 14.0001 3.75 14.0001C3.55109 14.0001 3.36032 13.9211 3.21967 13.7804C3.07902 13.6397 3 13.449 3 13.2501V7.75007C3 7.02073 3.28973 6.32125 3.80546 5.80553C4.32118 5.2898 5.02065 5.00007 5.75 5.00007H10.94L8.72 2.78007C8.57955 2.63945 8.50066 2.44882 8.50066 2.25007C8.50066 2.05132 8.57955 1.8607 8.72 1.72007Z"
                                                                    fill="#505B65" />
                                                            </svg>
                                                        </span>
                                                        <span>{{ track['incidentTypesCount']['hardCornering'] }} cornering
                                                            incidents</span>
                                                    </li>
                                                    <li *ngIf="track['incidentTypesCount']['hardBraking']">
                                                        <span class="incident-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                viewBox="0 0 16 16" fill="none">
                                                                <path
                                                                    d="M8 6V8.66667M8 10.6667V10.6733M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2C7.21207 2 6.43185 2.15519 5.7039 2.45672C4.97595 2.75825 4.31451 3.20021 3.75736 3.75736C3.20021 4.31451 2.75825 4.97595 2.45672 5.7039C2.15519 6.43185 2 7.21207 2 8Z"
                                                                    stroke="#505B65" stroke-width="1.5"
                                                                    stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </span>
                                                        <span>{{ track['incidentTypesCount']['hardBraking'] }} braking
                                                            incidents</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="activity-layout">
                                <div class="status-wrapper-left">
                                    <div class="status-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <rect width="20" height="20" rx="10" fill="#60A369" />
                                            <path
                                                d="M11.0203 4.1158C10.8805 3.78009 10.5508 3.56229 10.1871 3.5623C9.82337 3.56232 9.49364 3.78014 9.35375 4.11587L5.19689 14.0277C5.02902 14.4273 5.16888 14.8869 5.52657 15.1287C5.88426 15.3705 6.36386 15.3225 6.66961 15.0168L10.1868 11.4996L13.7037 15.0165C14.0094 15.3222 14.487 15.3682 14.8467 15.1284C15.2064 14.8886 15.3443 14.4269 15.1765 14.0273L11.0203 4.1158Z"
                                                fill="white" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="status-wrapper-right ">
                                    <div class="status-wrapper-job-left">
                                        <p class="status-message"
                                            *ngIf="['location', 'diary'].indexOf(track['startedFrom']) != -1 && !track['startedFromHome']">
                                            Start</p>
                                        <p class="status-message" *ngIf="track['startedFromHome']">Started from home</p>
                                        <p class="status-message" *ngIf="track['startedFrom']=='office'">Started from
                                            office</p>
                                        <p class="status-message" *ngIf="track['startedFrom']=='other'">Started from
                                            "{{track['startedFromDetails']['name']}}"</p>
                                        <p class="spot-time">{{track['startTime'] | date:'h mm a'}}</p>
                                    </div>
                                    <p class="sub-data" *ngIf="track['startAddress']">{{track['startAddress']}}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="track['type'] == 'spent'">
                        <div class="spend-activity-container activity-steps">
                            <div class="activity-layout">
                                <div class="status-wrapper-left">
                                    <div class="status-icon mtn-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect x="1" y="1" width="22" height="22" rx="11" fill="white" />
                                            <rect x="1" y="1" width="22" height="22" rx="11" stroke="white"
                                                stroke-width="2" />
                                            <path
                                                d="M12 3.25C14.3206 3.25 16.5462 4.17187 18.1872 5.81282C19.8281 7.45376 20.75 9.67936 20.75 12C20.75 14.3206 19.8281 16.5462 18.1872 18.1872C16.5462 19.8281 14.3206 20.75 12 20.75C9.67936 20.75 7.45376 19.8281 5.81282 18.1872C4.17187 16.5462 3.25 14.3206 3.25 12C3.25 9.67936 4.17187 7.45376 5.81282 5.81282C7.45376 4.17187 9.67936 3.25 12 3.25ZM11.1797 7.35156V12C11.1797 12.2734 11.3164 12.5298 11.5454 12.6836L14.8267 14.8711C15.2026 15.124 15.7119 15.0215 15.9648 14.6421C16.2178 14.2627 16.1152 13.7568 15.7358 13.5039L12.8203 11.5625V7.35156C12.8203 6.89697 12.4546 6.53125 12 6.53125C11.5454 6.53125 11.1797 6.89697 11.1797 7.35156Z"
                                                fill="#8E8E8E" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="status-wrapper-right">
                                    <div class="status-wrapper-job-left">
                                        <p class="status-message spend-align">Spent {{track['timeToShow']}}
                                            <span
                                                *ngIf="track['eventDetails'] && track['eventDetails']['location'] == 'office'">
                                                at office</span>
                                            <span
                                                *ngIf="track['eventDetails'] && track['eventDetails']['location'] == 'other'">
                                                at "{{track['eventDetails']['name']}}"</span>
                                        </p>
                                    </div>
                                    <p class="sub-data" *ngIf="track['showEventDetails']">
                                        {{track['eventDetails']['diaryType']}} {{track['eventDetails']['primaryId']}}
                                        <a target="_blank" class="fleet-diary-view-link"
                                            href="{{track['eventDetails']['viewLink']}}">
                                            <i class="fa-regular fa fa-external-link"></i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="no-trip-contain" *ngIf="trackingDetails.length == 0">
                    <img class="noIcon" src="images/live-map/car-vehicle.svg" alt="car-image" />
                    <p class="title-text">There's no trips</p>
                    <p>No trips were logged</p>
                </div>
            </div>
            <div *ngIf="loading">
                <div class="loading-wrap">
                    <span class="circle1"></span>
                    <span class="circle2"></span>
                    <span class="circle3"></span>
                    <p class="loading-text">
                        Loading...
                    </p>
                </div>
            </div>
            </div>
        </div>
        <div class="w-70 trips-map" [ngClass]="{'for-dashboard':screen == 'Dashboard_trips' && !showRoute}">
            <!-- Map section -->
            <div class="trip-map-section">
                <div id="map" class="map-loads"></div>
                <ng-container *ngIf="mapInitated">
                    <app-fleet-map-view [liveMapInstance]="map"></app-fleet-map-view>
                </ng-container>
                <div class="map-location-target pointer-cursor" (click)="getSelectedLocation()">
                    <img src="images/live-map/location-target.svg" alt="map-loaction-target" />
                </div>
            </div>
        </div>
    </div>
</div>