/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../directives/material-directives/cs-select2/cs-select2.directive";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/cs-components/template-preview/template-preview.component.ngfactory";
import * as i5 from "../../../../shared/cs-components/template-preview/template-preview.component";
import * as i6 from "../../../../sidepanel/services/sidepanel-modal.service";
import * as i7 from "../../../../shared/cs-components/search-template/search-template.component.ngfactory";
import * as i8 from "../../../../shared/cs-components/search-template/search-template.component";
import * as i9 from "../../../../shared/common-folder-structure/service/folder.service";
import * as i10 from "../../../../core/auth/auth.service";
import * as i11 from "@angular/common";
import * as i12 from "./sms-block-widget.component";
import * as i13 from "../../../services/workflowhttp.service";
var styles_SmsBlockWidgetComponent = ["[_nghost-%COMP%]     input.search-template { height: auto !important }[_nghost-%COMP%]     .folder-selection .folder-label { width: 124px !important;  max-width: 124px !important; display: unset !important; }"];
var RenderType_SmsBlockWidgetComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SmsBlockWidgetComponent, data: {} });
export { RenderType_SmsBlockWidgetComponent as RenderType_SmsBlockWidgetComponent };
function View_SmsBlockWidgetComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "loading-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "circle1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "circle2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "circle3"]], null, null, null, null, null))], null, null); }
function View_SmsBlockWidgetComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 26, "form", [["class", "no-form-style"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 540672, null, 0, i1.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.FormGroupDirective]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 21, "div", [["class", "cs-d-flex flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "cs-helper-section bg-white cs-border cs-border--bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "cs-help-text-color p--15"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 17, "div", [["class", "cs-flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 16, "div", [["class", "cs-block-widget--form cs-d-flex flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 5, "div", [["class", "cs-block-widget--form-control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "label", [["class", "control-label required_field mr-auto"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ":"])), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "input", [["class", "full-width-select2 cs-select2-wfe"], ["csSelect2", ""], ["id", "recipient_mobile_address"]], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.selectedMobileAddress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(16, 4276224, null, 0, i2.CsSelect2Directive, [i0.ElementRef, i0.Renderer, [2, i1.NgControl]], { select2Config: [0, "select2Config"], value: [1, "value"] }, { valueEmitter: "selected" }), (_l()(), i0.ɵeld(17, 0, null, null, 9, "div", [["class", "cs-block-widget--form-control"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 5, "div", [["class", "cs-d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 2, "label", [["class", "control-label required_field mr-auto"], ["translate", ""]], null, null, null, null, null)), i0.ɵdid(20, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["SMS.Template:"])), (_l()(), i0.ɵeld(22, 0, null, null, 1, "app-template-preview", [["type", "sms"]], null, null, null, i4.View_TemplatePreviewComponent_0, i4.RenderType_TemplatePreviewComponent)), i0.ɵdid(23, 114688, null, 0, i5.TemplatePreviewComponent, [i6.SidepanelModalService], { type: [0, "type"], message: [1, "message"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 1, "app-search-template", [["themeName", "compact"]], null, [[null, "selectedItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedItem" === en)) {
        var pd_0 = (_co.setTemplateDetails($event, "sms") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SearchTemplateComponent_0, i7.RenderType_SearchTemplateComponent)), i0.ɵdid(26, 638976, null, 0, i8.SearchTemplateComponent, [i9.FolderService, i6.SidepanelModalService, i10.AuthService], { screen: [0, "screen"], showAddNewTemplate: [1, "showAddNewTemplate"], screenLabel: [2, "screenLabel"], selectedTemplateId: [3, "selectedTemplateId"], themeName: [4, "themeName"] }, { selectedItem: "selectedItem" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_10 = _co.select2ConfigToSms; var currVal_11 = _co.selectedMobileInputs; _ck(_v, 16, 0, currVal_10, currVal_11); var currVal_12 = ""; _ck(_v, 20, 0, currVal_12); var currVal_13 = "sms"; var currVal_14 = _co.inputModel.message; _ck(_v, 23, 0, currVal_13, currVal_14); var currVal_15 = "sms_template"; var currVal_16 = true; var currVal_17 = "SMS templates"; var currVal_18 = _co.inputModel.templateId; var currVal_19 = "compact"; _ck(_v, 26, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _co.smsBlockData["description"]; _ck(_v, 8, 0, currVal_8); var currVal_9 = _co.smsBlockData["inputLabelText"]; _ck(_v, 13, 0, currVal_9); }); }
function View_SmsBlockWidgetComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-danger btn-medium float-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete "]))], null, null); }
function View_SmsBlockWidgetComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SmsBlockWidgetComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Saving"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SmsBlockWidgetComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockWidgetComponent_4)), i0.ɵdid(2, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockWidgetComponent_5)), i0.ɵdid(7, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockWidgetComponent_6)), i0.ɵdid(9, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDeleteBtn; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_co.isFormSubmitted; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.isFormSubmitted; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.form.valid || _co.isFormSubmitted); _ck(_v, 5, 0, currVal_1); }); }
function View_SmsBlockWidgetComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-link"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(4, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = true; _ck(_v, 2, 0, currVal_1); }); }
export function View_SmsBlockWidgetComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockWidgetComponent_1)), i0.ɵdid(5, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockWidgetComponent_2)), i0.ɵdid(7, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SmsBlockWidgetComponent_3)), i0.ɵdid(10, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵand(0, [["footerSection", 2]], null, 0, null, View_SmsBlockWidgetComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.form; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.isLoading; var currVal_4 = i0.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.smsBlockData["title"]; _ck(_v, 2, 0, currVal_0); }); }
export function View_SmsBlockWidgetComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sms-block-widget", [], null, null, null, View_SmsBlockWidgetComponent_0, RenderType_SmsBlockWidgetComponent)), i0.ɵdid(1, 114688, null, 0, i12.SmsBlockWidgetComponent, [i1.FormBuilder, i13.WorkflowhttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsBlockWidgetComponentNgFactory = i0.ɵccf("app-sms-block-widget", i12.SmsBlockWidgetComponent, View_SmsBlockWidgetComponent_Host_0, { workflowType: "workflowType", objectName: "objectName", blockObject: "blockObject", dagSourceModel: "dagSourceModel" }, { close: "close" }, []);
export { SmsBlockWidgetComponentNgFactory as SmsBlockWidgetComponentNgFactory };
