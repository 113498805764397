var ScheduledActivityBlockModel = /** @class */ (function () {
    function ScheduledActivityBlockModel(data) {
        if (data === void 0) { data = undefined; }
        this.activityType = undefined;
        this.assigneeDisplay = [];
        this.assigneeIds = [];
        this.description = '';
        this.displayTime = '';
        this.displaySelectedTime = '';
        this.notes = '';
        this.scheduleInterval = 0;
        this.scheduleTime = undefined;
        this.unit = '';
        if (data) {
            this.activityType = data['activityType'] ? parseInt(data['activityType']) : undefined;
            this.activityTypeDisplay = data['activityTypeDisplay'] ? data['activityTypeDisplay'] : '';
            this.assigneeDisplay = data['assigneeDisplay'] ? data['assigneeDisplay'] : [];
            this.assigneeIds = data['assigneeIds'] ? data['assigneeIds'] : [];
            this.description = data['description'] ? data['description'] : '';
            this.displayTime = data['displayTime'] ? data['displayTime'] : '';
            this.displaySelectedTime = data['displaySelectedTime'] ? data['displaySelectedTime'] : '';
            this.notes = data['notes'] ? data['notes'] : '';
            this.scheduleInterval = data.hasOwnProperty('scheduleInterval') ? parseInt(data['scheduleInterval']) : 0;
            this.scheduleTime = data['scheduleTime'] ? data['scheduleTime'] : undefined;
            this.unit = data['unit'] ? data['unit'] : '';
        }
    }
    return ScheduledActivityBlockModel;
}());
export { ScheduledActivityBlockModel };
