<div class="modal-header">
    <span id="modal-title-bar" class="title-heading" translate>Parts.{{jobPartStatus}}</span> - <span id="modal-title-bar" >{{ oneHourTime }}</span>
</div>
<div class="modal-body">
    <div class="view-diary-event-main-view">
        <div *ngFor="let jobParts of jobPartDetails; let i = index" style="border: 1px solid #dadada;margin: 16px;border-radius: 2px ; background-color: white;">
            <div style="display: flex; flex-direction: column; padding: 16px">

                <div class="field">
                    <span class="field-label ng-scope" translate="">Part</span>
                    <span>{{ jobParts.jobPartName }}</span>
                </div>
<!--                <div class="field" *ngIf="jobPartStatus == 'Requested'" >-->
<!--                    <span class="field-label ng-scope" translate="">Quantity </span>-->
<!--                    <span>{{ jobParts.requestedQty | number:'1.2-2'  }}</span>-->
<!--                </div>-->
                <div class="field" *ngIf="jobParts.fulFillmentType!=''">
                    <span class="field-label ng-scope" translate="" >Fulfill.Type</span>
                    <span translate="">{{ jobParts.fulFillmentType }}</span>
                </div>
                <div class="field">
                    <span class="field-label ng-scope" translate="">Quantity</span>
                    <span *ngIf="jobPartStatus === 'Part_Qty_Added'"> + {{ jobParts.quantity | number:'1.2-2' }}</span>
                    <span *ngIf="jobPartStatus === 'Part_Qty_Reduced'"> - {{ jobParts.quantity | number:'1.2-2'  }}</span>
                    <span *ngIf="jobPartStatus !== 'Part_Qty_Added' && jobPartStatus !== 'Part_Qty_Reduced'">{{ jobParts.quantity | number:'1.2-2' }} </span>
                </div>
                <div class="field" *ngIf="jobParts.poDetails && jobParts.poDetails.length > 0">
                    <span class="field-label ng-scope" translate="">Supplier</span>
                    <span>{{ jobParts.poDetails[0]?.supplierName }}</span>
                </div>
                <div class="field" *ngIf="jobParts.poDetails && jobParts.poDetails.length > 0">
                    <span class="field-label ng-scope" translate="">PO.number</span>
                    <span>{{ jobParts.poDetails[0]?.purchaseOrderId }}</span>
                </div>

                <div class="field">
                    <span class="field-label ng-scope" translate="">Created by</span>
                    <div class="title-with-image">
                        <div>
                            <img *ngIf="jobParts?.profilePic && jobParts?.requestBy != ''; else defaultProfile"
                                 src="{{jobParts.profilePic}}" alt="active-driver-image"
                                 class="profile-radius img-width margin-right-unset" >
                            <ng-template #defaultProfile>
                                <img src="images/profile_avatar_small.jpg" alt="active-driver-image1"
                                     class="profile-radius pointer-cursor img-width">
                            </ng-template>
                            <span class="name-text">{{jobParts.requestBy}} </span>
                        </div>
                    </div>
                </div>

                <div class="field" style=" padding-top: 10px;">
                    <span class="field-label ng-scope" translate="">Created on</span>
                    <span>{{ jobParts.createdDateTime }}</span>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<div class="modal-footer">
    <a class="btn" (click)="bsModalRef.hide()">Close</a>
</div>
