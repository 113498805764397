import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { CommonService } from "@app/services";
import { FormBuilder } from "@angular/forms";
var CustomfieldUserComponent = /** @class */ (function () {
    function CustomfieldUserComponent(commonService, fb) {
        this.commonService = commonService;
        this.fb = fb;
        this.userData = [];
        this.showUserField = false;
        this.listIndex = '';
        this.isFormControlNeed = true;
        this.allowShowDeletedUser = false;
        this.valueEmitter = new EventEmitter();
    }
    CustomfieldUserComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, userId;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        userId = '';
                        if (this.pageIndex && this.pageIndex.hasOwnProperty('page') && this.pageIndex.hasOwnProperty('index'))
                            this.listIndex = "_page" + (this.pageIndex.page + 1) + "_" + (this.pageIndex.index + 1);
                        if (this.fieldData && this.fieldData.hasOwnProperty('questionID') && this.parentForm) {
                            userId = this.parentForm.getRawValue()[this.fieldData['questionID'] + this.listIndex];
                            if ((!userId) && this.fieldData.hasOwnProperty('defaultID') && this.fieldData['defaultID'])
                                this.isDefaultUserId = this.fieldData.hasOwnProperty('defaultID') && this.fieldData['defaultID'] != userId;
                            userId = userId ? userId : this.fieldData.hasOwnProperty('defaultID') ? this.fieldData['defaultID'] : '';
                            this.getSelectedUser(userId);
                        }
                        else {
                            userId = this.fieldData.hasOwnProperty('defaultID') ? this.fieldData['defaultID'] : '';
                            this.isDefaultUserId = userId != '';
                        }
                        this.userForm = this.fb.group((_a = {},
                            _a[this.fieldData['questionID'] + this.listIndex] = [userId],
                            _a));
                        return [4 /*yield*/, this.getUserDetails()];
                    case 1:
                        _b.sent();
                        if (!userId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.checkUserIsActive(userId)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        this.showUserField = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomfieldUserComponent.prototype.getUserDetails = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.commonService.getUsersList({ sort: 'name:asc' })
                .toPromise()
                .then(function (data) {
                if (data) {
                    _this.userData = data.userRecords;
                }
                resolve();
            }, function (err) {
                reject(err);
            });
        });
    };
    CustomfieldUserComponent.prototype.checkUserIsActive = function (userId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (userId == '') {
                resolve();
                return;
            }
            _this.commonService.getIsUserActive(userId)
                .toPromise()
                .then(function (data) {
                if (data.isDeleted && (_this.allowShowDeletedUser || !_this.isDefaultUserId)) {
                    _this.userData.push(data);
                }
                else if (data.isDeleted && _this.isDefaultUserId) {
                    var userFieldControl = _this.userForm.get(_this.fieldData['questionID'] + _this.listIndex);
                    if (userFieldControl) {
                        userFieldControl.setValue('');
                    }
                    _this.getSelectedUser('');
                }
                resolve();
            }, function (err) {
                reject(err);
            });
        });
    };
    CustomfieldUserComponent.prototype.getSelectedUser = function (userId) {
        var userFieldControl = this.parentForm.get(this.fieldData['questionID'] + this.listIndex);
        if (userFieldControl) {
            userFieldControl.setValue(userId);
            if (!this.listIndex) {
                delete this.fieldData['defaults'];
                delete this.fieldData['defaultID'];
                delete this.fieldData['defaultValue'];
            }
        }
    };
    CustomfieldUserComponent.prototype.setDefaultUser = function (userId) {
        var user = userId === '' ? [] : this.userData.filter(function (user) { return (user['id'] == userId); });
        var changedValue = {};
        changedValue['defaultValue'] = user.length > 0 ? user[0]['name'] : null;
        changedValue['defaults'] = userId;
        changedValue['defaultID'] = userId;
        this.valueEmitter.emit(changedValue);
    };
    return CustomfieldUserComponent;
}());
export { CustomfieldUserComponent };
