import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-calculation-field-parser',
  templateUrl: './calculation-field-parser.component.html',
  styles: []
})
export class CalculationFieldParserComponent implements OnChanges {
    @Input() expression= [];
    @Input() questionId= '';
    @Input() Values= {};
    @Input() listValues: any;
    @Input() listIndex: any;
    @Output() calculatedResult: EventEmitter<any> = new EventEmitter();
    calculatedMath = undefined;
    constructor() { }

    ngOnChanges (changes:SimpleChanges) {
          if(changes){
              let expressionField = this.expression['fields'];
              const decimalPoint = this.expression && this.expression.hasOwnProperty('field_data') && this.expression['field_data'].hasOwnProperty('decimalPoint')
                    && this.expression['field_data'].decimalPoint !== ''
                  ? Math.min(parseInt(this.expression['field_data'].decimalPoint), 10) : 4;

              const mathExpression = expressionField.map((item) => {
                  const answer = this.listValues && this.listValues.hasOwnProperty(item+this.listIndex) ? this.listValues[item+this.listIndex] :
                      this.Values.hasOwnProperty(item) ? this.Values[item] :
                      this.Values.hasOwnProperty(item+this.listIndex) ? this.Values[item+this.listIndex] : item;
                  return answer == null || answer+'' == '' ? 'undefined' : answer;
              }).join('');

              try {
                  const mathResult = this.convertExponentialDigit(eval(mathExpression));

                  this.calculatedMath = isNaN(mathResult) ? undefined : decimalPoint !== 0 &&
                                        mathResult % 1 !== 0 ? mathResult.toFixed(decimalPoint) : mathResult;
              } catch (error) {
                  this.calculatedMath = undefined;
              }

              if(!(this.Values[this.questionId] === this.calculatedMath || this.listValues && this.listValues[this.questionId] === this.calculatedMath))
                this.calculatedResult.emit({[this.questionId]: this.calculatedMath});
          }
    }

    convertExponentialDigit (inputNumber) {
        let sign = +inputNumber < 0 ? "-" : "", inputString = inputNumber.toString();

        if (!/e/i.test(inputString)) {
            return inputNumber; // if number doesn't contain exponential.
        }

        let [lead, decimal, power] = inputString.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);

        return +power < 0 ?
            sign + "0." + "0".repeat(Math.max(Math.abs(power) - 1 || 0, 0)) + lead + decimal :
            sign + lead + (+power >= decimal.length ? (decimal + "0".repeat(Math.max(+power - decimal.length || 0, 0))) :
                (decimal.slice(0, +power) + "." + decimal.slice(+power)));
    }
}
