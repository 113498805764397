// Angular7 routes regex list
export const UPGRADED_ROUTES = [
    '^/reporting_v2',
    '^/reporting_v2/([a-z]+)/([a-z]+)',
    '^/system_settings/contract-templates',
    '^/system_settings/contract_templates',
    '^/system_settings/sla_template',
    '^/system_settings/ppm',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/contracts',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/customer_contracts',
    '^/system_settings/ppm-jobs-setup',
    '^/system_settings/contract-categories',
    '^/system_settings/sla_email_template',
    '^/notifications',
    '^/system_settings/custom-forms',
    '^/system_settings/parts_and_prices',
    '^/system_settings/invoice_message',
    '^/system_settings/customise_invoice_names',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/[0-9]+/invoices/new',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/[0-9]+/invoices/[0-9]+/edit',
    '^/system_settings/terms_and_conditions_contract',
    '^/customers/(customer|invoice_address)/[0-9]+/multiple_invoice',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/opportunities/[0-9]+/invoices/[0-9]+/edit',
    '^/invoice_address/[0-9]+/multiple_invoice',
    '^/system_settings/labour_rates',
    '^/system_settings/price_books',
    '^/system_settings/job-descriptions',
    '^/system_settings/advanced_booking_form',
    '^/company_settings/license_contract',
    '^/system_settings/leave-job-questions',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/news',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/[0-9]+/news',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/[0-9]+/recall/new',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/asset/[0-9]+/new',
    '^/company_settings/user/[0-9]+/voip_settings',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs/views/[0-9]+/edits',
    '^/system_settings/contract-renewal-setup',
    '^/system_settings/pricing_items',
    '^/company_settings/uk_industry_specific_number',
    '^/system_settings/scheduling-options',
    '^/system_settings/stock_locations',
    '^/system_settings/arrive-questions',
    '^/company_settings/import_emails',
    '^/system_settings/customer_portal_email_message',
    '^/system_settings/nominal-accounts',
    '^/system_settings/scheduling-options',
    '^/system_settings/stock_locations',
    '^/system_settings/stock_inventory_system',
    '^/company_settings/timesheet_setup',
    // '^/system_settings/nominal_accounts',
    '^/system_settings/invoicecategories',
    '^/company_settings/regional_settings',
    '^/company_settings/integrations',
    '^/system_settings/general-custom-templates',
    '^/system_settings/general-custom-templates-config',
    '^/system_settings/customer_view_template',
    '^/system_settings/bolt_on_templates',
    '^/customers/(new_customer|new_company)/[0-9]+/new',
    '^/customers/(customer|company|work_address|customer_list)/[0-9]+/property/edits',
    '^/customers/(customer|company|customer_list|work_address)/[0-9]+/workaddress/new',
    '^/customers/(customer|Company|work_address|customer_list)/[0-9]+/property/edits',
    '^/customers/(customer|company|customer_list|work_address)/[0-9]+/invoice_address_v2/new',
    '^/invoice_address/[0-9]+/details/edit_new',
    //'^/system_settings/email_templates',
    '^/scheduled_activities',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs_v2/',
    '^/system_settings/engineer_portal_settings',
    '^/system_settings/after_sales_portal_settings',
    '^/system_settings/statement_portal_settings',
    '^/system_settings/invoice_portal_settings',
    '^/system_settings/payment_portal_settings',
    '^/system_settings/job_booking_portal_settings',
    '^/system_settings/service_reminder_portal_settings',
    '^/system_settings/rebooking_portal_settings',
    '^/system_settings/service_scheduling_rules_settings',
    '^/system_settings/contracts_ppm',
    '^/system_settings/contracts_category',
    '^/system_settings/set_up_pipelines',
    '^/system_settings/opportunity_template',
    '^/system_settings/proposal_templates',
    '^/system_settings/fleet_management',
    '^/system_settings/option_template',
    '^/system_settings/reporting_categories',
    '^/customers/customer/[0-9]+/opportunity',
    '^/system_settings/email_templates',
    '^/customers/customer_list/[0-9]+/jobs/[0-9]+/opportunity/new',
    '^/system_settings/sms_templates',
    '^/system_settings/opportunity_reject_reasons',
    '^/system_settings/opportunity_cancel_reasons',
    '^/system_settings/acceptance_template',
    '^/system_settings/finance_templates',
    '^/system_settings/grant_templates',
    '^/opportunities',
    '^/system_settings/finance_communication',
    '^/system_settings/branding_colors',
    '^/system_settings/proposal_branding',
    '^/system_settings/opportunity_engineer_message',
    '^/system_settings/opportunity_arrive_questions',
    '^/system_settings/opportunity_leave_questions',
    '^/system_settings/firstopportunitynumber',
    '^/settings',
    '^/system_settings/general-custom-templates',
    '^/system_settings/general-custom-templates-config',
    '^/settings',
    '^/system_settings/workflow_marketplace',
    '^/system_settings/workflow_setup',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/view_v2',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/customer_view/config',
    '^/settings',
    '^/customers/(customer|customer_list|work_address)/[0-9]+/jobs_v2/',
    '^/system_settings/workflow_setup_sequence',
    '^/invoice_address/[0-9]+/details/edit_new',
    '^/system_settings/customer_vat',
    '^/system_settings/letter_templates',
    '^/jobs',
    '^/system_settings/saved_colors',
    '^/system_settings/addon_template',
    '^/system_settings/loan_application_submitted',
    '^/system_settings/loan_application_accepted',
    '^/system_settings/loan_application_rejected',
    '^/system_settings/supplier_quotation_email_template',
    '^/system_settings/job-views',
    '^/system_settings/content_library',
    '^/system_settings/job_report_customization',
    '^/system_settings/opportunity-views',
    '^/system_settings/job-teams',
    '^/suppliers/listAutomatedInvoices',
    '^/system_settings/jobs_pipelines',
    '^/company_settings/setup_email',
    '^/system_settings/vehicle_status',
    '^/system_settings/vehicle_makes',
    '^/system_settings/vehicle_reminder',
    '^/system_settings/vehicle_types',
    '^/system_settings/vehicle_models',
    '^/fleet_management',
    '^/system_settings/vehicle_maintenance',
    '^/fleet_management/vehicle_list',
    '^/system_settings/vehicle_check_forms',
    '^/system_settings/vehicle_check_template',
    '^/system_settings/fleet/geo-fences',
    '^/fleet_management/live_map',
    '^/fleet_management/maintenance',
    '^/fleet_management/reminder',
    '^/fleet_management/trips',
    '^/system_settings/vehicle_out_of_hours',
    '^/system_settings/departure_notification',
    '^/system_settings/late_arrival_notification',
    '^/system_settings/proposal_portal',
    '^/system_settings/custom_forms_categories',
    '^/settings/custom_forms',
    '^/system_settings/job_stages',
];
