import { NgModule } from '@angular/core';
import { AppCommonModule } from "@app/shared";
import { DndModule } from 'ngx-drag-drop';

import { DynamicCertificateFormComponent } from './dynamic-certificate-form.component';
import { DynamicFormResizeDirective } from './directives/dynamic-form-resize.directive';
import { DynamicSectionComponent } from './dynamic-section/dynamic-section.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicPageSectionFieldAddEditComponent } from './dynamic-page-section-field-add-edit/dynamic-page-section-field-add-edit.component';
import { DynamicPageSectionFieldDeleteComponent } from './dynamic-page-section-field-delete/dynamic-page-section-field-delete.component';
import { DynamicFieldOptionsComponent } from './dynamic-field/dynamic-field-options/dynamic-field-options.component';
import { DynamicFieldOptionValuesComponent } from './dynamic-field/dynamic-field-option-values/dynamic-field-option-values.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives";
import {PipesModule} from "@app/pipes";
import {TooltipModule} from "ngx-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";
import {
    DynamicCertificateFormParserModule
} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser.module";

@NgModule({
    declarations: [
        DynamicCertificateFormComponent,
        DynamicFormResizeDirective,
        DynamicSectionComponent,
        DynamicFieldComponent,
        DynamicPageSectionFieldAddEditComponent,
        DynamicPageSectionFieldDeleteComponent,
        DynamicFieldOptionsComponent,
        DynamicFieldOptionValuesComponent
    ],
    exports:[
        DynamicCertificateFormComponent,
        DynamicFormResizeDirective,
        DynamicSectionComponent,
        DynamicFieldComponent,
        DynamicPageSectionFieldAddEditComponent,
        DynamicPageSectionFieldDeleteComponent,
        DynamicFieldOptionsComponent,
        DynamicFieldOptionValuesComponent
    ],
    imports: [
        // AppCommonModule,
        DndModule,
        FormsModule,
        DirectivesModule,
        PipesModule,
        TooltipModule,
        TranslateModule,
        ReactiveFormsModule,
        CsComponentsModule,
        DynamicCertificateFormParserModule,
    ],
})
export class DynamicCertificateFormModule { }
