import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import { TimelineDatePipe } from "@app/pipes/timeline-date.pipe";
var PreviewCardService = /** @class */ (function (_super) {
    tslib_1.__extends(PreviewCardService, _super);
    function PreviewCardService(http, timelineDatePipe) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.timelineDatePipe = timelineDatePipe;
        return _this;
    }
    PreviewCardService.prototype.formatInfo = function (cardValue, config, index, type) {
        var cardConfiguration = {};
        cardConfiguration =
            {
                "type": type,
                "label": this.getLabelProperties(cardValue, config),
                "value": this.getValueProperties(cardValue, config),
                "fieldId": config.hasOwnProperty('fieldId') ? config.fieldId : ''
            };
        if (index == 0) {
            cardConfiguration.children = [{
                    "type": "image_list",
                    "label": {},
                    "value": this.validateKeyExist(cardValue, 'jobTeamUsers')
                },
            ];
        }
        return cardConfiguration;
    };
    PreviewCardService.prototype.validateKeyExist = function (obj, key) {
        if (obj && obj.hasOwnProperty(key)) {
            return obj[key];
        }
        return '';
    };
    PreviewCardService.prototype.getLabelProperties = function (cardValue, config) {
        if (config.hasOwnProperty('showLabel') && config.showLabel) {
            return {
                text: config.fieldLabel,
                bold: false
            };
        }
        return {};
    };
    PreviewCardService.prototype.getValueProperties = function (cardValue, config) {
        if (config && config.hasOwnProperty('fieldId') && config.fieldId && cardValue) {
            if (config.fieldType == 'date' && cardValue.hasOwnProperty("" + config['fieldId']) && cardValue[config['fieldId']]) {
                if (typeof cardValue[config['fieldId']] == 'object' && cardValue[config['fieldId']].date) {
                    cardValue[config['fieldId']] = this.timelineDatePipe.transform(cardValue[config['fieldId']].date, 'datetime');
                }
                else if (typeof cardValue[config['fieldId']] == 'string') {
                    cardValue[config['fieldId']] = this.timelineDatePipe.transform(cardValue[config['fieldId']], 'datetime');
                }
            }
            else if (config.fieldType == 'time' && cardValue.hasOwnProperty("" + config['fieldId']) && cardValue[config['fieldId']] && cardValue[config['fieldId']]) {
                cardValue[config['fieldId']] = this.formatTime(cardValue[config['fieldId']]);
            }
            return {
                text: cardValue.hasOwnProperty("" + config['fieldId']) ? cardValue[config['fieldId']] : '',
                bold: config['fieldId'] == 'jobs_description',
                background: config['fieldId'] == 'jobs_pipelineStage' && cardValue.hasOwnProperty('jobs_pipelineStage') && cardValue.jobs_pipelineStage && cardValue.hasOwnProperty('stageColour') && cardValue.stageColour ? cardValue.stageColour : ''
            };
        }
        return {};
    };
    PreviewCardService.prototype.formatPreviewCardInfo = function (config, data) {
        var _this = this;
        var cardValue;
        var cardInfo = [];
        (config || []).forEach(function (val, key) {
            var type = val.fieldId == 'jobs_pipelineStage' ? 'text' : 'text';
            // let type = 'text';
            cardInfo.push(_this.formatInfo(data, val, key, type));
        });
        cardValue = [{
                lineItemId: data.hasOwnProperty('jobs_number') ? data['jobs_number'] : '',
                someoneViewing: false,
                isNewlyAdded: false,
                isDeleted: false,
                data: [cardInfo],
            }];
        return cardValue;
    };
    PreviewCardService.prototype.formatTime = function (seconds) {
        var symbol = seconds >= 0 ? '' : '-';
        seconds = Math.abs(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var days = d != 0 ? symbol + d + (d == 1 ? " day " : " days ") : "";
        var hours = h != 0 ? symbol + h + (h == 1 ? " hour " : " hours ") : "";
        var minutes = m != 0 ? symbol + m + (m == 1 ? " minute " : " minutes ") : "";
        var data = days + hours + minutes;
        return data.trim();
    };
    return PreviewCardService;
}(InterfaceService));
export { PreviewCardService };
