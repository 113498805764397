import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationBlockModel} from "@app/workflow-common/services/models/NotificationBlockModel";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {WorkflowCommonService} from "@app/workflow-common/services/workflow.common.service";
import {QuillEditorComponent} from "ngx-quill";
import "quill-mention";

@Component({
  selector: 'app-notification-block-widget',
  templateUrl: './notification-block-widget.component.html',
  styles: []
})
export class NotificationBlockWidgetComponent implements OnInit {

    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();
    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;

    form: FormGroup;
    notificationBlockData:{} = {};
    isLoading: boolean = true;
    isFormSubmitted:boolean = false;
    userData: any[] = [];
    hashTagsData: any[] = [];
    userRolesData:any[];
    select2RolesInputConfig: {};
    editorConfig: {};
    csTaggingList:any[]=[];
    assignedUser: string;
    showDeleteBtn: boolean = false;
    disableSaveBtn: boolean;

    inputModel: NotificationBlockModel = undefined;

    constructor(
        private formBuilder: FormBuilder,
        private workflowService: WorkflowhttpService,
        private commonService: WorkflowCommonService,
    ) { }

    async ngOnInit() {
        this.notificationBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
        const blockObject = {...this.blockObject};
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if(modelData) {
            this.showDeleteBtn=true;
        }
        this.inputModel = new NotificationBlockModel(modelData);

        this.userRolesData = CommonDataSource.userRoles;
        await this.getUser();
        await this.getHashTags();
        this.isLoading = false

        this.setSelect2RolesInputConfig();
        this.setEditorConfig();

        this.csTaggingList = this.commonService.getCsTaggingList(this.dagSourceModel.mainObjectName, CommonDataSource.allTagsList, this.workflowType);

        this.form = this.formBuilder.group({
            roles: new FormControl(this.inputModel.roles, [Validators.required]),
            message: new FormControl(this.inputModel.message, [Validators.required]),
        });


    }

    onChangeEditor(event) {
        this.disableSaveBtn = event.content.ops.length <= 1 && event.text.replace('\n', '').trim().length == 0;
        if(!event.html) {
            this.inputModel.messageDelta = '';
            this.assignedUser = '';
            this.inputModel.message = '';
            return;
        }
        const delta = event.content;
        //this.inputModel.messageDelta = JSON.stringify(delta);
        //this.inputModel.plainContent = this.inputModel.message.replace(/(<([^>]+)>)/gi, "");
    }

    getUser() {
        return new Promise((resolve) => {
            this.workflowService.getUsersList()
            .subscribe(resp => {
                this.userData = resp['users'].map((item) => {
                    return {id: item.id, value: item.username, username: item.username}
                });
                resolve();
            });
        });
    }

    getHashTags() {
        return new Promise((resolve) => {
            this.workflowService.getHashTagsList()
            .subscribe(resp => {
                this.hashTagsData = resp['hashtags'].map((item) => {
                    return {id: item.id, value: item.description}
                });
                resolve();
            });
        });
    }

    setSelect2RolesInputConfig() {
        const select2DisplayFormat = (item: any) => {
            return item.text;
        };
        this.select2RolesInputConfig = {
            width: '100%',
            data: this.userRolesData,
            tags: true,
            formatSelection: select2DisplayFormat,
            formatResult: select2DisplayFormat,
            multiple: true
        };
    }

    setEditorConfig() {
        let mentionDenotationChars=[];
        this.editorConfig = CommonDataSource.editorCommonConfig(
            (item: DOMStringMap, insertItem) => {
                if(item.denotationChar === '[') {
                    item['value'] = item['value']+']';
                }
                insertItem(item)
                if(item.denotationChar === '@') {
                    this.assignedUser = item.id;
                }
            },
            (searchTerm, renderList, mentionChar) => {
                let values = [];
                if(mentionChar === '#') {
                    values = this.hashTagsData;
                }
                if(mentionChar === '[') {
                    values = this.csTaggingList;
                }
                else if(mentionChar === '@') {
                    values = this.assignedUser ? [] : this.userData;
                }

                if (searchTerm.length === 0) {
                    renderList(values, searchTerm)
                }
                else {
                    const matches = [];
                    for (let i = 0; i < values.length; i++)
                        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                            matches.push(values[i]);
                    renderList(matches, searchTerm);
                }
            },
            ["["],
            false
        );
    }

    selectedRoles(event) {
        this.form.get('roles').setValue(event);
    }

    setAssignedTo() {
        if(!this.assignedUser) {
            return;
        }
        const selectedUser = this.userData.find( user => user.id == this.assignedUser );
        if(selectedUser) {
            this.inputModel.assignToDisplay = selectedUser['username'];
            this.inputModel.assignTo = this.assignedUser;
        }
    }

    setDisplayRoles() {
        if(!this.inputModel.roles) {
            return;
        }
        const selectedRoles = this.userRolesData.filter( role => this.inputModel.roles.includes( ""+role['id']) );
        let displayText:any[] = [];
        if(selectedRoles.length) {
            selectedRoles.forEach( (item) => {
                displayText.push(item['text']);
            });
        }
        this.inputModel.rolesDisplay=displayText;
    }

    save() {
        const {roles, message} = this.form.value;
        this.isFormSubmitted = true;
        this.inputModel.roles = roles;
        this.inputModel.message = message;
        this.inputModel.textMessage = this.inputModel.message.replace(/(<([^>]+)>)/gi, "");

        this.setAssignedTo();
        this.setDisplayRoles();

        const blockObject = {...this.blockObject};
        blockObject['model'] = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    }

    closeModal() {
        this.close.emit({
            action: 'close',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }

}
