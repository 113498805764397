import { Component, Inject, Input, NgZone, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CsToastBoxService } from '@app/services';
import { CsTaggingService } from '@app/directives/material-directives/cs-tagging/cs-tagging.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { FolderService } from '@app/shared/common-folder-structure/service/folder.service';
import { TranslateService } from '@ngx-translate/core';
import {AuthService} from "@app/core";
import {
    OpportunityTemplateService
} from "@app/features/system-settings/opportunity-template/services/opportunity-template.services";
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";


@Component({
    selector: 'app-template-modal',
    templateUrl: './template-modal.component.html',
    styleUrls: ['./template-modal.component.scss']
})
export class TemplateModalComponent implements OnInit, AfterViewInit  {
    templateForm: FormGroup;
    page: string = 'add';
    templateData: any = {};
    action: string = 'save';
    showSidepanelTagging: boolean = false;
    taggingNotificationName: string = 'Use tagging';
    editor: any;
    uniqueName: boolean = false;
    tagsList: any[] = [];
    customTagsList: any[] = [];
    title: string = "";
    folderId: any = null;
    templateId: any = null;
    onClose: Subject<any>;
    screenType: string = 'email_template';
    @Input() templateName: string = "-- Select folder --";
    isFolderSelectable: boolean = true;
    treeData: any = [];
    type = 'folder';
    showSubject:boolean = false;
    folderParentIds:Array<any> = [];
    @Input() helpText: string = 'Create a new template here and fill in all fields with valid information. Use tagging when necessary.';
    @Input() editFormData: any=false;
    templatesName:any;
    enableSave: boolean = false;
    contentPage: boolean = false;
    coverPage: boolean = false;
    pageType: any;
    layout: any = '';
    freemiumAccess: boolean = false;
    jobDescriptionAccess: boolean = false;
    opportunityFormData = {
        id: '',
        templateName: '',
        jobDescription: '',
        expiry: '',
        proposal: '',
        pipeline: '',
        expiryOption: '0'
    }
    expiryDays: any = [{'value':'','checked':false,'showError':false},{'value':'','checked':false,'showError':false},{'value':'','checked':false,'showError':false}];
    saveDisabled : boolean = false;
    loading: boolean = true;
    expiryDayDisable:boolean=true;
    jobDescriptionList: any[] = [];
    proposalList: any[] = [];
    showexpiryInput: boolean = true;
    pipelineList: any[] = [];
    dynamicDayValue: any = "N";
    proposal: any;
    addon: boolean = false;
    isFreemium: boolean = false;
    customerOptionName: any = '';
    selectedTemplateId: any;
    disabled: boolean = false;
    pipelineReadAccess: boolean = false;
    constructor(private fb: FormBuilder,
                private templateService: FolderService,
                private router: Router,
                private route: ActivatedRoute,
                private toastBox: CsToastBoxService,
                private tagService: CsTaggingService,
                public bsModalRef: BsModalRef,
                public ctModalRef: BsModalRef,
                private translate: TranslateService,
                private opportunityTemplateService: OpportunityTemplateService,
                @Inject('$scope') private ajsScope,
                @Inject('confirmationBoxHelper') private confirmationBoxHelper,
                private zone: NgZone,
                private authService: AuthService,
                private sidePanelModalService: SidepanelModalService,) {
        if (this.route.snapshot.params.id) {
            this.page = 'edit';
        } else {
            this.page = 'add';
        }

        this.jobDescriptionAccess = this.authService.permitted(['Setupjobdescriptions.writeaccess']) === true;
        this.pipelineReadAccess = this.authService.permitted(['SetupPipelines.readaccess']) === true;
        // this.templateService.getUserId().subscribe(value => {
        //     this.isFreemium = value['salesClient']
        // });
    }

    ngOnInit() {
        let proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if(!proposalEditor){
            this.freemiumAccess = true
            this.isFreemium = true
        } else {
            this.freemiumAccess = false
            this.isFreemium = false
        }
        this.buildTemplateForm();
        this.onClose = new Subject();
        if (this.page == 'edit') {
            this.updateForm(this.templateData);
        }
        else if(this.editFormData){
            this.page = 'edit'
            this.updateForm(this.editFormData);
            this.templateId = this.editFormData.templateId;
        }
        this.tagService.getTags().then(
            (tags) => {
                this.tagsList = tags;
            }
        )
        this.templateForm.controls['subject'].valueChanges.subscribe(value => {
            if(!$(value).text()){
                this.templateForm.controls['subject'].setErrors({ customError: true });
            }
        });
        this.templateForm.controls['message'].valueChanges.subscribe(value => {
            if(!$(value).text()){
                this.templateForm.controls['message'].setErrors({ customError: true });
            }
        });
        if(this.pageType && this.pageType == "EDITOR_CONTENT_PAGE"){
            this.contentPage = true;
        } else if (this.pageType && this.pageType == "EDITOR_HEADER_PAGE") {
            this.coverPage = true;
        }
        if (this.screenType == 'content_library') {
            if (this.page == 'edit') {
                this.helpText = 'edit.content.library.helptext';
            } else {
                this.helpText = 'add.content.library.helptext';
            }
        }
        if (this.screenType == 'email_template') {
            if (this.page == 'edit') {
                this.helpText = 'edit.email.template.helptext';
            } else {
                this.helpText = 'add.email.template.helptext';
            }
        }
        if (this.templateId && this.screenType == 'opportunity_template') {
            this.opportunityTemplateService.opportunityTemplateLinkedOpportunity(this.templateId).subscribe(
                resp => {
                    if (resp['message'] == 'success') {
                        this.templateForm.controls['pipeline'].disable();
                        this.templateForm.controls['proposal'].disable();
                        this.disabled = true;
                    }
                });
        }
        if(this.expiryDays[1].value != '' && this.expiryDays[1].value != null){
            this.expiryDayDisable = false;
        }
    }

    ngAfterViewInit() {

    }

    // update form
    updateForm(data) {
        this.templateForm.patchValue({
            id: data['id'],
            templateName: data['templateName'],
            subject: data['subject'] ? data['subject'] : '',
            message: data['message'],
            customerOptionName: this.customerOptionName ? this.customerOptionName : data['templateName'],
            jobDescription: data['jobDescription'] ? data['jobDescription'] : '',
            proposal: data['proposal'] ? data['proposal'] : '',
            pipeline: data['pipeline'] ? data['pipeline'] : ''
        });
        this.templateForm.updateValueAndValidity();
        this.customTagsList = this.templateData && this.templateData.customTags && this.templateData.customTags.map((tag) =>  {
            let tagData: any = {
                id: tag.name,
                value: tag.name,
                denotationChar: '{'
            };
            if(tag.options && tag.options.length) {
                tagData['options'] = tag.options;
            }
            return tagData;
        }) || [];
    }
    //Form Build
    buildTemplateForm() {
        this.templateForm = this.fb.group({
            'subject':[''] ,
            'templateName': ['', Validators.required],
            'message': [''],
            'customerOptionName': [''],
            'jobDescription': [''],
            'proposal': [''],
            'pipeline': ['']
        });
        if(this.screenType == 'email_template'){
            this.showSubject = true;
            this.templateForm.get('subject').setValidators([
                Validators.required,
            ]);
        }
        if(this.screenType == 'email_template' || this.screenType == 'sms_template' || this.screenType == 'letter_template' || this.screenType == 'call_note_template') {
            this.templateForm.get('message').setValidators([
                Validators.required,
            ]);
        }
        if(this.screenType == 'opportunity_template') {
            if(this.jobDescriptionAccess){
                this.templateForm.get('jobDescription').setValidators([
                    Validators.required,
                ]);
            }
            this.templateForm.get('proposal').setValidators([
                Validators.required,
            ]);
            this.templateForm.get('pipeline').setValidators([
                Validators.required,
            ]);
            if(!this.pipelineReadAccess){
                this.templateForm.controls['pipeline'].setValue(this.pipelineList[0]['id']);
            }
        }
    }

    stripTags(content: string) {
        const regex = /<\/?span[^>]*>/g;
        return content.replace(regex, '');
    }

    replaceQuotes(replaceString) {
        if(replaceString == '') {
            return '';
        }
        const find    = ["&quot;Trebuchet MS&quot;", "&quot;Times New Roman&quot;", "&quot;Courier New&quot;"];
        const replace = ['Trebuchet MS','Times New Roman','Courier New'];
        let regex;
        for (let i = 0; i < find.length; i++) {
            regex = new RegExp(find[i], "g");
            replaceString = replaceString.replace(regex, replace[i]);
        }
        return replaceString;
    }

    // Save Data
    saveTemplate() {
        if(this.templateForm.valid && this.screenType != 'content_library' && this.screenType != 'option_template' && this.screenType != 'proposal_template' && this.screenType != 'opportunity_template' && this.screenType != 'call_note_template'){
            this.action = 'saving';
            let value = {};
            value['message'] = this.replaceQuotes(this.templateForm.controls['message'].value);
            value['subject'] = this.stripTags(this.templateForm.controls['subject'].value);
            value['templateName'] = this.templateForm.controls['templateName'].value;
            value['folderId'] = this.folderId
            value['templateId'] = this.templateId
            value['screen'] = this.screenType;
            if (this.templateData && this.templateData.id) {
                value['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value).subscribe(data => {
                this.templateData.id = data['templateId'];
                this.action = 'save';
                if (this.page == 'edit') {
                    this.toastBox.show('Updated');
                }
                else {
                    this.toastBox.show('Saved');
                }
                value['templateId'] = data['templateId'];
                value['parentIds'] = this.folderParentIds;
                this.onClose.next(value)
                this.bsModalRef.hide();
            }, error => {
                console.log(error)
            });
        } else if (this.screenType == 'content_library'){
            this.action = 'saving';
            let value = {};
            value['templateName'] = this.templateForm.controls['templateName'].value;
            value['folderId'] = this.folderId;
            value['templateId'] = this.templateId;
            value['screen'] = this.screenType;
            value['pageType'] = 'contentPage'
            // if(this.coverPage){
            //     value['pageType'] = 'coverPage'
            // } else if(this.contentPage) {
            // }
            if (this.templateData && this.templateData.id) {
                value['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value).subscribe(data => {
                this.templateData.id = data['templateId'];
                this.action = 'save';
                if (this.page == 'edit') {
                    this.toastBox.show('Updated');
                } else {
                    this.toastBox.show('Saved');
                }
                value['templateId'] = data['templateId'];
                value['parentIds'] = this.folderParentIds;
                this.onClose.next(value)
                this.bsModalRef.hide();
            }, error => {
                console.log(error)
            });
        } else if (this.screenType == 'option_template'){
            this.action = 'saving';
            let setupOption = {
                'customerOptionName': this.templateForm.controls['customerOptionName'].value,
                'optionName': this.templateForm.controls['templateName'].value,
                'id': this.templateId,
                'addOn': this.addon
            }
            let value = {};
            value['templateName'] = this.templateForm.controls['templateName'].value;
            value['folderId'] = this.folderId;
            value['templateId'] = this.templateId;
            value['screen'] = this.screenType;
            value['customerOptionName'] = this.templateForm.controls['customerOptionName'].value;
            value['setupOption'] = setupOption;
            value['isFreemium'] = this.isFreemium;
            value['layout'] = this.layout ? this.layout : '';
            value['addon'] = this.addon;
            if (this.templateData && this.templateData.id) {
                value['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value).subscribe(data => {
                this.templateData.id = data['templateId'];
                this.action = 'save';
                if (this.page == 'edit') {
                    this.toastBox.show('Updated');
                } else {
                    this.toastBox.show('Saved');
                }
                value['templateId'] = data['templateId'];
                value['parentIds'] = this.folderParentIds;
                this.onClose.next(value);
                this.bsModalRef.hide();
            }, error => {
                console.log(error);
            });
        } else if (this.screenType == 'proposal_template'){
            this.action = 'saving';
            let value = {};
            value['templateName'] = this.templateForm.controls['templateName'].value;
            value['folderId'] = this.folderId;
            value['templateId'] = this.templateId;
            value['screen'] = this.screenType;
            value['freebee'] = this.freemiumAccess;
            if (this.templateData && this.templateData.id) {
                value['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value).subscribe(data => {
                this.templateData.id = data['templateId'];
                this.action = 'save';
                if (this.page == 'edit') {
                    this.toastBox.show('Updated');
                } else {
                    this.toastBox.show('Saved');
                }
                value['templateId'] = data['templateId'];
                value['parentIds'] = this.folderParentIds;
                this.onClose.next(value);
                this.bsModalRef.hide();
            }, error => {
                console.log(error);
            });
        } else if (this.screenType == 'opportunity_template'){
            this.action = 'saving';
            let value = {};
            value['templateName'] = this.templateForm.controls['templateName'].value;
            value['folderId'] = this.folderId;
            value['templateId'] = this.templateId;
            value['screen'] = this.screenType;
            value['freebee'] = this.freemiumAccess;
            if (this.templateData && this.templateData.id) {
                value['id'] = this.templateData.id;
            }
            let opportunityForm = {
                "id": this.templateId,
                "templateName": this.templateForm.controls['templateName'].value,
                "jobDescription": this.templateForm.controls['jobDescription'].value,
                "expiry": JSON.stringify(this.expiryDays),
                "expiryOption": "0",
                "proposal": this.templateForm.controls['proposal'].value,
                "pipeline" : this.templateForm.controls['pipeline'].value,
                "isFreemium": this.isFreemium
            }
            value['opportunityForm'] = opportunityForm;
            this.templateService.addEditEmailTemplate(value).subscribe(data => {
                this.templateData.id = data['templateId'];
                this.action = 'save';
                if (this.page == 'edit') {
                    this.toastBox.show('Updated');
                } else {
                    this.toastBox.show('Saved');
                }
                value['templateId'] = data['templateId'];
                value['parentIds'] = this.folderParentIds;
                this.onClose.next(value);
                this.bsModalRef.hide();
            }, error => {
                console.log(error);
            });
        } else if(this.screenType === 'call_note_template') {
            this.action = 'saving';
            let value = {};
            value['templateName'] = this.templateForm.controls['templateName'].value;
            value['folderId'] = this.folderId;
            value['message'] = this.templateForm.controls['message'].value;
            value['templateId'] = this.templateId;
            value['screen'] = this.screenType;
            const customTags = []
            if(this.customTagsList.length) {
                this.customTagsList.forEach(tag => {
                    customTags.push({
                        name: tag.value,
                        fieldType: tag.fieldType,
                        options: tag.options,
                        id: tag.id
                    });
                });
                value['customTags'] = JSON.stringify(customTags);
            }
            if (this.templateData && this.templateData.id) {
                value['id'] = this.templateData.id;
            }
            this.templateService.addEditEmailTemplate(value).subscribe(data => {
                this.templateData.id = data['templateId'];
                this.action = 'save';
                if (this.page == 'edit') {
                    this.toastBox.show('Updated');
                } else {
                    this.toastBox.show('Saved');
                }
                value['templateId'] = data['templateId'];
                value['parentIds'] = this.folderParentIds;
                this.onClose.next(value);
                this.bsModalRef.hide();
            }, error => {
                console.log(error);
            });
        }
    }
    get f() {
        return this.templateForm.controls;
    }

    selectedFolder(folderDetails) {
        if(folderDetails.hasOwnProperty('parentIds')){
            this.folderParentIds = folderDetails.parentIds;
        }
        this.folderId = folderDetails.id;
    }

    // open tags panel
    openSidePanelNotificationTagging() {
        this.showSidepanelTagging = !this.showSidepanelTagging;
        if (this.showSidepanelTagging) {
            this.taggingNotificationName = 'Close tagging';
        }
        else {
            this.taggingNotificationName = 'Use tagging';
        }
    }

    customTagCall(data) {
        this.openCustomTagModal(data);
    }

    openCustomTagModal(data) {
        /*this.ctModalRef = this.sidePanelModalService.show(
            CustomTagModalComponent,
            {
                initialState: data,
            }
        );
        this.ctModalRef.content.onClose.subscribe((result) => {
            data['fieldType'] = result['fieldType'];
            if(result['options']) {
                data['options'] = result['options']
            }
            data['new'] = true;
            const ctList = [...this.customTagsList];
            ctList.push(data);
            this.customTagsList = ctList;
        });*/
    }


    checkForm(){
        if ( this.screenType == 'content_library' || this.screenType == 'proposal_template' || this.screenType == 'opportunity_template') {
            if ( this.templateForm.controls['templateName'].value != '') {
                this.enableSave = true;
            } else {
                this.enableSave = false;
            }
        }
    }

    onlyOne(checkbox) {
        if(checkbox == 'content-page') {
            this.contentPage = true;
            this.coverPage = false;
        } else {
            this.contentPage = false;
            this.coverPage = true;
        }
        this.checkForm();
    }

    selectLayout(type,selectedClass){
        $(document).ready(function() {
            $('.layout').click(function() {
                $('.layout').removeClass('selected');
                $(this).addClass('selected');
            });
        });
        this.layout = type;
        this.checkForm();
    }

    checkValidDays() {
        this.expiryDays.forEach(function (val,key) {
            if(val.checked && !val.value){
                val.showError = true;
            }
        });
        if(this.expiryDays[0].showError || this.expiryDays[1].showError || this.expiryDays[2].showError ) {
            this.saveDisabled = true;
        }
    }

    checkboxChecked(index){
        this.expiryDays[index].checked = !this.expiryDays[index].checked;
        if(!this.expiryDays[index].checked){
            this.expiryDays[index].value = '';
            this.expiryDays[index].showError = false;
            if(index == 1){
                this.expiryDays[2].value = '';
                this.expiryDays[2].showError = false;
                this.expiryDays[2].checked = false;
                this.expiryDayDisable=true;
            }
            if(!this.expiryDays[0].showError && !this.expiryDays[1].showError && !this.expiryDays[2].showError ) {
                this.saveDisabled = false;
            }
        }
        this.expiryDayCheckBox();
        this.checkValidDays();
    }
    expiryDayCheckBox(){
        if(this.expiryDays[1].checked ==true && !this.expiryDays[1].showError &&  this.expiryDays[1].value != ''){
            this.expiryDayDisable=false;
        }else{
            this.expiryDayDisable=true;
            this.expiryDays[2].value = '';
            this.expiryDays[2].checked = false;
        }
    }

    validateNumber(e: any, index) {

        let value:any = parseFloat(e.target.value)
        if (value == 0 || isNaN(value)){
            this.expiryDays[index].invalid = true;
        }else{
            this.expiryDays[index].invalid = false;
        }
        if(!value  && this.expiryDays[index].checked == true || isNaN(value) || !$.isNumeric(e.target.value)){
            this.expiryDays[index].showError = true;
            this.saveDisabled = true;
            e.preventDefault();
            this.expiryDayCheckBox();
            return false;
        }
        else{
            this.expiryDays[index].showError = false;
            if(!this.expiryDays[0].showError && !this.expiryDays[1].showError && !this.expiryDays[2].showError ) {
                this.saveDisabled = false;
            }
            this.expiryDays[index].value = e.target.value.slice(0, 10);
            this.checkValidDays();
            this.expiryDayCheckBox();
            return true;
        }
    }


    checkUniqueName(value){
        if(this.screenType == 'content_library' || this.screenType == 'call_note_template'){
            let text = value
            let data = {
                title: text,
                screen: this.screenType,
            }
            let checkName = false;
            if (this.page == 'edit') {
                if (this.templateData.templateName == value) {
                    checkName = false;
                } else {
                    checkName = true;
                }
            } else {
                checkName = true;
            }
            if (checkName) {
                this.templateService.checkUniqueTemplateName(data).subscribe((resp: any) => {
                    this.uniqueName = resp
                })
            }
        } else if (this.screenType == 'option_template') {
            let params: any = {
                optionName: value,
                isAddonTemplate:this.addon
            };
            let checkName = false;
            if (this.page == 'edit') {
                params.selectedId = this.templateId;
                if (this.templateData.templateName === value.trim()) {
                    checkName = false;
                } else {
                    checkName = true;
                }
            } else {
                checkName = true;
            }
            if (checkName) {
                this.templateService.checkTemplateName(params).subscribe(resp => {
                    if (resp == true){
                        this.uniqueName = true;
                    }
                    else {
                        this.uniqueName = false;
                    }
                });
            } else {
                this.uniqueName = false;
            }
        } else if (this.screenType == 'proposal_template') {
            let params:any = {
                template: value
            };
            let checkName = false;
            if(this.page == 'edit') {
                params.selectedId = this.templateId;
                if(this.templateData.templateName === value.trim()){
                    checkName = false;
                } else {
                    checkName = true;
                }
            } else {
                checkName = true;
            }
            if (checkName) {
                this.templateService.checkProposalTemplateExists(params).subscribe(resp => {
                    if (resp == true) {
                        this.uniqueName = true;
                    }
                    else {
                        this.uniqueName = false;
                    }
                });
            } else {
                this.uniqueName = false;
            }
        } else if (this.screenType == 'opportunity_template') {
            let params: any = {
                template: value,
            };
            let checkName = false;
            if (this.page == 'edit') {
                params.selectedId = this.templateId;
                if (this.templateData.templateName === value.trim()) {
                    checkName = false;
                } else {
                    checkName = true;
                }
            } else {
                checkName = true;
            }
            if (checkName && value.trim()!='' ) {
                this.opportunityTemplateService.checkTemplateName(params).subscribe(resp => {
                    if (resp == true) {
                        this.uniqueName = true;
                    }
                    else {
                        this.uniqueName = false;
                    }
                })
            } else {
                this.uniqueName=false;
            }
        }
    }

    templateSelected(event) {
        if (event.isFolder === '0') {
            this.templateForm.controls['proposal'].setValue(event.templateId);
            this.selectedTemplateId = event.templateId;
        }
    }}
