import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";

@Injectable({
    providedIn: 'root',
})

export class SetupPipelineService extends InterfaceService {

    private $stageEmitted: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public stageEmitted: Observable<any> = this.$stageEmitted.asObservable();

    private $stageAfterTracking: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public stageAfterTracking: Observable<any> = this.$stageAfterTracking.asObservable();

    private $activeMenu: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public menu: Observable<any> = this.$activeMenu.asObservable();

    constructor(private http: HttpClient) {
        super();
    }


    // Add or Edit Pipeline
    addEditPipelines(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addOrEditPipelines`), data);
    }

    // Check Pipeline name exists
    checkUniqueName(params?: Object): Observable<any> {
        return this.http
            .get(this.getApiUrl(`checkSettingsPipelineExists`, params));
    }

    // Confirmation Modal for Delete
    beforeDelete(id?: any,screen?:any): Observable<any> {
        if(screen == 'job'){
            return this.http.get(this.getApiUrl(`pipelineDeleteCheck/${id}?screenType=${screen}`));
        }else{
            return this.http.get(this.getApiUrl(`pipelineDeleteCheck/${id}`));
        }
    }

    beforeStageDelete(id?: any,screen?:any): Observable<any> {
        if(screen == 'job'){
            return this.http.get(this.getApiUrl(`pipelineStageDeleteCheck/${id}?screenType=${screen}`));
        }else{
            return this.http.get(this.getApiUrl(`pipelineStageDeleteCheck/${id}`));
        }

    }

    // Delete Pipeline
    deletePipelineRequest(id, params?: any): Observable<any> {
        return this.http
            .delete(this.getApiUrl(`${id}/deleteSettingsPipeline`,params));
    }

    // Reorder Settings Pipeline List
    reOrderSettingsPipeline(data: object): Observable<any> {
        return this.http.put(this.getApiUrl(`reorderPipeline`), data);
    }

    // Setup Stage List
    getStageForPipeline(id ,type: any): Observable<any> {
        if(type == 'job'){
            return this.http.get(this.getApiUrl(`${id}/listSettingStages?screenType=${type}`));
        }else{
            return this.http.get(this.getApiUrl(`${id}/listSettingStages`));
        }
    }

    // Add or Edit Stages
    addEditStages(data: any, screen: string = ''): Observable<any> {
        if (screen == 'stage') {
            if (data && data.hasOwnProperty('id') && data.id) {
                return this.http.put(this.getJobApiUrl(`update_stage_views/${data.id}`), data);
            } else {
                return this.http.post(this.getJobApiUrl(`add_stage_views`), data);
            }
        } else {
            return this.http.post(this.getApiUrl(`addOrEditSettingStages`), data);
        }
    }

    // Delete Stages
    deleteStages(id, type?: any, pipelineId?: any): Observable<any> {

        if (type == 'stage') {
            return this.http.post(this.getJobApiUrl(`delete_stage_views`), {id});
        } else {
            let path = `${id}/deleteSettingStages?screenType=${type}`;
            if (pipelineId) path = path + `&pipelineId=${pipelineId}`;
            return this.http.delete(this.getApiUrl(path));
        }
    }

    // Trigger Stages Modal
    triggerStages(pipeline) {
        this.$stageEmitted.next(pipeline)
    }

    setAfterTrackingDetails(afterTracaking) {
        this.$stageAfterTracking.next(afterTracaking)
    }

    reorderList(data: object, type: any, pipelineId?: any): Observable<any> {
        let path = `commonSettingsReorder?type=${type}`;
        if(pipelineId) {
            path = path + `&pipelineId=${pipelineId}`
        }
        return this.http.put(this.getApiUrl(path), data);
    }

    setActiveMenu(menu){
        this.$activeMenu.next(menu)
    }
    getSideMenuStatus(id: number): Observable<any> {
        return this.http.get(this.getApiUrl(`getPipelineSideMenuStatus/${id}`));
    }

    getPipeline(id: number): Observable<any> {
        return this.http.get(this.getApiUrl(`getPipeline/${id}`));
    }

    getDropdownData() {
        return this.http.get(this.getApiUrl(`sales/getPipelineDropdownData`))
    }

    activatePipeline(id, data){
        return this.http.post(this.getApiUrl(`pipelines/${id}/activate`), data);
    }

    getPipelineCustomTemplates(action: string, oppTemplateId: number, tablePKID?: number, propertyID?: number, stageID?: number): Observable<any>{
        return this.http.get(this.getApiUrl(`getPipelineCustomFields?screenMode=addCustomTemplates&action=${action}&objects=Pipelines&objectTypes=Pipeline&objectTypeID=${oppTemplateId}&propertyID=${propertyID}&tablePKID=${tablePKID}&stageID=${stageID}`));
    }

    getStages(id: number): Observable<any> {
        return this.http.get(this.getApiUrl(`getStageCustomFields/${id}`));
    }

    addEditCustomFields(data: object): Observable<any> {
        return this.http.post(this.getApiUrl(`addOrEditCustomFields`), data);
    }
}
