import { ElementRef, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ViewVehicleService } from '@app/features/fleet-management/add-vehicle/services/view-vehicle-service';
import * as moment from 'moment';
import { FleetLiveMapSidepanelComponent } from "@app/features/fleet-management/fleet-live-map/fleet-live-map-sidepanel/fleet-live-map-sidepanel.component";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
var TripsComponent = /** @class */ (function () {
    function TripsComponent(router, httpService, renderer, el, modalService) {
        this.router = router;
        this.httpService = httpService;
        this.renderer = renderer;
        this.el = el;
        this.modalService = modalService;
        this.maxDate = new Date();
        this.trackingDetails = [];
        this.trackingRoute = [];
        this.tripRoutes = [];
        this.customSelectedDate = '';
        this.combinedData = [];
        this.tripsLayer = false;
        this.selectedLayer = false;
        this.selectedIndex = -1;
        this.mapZoomHappened = false;
        this.mapZoomHappenedByFit = false;
        this.viewMode = false;
        this.showRoute = false;
        this.loading = false;
        this.boundCollection = [];
        this.mapInitated = false;
        this.mapResizeDone = false;
        this.resetToBound = {};
        this.showDropdown = false;
        this.hoveredIndex = null;
        this.moveNext = true;
        this.movePrevious = true;
        this.startIcon = {
            icon: L.divIcon({
                className: 'customer-marker',
                html: " <div class=\"status-icon\">\n                      <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"28\" height=\"28\" viewBox=\"0 0 28 28\" fill=\"none\">\n                      <g filter=\"url(#filter0_d_1169_41171)\">\n                        <circle cx=\"16\" cy=\"12\" r=\"12\" fill=\"#408962\"/>\n                        <circle cx=\"16\" cy=\"12\" r=\"11\" stroke=\"white\" stroke-width=\"2\"/>\n                      </g>\n                      <defs>\n                        <filter id=\"filter0_d_1169_41171\" x=\"0\" y=\"0\" width=\"32\" height=\"32\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n                          <feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n                          <feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n                          <feOffset dy=\"4\"/>\n                          <feGaussianBlur stdDeviation=\"2\"/>\n                          <feComposite in2=\"hardAlpha\" operator=\"out\"/>\n                          <feColorMatrix type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0\"/>\n                          <feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_1169_41171\"/>\n                          <feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_1169_41171\" result=\"shape\"/>\n                        </filter>\n                      </defs>\n                    </svg>\n                      </div>",
                iconSize: [10, 12],
                iconAnchor: [15, 12]
            })
        };
        this.stopIcon = {
            icon: L.divIcon({
                className: 'customer-marker',
                html: "<div class=\"status-icon\">\n                <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"28\" height=\"28\" viewBox=\"0 0 28 28\" fill=\"none\">\n                <g filter=\"url(#filter0_d_1169_41165)\">\n                  <circle cx=\"16\" cy=\"12\" r=\"12\" fill=\"#BE6D6D\"/>\n                  <circle cx=\"16\" cy=\"12\" r=\"11\" stroke=\"white\" stroke-width=\"2\"/>\n                </g>\n                <defs>\n                  <filter id=\"filter0_d_1169_41165\" x=\"0\" y=\"0\" width=\"32\" height=\"32\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n                    <feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n                    <feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n                    <feOffset dy=\"4\"/>\n                    <feGaussianBlur stdDeviation=\"2\"/>\n                    <feComposite in2=\"hardAlpha\" operator=\"out\"/>\n                    <feColorMatrix type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0\"/>\n                    <feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_1169_41165\"/>\n                    <feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_1169_41165\" result=\"shape\"/>\n                  </filter>\n                </defs>\n              </svg>\n                    </div>",
                iconSize: [10, 12],
                iconAnchor: [15, 12]
            })
        };
        this.routingId = '';
        this.screen = '';
        this.defaultIconsList = [];
        this.vehicleId = this.screen == 'Dashboard_trips' ? this.routingId : this.router.url.split('/')[3];
        this.minDate = new Date();
        this.minDate.setDate(this.maxDate.getDate() - 90);
    }
    TripsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.httpService.getLiveMapData().subscribe(function (result) {
            _this.defaultIconsList = result['defaultIcons'];
        });
        this.httpService.getBranchLocation().subscribe(function (res) {
            _this.branchLocation = res['officeLocation'];
            _this.updateMapRadious();
        });
        this.todayDate = new Date().toDateString();
        if (this.screen != 'Dashboard_trips') {
            this.showRoute = true;
            this.selectedCustomDate = new Date();
            var data = {
                date: moment(this.selectedCustomDate).format('DD-MM-YYYY'),
                id: this.vehicleId
            };
            this.getMap(data);
        }
        else {
            this.startIcon = {
                icon: L.divIcon({
                    className: 'customer-marker',
                    html: "<div class=\"status-icon\">\n                          <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"27\" height=\"20\" viewBox=\"0 0 20 20\"\n                              fill=\"none\">\n                              <rect width=\"20\" height=\"20\" rx=\"10\" fill=\"#60A369\" />\n                              <path\n                                  d=\"M11.0203 4.1158C10.8805 3.78009 10.5508 3.56229 10.1871 3.5623C9.82337 3.56232 9.49364 3.78014 9.35375 4.11587L5.19689 14.0277C5.02902 14.4273 5.16888 14.8869 5.52657 15.1287C5.88426 15.3705 6.36386 15.3225 6.66961 15.0168L10.1868 11.4996L13.7037 15.0165C14.0094 15.3222 14.487 15.3682 14.8467 15.1284C15.2064 14.8886 15.3443 14.4269 15.1765 14.0273L11.0203 4.1158Z\"\n                                  fill=\"white\" />\n                          </svg>\n                        </div>",
                    iconSize: [10, 12],
                    iconAnchor: [15, 12]
                })
            };
            this.stopIcon = {
                icon: L.divIcon({
                    className: 'customer-marker',
                    html: " <div class=\"status-icon\">\n                              <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"28\" height=\"28\" viewBox=\"0 0 28 28\"\n                                  fill=\"none\">\n                                  <rect x=\"1\" y=\"1\" width=\"26\" height=\"26\" rx=\"13\" fill=\"white\" />\n                                  <rect x=\"1\" y=\"1\" width=\"26\" height=\"26\" rx=\"13\" stroke=\"white\" stroke-width=\"2\" />\n                                  <path\n                                      d=\"M14 24.5C16.7848 24.5 19.4555 23.3938 21.4246 21.4246C23.3938 19.4555 24.5 16.7848 24.5 14C24.5 11.2152 23.3938 8.54451 21.4246 6.57538C19.4555 4.60625 16.7848 3.5 14 3.5C11.2152 3.5 8.54451 4.60625 6.57538 6.57538C4.60625 8.54451 3.5 11.2152 3.5 14C3.5 16.7848 4.60625 19.4555 6.57538 21.4246C8.54451 23.3938 11.2152 24.5 14 24.5ZM11.375 10.0625H16.625C17.351 10.0625 17.9375 10.649 17.9375 11.375V16.625C17.9375 17.351 17.351 17.9375 16.625 17.9375H11.375C10.649 17.9375 10.0625 17.351 10.0625 16.625V11.375C10.0625 10.649 10.649 10.0625 11.375 10.0625Z\"\n                                      fill=\"#BE6D6D\" />\n                              </svg>\n                          </div>",
                    iconSize: [10, 12],
                    iconAnchor: [15, 12]
                })
            };
        }
    };
    TripsComponent.prototype.updateMapRadious = function () {
        if (this.branchLocation && this.branchLocation.length) {
            if (this.map) {
                this.map.setView([this.branchLocation[0], this.branchLocation[1]], 10);
            }
            else {
                var self_1 = this;
                setTimeout(function () {
                    self_1.map.setView([self_1.branchLocation[0], self_1.branchLocation[1]], 10);
                }, 1000);
            }
        }
    };
    TripsComponent.prototype.ngAfterContentInit = function () {
        var self = this;
        setTimeout(function () {
            self.setMapConfig(51.509865, -0.118092);
        }, 100);
    };
    TripsComponent.prototype.updateMapBounds = function () {
        if (this.map && !this.mapResizeDone) {
            this.map.invalidateSize();
            this.mapResizeDone = true;
        }
        if (this.boundCollection.length) {
            var bound_1 = L.latLngBounds(this.boundCollection);
            if (this.map) {
                this.map.fitBounds(bound_1, { padding: [10, 10] });
            }
            else {
                var self_2 = this;
                setTimeout(function () {
                    self_2.map.fitBounds(bound_1, { padding: [10, 10] });
                }, 1000);
            }
        }
    };
    TripsComponent.prototype.ngOnChanges = function (changes) {
        if (changes['routingId'] && this.routingId) {
            this.showRoute = true;
            this.vehicleId = this.routingId;
            this.trackingDetails = [];
            this.removeLayers();
            this.selectedCustomDate = new Date();
            this.moveNext = true;
            var data = {
                date: moment(this.selectedCustomDate).format('YYYY-MM-DD'),
                id: this.vehicleId
            };
            this.getMap(data);
        }
    };
    TripsComponent.prototype.updateTripRoutes = function () {
        var _this = this;
        var combinedData = [];
        this.boundCollection = [];
        this.removeLayers();
        if (this.trackingDetails && this.trackingDetails.length) {
            this.trackingDetails.forEach(function (tripData) {
                if (tripData['type'] == 'trip') {
                    var elementIndex = tripData['elementIndex'];
                    var startPositionId_1 = tripData['startPositionId'];
                    var endPositionId_1 = tripData['endPositionId'];
                    var startIndex = _this.trackingRoute.findIndex(function (route) { return route['id'] == startPositionId_1; });
                    var endIndex = _this.trackingRoute.findIndex(function (route) { return route['id'] == endPositionId_1; });
                    var tripCoordinates = _this.trackingRoute.slice(startIndex, endIndex).map(function (value) { return [value['latitude'], value['longitude']]; });
                    combinedData.push({ elementIndex: elementIndex, positions: tripCoordinates });
                    _this.boundCollection = _this.boundCollection.concat(tripCoordinates);
                }
            });
            this.combinedData = combinedData;
            this.updateMaps(-1);
        }
    };
    TripsComponent.prototype.removeLayers = function () {
        if (this.tripsLayer !== false) {
            this.tripsLayer.remove();
        }
        if (this.selectedLayer !== false) {
            this.selectedLayer.remove();
            this.selectedLayer = false;
        }
    };
    TripsComponent.prototype.updateMaps = function (elementIndex) {
        if (this.combinedData && this.combinedData.length) {
            this.tripsLayer = L.featureGroup();
            for (var i = 0; i < this.combinedData.length; i++) {
                var positions = this.combinedData[i]['positions'];
                if (positions && positions.length) {
                    var isSelectedRoute = this.combinedData[i]['elementIndex'] == elementIndex;
                    var routeColor = isSelectedRoute ? '#408962' : '#A2A2A2';
                    var polyline = L.polyline(this.combinedData[i]['positions'], { color: routeColor, dashArray: '5, 10', className: 'moving-dots', weight: 5, delay: 640 });
                    if (isSelectedRoute) {
                        this.selectedLayer = L.featureGroup();
                        var currentIndex = this.trackingDetails.findIndex(function (track) { return track['elementIndex'] == elementIndex; });
                        var tripData = this.trackingDetails[currentIndex];
                        this.iconStart = L.marker([tripData['startLat'], tripData['startLon']], this.startIcon).addTo(this.selectedLayer);
                        this.iconStop = L.marker([tripData['endLat'], tripData['endLon']], this.stopIcon).addTo(this.selectedLayer);
                        polyline.addTo(this.selectedLayer);
                        this.selectedLayer.addTo(this.map);
                    }
                    else {
                        polyline.addTo(this.tripsLayer);
                    }
                }
            }
            this.tripsLayer.addTo(this.map);
            if (this.selectedLayer !== false) {
                if (!this.mapZoomHappened) {
                    this.mapZoomHappenedByFit = true;
                    this.map.fitBounds(this.selectedLayer.getBounds());
                }
                this.selectedLayer.bringToFront();
            }
            else {
                if (!this.mapZoomHappened) {
                    this.mapZoomHappenedByFit = true;
                    this.map.fitBounds(this.tripsLayer.getBounds());
                }
            }
        }
    };
    TripsComponent.prototype.dateCreated = function ($event) {
        $('#caret-up').addClass('up-caret-icon');
        this.selectedCustomDate = $event.startDate;
        var currentDate = new Date(this.selectedCustomDate).toDateString();
        var restrictDate = new Date(this.minDate).toDateString();
        if (currentDate == restrictDate) {
            this.movePrevious = false;
        }
        else {
            this.movePrevious = true;
        }
        if (currentDate != this.todayDate) {
            this.moveNext = false;
        }
        else
            this.moveNext = true;
        var backendDate = moment(this.selectedCustomDate).format('YYYY-MM-DD');
        var data = {
            date: backendDate,
            id: this.vehicleId
        };
        this.mapZoomHappened = false;
        this.getMap(data);
    };
    TripsComponent.prototype.getMap = function (data) {
        var _this = this;
        this.loading = true;
        if (this.currentRoute)
            this.map.removeLayer(this.currentRoute);
        if (this.selectedRoute)
            this.map.removeLayer(this.selectedRoute);
        if (this.iconStart)
            this.map.removeLayer(this.iconStart);
        if (this.iconStop)
            this.map.removeLayer(this.iconStop);
        if (this.screen != 'Dashboard_trips') {
            this.httpService.getVehicleTripRoutes(data).subscribe(function (res) {
                _this.trackingRoute = res['trips'];
                _this.updateTripRoutes();
            });
            this.httpService.getTrackingDetails(data).subscribe(function (res) {
                _this.loading = false;
                _this.trackingDetails = res['details'];
                var restrictDate = res['tripDate'];
                _this.minDate = new Date(restrictDate['date']);
                var _a = res['summary']['activityTime'].split('-'), start = _a[0], end = _a[1];
                res['summary']['totalTime'] = _this.calculateTimeDifference(start, end);
                _this.summary = res['summary'];
            });
        }
        else {
            this.httpService.getVehicleTripRoutes(data).subscribe(function (res) {
                _this.trackingRoute = res['trips'];
                _this.updateTripRoutes();
            });
            this.httpService.getTrackingDetails(data).subscribe(function (res) {
                _this.loading = false;
                _this.trackingDetails = res['details'];
                var restrictDate = res['tripDate'];
                _this.minDate = new Date(restrictDate['date']);
                var _a = res['summary']['activityTime'].split('-'), start = _a[0], end = _a[1];
                res['summary']['totalTime'] = _this.calculateTimeDifference(start, end);
                _this.summary = res['summary'];
            });
        }
    };
    TripsComponent.prototype.calculateTimeDifference = function (startTime, endTime) {
        var start = moment(startTime, 'h:mm A');
        var end = moment(endTime, 'h:mm A');
        var duration = moment.duration(end.diff(start));
        var hours = duration.hours();
        var minutes = duration.minutes();
        return hours + " h " + minutes + " min";
    };
    TripsComponent.prototype.setRoute = function (track) {
        if (track['type'] == 'trip') {
            if (this.selectedIndex != track['elementIndex']) {
                this.selectedIndex = track['elementIndex'];
            }
            else {
                this.selectedIndex = -1;
            }
            this.removeLayers();
            this.updateMaps(this.selectedIndex);
        }
    };
    TripsComponent.prototype.changeDate = function (event) {
        var dateObject = new Date(this.selectedCustomDate);
        if (event === 'before') {
            dateObject.setDate(dateObject.getDate() - 1);
        }
        else {
            dateObject.setDate(dateObject.getDate() + 1);
        }
        this.selectedCustomDate = dateObject;
        var currentDate = new Date(this.selectedCustomDate).toDateString();
        var restrictDate = new Date(this.minDate).toDateString();
        if (currentDate == restrictDate) {
            this.movePrevious = false;
        }
        else {
            this.movePrevious = true;
        }
        if (currentDate == this.todayDate) {
            this.moveNext = true;
        }
        else
            this.moveNext = false;
        var backendDate = moment(this.selectedCustomDate).format('YYYY-MM-DD');
        var data = {
            date: backendDate,
            id: this.vehicleId
        };
        this.customSelectedDate = backendDate;
        this.getMap(data);
    };
    TripsComponent.prototype.displayViewMode = function (data) {
    };
    TripsComponent.prototype.toggleViewMode = function () {
        this.viewMode = !this.viewMode;
    };
    TripsComponent.prototype.getSelectedLocation = function () {
        if (this.selectedLayer !== false) {
            this.updateMapBounds();
        }
    };
    TripsComponent.prototype.setMapConfig = function (latitude, longitude) {
        var self = this;
        // Maps
        $(function () {
            self.config = {
                center: [latitude, longitude],
                zoom: 10,
                layers: [],
                inertia: true,
                inertiaDeceleration: 10000,
                inertiaMaxSpeed: 1000,
                bubblingMouseEvents: true,
                doubleClickZoom: false // disable default double-click zoom
            };
            if (self.map != undefined)
                self.map.remove();
            self.map = L.map('map', self.config);
            self.map.markerZoomAnimation;
            self.map.keyboard.disable();
            self.map.scrollWheelZoom.enable();
            self.map.boxZoom.disable();
            self.map.on('zoom', function () {
                if (!self.mapZoomHappenedByFit) {
                    self.mapZoomHappened = true;
                }
                else {
                    self.mapZoomHappenedByFit = false;
                }
            });
            self.mapInitated = true;
            L.tileLayer('https://map.commusoft.net/styles/com-streets/{z}/{x}/{y}.png', { attribution: '' }).addTo(self.map);
            var clickTimeout;
            // self.map.on('moveend', function(e) {
            //     self.removeMarker();  // if you need dragend remove logic enable this
            // });
            self.map.on('click', function (e) {
                if (clickTimeout) {
                    clearTimeout(clickTimeout);
                }
                clickTimeout = setTimeout(function () {
                    clickTimeout = null;
                    self.onMapClick(e);
                }, 250);
            });
            self.map.on('dblclick', function (e) {
                if (clickTimeout) {
                    clearTimeout(clickTimeout);
                    clickTimeout = null;
                }
                self.removeMarker();
                self.map.setZoom(self.map.getZoom() + 1);
            });
        });
    };
    TripsComponent.prototype.removeMarker = function () {
        if (this.currentMarker) {
            this.currentMarker.remove();
            this.currentMarker = undefined;
        }
        if (this.clickListener) {
            this.clickListener();
            this.clickListener = null;
        }
    };
    TripsComponent.prototype.onMapClick = function (e) {
        var _this = this;
        var latlng = e.latlng;
        var zoomLevel = this.map.getZoom();
        var data = {
            latitude: latlng.lat,
            longitude: latlng.lng,
            zoomLevel: zoomLevel,
        };
        if (this.currentMarker) {
            this.currentMarker.remove();
        }
        this.currentMarker = this.createMarker(latlng);
        this.updatePopupContent(this.getLoadingPopupContent());
        this.httpService.getLiveAddress(data).subscribe(function (response) {
            if (response.hasOwnProperty('display_name')) {
                var address = response.display_name;
                var properties = [
                    'name', 'amenity', 'building', 'office', 'tourism',
                    'place', 'road', 'suburb', 'village', 'town',
                    'city', 'shop', 'landuse', 'natural', 'historic',
                    'leisure', 'man_made', 'waterway', 'railway',
                    'aeroway', 'public_transport', 'boundary', 'military',
                    'healthcare'
                ];
                var placeName = 'Unknown Place';
                for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
                    var property = properties_1[_i];
                    if (response.address[property]) {
                        placeName = response.address[property];
                        break;
                    }
                }
                var capitalizeFirstLetter = function (str) {
                    if (str.length === 0)
                        return str;
                    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                };
                placeName = capitalizeFirstLetter(placeName);
                _this.updatePopupContent(_this.getAddressPopupContent(placeName, address));
                _this.attachEventListeners(response);
            }
            else {
                _this.updatePopupContent(_this.getAddressPopupContent('', '<p>Something went wrong. Please try again.</p>'));
            }
        }, function (error) {
            console.error('Error fetching address:', error);
            _this.updatePopupContent(_this.getAddressPopupContent('', '<p>Something went wrong. Please try again.</p>'));
        });
        if (this.clickListener) {
            this.clickListener();
        }
    };
    TripsComponent.prototype.createMarker = function (latlng) {
        return L.circleMarker(latlng, {
            radius: 8,
            fillColor: '#505B65',
            color: '#fff',
            weight: 2,
            opacity: 1,
            fillOpacity: 1,
        }).addTo(this.map);
    };
    TripsComponent.prototype.updatePopupContent = function (content) {
        this.currentMarker.bindPopup(content, {
            className: 'live-map-custom-popup-class',
            closeButton: false,
        }).openPopup();
    };
    TripsComponent.prototype.getLoadingPopupContent = function () {
        return "\n        <div class=\"map_search_result cursor-not-allowed pointer-events-none\">\n            <div class=\"selected_vehicle_card_box\">\n                <div class=\"card_tilte\">\n                    <div class=\"animated-background w-80 loading-height\"></div>\n                    <div class=\"animated-background w-20 loading-height\"></div>\n                    <div class=\"animated-background w-100 loading-height\"></div>\n                </div>\n                <div class=\"card_action\">\n                    " + this.getActionSteps() + "\n                </div>\n            </div>\n        </div>\n    ";
    };
    TripsComponent.prototype.getAddressPopupContent = function (PlaceName, address) {
        return "\n        <div class=\"map_search_result\">\n            <div class=\"selected_vehicle_card_box\">\n                <div class=\"card_tilte\">\n                    <p><strong>" + PlaceName + "</strong></p>\n                    <p>" + address + "</p>\n                </div>\n                <div class=\"card_action\">\n                    " + this.getActionSteps() + "\n                </div>\n            </div>\n        </div>\n    ";
    };
    TripsComponent.prototype.getActionSteps = function () {
        return "\n        <div class=\"close-btn-section close-btn\">\n            <i id=\"fleet-modal-popup-close-btn\" class=\"far fa-xmark close-icon\" aria-hidden=\"true\"></i>\n        </div>\n        <!--<div class=\"card_action_steps\">\n            <p><i class=\"action_icon_text fa-regular fa-swap-arrows\"></i></p>\n            <p class=\"action_desc_text\">Check distance</p>\n        </div>-->\n        <div class=\"card_action_steps\" id=\"save-location\">\n            <p><i class=\"action_icon_text fa-solid fa-bookmark\"></i></p>\n            <p class=\"action_desc_text\">Save location</p>\n        </div>\n        <!--<div class=\"card_action_steps\">\n            <p><i class=\"action_icon_text fa-solid fa-user\"></i></p>\n            <p class=\"action_desc_text\">Send to customer</p>\n        </div>-->\n       <!-- <div class=\"card_action_steps disabled\">\n            <p><i class=\"action_icon_text fa-solid fa-car-side\"></i></p>\n            <p class=\"action_desc_text\">Send to driver</p>\n        </div>-->\n    ";
    };
    TripsComponent.prototype.attachEventListeners = function (data) {
        var _this = this;
        // Add event listener and store the reference so it can be removed later
        this.clickListener = this.renderer.listen(this.el.nativeElement, 'click', function (event) {
            var target = event.target;
            if (target && target.closest('#save-location')) {
                _this.savedLocation(data);
            }
            if (target && target.closest('#close')) {
                _this.removeMarker();
            }
        });
    };
    TripsComponent.prototype.savedLocation = function (locationData) {
        var _this = this;
        if (!this.modalService.isOpen()) {
            var initialState = {
                title: 'Save.location',
                addressDetails: locationData,
                defaultIconsList: this.defaultIconsList
            };
            this.bsModalRef = this.modalService.show(FleetLiveMapSidepanelComponent, {
                initialState: initialState
            });
            this.bsModalRef.content.closeBtnName = 'Close';
            this.bsModalRef.content.onClose.subscribe(function (result) {
                if (result) {
                    _this.removeMarker();
                    _this.FleetMapViewComponent.getMapPinData();
                }
            });
        }
    };
    TripsComponent.prototype.handleGlobalEvents = function (event) {
        if (event instanceof KeyboardEvent) {
            switch (event.key) {
                case 'Escape':
                case 'Esc':
                    this.removeMarker();
                    break;
                default:
                    // Handle other keyboard events if needed
                    break;
            }
        }
        else if (event instanceof MouseEvent) {
            var targetElement = event.target;
            if (targetElement && targetElement.id === 'fleet-modal-popup-close-btn') {
                this.removeMarker();
            }
        }
    };
    TripsComponent.prototype.ngOnDestroy = function () {
        if (this.map != undefined) {
            this.map.off();
            this.map.remove();
        }
    };
    return TripsComponent;
}());
export { TripsComponent };
