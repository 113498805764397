<ng-container *ngIf="showUserField; else loader;" >
    <ng-container *ngIf="isFormControlNeed; else fieldWithUserForm;" >
        <div [formGroup]="userForm">
            <select [id]="fieldData.questionID"
                    csSelect2
                    [select2Config]="config"
                    class="full-width-select2"
                    [formControlName]="fieldData.questionID" (selected)="setDefaultUser($event)">
                <option value="" translate>Please.Choose</option>
                <option *ngFor="let option of userData" [disabled]="option.disable"
                        [value]="option.id">{{option.name}}
                </option>
            </select>
        </div>
    </ng-container>
</ng-container>
<ng-template #loader>
    <div class="cs-flex-1">
        <div>
            <div class="ph-item height-100" [style.padding-left]="0" [style.padding-right]="0" [style.width]="'100%'" >
                <div class="ph-col-12 height-100" [style.padding-left]="0" [style.padding-right]="0">
                    <div class="ph-row">
                        <div class="ph-col-12 big2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #fieldWithUserForm>
    <div [formGroup]="userForm">
        <select csSelect2 [id]="fieldData.questionID+this.listIndex" [formControlName]="fieldData.questionID+this.listIndex" [select2Config]="config" class="full-width-select2" (selected)="getSelectedUser($event)">
            <option value="" translate>Please.Choose</option>
            <option *ngFor="let option of userData" [disabled]="option.disable"
                    [value]="option.id">{{option.name}}
            </option>
        </select>
    </div>
</ng-template>
