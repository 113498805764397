import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DagSourceModel} from "@app/workflow-common/services/DagSourceModel";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CommonDataSource} from "@app/workflow-common/services/CommonDataSource";
import {
    StartSequenceBlockModel,
    StartSequenceBlockModelInterface
} from "@app/workflow-common/services/models/StartSequenceBlockModel";

@Component({
  selector: 'app-start-sequence-block-widget',
  templateUrl: './start-sequence-block-widget.component.html',
  styles: ['.empty-message-icon {\n' +
  '    width: 80px;\n' +
  '    height: 80px;\n' +
  '    background: #F0F0F0;\n' +
  '    font-size: 36px;\n' +
  '    margin: 0 auto;\n' +
  '    line-height: 80px;\n' +
  '    border-radius: 50%;\n' +
  '}\n' +
  '.empty-message-content {\n' +
  '    margin: 15px 20% 0\n' +
  '}']
})
export class StartSequenceBlockWidgetComponent implements OnInit {

    @Input() workflowType: string;
    @Input() blockObject;
    @Input() dagSourceModel: DagSourceModel;
    @Output() close:EventEmitter<{}> = new EventEmitter<{}>();

    form: FormGroup;

    contactsList: any[] = [];

    isLoading: boolean = true;
    blockData:{} = {};

    usersList: any[] = [];
    sequenceListData: any[] = [];
    isFormSubmitted:boolean = false;
    inputModel: StartSequenceBlockModelInterface = undefined;
    showDeleteBtn: boolean = false;
    select2Config = {
    };
    validation:boolean = false;

    activeDagSource: Array<any> = [];
    onChangeSequenceLoading: boolean = false;
    selectedWorkflowTemplateList: any[] = [];

    displayBlocks = {
        delayBlock: {
            text: 'Delay',
            iconClass: 'fas fa-history',
            textClass: null,
        },
        emailBlock: {
            text: 'Email',
            iconClass: 'fas fa-envelope',
            textClass: null,
        },
        smsBlock: {
            text: 'SMS',
            iconClass: 'fas fa-comment',
            textClass: null,
        },
        officeTaskBlock: {
            text: 'Office task',
            iconClass: 'fas fa-tasks',
            textClass: null,
        },
        notificationBlock: {
            text: 'Notification task',
            iconClass: 'fas fa-bell',
            textClass: null,
        },
        scheduledActivityBlock: {
            text: 'Schedule activity task',
            iconClass: 'fas fa-calendar-alt',
            textClass: null,
        }
    };
    constructor(
        private formBuilder: FormBuilder,
        private workflowService: WorkflowhttpService,
    ) { }

    async ngOnInit() {
        this.contactsList = CommonDataSource.contactsList;
        this.blockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);

        const blockObject = {...this.blockObject};
        const modelData = blockObject['model'] ? blockObject['model'] : undefined;
        if(modelData) {
            this.showDeleteBtn=true;
        }
        await this.getSequences();

        this.inputModel = new StartSequenceBlockModel(modelData);

        this.form = this.formBuilder.group({
            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
            contactId: new FormControl(this.inputModel.contactId, [Validators.required]),
            templateName: new FormControl(this.inputModel.templateName),
        });
        if(this.inputModel.templateId) {
            this.onChangeSequence(this.inputModel.templateId);
        }
        this.isLoading = false;
    }

    onChangeSequence(templateId:any) {
        this.form.get('templateId').setValue(templateId);
        this.onChangeSequenceLoading=true;
        if(!templateId) {
            this.activeDagSource = [];
            this.onChangeSequenceLoading=false;
            return;
        }

        let dags: Array<any> = [], emailSmsTemplatesId: Array<any>=[];

        const activeSequenceItem = this.sequenceListData.find( item => item.id == parseInt(templateId));
        this.form.get('templateName').setValue(activeSequenceItem['text'])
        const dagSource = JSON.parse(activeSequenceItem['dagsource']);
        let initBlock: any[] = dagSource['dagObject']['cs_initializer'];
        const getChild = (child: any[]) => {
            child.forEach( x => {
                if(dagSource['blockObjects'].hasOwnProperty(x)) {
                    dags.push(dagSource['blockObjects'][x])
                    if(dagSource['dagObject'][x]) {
                        getChild(dagSource['dagObject'][x]);
                    }
                }
            })
        };
        getChild(initBlock);
        dags.forEach( (dag) => {
            if(dag['name'] == 'emailBlock' || dag['name'] == 'smsBlock') {
                emailSmsTemplatesId.push(dag['model']['templateId']);
            }
        });

        this.workflowService.getEmailSmsTemplatesByIds({ids: emailSmsTemplatesId})
        .subscribe({
            next: (resp:any) => {
                this.selectedWorkflowTemplateList = resp['templates']
                this.activeDagSource = dags;
                this.onChangeSequenceLoading=false;
            },
            error: () => {
                this.onChangeSequenceLoading=false;
            }
        });
    }

    save() {
        this.isFormSubmitted=true;

        const blockObject = {...this.blockObject};
        blockObject.model = new StartSequenceBlockModel(this.form.value);
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        })
    }

    closeModal() {
        this.close.emit({
            action: 'close',
            data: null
        });
    }

    remove() {
        const blockObject = {...this.blockObject};
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    }

    getSequences() {
        this.isLoading=true;
        return this.workflowService.getWorkflowSetupSequencesList({
            sequenceType: 'Opportunity'
        }).toPromise().then( (resp) => {
            this.sequenceListData = resp['workflow_sequence'];
        })
    }

    getDisplayText(block) {
        const { model, name } = block;
        if(name == 'delayBlock') {
            return model.interval + ' ' + model.unit
        }
        else if(name == 'emailBlock' || name == 'smsBlock') {
            if(this.selectedWorkflowTemplateList[model['templateId']]) {
                return this.selectedWorkflowTemplateList[model['templateId']]['templateName'];
            }
        }
        else if(name == 'officeTaskBlock' || name == 'notificationBlock') {
            return model['textMessage']
        }
        else if(name == 'scheduledActivityBlock') {
            return model['description']
        }
        else {
            return '';
        }
    }

}

