import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TriggerDatasetService} from "@app/workflow-common/services/trigger-dataset.service";
import {pairwise, startWith} from "rxjs/operators";
import {ConfirmModalConfig} from "@app/interfaces/common-modal-popups";
import {TranslateService} from "@ngx-translate/core";
import {DagSourceModel, InitialBlockInterface} from "@app/workflow-common/services/DagSourceModel";

@Component({
  selector: 'app-trigger-rule-builder',
  templateUrl: './trigger-rule-builder.component.html',
  styleUrls: ['./trigger-rule-builder.component.scss']
})
export class TriggerRuleBuilderComponent implements OnInit, OnDestroy {
    public onClose: Subject<any>;
    deleteConfirmModalConfig: ConfirmModalConfig;
    dagSourceModel: DagSourceModel;
    isEdit: boolean;
    isFormSubmitted: boolean = false;
    fieldMetadata: object = {};
    eventsList: any[] = [];
    itemTitle: string;
    objectName: string;
    stageName: string;
    activeInputs: object = {};
    operators:object = {};
    selectedColName:any = [];
    select2FieldInstance: object = {};
    select2OperatorInstance: object = {};
    selectedEvent: any;
    eventHelpText: any;
    hasHelpText: boolean = false;

    select2OptionsEvents: any = {
        minimumResultsForSearch: 1
    };
    select2OptionsFields: any = {};
    select2DataFunction = (item) => { return item.text; };

    form: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private triggerService: TriggerDatasetService,
        public modalRef: BsModalRef,
        private translate: TranslateService,
    ) { }


    ngOnInit() {
        console.log('trigger-panel', this);
        this.onClose = new Subject();
        this.operators = this.triggerService.getOperators();

        let formData = {
            mainObjectName:this.objectName,
            triggerEvent: '',
            rules: [],
        };

        if(this.isEdit) {
            console.log('isEdit', this.dagSourceModel)
            const {blockObjects, eventName, mainObjectName} = this.dagSourceModel;
            formData['triggerEvent'] = eventName;
            formData['mainObjectName'] = mainObjectName;
            const initBlock:InitialBlockInterface = blockObjects[this.dagSourceModel.getInitialBlockId()];
            formData['rules'] = initBlock.rules;
            this.onSelectEvent(eventName);
        }

        this.form = this.formBuilder.group({
            mainObjectName: new FormControl(formData['mainObjectName'], [Validators.required]),
            conditions: 'AND',
            triggerEvent: new FormControl(formData['triggerEvent'], [Validators.required]),
            rules: this.formBuilder.array([]),
        });

        if(formData['rules'].length) {
            formData['rules'].forEach( (rule) => {
                this.addRule(rule);
            });
        }


        this.selectedColName = this.form.get('rules').value.map((item) => item.col_name);
        this.prepareActiveInputs();

        this.deleteConfirmModalConfig = {
            showModal: false,
            modalType: 'confirm',
            title: 'Dummy',
            actionText: 'DELETE',
            itemText: 'DELETE',
            confirmBtnText: 'DELETE',
            confirmBtnType: 'DELETE',
            closeText: 'cancel',
            confirmBxValidationMsg: this.translate.instant('confirmation.warning.text', {word:'DELETE',message:'remove the automation'}),
            message: { warningTxt:  'Delete.Automation.Warning.Message', helpTxtList: ['Delete.Automation.Confirmation.Message']},
            defaultTextMessage: false,
            doAction: this.removeRuleCondition.bind(this)
        };
    }

    prepareActiveInputs() {
        if(this.form.get('mainObjectName').value != '') {
            this.fieldMetadata['fields'].forEach( (field) => {
                this.activeInputs[field.col_name] = {
                    input: field.input,
                    type: field.type,
                    text: field.text,
                    operators: field.operators,
                    fieldType: 'static',
                    pageType: '',
                };
                if(field.hasOwnProperty('options') && field.options.length > 0) {
                    this.activeInputs[field.col_name]['options'] = field.options;
                }
            });
        }
    }

    get f() {
        return this.form.controls;
    }

    get formRules() : FormArray {
        return this.form.controls.rules as FormArray;
    }

    addRule(ruleItem:any=null) {
        const formRules = this.form.controls.rules as FormArray;
        let rule = {
            col_name: '',
            input: '',
            operator: 'equal',
            text: '',
            type: '',
            value: '',
            fieldType: 'static',
            pageType: '',
            templateId: '',
        };

        if (ruleItem) {
            rule['col_name'] = ruleItem['col_name'];
            rule['input'] = ruleItem['input'];
            rule['operator'] = ruleItem['operator'];
            rule['text'] = ruleItem['text'];
            rule['type'] = ruleItem['type'];
            rule['value'] = ruleItem['value'];
            rule['fieldType'] = !ruleItem['fieldType'] ? 'static' : ruleItem['fieldType'];
            rule['pageType'] = !ruleItem['pageType'] ? '' : ruleItem['pageType'];
            rule['templateId'] = !ruleItem['templateId'] ? '' : ruleItem['templateId'];
        }
        formRules.push(this.formBuilder.group({
            col_name: new FormControl(rule['col_name'], [Validators.required]),
            operator: new FormControl(rule.operator, [Validators.required]),
            value: new FormControl(rule.value, [Validators.required]),
            type: new FormControl(rule.type),
            input: new FormControl(rule.input),
            text: new FormControl(rule.text),
            fieldType: new FormControl(rule.fieldType),
            pageType: new FormControl(rule.pageType),
            templateId: new FormControl(rule.templateId)
        }));
        this.selectedColName = this.form.get('rules').value.map((item) => item.col_name);
    }

    setSelect2FieldValueInstance(instance, index:number, isMultiple:boolean=false) {
        let keyName = isMultiple ? 'field_multiple_'+index : 'field_' + index;
        this.select2FieldInstance[keyName] = instance;
    }

    setSelect2OperatorInstance(instance, index:number) {
        let keyName = 'field_' + index;
        this.select2OperatorInstance[keyName] = instance;
    }

    save() {
        this.isFormSubmitted = true;
        const formData = this.form.value;
        if(formData['triggerEvent']) {
            this.dagSourceModel.setEventName(formData['triggerEvent']);
        }
        this.dagSourceModel.addTask(formData, 0);
        console.log('save', this.form.value);
        this.onClose.next(this.dagSourceModel);
    }

    onChangeField(value:any, index:number) {
        let keyName = 'field_' + index;
        const formRules: FormArray = <FormArray>this.formRules;
        console.log('onChangeField', value, index, this.selectedColName);

        formRules.controls[index].get('type').setValue(this.activeInputs[value]['type']);
        formRules.controls[index].get('input').setValue(this.activeInputs[value]['input']);
        formRules.controls[index].get('text').setValue(this.activeInputs[value]['text']);
        formRules.controls[index].get('fieldType').setValue(this.activeInputs[value]['fieldType']);
        formRules.controls[index].get('pageType').setValue(this.activeInputs[value]['pageType']);

        formRules.controls[index].get('operator').setValue(this.activeInputs[value]['operators'][0]);
        if(this.select2OperatorInstance.hasOwnProperty(keyName)) {
            this.select2OperatorInstance[keyName].select2('val', this.activeInputs[value]['operators'][0]);
        }

        formRules.controls[index].get('value').setValue('');

        if(this.select2FieldInstance.hasOwnProperty(keyName)) {
            this.select2FieldInstance[keyName].select2('val', '');
            if(this.select2FieldInstance.hasOwnProperty('field_multiple_'+index)){
                this.select2FieldInstance['field_multiple_'+index].select2('destroy');
            }else{
                this.select2FieldInstance['field_multiple_'+(index+1)].select2('destroy');
                this.select2OperatorInstance['field_'+(index+1)].select2('destroy');
            }
        }
    }

    onChangeOperator(value:any, index:number) {
        const formRules: FormArray = <FormArray>this.formRules;
        formRules.controls[index].get('value').setValue('');
        this.destroyFieldsData(value, index);
    }
    destroyFieldsData(value:any, index:number){
        let keyName = 'field_' + index;
        if(value == 'in' || value == 'not_in') {
            keyName = 'field_' + index;
            if(this.select2FieldInstance.hasOwnProperty('field_multiple_'+index)){
                this.select2FieldInstance['field_multiple_'+index].select2('val', '');
            }
        }
        else {
            keyName = 'field_multiple_'+index;
            if(this.select2FieldInstance.hasOwnProperty('field_'+index)){
                this.select2FieldInstance['field_'+index].select2('val', '');
            }
        }
        if(this.select2FieldInstance.hasOwnProperty(keyName)) {
            this.select2FieldInstance[keyName].select2('destroy');
        }
    }

    onSelectMultipleSelect(value:any, index:number) {
        const formRules: FormArray = <FormArray>this.formRules;
        formRules.controls[index].get('value').setValue(value);
    }

    onCloseModal() {
        this.modalRef.hide();
    }

    onCloseConfirmation() {
        this.deleteConfirmModalConfig.showModal=false;
    }

    onBeforeRemove(index:number) {
        let nthCondition = (index+1);
        this.deleteConfirmModalConfig.title = this.translate.instant('Delete.Trigger.Condition', {nth: nthCondition});
        this.deleteConfirmModalConfig.confirmBxValidationMsg = this.translate.instant('confirmation.warning.text', {word:'DELETE',message:'remove the condition '+nthCondition});
        this.deleteConfirmModalConfig.actionArg = index;
        this.deleteConfirmModalConfig.showModal=true;
    }

    removeRuleCondition(deletableIndex:number, callBackFn: any) {
        const formRules: FormArray = <FormArray>this.formRules;
        formRules.removeAt(deletableIndex);
        this.deleteConfirmModalConfig.showModal = false;
        callBackFn('SUCCESS');
    }
    onSelectEvent(event){
        const selectedEvent = event;
        this.selectedEvent = this.eventsList.find(x=> x.id == selectedEvent)
        if(this.selectedEvent.hasOwnProperty('helpText')){
            this.hasHelpText = true;
            this.eventHelpText = this.selectedEvent.helpText;
        }else{
            this.hasHelpText = false;
        }
    }

    ngOnDestroy(): void {

    }
}
