import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { SidepanelModalService } from '@app/sidepanel/services/sidepanel-modal.service';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@app/core";
import { FolderService } from '@app/shared/common-folder-structure/service/folder.service';
import { TemplateModalComponent } from '@app/shared/common-folder-structure/template-modal/template-modal.component';
var UseTemplateComponent = /** @class */ (function () {
    function UseTemplateComponent(folderService, translate, sidepanelModalService, authService) {
        this.folderService = folderService;
        this.translate = translate;
        this.sidepanelModalService = sidepanelModalService;
        this.authService = authService;
        this.selectedFolder = '';
        this.loading = false;
        this.searchValue = '';
        this.timeDelay = "";
        this.templateList = [];
        this.templateSearchList = [];
        this.treeData = [];
        this.templateData = {};
        this.screen = '';
        this.type = '';
        this.isFolderSelectable = false;
        this.showAddNewTemplate = false;
        this.screenLabel = '';
        this.selectedTemplate = '';
        this.selectedTemplateId = '';
        this.themeName = 'default';
        this.placeHolder = '-- Please choose --';
        this.showEditOption = false;
        this.initialScreenLabel = '';
        this.filterParams = {
            screen: '',
            parentId: null,
            search: ''
        };
        this.showFolderBorder = false;
        this.selectedItem = new EventEmitter();
        this.viewMode = false;
        this.freemiumAccess = false;
        this.excludeOptions = [];
        this.disabled = false;
        this.addon = false;
        this.openDropDown = false;
        this.cancelEvent = new EventEmitter();
    }
    UseTemplateComponent.prototype.ngOnInit = function () {
        this.filterParams.screen = this.screen;
        this.filterParams.type = this.type;
        if (this.selectedTemplateId) {
            this.getSelectedTempate();
        }
        if (this.treeData.length) {
            this.templateList = this.treeData;
            this.setCurrentSelectedRow();
        }
        else {
            this.getTemplatesList();
        }
        var proposalEditor = this.authService.permitted(['AdvancedProposalEditor.readaccess']) === true;
        if (!proposalEditor && (this.screen == 'option_template' || this.screen == 'proposal_template' || this.screen == 'opportunity_template')) {
            this.freemiumAccess = true;
        }
        else {
            this.freemiumAccess = false;
        }
        if (this.openDropDown) {
            setTimeout(function () {
                $('.template-object').addClass('open');
            }, 1000);
        }
    };
    UseTemplateComponent.prototype.ngOnChanges = function (changes) {
        if (this.screenLabel && changes.hasOwnProperty('screenLabel') && changes.screenLabel.firstChange) {
            this.initialScreenLabel = this.screenLabel;
        }
        if (this.selectedTemplate && changes.hasOwnProperty('selectedTemplate')) {
            this.screenLabel = this.selectedTemplate;
            if (!changes.selectedTemplate.firstChange) {
                this.clearSearch();
            }
        }
    };
    UseTemplateComponent.prototype.getSelectedTempate = function () {
        var _this_1 = this;
        var url = 'get_template_details/' + this.selectedTemplateId + '?screen=' + this.screen;
        this.folderService.getApiCall(url).subscribe(function (data) {
            if (data.length && data[0].templateName) {
                _this_1.screenLabel = data[0].templateName;
            }
        });
    };
    UseTemplateComponent.prototype.setCurrentSelectedRow = function () {
        var _this = this;
        this.templateList.forEach(function iter(currentChildren, key) {
            Array.isArray(currentChildren.children) &&
                currentChildren.children.forEach(iter);
            if (currentChildren.selected === true) {
                _this.screenLabel = currentChildren.value;
                _this.currentRow = currentChildren;
            }
        });
    };
    UseTemplateComponent.prototype.getTemplatesList = function (showInitialVal) {
        var _this_1 = this;
        if (showInitialVal === void 0) { showInitialVal = true; }
        this.loading = true;
        var listUrl = "list_templates";
        if (this.screen == 'vehicleForms') {
            listUrl = "listVehicleForms";
        }
        if (showInitialVal) {
            this.templateList = [{
                    value: this.initialScreenLabel,
                    id: null,
                    parentId: null,
                    isFolder: "1",
                    isOpen: true,
                    selected: true,
                    deleted: 0,
                    children: [],
                }];
            this.currentRow = this.templateList[0];
        }
        else {
            this.templateList = [];
        }
        if (this.screen == 'vehicleForms') {
            this.folderService.postApiV5Call(listUrl, this.filterParams).subscribe(function (templateData) {
                if (templateData) {
                    _this_1.loading = false;
                    if (!_this_1.searchValue && showInitialVal && !_this_1.selectedFolder) {
                        _this_1.templateList[0].children = templateData.data;
                    }
                    else {
                        _this_1.templateList = templateData.data;
                    }
                    // let templateIndex = currentRow.children.findIndex(x => x.templateId == value.templateId);
                    // if(templateIndex !== -1){
                    //     currentRow.children[templateIndex].selected = true;
                    // }
                }
            });
        }
        else {
            if (this.screen == 'option_template') {
                this.filterParams['addOn'] = this.addon;
            }
            this.folderService.postApiCall(listUrl, this.filterParams).subscribe(function (templateData) {
                if (templateData) {
                    _this_1.loading = false;
                    if (_this_1.screen == 'option_template' || _this_1.screen == 'proposal_template' || _this_1.screen == 'opportunity_template') {
                        templateData.data = _this_1.excludeOptionTemplates(templateData.data);
                    }
                    if (!_this_1.searchValue && showInitialVal && !_this_1.selectedFolder) {
                        _this_1.templateList[0].children = templateData.data;
                    }
                    else {
                        _this_1.templateList = templateData.data;
                    }
                    // let templateIndex = currentRow.children.findIndex(x => x.templateId == value.templateId);
                    // if(templateIndex !== -1){
                    //     currentRow.children[templateIndex].selected = true;
                    // }
                }
            });
        }
    };
    UseTemplateComponent.prototype.initializeSearch = function (event) {
        var searchVal = event.target.value;
        if (!this.filterParams.search && !searchVal) {
            this.removeFolder(event);
        }
        else {
            this.showFolderBorder = false;
        }
        this.loading = true;
        var _self = this;
        clearTimeout(this.timeDelay);
        this.timeDelay = setTimeout(function () {
            _self.searchValue = _self.filterParams.search = searchVal;
            _self.getTemplatesList();
        }, 500);
    };
    UseTemplateComponent.prototype.removeFolder = function (event) {
        if (event && event.key === 'Backspace') {
            if (!this.filterParams.search && this.selectedFolder && !this.showFolderBorder) {
                this.showFolderBorder = true;
            }
            else if (!this.filterParams.search && this.selectedFolder && this.showFolderBorder) {
                this.showFolderBorder = false;
                this.clearSearch();
            }
        }
        else {
            this.showFolderBorder = false;
        }
    };
    UseTemplateComponent.prototype.viewChild = function (currentRow, loadInitialData) {
        var _this_1 = this;
        if (loadInitialData === void 0) { loadInitialData = false; }
        if (currentRow.isFolder && !this.searchValue && !this.selectedFolder) {
            if (typeof currentRow.isOpen != 'undefined' && (currentRow.isOpen || !currentRow.hasOwnProperty('isOpen'))) {
                currentRow.isOpen = false;
            }
            else if (currentRow.visited && !currentRow.isOpen) {
                currentRow.isOpen = true;
            }
            else {
                $(".folder-left-view").addClass("disable-click");
                currentRow.isOpen = true;
                currentRow.loading = true;
                var folderListUrl = "list_templates";
                var params = {
                    parentId: currentRow.id,
                    screen: this.screen,
                    type: this.type
                };
                if (this.screen == 'option_template') {
                    params['addOn'] = this.addon;
                }
                currentRow.children = [];
                this.folderService
                    .postApiCall(folderListUrl, params)
                    .subscribe(function (listData) {
                    var data = listData.data;
                    if (_this_1.excludeOptions && _this_1.excludeOptions.length > 0) {
                        data = data.filter(function (item) { return !_this_1.excludeOptions.includes(item.templateId); });
                    }
                    currentRow.loading = false;
                    if (data && data.length) {
                        data.forEach(function (val) {
                            currentRow.children.push(val);
                        });
                    }
                    currentRow.isOpen = true;
                    currentRow.visited = true;
                    $(".folder-left-view").removeClass("disable-click");
                });
            }
        }
        else if ((currentRow.isFolder && this.searchValue) || this.selectedFolder) {
            this.filterParams.search = '';
            this.searchValue = '';
            this.showFolderBorder = false;
            this.selectedFolder = currentRow.value;
            this.filterParams.parentId = currentRow.id;
            this.getTemplatesList();
        }
    };
    UseTemplateComponent.prototype.selectFolder = function (item) {
        this.currentRow.selected = false;
        if (this.isFolderSelectable) {
            $('.template-object').removeClass('open');
            this.currentRow.selected = false;
            this.selectedFolderId = item.id;
            item.selected = true;
            if (item.value) {
                this.placeHolder = '';
                this.screenLabel = item.value;
            }
            else if (item.templateName) {
                this.screenLabel = item.templateName;
            }
            var parentIds = this.getParents(this.templateList, []);
            if (typeof parentIds === 'object' && parentIds.length) {
                item.parentIds = parentIds.reverse();
            }
            this.selectedItem.emit(item);
            this.currentRow = item;
        }
        else if (item.isFolder == '1') {
            this.filterParams.search = '';
            this.searchValue = '';
            if (item.value) {
                this.selectedFolder = item.value;
            }
            else if (item.templateName) {
                this.selectedFolder = item.templateName;
            }
            this.filterParams.parentId = item.id;
            this.getTemplatesList(false);
        }
        else {
            $('.template-object').removeClass('open');
            if (item.value) {
                this.screenLabel = item.value;
            }
            else if (item.templateName) {
                this.screenLabel = item.templateName;
            }
            item.selected = true;
            this.currentRow = item;
            this.selectedItem.emit(item);
        }
    };
    UseTemplateComponent.prototype.getParents = function (list, parentIds) {
        for (var i = 0; i < list.length; i++) {
            var node = list[i];
            if (node.id === this.selectedFolderId) {
                // If the current node is the child, add its ID to the parentIds array
                parentIds.push(node.id);
                return true;
            }
            if (node.children && node.children.length > 0) {
                // If the current node has children, recursively search in its children
                if (this.getParents(node.children, parentIds)) {
                    // If the child is found in the children, add the current node's ID to the parentIds array
                    parentIds.push(node.id);
                    return parentIds;
                }
            }
        }
        return parentIds;
    };
    UseTemplateComponent.prototype.clearSearch = function () {
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.searchValue = '';
        this.selectedFolder = '';
        this.getTemplatesList();
    };
    UseTemplateComponent.prototype.cancelTemplate = function () {
        $("#emailcontent").removeClass("overlay");
        $(".template-wrapper").removeClass("template-open");
    };
    UseTemplateComponent.prototype.addNewTemplate = function (val) {
        if (val === void 0) { val = false; }
        if (this.screen == 'email_template') {
            this.openEmailTemplateWidget(val);
        }
        else if (this.screen == 'sms_template') {
            this.openSmsTemplateWidget(val);
        }
        else if (this.screen == 'letter_template') {
            this.openLetterTemplateWidget(val);
        }
    };
    UseTemplateComponent.prototype.openEmailTemplateWidget = function (val) {
        var _this_1 = this;
        var initialState = {
            title: val ? "edit.email.templates" : "add.email.templates",
            screenLabel: "email.templates",
            editFormData: val ? val : false
        };
        this.bsModalRef = this.sidepanelModalService.show(TemplateModalComponent, {
            initialState: initialState,
            size: 'xl',
        });
        this.bsModalRef.content.onClose.subscribe(function (result) {
            if (result) {
                _this_1.handleSelectedTreeView(result);
                _this_1.selectedItem.emit(result);
                _this_1.clearSearch();
            }
        });
    };
    UseTemplateComponent.prototype.handleSelectedTreeView = function (value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var parentIds, currentRow, _loop_1, this_1, key;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.screenLabel = value.templateName;
                        this.currentRow.selected = false;
                        if (!(value && value.hasOwnProperty('parentIds'))) return [3 /*break*/, 6];
                        parentIds = value.parentIds;
                        currentRow = void 0;
                        if (!(parentIds.length == 0 && value.hasOwnProperty('templateId') && value.templateId)) return [3 /*break*/, 1];
                        this.getTemplatesList();
                        return [3 /*break*/, 6];
                    case 1:
                        parentIds.shift();
                        _loop_1 = function (key) {
                            var folderId, index, index, templateIndex;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        folderId = parentIds[key];
                                        if (!(key == 0)) return [3 /*break*/, 3];
                                        index = this_1.templateList[key].children.findIndex(function (x) { return x.id == folderId; });
                                        console.log(index);
                                        if (!(index !== -1)) return [3 /*break*/, 2];
                                        currentRow = this_1.templateList[key].children[index];
                                        if (!currentRow.hasOwnProperty('children')) {
                                            currentRow.children = [];
                                        }
                                        return [4 /*yield*/, this_1.loadTreeView(currentRow, folderId)];
                                    case 1:
                                        currentRow = _a.sent();
                                        _a.label = 2;
                                    case 2: return [3 /*break*/, 5];
                                    case 3:
                                        index = currentRow.children.findIndex(function (x) { return x.id == folderId; });
                                        if (index !== -1) {
                                            currentRow = currentRow.children[index];
                                        }
                                        if (!currentRow.hasOwnProperty('children')) {
                                            currentRow.children = [];
                                        }
                                        return [4 /*yield*/, this_1.loadTreeView(currentRow, folderId)];
                                    case 4:
                                        currentRow = _a.sent();
                                        _a.label = 5;
                                    case 5:
                                        currentRow.isOpen = true;
                                        templateIndex = currentRow.children.findIndex(function (x) { return x.templateId == value.templateId; });
                                        if (templateIndex !== -1) {
                                            currentRow.children[templateIndex].selected = true;
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        key = 0;
                        _a.label = 2;
                    case 2:
                        if (!(key < parentIds.length)) return [3 /*break*/, 5];
                        return [5 /*yield**/, _loop_1(key)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        key++;
                        return [3 /*break*/, 2];
                    case 5:
                        ;
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    UseTemplateComponent.prototype.loadTreeView = function (currentRow, folderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this;
            return tslib_1.__generator(this, function (_a) {
                _this = this;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        var fodlerListUrl = "list_templates";
                        var params = {
                            parentId: folderId,
                            screen: _this.screen,
                            type: _this.type
                        };
                        _this.folderService
                            .postApiCall(fodlerListUrl, params)
                            .subscribe(function (listData) {
                            var data = listData.data;
                            currentRow.loading = false;
                            if (data && data.length) {
                                data.forEach(function (val) {
                                    var checkIndex = currentRow.children.findIndex(function (x) { return x.id == val.parentId; });
                                    if (checkIndex === -1) {
                                        currentRow.children.push(val);
                                    }
                                });
                            }
                            currentRow.isOpen = true;
                            currentRow.visited = true;
                            // let index = currentRow.children.findIndex(x => x.id == folderId);
                            // if (index !== -1) {
                            // currentRow = currentRow.children;
                            // }
                            $(".folder-left-view").removeClass("disable-click");
                            resolve(currentRow);
                        });
                    })];
            });
        });
    };
    UseTemplateComponent.prototype.openSmsTemplateWidget = function (val) {
        var _this_1 = this;
        var initialState = {
            title: "add.sms.templates",
            screenLabel: "sms.templates",
            screenType: 'sms_template',
            editFormData: val ? val : false
        };
        this.bsModalRef = this.sidepanelModalService.show(TemplateModalComponent, {
            initialState: initialState,
            size: 'xl',
        });
        this.bsModalRef.content.onClose.subscribe(function (result) {
            if (result) {
                _this_1.handleSelectedTreeView(result);
                _this_1.selectedItem.emit(result);
                _this_1.clearSearch();
            }
        });
    };
    UseTemplateComponent.prototype.openLetterTemplateWidget = function (val) {
        var _this_1 = this;
        var initialState = {
            title: "add.letter.templates",
            screenLabel: "letter.templates",
            screenType: 'letter_template',
            editFormData: val ? val : false
        };
        this.bsModalRef = this.sidepanelModalService.show(TemplateModalComponent, {
            initialState: initialState,
            size: 'xl',
        });
        this.bsModalRef.content.onClose.subscribe(function (result) {
            if (result) {
                _this_1.handleSelectedTreeView(result);
                _this_1.selectedItem.emit(result);
                _this_1.clearSearch();
            }
        });
    };
    UseTemplateComponent.prototype.excludeOptionTemplates = function (templateData) {
        var _this_1 = this;
        var array = [];
        if (this.excludeOptions && this.excludeOptions.length > 0) {
            templateData = templateData.filter(function (item) { return !_this_1.excludeOptions.includes(item.templateId); });
        }
        return templateData;
    };
    UseTemplateComponent.prototype.findPosition = function (event, type) {
        var element = event.target;
        var top = $(element).position().top;
        if ((top >= 630)) {
            top -= 42;
        }
        else {
            top += 46;
        }
        $(element).find('.tooltipTemplatetext').css({ 'top': top });
        $(element).find('.tooltipTemplatetext').css('display', 'block');
    };
    UseTemplateComponent.prototype.tooltipHide = function (type) {
        $('.tooltipTemplatetext').css('display', 'none');
    };
    UseTemplateComponent.prototype.closeTemplate = function () {
        this.cancelEvent.emit();
    };
    return UseTemplateComponent;
}());
export { UseTemplateComponent };
