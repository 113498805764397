import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@app/core";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../core/auth/auth.service";
var PipelineSideMenuModalService = /** @class */ (function () {
    function PipelineSideMenuModalService(translate, authService) {
        this.translate = translate;
        this.authService = authService;
        this.progressPanelData = [];
        this.baseUrlPath = ['/system_settings', 'set_up_pipelines'];
        this.jobPermission = false;
        this.financePermission = false;
        this.screenType = 'opportunity';
        this.appliedPermission = 'SetupPipelines.updateaccess';
        this.automationPermission = '';
    }
    PipelineSideMenuModalService.prototype.getProgressPanelData = function (screen) {
        if (screen == 'job') {
            this.appliedPermission = 'SetupJobPipelines.updateaccess';
            this.automationPermission = 'SetupJobPipelineAutomation.updateaccess';
        }
        else {
            this.appliedPermission = 'SetupPipelines.updateaccess';
            this.automationPermission = 'SetupSalesPipelineAutomation.updateaccess';
        }
        this.progressPanelData = [
            {
                stepText: this.translate.instant('Basic.Details.Title'),
                id: 'basic_details',
                stepRouting: ['basic_details'],
                isStepRequired: true,
                stepPermission: this.authService.permitted([this.appliedPermission]) === true,
                showSection: false,
                isStepCompleted: false,
                stepActive: true,
                isStepDisable: false,
                sequence: 1,
            },
            {
                stepText: this.translate.instant('Pipeline.Stages'),
                id: 'pipeline_stages',
                stepRouting: ['pipeline_stages'],
                isStepRequired: true,
                stepPermission: this.authService.permitted([this.appliedPermission]) === true,
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                sequence: 2,
            },
            {
                stepText: this.translate.instant('Automations'),
                id: 'automations',
                stepRouting: ['automations'],
                isStepRequired: false,
                stepPermission: this.authService.permitted([this.automationPermission]) === true,
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                sequence: 3,
            }
        ];
        this.jobPermission = this.authService.permitted(['Jobs.readaccess']) === true;
        this.financePermission = this.authService.permitted(['SetupFinance.writeaccess']) === true;
        if (screen == 'job') {
            this.baseUrlPath = ['/system_settings', 'jobs_pipelines'];
            this.screenType = 'job';
        }
        else if (screen == 'stage') {
            this.baseUrlPath = ['/system_settings', 'job_stages'];
            this.screenType = 'stage';
        }
        else {
            this.baseUrlPath = ['/system_settings', 'set_up_pipelines'];
            this.screenType = 'opportunity';
        }
        // need to change the condition once the finance enabled
        if ((this.jobPermission || this.financePermission) && (screen != 'job')) {
            this.progressPanelData.push({
                stepText: this.translate.instant('After.acceptance.tracking'),
                id: 'after_acceptance_tracking',
                stepRouting: ['acceptance_tracking'],
                isStepRequired: true,
                stepPermission: this.authService.permitted(['SetupPipelines.updateaccess']) === true,
                showSection: false,
                isStepCompleted: false,
                sequence: 4,
            });
        }
        return this.progressPanelData;
    };
    PipelineSideMenuModalService.ngInjectableDef = i0.defineInjectable({ factory: function PipelineSideMenuModalService_Factory() { return new PipelineSideMenuModalService(i0.inject(i1.TranslateService), i0.inject(i2.AuthService)); }, token: PipelineSideMenuModalService, providedIn: "root" });
    return PipelineSideMenuModalService;
}());
export { PipelineSideMenuModalService };
