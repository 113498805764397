import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { AppValidators } from "@app/directives";
var CommonModalPopupsComponent = /** @class */ (function () {
    function CommonModalPopupsComponent(fb) {
        this.fb = fb;
        this.onCloseModal = new EventEmitter();
        this.closeText = { warning: 'close', confirm: 'cancel' };
    }
    CommonModalPopupsComponent.prototype.ngOnInit = function () {
        this.setDefaultConfig();
    };
    CommonModalPopupsComponent.prototype.setDefaultConfig = function () {
        if (this.modalConfig) {
            this.modalConfig.closeText = this.modalConfig['closeText'] || this.closeText[this.modalConfig['modalType']];
            if (this.modalConfig['modalType'] === 'confirm') {
                this.modalConfig.operationFailedMsg = this.modalConfig.operationFailedMsg || 'failed.to.perform.operation';
                this.modalConfig.actionStatus = 'TODO';
                this.modalConfig.confirmBtnType = this.modalConfig.confirmBtnType || 'DEFAULT';
                if (this.modalConfig['dropDownS2InputConfig']) {
                    this.modalTabIndex = null;
                    this.confirmationForm = this.fb.group({
                        's2Data': ['', [Validators.required]]
                    });
                }
                else {
                    this.confirmationForm = this.fb.group({
                        'confirmationText': ['', [
                                Validators.required,
                                AppValidators.confirmationTextValidator(this.modalConfig['actionText'])
                            ]],
                    });
                }
            }
            this.modalConfig['doAction'] = this.modalConfig['doAction'] || (function () { });
        }
    };
    CommonModalPopupsComponent.prototype.ons2ChoiceSelect = function (val) {
        this.confirmationForm.get('s2Data').setValue(val);
    };
    CommonModalPopupsComponent.prototype.closeModal = function (callFrom) {
        if (this.modalConfig['modalType'] === 'confirm') {
            this.confirmationForm.reset();
            this.modalConfig['actionStatus'] = 'TODO';
        }
        this.onCloseModal.emit(callFrom);
        // Note: Here this used only for the issue of `backdrop` in `cs-modal`
        $('div.modal-backdrop').remove();
        $('body.modal-open').css('overflow', 'auto');
        // ------------------------
        this.modalConfig.showModal = false;
    };
    CommonModalPopupsComponent.prototype.executeAction = function () {
        var _this = this;
        if (this.modalConfig['modalType'] === 'confirm' && this.confirmationForm.valid) {
            this.modalConfig['actionStatus'] = 'PROCESSING';
            var formVal = { s2Data: this.modalConfig['dropDownS2InputConfig'] ? this.confirmationForm.get('s2Data').value : null };
            this.modalConfig.doAction(this.modalConfig.actionArg, function (status) {
                if (status === void 0) { status = 'SUCCESS'; }
                _this.modalConfig['actionStatus'] = status.toUpperCase();
                if (_this.modalConfig['actionStatus'] === 'SUCCESS') {
                    _this.closeModal('CONFIRM_BTN');
                }
            }, formVal);
        }
    };
    return CommonModalPopupsComponent;
}());
export { CommonModalPopupsComponent };
