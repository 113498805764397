import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InterfaceService} from "@app/interfaces";
import {TimelineDatePipe} from "@app/pipes/timeline-date.pipe";

@Injectable()

export class PreviewCardService extends InterfaceService {

    constructor(
        private http: HttpClient,
        private timelineDatePipe: TimelineDatePipe) {
        super();
    }

    formatInfo(cardValue, config, index, type) {
        let cardConfiguration: any = {};
        cardConfiguration =
            {
                "type": type,
                "label": this.getLabelProperties(cardValue, config),
                "value": this.getValueProperties(cardValue, config),
                "fieldId": config.hasOwnProperty('fieldId') ? config.fieldId : ''
            };
        if (index == 0) {
            cardConfiguration.children = [{
                "type": "image_list",
                "label": {},
                "value": this.validateKeyExist(cardValue, 'jobTeamUsers')
            },
            ];
        }
        return cardConfiguration;
    }

    validateKeyExist(obj, key) {
        if (obj && obj.hasOwnProperty(key)) {
            return obj[key];
        }
        return '';
    }

    getLabelProperties(cardValue, config) {
        if (config.hasOwnProperty('showLabel') && config.showLabel) {
            return {
                text: config.fieldLabel,
                bold: false
            }
        }
        return {};
    }

    getValueProperties(cardValue, config) {
        if (config && config.hasOwnProperty('fieldId') && config.fieldId && cardValue) {
            if (config.fieldType == 'date' && cardValue.hasOwnProperty(`${config['fieldId']}`) && cardValue[config['fieldId']]) {
                if (typeof cardValue[config['fieldId']] == 'object' && cardValue[config['fieldId']].date) {
                    cardValue[config['fieldId']] = this.timelineDatePipe.transform(cardValue[config['fieldId']].date, 'datetime');
                } else if (typeof cardValue[config['fieldId']] == 'string') {
                    cardValue[config['fieldId']] = this.timelineDatePipe.transform(cardValue[config['fieldId']], 'datetime');
                }
            } else if (config.fieldType == 'time' && cardValue.hasOwnProperty(`${config['fieldId']}`) && cardValue[config['fieldId']] && cardValue[config['fieldId']]) {
                cardValue[config['fieldId']] = this.formatTime(cardValue[config['fieldId']]);
            }
            return {
                text: cardValue.hasOwnProperty(`${config['fieldId']}`) ? cardValue[config['fieldId']] : '',
                bold: config['fieldId'] == 'jobs_description',
                background: config['fieldId'] == 'jobs_pipelineStage' && cardValue.hasOwnProperty('jobs_pipelineStage') && cardValue.jobs_pipelineStage && cardValue.hasOwnProperty('stageColour') && cardValue.stageColour ? cardValue.stageColour : ''
            };
        }
        return {};
    }

    formatPreviewCardInfo(config, data) {
        let cardValue: any;
        let cardInfo: any = [];
        (config || []).forEach((val, key) => {
            let type = val.fieldId == 'jobs_pipelineStage' ? 'text' : 'text';
            // let type = 'text';
            cardInfo.push(this.formatInfo(data, val, key, type));
        });
        cardValue = [{
            lineItemId: data.hasOwnProperty('jobs_number') ? data['jobs_number'] : '',
            someoneViewing: false,
            isNewlyAdded: false,
            isDeleted: false,
            data: [cardInfo],
        }];
        return cardValue;
    }

    formatTime(seconds) {
        let symbol = seconds >= 0 ? '' : '-';
        seconds = Math.abs(seconds);
        let d = Math.floor(seconds / (3600 * 24));
        let h = Math.floor(seconds % (3600 * 24) / 3600);
        let m = Math.floor(seconds % 3600 / 60);

        let days = d != 0 ? symbol + d + (d == 1 ? " day " : " days ") : "";
        let hours = h != 0 ? symbol + h + (h == 1 ? " hour " : " hours ") : "";
        let minutes = m != 0 ? symbol + m + (m == 1 ? " minute " : " minutes ") : "";
        let data = days + hours + minutes;
        return data.trim();
    }
}
