import { OnInit, ApplicationRef, ComponentFactoryResolver, AfterViewInit, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { DataTableDirective } from "angular-datatables";
import { Subject, from } from "rxjs/index";
import { DomService } from "@app/smart_table/services/dom.service";
import { HttpClient } from '@angular/common/http';
import { pluck } from "rxjs/operators";
import { SmartTableInstanceService } from "@app/smart_table/services/smarttable.instance.service";
import { environment } from "../../../environments/environment";
import { SmartTableMetaDataService } from "@app/smart_table/services/metadata.service";
import { filter, map } from "rxjs/internal/operators";
var DataTablesResponse = /** @class */ (function () {
    function DataTablesResponse() {
        this.draw = 0;
    }
    return DataTablesResponse;
}());
var SmartTableComponent = /** @class */ (function () {
    function SmartTableComponent(ajsScope, appRef, componentFactoryResolver, domService, http, smartTableInstance, metadataService) {
        this.ajsScope = ajsScope;
        this.appRef = appRef;
        this.componentFactoryResolver = componentFactoryResolver;
        this.domService = domService;
        this.http = http;
        this.smartTableInstance = smartTableInstance;
        this.metadataService = metadataService;
        this._rowSelect = false;
        this.emptyMessage = 'No records found';
        this.isServerProcessing = true;
        this.sorting = true;
        this.columnEdit = true;
        this.params = {};
        this.subcategory = '';
        this.paramName = 'filter';
        this.$instance = new EventEmitter();
        this.onSelectCheckbox = new EventEmitter();
        this.onSelectAllCheckbox = new EventEmitter();
        this.afterRender = new EventEmitter();
        this.$dataCount = new EventEmitter();
        this.noDisplay = false;
        this.loadingFirstTime = true;
        this.columns = [];
        this.limit = 20;
        this.dtOptions = {};
        this.dtTrigger = new Subject();
        this.toggleData = {};
        this.actionType = null;
        this.columnsCount = 0;
        this.loading = true;
        this.selectAllCheckbox = false;
        this.columnsSaveUrl = 'userColumnOrder';
    }
    Object.defineProperty(SmartTableComponent.prototype, "rowSelect", {
        // @Input() rowSelect: boolean = false;
        set: function (value) {
            this._rowSelect = value;
            var isCheckbox = this.columns.findIndex(function (item) {
                return item.data === '@empty_select';
            });
            if (this.columns.length === 0) {
                //first time
                this.columns.push(this.checkBoxColumn(this._rowSelect));
                return;
            }
            if (isCheckbox > -1) {
                this.onShowHideColumn({
                    columnIndex: 0,
                    visible: this._rowSelect
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    SmartTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('rowSelect')) {
            this.columnsAdjust();
        }
    };
    SmartTableComponent.prototype.ngOnInit = function () {
        this.metadataService.initiaze(this.metadata);
        if (this.columns.length) {
            this.columns = this.columns.concat(this.metadataService.getColumns());
        }
        else {
            this.columns = this.metadataService.getColumns();
        }
        this.limit = this.metadataService.getLimit();
        this.renderTable();
    };
    SmartTableComponent.prototype.defaultProps = function () {
        var _this = this;
        var that = this;
        return {
            dom: 'rtipl',
            // dom: 'lrtip',
            initComplete: function () {
                //that.columnsAdjust(50);
            },
            lengthMenu: [[10, 20, 50, 100], [10, 20, 50, 100]],
            autoWidth: false,
            serverSide: true,
            sPaginationType: "bootstrap",
            processing: true,
            pagingType: 'full_numbers',
            bScrollCollapse: true,
            //sScrollX: "100%",
            scrollX: true,
            colReorder: true,
            aaSorting: [],
            destroy: true,
            select: true,
            //"order": [[ 1, "desc" ], [ 3, "asc" ]],
            responsive: false,
            ajax: function (dataTablesParameters, callback) {
                //console.log('params', that.paramsTransform(dataTablesParameters), dataTablesParameters);
                var params = that.paramsTransform(dataTablesParameters);
                that._headerOldHash = that.getHeaderHash();
                var headerData = {
                    limit: dataTablesParameters.length,
                    order: dataTablesParameters.order
                };
                that.setHeaderHash(headerData);
                if (!that._headerOldHash) {
                    that._headerOldHash = that.getHeaderHash();
                }
                // console.log('hashOld', that._headerOldHash);
                // console.log('hashNew', that.getHeaderHash());
                that.loading = true;
                that.http.post(that.getApiUrl(that.dataUrl, params['smartTableParams']), params, {}).subscribe(function (resp) {
                    var _resonseDatas = resp[that.category];
                    that.smartTableInstance.setDatas(_resonseDatas.data);
                    that.loadingFirstTime = false;
                    that.loading = false;
                    callback({
                        recordsTotal: parseInt(_resonseDatas.count),
                        recordsFiltered: parseInt(_resonseDatas.count),
                        data: []
                    });
                    _this.$dataCount.emit(_resonseDatas.count);
                    _this.ajsScope.$emit('setTableRowCount', _resonseDatas.count);
                });
            },
            preDrawCallback: function () {
                _this.columnsAdjust();
            },
            drawCallback: function (settings) {
                if (that._headerOldHash !== that.getHeaderHash()) {
                    var oAjaxData = settings.oAjaxData;
                    that.metadata.limit = oAjaxData.length;
                    that.onSaveColumns({ columns: that._prepareSaveColumns() }, false);
                    // console.log('update column save', that.metadata, that._prepareSaveColumns());
                }
                setTimeout(function () {
                    _this.selectAllCheckbox = false;
                    _this.datatableElement.dtInstance.then(function (dtInstance) {
                        dtInstance.columns.adjust();
                        $('.cs-smart-table-container thead th').off('mousedown');
                    });
                    _this.afterRender.emit();
                }, 50);
            },
            language: {
                processing: '<div class="spreadsheet-loading-wrap">\n' +
                    ' <span class="loading-spreadsheet">\n' +
                    '  <span class="loading-x-axis"></span>\n' +
                    '  <span class="loading-y-axis"></span>\n' +
                    '  <div class="loading-row row-1"></div>\n' +
                    '  <div class="loading-row row-2"></div>\n' +
                    '  <div class="loading-row row-3"></div>\n' +
                    '  <div class="loading-row row-4"></div>\n' +
                    '  <div class="loading-row row-5"></div>\n' +
                    ' </span>\n' +
                    '</div>',
                lengthMenu: "Show _MENU_ entries"
            }
        };
    };
    SmartTableComponent.prototype.setHeaderHash = function (params) {
        var headerData = {
            limit: params.limit,
            order: params.order
        };
        this._headerHash = btoa(JSON.stringify(headerData));
    };
    SmartTableComponent.prototype.getHeaderHash = function () {
        return this._headerHash;
    };
    SmartTableComponent.prototype.renderTable = function () {
        var options = this.defaultProps();
        options.pageLength = this.limit;
        options.columns = this.prepareColumns();
        options.order = this.prepareSorting();
        if (!options.columns.length) {
            throw new Error("Columns must not be empty.");
        }
        this.dtOptions = options;
    };
    SmartTableComponent.prototype.toggleActions = function (event) {
        this.toggleData = event;
    };
    SmartTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        //this.dtTrigger.next();
        this.smartTableInstance.datatableElement = this.datatableElement;
        this.smartTableInstance.dtTrigger = this.dtTrigger;
        this.$instance.emit(this.smartTableInstance);
        this.smartTableInstance.container = this.container;
        this.smartTableInstance.setComponent(this);
        this.datatableElement.dtInstance.then(function (dtInstance) {
            var table = dtInstance.table('#cs-smart-table-v2');
            table.on('draw.dt', function () {
                setTimeout(function () {
                    $('.dataTables_scrollHead thead tr th').each(function (idx, ele) {
                        var xPos = $(ele).textWidth() + 20;
                        $(ele).css('background-position-x', xPos + 'px');
                    });
                }, 100);
            });
        });
        $(window).on('resize', function () {
            _this.datatableElement.dtInstance.then(function (dtInstance) {
                dtInstance.columns.adjust();
            });
        });
    };
    SmartTableComponent.prototype.ngOnDestroy = function () {
        this.dtTrigger.unsubscribe();
        this.smartTableInstance.empty();
    };
    SmartTableComponent.prototype.onShowHideColumn = function (event) {
        var _this = this;
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.columns([event.columnIndex]).visible(event.visible);
            _this.columns[event.columnIndex].visible = event.visible;
            setTimeout(function () {
                dtInstance.columns.adjust();
            }, 100);
        });
    };
    SmartTableComponent.prototype.onReOrderColumn = function (cols) {
        var _this = this;
        this.datatableElement.dtInstance.then(function (dtInstance) {
            _this.columns = cols;
            var source = from(cols);
            var example = source.pipe(pluck('columnIndex'));
            var colPos = [];
            example.subscribe(function (val) { return colPos.push(val); });
            _this.columns.forEach(function (col, index) {
                col.columnIndex = index;
            });
            dtInstance.colReorder.order(colPos);
            setTimeout(function () {
                dtInstance.columns.adjust();
            }, 100);
        });
    };
    SmartTableComponent.prototype.setParams = function (newParams) {
        this.params = newParams;
    };
    SmartTableComponent.prototype.onSelectRow = function (data, col) {
        data.selected = !data.selected;
        this.onSelectCheckbox.emit({
            data: data,
            isChecked: data.selected
        });
        if (data.selected && !this.selectAllCheckbox) {
            this.selectAllCheckbox = true;
        }
        if (!data.selected && this.smartTableInstance.getSelectedRows().length === 0) {
            this.selectAllCheckbox = false;
        }
    };
    SmartTableComponent.prototype.onSelectAllRows = function () {
        this.selectAllCheckbox = !this.selectAllCheckbox;
        this.smartTableInstance.checkUncheckAllRows(this.selectAllCheckbox);
        this.onSelectAllCheckbox.emit(this.selectAllCheckbox);
    };
    SmartTableComponent.prototype.onSaveColumns = function (event, fromEvent) {
        var _this = this;
        if (fromEvent === void 0) { fromEvent = true; }
        var _a = this.metadata, limit = _a.limit, sort = _a.sort;
        var colsParams = {
            columns: event.columns,
            limit: limit,
            sort: sort
        }, postParams = {
            columnValue: JSON.stringify(colsParams),
            screenName: (this.subcategory == '' || this.subcategory == undefined) ? this.category : this.subcategory,
        };
        this.http.get(this.getApiUrl(this.columnsSaveUrl, null), {
            params: postParams
        }).subscribe(function (resp) {
            if (fromEvent) {
                event.modal.hide();
                setTimeout(function () {
                    _this.columnsAdjust();
                }, 600);
            }
        });
    };
    SmartTableComponent.prototype.onCloseColumnModal = function () {
        var _this = this;
        setTimeout(function () {
            _this.columnsAdjust();
        }, 600);
    };
    SmartTableComponent.prototype.checkBoxColumn = function (visible) {
        if (visible === void 0) { visible = true; }
        return {
            data: '@empty_select',
            title: '',
            className: 'select-checkbox-all',
            orderable: false,
            visible: visible,
            width: '10px'
        };
    };
    SmartTableComponent.prototype.actionsBtnColumn = function () {
        return {
            data: '@empty_actions',
            title: '',
            className: 'st-last-column plus-actions',
            orderable: false,
            visible: true,
            width: '16px'
        };
    };
    SmartTableComponent.prototype.prepareColumns = function () {
        //if(this._rowSelect) {
        //this.columns.unshift(this.checkBoxColumn());
        //}
        if (this.actions.length) {
            this.columns.push(this.actionsBtnColumn());
        }
        this.columns.forEach(function (col, index) {
            col.columnIndex = index;
        });
        this.columnsCount = this.columns.length;
        return this.columns;
    };
    SmartTableComponent.prototype.prepareSorting = function () {
        var sort = this.metadataService.getSorting(), newOrderList = [];
        if (sort && typeof sort === 'object') {
            //console.log('this.cc', this.columns)
            var result = from(this.columns)
                .pipe(map(function (col, index) {
                if (sort.hasOwnProperty(col.title)) {
                    col._orderby = [index, sort[col.title]];
                }
                return col;
            }))
                .pipe(filter(function (cols) { return cols.hasOwnProperty('_orderby'); }));
            result.subscribe(function (x) { return newOrderList.push(x._orderby); });
            return newOrderList;
        }
    };
    SmartTableComponent.prototype.renderActionButton = function (event) {
        var data = event.data, buttonProp = event.buttonProp, index = event.index;
        if (buttonProp.onClick && isFunction(buttonProp.onClick)) {
            buttonProp.onClick(data, buttonProp, this.smartTableInstance, index);
        }
    };
    SmartTableComponent.prototype.columnsAdjust = function () {
        if (!this.datatableElement) {
            return;
        }
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.columns.adjust();
        });
    };
    SmartTableComponent.prototype.getApiUrl = function (path, params) {
        var url = environment.apiHost + environment.apiBasePath + path;
        if (params instanceof Object) {
            url += '?page=' + params.page + '&limit=' + params.limit;
        }
        return url;
    };
    SmartTableComponent.prototype.paramsTransform = function (params) {
        var tableParams = {}, page = 1;
        var externalParams = {};
        externalParams[this.paramName] = typeof this.params === 'object' ? JSON.stringify(this.params) : this.params;
        if (params.length) {
            tableParams['limit'] = params.length;
        }
        tableParams['page'] = page + (params.start / params.length);
        tableParams['order'] = this.getSortableData(params);
        tableParams['tableCategory'] = this.category;
        externalParams['smartTableParams'] = tableParams;
        return externalParams;
    };
    SmartTableComponent.prototype.getSortableData = function (params) {
        var _this = this;
        if (!params.order) {
            return [];
        }
        var columnsOrder = {};
        params.order.forEach(function (item) {
            var tmp = {};
            columnsOrder[_this.columns[item.column].data] = item.dir;
            //columnsOrder.push(tmp);
        });
        return columnsOrder;
    };
    SmartTableComponent.prototype._prepareSaveColumns = function () {
        var newCols = [];
        var filterColumns = from(Object.assign([], this.columns))
            .pipe(filter(function (col) { return !col.data.startsWith('@') && col.visible; }))
            .pipe(map(function (col, index) {
            return {
                col_name: col.col_name,
                pos: index + 1,
                active: col.visible
            };
        }));
        filterColumns.subscribe(function (col) { return newCols.push(col); });
        return newCols;
    };
    SmartTableComponent.prototype.onResize = function (event) {
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.columns.adjust();
        });
    };
    return SmartTableComponent;
}());
export { SmartTableComponent };
export function isFunction(x) {
    return typeof x === 'function';
}
// this.dtOptions = {
//   dom: 'lrtip',
//   initComplete: () => {
//   },
//   serverSide: true,
//   sPaginationType: "bootstrap",
//   processing: true,
//   //ajax: this.dataUrl,
//   ajax: (dataTablesParameters: any, callback) => {
//     that.http.post<DataTablesResponse>(
//       that.dataUrl,
//       dataTablesParameters,
//       {}
//     ).subscribe(resp => {
//       that.datas = that.oldDatas = resp.data;
//       callback({
//           recordsTotal: resp.recordsTotal,
//           recordsFiltered: resp.recordsFiltered,
//           data: []
//       });
//     })
//   },
//   columns: this.prepareColumns(),
//   drawCallback: (settings) => {
//       setTimeout( () => {
//           this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
//               dtInstance.columns([0]).order('No');
//               dtInstance.columns.adjust();
//           })
//       },100);
//   },
//   bSort: this.sorting,
//   pagingType: 'full_numbers',
//   pageLength: 10,
//   scrollCollapse: true,
//   scrollX: true,
//   colReorder: true,
//   aaSorting: [],
//   destroy: true,
//     select: true,
// fixedColumns: {
//   rightColumns:0,
//   leftColumns:0
// },
// columnDefs: [{
//   targets: -1,
//   className: 'st-last-column plus-actions',
//   width: '16px',
//   orderable: false,
//   // render: (data, type, full, meta ) => {
//   //     const $table = $(meta.settings.oInstance.api().table().node());
//   //     const $td = $table.find('tr:eq(' + meta.row + ') > td:eq(' + meta.col + ')');
//   //     // const componentRef = that.componentFactoryResolver
//   //     //     .resolveComponentFactory(ActionButtonsComponent).create();
//   //     // that.appRef.attachView(componentRef);
//   //     //return that.renderActions(data, type, full, meta);
//   //     that.viewContainerRef.clear();
//   //     const domElem = this.domService.createEmbeddedView(ActionButtonsComponent, {
//   //         type: type
//   //     });
//   //     return $(domElem).html();
//   // }
// },
// {
//     targets: this.columns.length-2,
//     className: 'st-last-before-column',
// }]
// };
