import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[appDigitDecimaNumber]'
})
export class DigitDecimalInputDirective implements OnInit {
    @Input('scale') scale:number=4;
    @Input('isLimitScale') isLimitScale=false;
    @Input('precision') precision:number=0;
    @Input('negativeAllowable') negativeAllowable:boolean=false;
    private regex: RegExp;
    //private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) {
    }

    ngOnInit() {

    }

    getPrecision() {
        return this.precision > 0 ? this.precision-1 : 16;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent | any) {
        this.regex = this.negativeAllowable === true ? new RegExp('^-?\\d*\\.?\\d{0,'+this.scale+'}$','g')
            : new RegExp('^\\d*\\.?\\d{0,'+this.scale+'}$','g');
        // Allow Backspace, tab, end, and home keys
        let key = e.charCode || e.keyCode || 0;
        let isNumeric = ((key == 8) || (key == 9) || (key == 13) || (key == 46)
            || (key == 116) || (key == 123) || ((key >= 35) && (key <= 40))
            || ((key >= 48) && (key <= 57)) || ((key >= 96) && (key <= 105)) || (key == 190 || key == 110) ) || (this.negativeAllowable === true && e.key == "-");
        let actionKeys = ((key == 8) || (key == 9) || (key == 46) || (key == 13) || ((key >= 35) && (key <= 40)) );

        if (!isNumeric ) {
            e.preventDefault();
            return;
        }

        if (e.shiftKey ) {
            e.preventDefault();
        }
        let hasDecimal=e.target.value.indexOf('.') !== -1;
        let dotcontains = e.target.value.indexOf(".");
        let newValue = e.target.value;
        let caratPos = e.target.selectionStart;
        if(hasDecimal && (e.keyCode==190 || e.keyCode == 110) ) {  console.log('key', e.keyCode);
            e.preventDefault();
        }

        if(dotcontains > -1) {
            const arrVal = newValue.split(".");
            let scale = caratPos > dotcontains ? this.scale-1 : this.getPrecision() ;
            let ind = caratPos > dotcontains ? 1 : 0;
            if( arrVal[ind].length > scale && !actionKeys && !this.isLimitScale){
                e.preventDefault();
            }
        }
        else{
            newValue.length > this.getPrecision() && !(key == 190 || key == 110) && !actionKeys && e.preventDefault();
        }
    }

    @HostListener('input', ['$event'])
    onKeyUp(e: KeyboardEvent | any) {
        if(this.negativeAllowable && !this.regex.test(e.target.value) ) {
            e.target.value = e.target.value.replace(/(?!^-)[^0-9\\.]/g, '');
        }
    }
}
