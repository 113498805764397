import { AfterViewInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Subject } from "rxjs/internal/Subject";
import { InterfaceService } from "@app/interfaces";

@Injectable({
    providedIn: 'root'
})
export class FolderService extends InterfaceService {

    private triggerEvent = new BehaviorSubject(null);
    triggerEvent$ = this.triggerEvent.asObservable();

    coreUrlPrefix = 'e5ec7051';

    constructor(private http: HttpClient) {
        super();
    }

    getApiCall(url){
        return this.http.post(this.getApiUrl(url),[]);
    }

    postApiCall(url,params){
        return this.http.post(this.getApiUrl(url),params);
    }

    addEditEmailTemplate(params){
        return this.http.post(this.getApiUrl('save_templates'),params);
    }

    getReplacedMessage(params){
        return this.http.post(this.getApiUrl('replaced_email_message'),params);
    }

    checkTemplateStatus(params){
        return this.http.post(this.getApiUrl('check_template_status'),params);
    }

    checkFolderStatus(params){
        return this.http.post(this.getApiUrl('check_folder_status'),params);
    }

    checkUniqueFolderName(params){
        return this.http.post(this.getApiUrl('check_unique_folder_name'),params);
    }

    checkUniqueTemplateName(params) {
        return this.http.post(this.getApiUrl('check_unique_template_name'),params);
    }

    loadData(value) {
        this.triggerEvent.next(value);
    }

    checkTemplateName(params?: Object): Observable<any> {
        return this.http.get(this.getApiUrl(`checkSetupOptionsExists`, params));
    }

    checkProposalTemplateExists(value) {
        return this.http.get(this.getApiUrl(`checkProposalTemplateExists`, value));
    }

    postApiV5Call(url,params){
        return this.http.post(this.getFleetApiUrl(url),params);
    }
    getApiV5Call(url){
        return this.http.post(this.getFleetApiUrl(url),[]);
    }
    checkTemplateFormStatus(id){
        return this.http.get(this.getFleetApiUrl(`${id}/is_vehicle_form_deletable`))
    }

    // Common API methods :

    getFolderList(urlFormatter: string, params?: object): Observable<any> {
        return this.http.get(this[urlFormatter](`${this.coreUrlPrefix}/folders`, params));
    }

    getChildFolderList(urlFormatter: string, params?: object): Observable<any> {
        return this.http.get(this[urlFormatter](`${this.coreUrlPrefix}/folders/${params['id']}`, params['queryParam']));
    }

    addOrEditFolder(urlFormatter: string, payload: object): Observable<any> {
        return this.http.post(this[urlFormatter](`${this.coreUrlPrefix}/folder`), payload);
    }

    deleteFolder(urlFormatter: string, params: object): Observable<any> {
        return this.http.delete(this[urlFormatter](`${this.coreUrlPrefix}/folder/${params['deleteId']}/delete`, {tableType: params['tableType']}));
    }

    checkBeforeFolderDelete(urlFormatter: string, params: object): Observable<any> {
        return this.http.get(this[urlFormatter](`${this.coreUrlPrefix}/${params['folderId']}/check_folder_delete`, params));
    }

    checkIsFolderExists(urlFormatter: string, params: object): Observable<any> {
        return this.http.get(this[urlFormatter](`${this.coreUrlPrefix}/is_folder_exists`, params));
    }

    moveFolderOrTemplate(urlFormatter: string, params: object): Observable<any> {
        return this.http.post(this[urlFormatter](`${this.coreUrlPrefix}/move_templates`), params);
    }
}
