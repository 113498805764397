import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ViewVehicleService } from '@app/features/fleet-management/add-vehicle/services/view-vehicle-service';
import * as moment from 'moment';
import {
    FleetLiveMapSidepanelComponent
} from "@app/features/fleet-management/fleet-live-map/fleet-live-map-sidepanel/fleet-live-map-sidepanel.component";
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import { FleetMapViewComponent } from '../fleet-map-view/fleet-map-view.component';



declare let L;

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss','../../../features/fleet-management/fleet-live-map/fleet-live-map-common.scss']
})
export class TripsComponent implements OnInit {

  map: any;
  private currentMarker: L.CircleMarker | undefined;  // Store the marker reference
  private clickListener: () => void;
  config: any;
  selectedCustomDate: Date;
  maxDate = new Date();
  minDate: Date;
  bsDateConfig: any;
  vehicleId: any;
  trackingDetails: Object[] = [];
  trackingRoute: Object[] = [];
  currentRoute: any;
  selectedRoute: any;
  tripRoutes = [];
  iconStart: any;
  iconStop: any;
  selectedLine: any;
  customSelectedDate: any = '';
  combinedData = [];
  tripsLayer: any = false;
  selectedLayer: any = false;
  selectedIndex = -1;
  summary: any;
  mapZoomHappened: boolean = false;
  mapZoomHappenedByFit: boolean = false;
  viewMode:boolean = false;
  selectValidDate: any;
  showRoute:boolean = false;
  loading:boolean = false;
  boundCollection:Array<any> = [];
  mapInitated = false;
  branchLocation: any;
  mapResizeDone = false;
  resetToBound:any = {};
  showDropdown = false;
  hoveredIndex: number | null = null;
  moveNext = true;
  movePrevious = true;
  todayDate:any;
  startIcon = {
    icon: L.divIcon({
      className: 'customer-marker',
      html: ` <div class="status-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                      <g filter="url(#filter0_d_1169_41171)">
                        <circle cx="16" cy="12" r="12" fill="#408962"/>
                        <circle cx="16" cy="12" r="11" stroke="white" stroke-width="2"/>
                      </g>
                      <defs>
                        <filter id="filter0_d_1169_41171" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="4"/>
                          <feGaussianBlur stdDeviation="2"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1169_41171"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1169_41171" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                      </div>`,
      iconSize: [10, 12],
      iconAnchor: [15, 12]
    })
  };
  stopIcon = {
    icon: L.divIcon({
      className: 'customer-marker',
      html: `<div class="status-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <g filter="url(#filter0_d_1169_41165)">
                  <circle cx="16" cy="12" r="12" fill="#BE6D6D"/>
                  <circle cx="16" cy="12" r="11" stroke="white" stroke-width="2"/>
                </g>
                <defs>
                  <filter id="filter0_d_1169_41165" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1169_41165"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1169_41165" result="shape"/>
                  </filter>
                </defs>
              </svg>
                    </div>`,
      iconSize: [10, 12],
      iconAnchor: [15, 12]
    })
  };

  @Input() routingId = '';
  @Input() screen = '';

  defaultIconsList= [];
  bsModalRef: BsModalRef;
  @ViewChild(FleetMapViewComponent) FleetMapViewComponent;
  constructor(
    private router: Router,
    private httpService: ViewVehicleService,
    private renderer: Renderer2,
    private el: ElementRef,
    private modalService: SidepanelModalService,
  ) {
    this.vehicleId = this.screen == 'Dashboard_trips' ? this.routingId : this.router.url.split('/')[3];
    this.minDate = new Date();
    this.minDate.setDate(this.maxDate.getDate() - 90);
  }

  ngOnInit() {

      this.httpService.getLiveMapData().subscribe(result => {
          this.defaultIconsList = result['defaultIcons'];
      });


    this.httpService.getBranchLocation().subscribe(res => {
      this.branchLocation = res['officeLocation'];
      this.updateMapRadious();
    });
    this.todayDate = new Date().toDateString();
    if(this.screen != 'Dashboard_trips'){
      this.showRoute = true;
      this.selectedCustomDate = new Date();
      let data = {
        date: moment(this.selectedCustomDate).format('DD-MM-YYYY'),
        id: this.vehicleId
      };
      this.getMap(data);
    }else{
      this.startIcon = {
        icon: L.divIcon({
          className: 'customer-marker',
          html: `<div class="status-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="20" viewBox="0 0 20 20"
                              fill="none">
                              <rect width="20" height="20" rx="10" fill="#60A369" />
                              <path
                                  d="M11.0203 4.1158C10.8805 3.78009 10.5508 3.56229 10.1871 3.5623C9.82337 3.56232 9.49364 3.78014 9.35375 4.11587L5.19689 14.0277C5.02902 14.4273 5.16888 14.8869 5.52657 15.1287C5.88426 15.3705 6.36386 15.3225 6.66961 15.0168L10.1868 11.4996L13.7037 15.0165C14.0094 15.3222 14.487 15.3682 14.8467 15.1284C15.2064 14.8886 15.3443 14.4269 15.1765 14.0273L11.0203 4.1158Z"
                                  fill="white" />
                          </svg>
                        </div>`,
          iconSize: [10, 12],
          iconAnchor: [15, 12]
        })
      };
      this.stopIcon = {
        icon: L.divIcon({
          className: 'customer-marker',
          html: ` <div class="status-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28"
                                  fill="none">
                                  <rect x="1" y="1" width="26" height="26" rx="13" fill="white" />
                                  <rect x="1" y="1" width="26" height="26" rx="13" stroke="white" stroke-width="2" />
                                  <path
                                      d="M14 24.5C16.7848 24.5 19.4555 23.3938 21.4246 21.4246C23.3938 19.4555 24.5 16.7848 24.5 14C24.5 11.2152 23.3938 8.54451 21.4246 6.57538C19.4555 4.60625 16.7848 3.5 14 3.5C11.2152 3.5 8.54451 4.60625 6.57538 6.57538C4.60625 8.54451 3.5 11.2152 3.5 14C3.5 16.7848 4.60625 19.4555 6.57538 21.4246C8.54451 23.3938 11.2152 24.5 14 24.5ZM11.375 10.0625H16.625C17.351 10.0625 17.9375 10.649 17.9375 11.375V16.625C17.9375 17.351 17.351 17.9375 16.625 17.9375H11.375C10.649 17.9375 10.0625 17.351 10.0625 16.625V11.375C10.0625 10.649 10.649 10.0625 11.375 10.0625Z"
                                      fill="#BE6D6D" />
                              </svg>
                          </div>`,
          iconSize: [10, 12],
          iconAnchor: [15, 12]
        })
      };
    }
  }

  updateMapRadious() {
    if(this.branchLocation && this.branchLocation.length) {
      if(this.map) {
        this.map.setView([this.branchLocation[0], this.branchLocation[1]], 10);
      } else {
        let self = this;
        setTimeout(function (){
          self.map.setView([self.branchLocation[0], self.branchLocation[1]], 10);
        },1000)
      }
    }
  }

  ngAfterContentInit() {
      let self = this;
      setTimeout(function (){
          self.setMapConfig(51.509865, -0.118092)
      },100);
  }

    updateMapBounds() {
        if(this.map && !this.mapResizeDone) {
            this.map.invalidateSize();
            this.mapResizeDone = true;
        }
        if(this.boundCollection.length) {
            let bound = L.latLngBounds(this.boundCollection);
            if(this.map) {
              this.map.fitBounds(bound,{ padding: [10, 10] });
            } else {
              let self = this;
              setTimeout(function (){
                self.map.fitBounds(bound,{ padding: [10, 10] });
              },1000)
            }
        }
    }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['routingId'] && this.routingId) {
        this.showRoute = true;
        this.vehicleId = this.routingId;
        this.trackingDetails = [];
        this.removeLayers();
        this.selectedCustomDate = new Date();
        this.moveNext = true;
        let data = {
        date:moment(this.selectedCustomDate).format('YYYY-MM-DD'),
        id:this.vehicleId
        };
        this.getMap(data);
    }
}

  updateTripRoutes() {
    let combinedData = [];
    this.boundCollection = [];
    this.removeLayers();
    if (this.trackingDetails && this.trackingDetails.length) {
      this.trackingDetails.forEach(tripData => {
        if (tripData['type'] == 'trip') {
          let elementIndex = tripData['elementIndex'];
          let startPositionId = tripData['startPositionId'];
          let endPositionId = tripData['endPositionId'];
          let startIndex = this.trackingRoute.findIndex(route => route['id'] == startPositionId);
          let endIndex = this.trackingRoute.findIndex(route => route['id'] == endPositionId);
          let tripCoordinates = this.trackingRoute.slice(startIndex, endIndex).map(value => { return [value['latitude'], value['longitude']] });
          combinedData.push({ elementIndex: elementIndex, positions: tripCoordinates });
          this.boundCollection = this.boundCollection.concat(tripCoordinates);
        }
      });
      this.combinedData = combinedData;
      this.updateMaps(-1);
    }
  }

  removeLayers() {
    if (this.tripsLayer !== false) {
      this.tripsLayer.remove();
    }
    if (this.selectedLayer !== false) {
      this.selectedLayer.remove();
      this.selectedLayer = false;
    }
  }

  updateMaps(elementIndex) {
    if (this.combinedData && this.combinedData.length) {
      this.tripsLayer = L.featureGroup();
      for (let i = 0; i < this.combinedData.length; i++) {
        let positions = this.combinedData[i]['positions'];
        if (positions && positions.length) {
          let isSelectedRoute = this.combinedData[i]['elementIndex'] == elementIndex;
          let routeColor = isSelectedRoute ? '#408962' : '#A2A2A2';
          let polyline = L.polyline(this.combinedData[i]['positions'], { color: routeColor, dashArray: '5, 10', className: 'moving-dots', weight: 5, delay: 640 });
          if (isSelectedRoute) {
            this.selectedLayer = L.featureGroup();
            let currentIndex = this.trackingDetails.findIndex(track => track['elementIndex'] == elementIndex);
            let tripData = this.trackingDetails[currentIndex];
            this.iconStart = L.marker([tripData['startLat'], tripData['startLon']], this.startIcon).addTo(this.selectedLayer);
            this.iconStop = L.marker([tripData['endLat'], tripData['endLon']], this.stopIcon).addTo(this.selectedLayer);
            polyline.addTo(this.selectedLayer);
            this.selectedLayer.addTo(this.map);
          } else {
            polyline.addTo(this.tripsLayer);
          }
        }
      }
      this.tripsLayer.addTo(this.map);
      if (this.selectedLayer !== false) {
        if (!this.mapZoomHappened) {
          this.mapZoomHappenedByFit = true;
          this.map.fitBounds(this.selectedLayer.getBounds());
        }
        this.selectedLayer.bringToFront();
      } else {
        if (!this.mapZoomHappened) {
          this.mapZoomHappenedByFit = true;
          this.map.fitBounds(this.tripsLayer.getBounds());
        }
      }
    }
  }

  dateCreated($event) {
    $('#caret-up').addClass('up-caret-icon');
    this.selectedCustomDate = $event.startDate;
    let currentDate = new Date(this.selectedCustomDate).toDateString();
    let restrictDate = new Date(this.minDate).toDateString();
    if(currentDate == restrictDate){
      this.movePrevious = false;
    }else{
      this.movePrevious = true;
    }
    if(currentDate != this.todayDate) {
      this.moveNext = false;
    }else this.moveNext = true;
    let backendDate = moment(this.selectedCustomDate).format('YYYY-MM-DD');
    let data = {
      date: backendDate,
      id: this.vehicleId
    };
    this.mapZoomHappened = false;
    this.getMap(data);
  }

  getMap(data) {
    this.loading = true;
    if (this.currentRoute) this.map.removeLayer(this.currentRoute);
    if (this.selectedRoute) this.map.removeLayer(this.selectedRoute);
    if (this.iconStart) this.map.removeLayer(this.iconStart);
    if (this.iconStop) this.map.removeLayer(this.iconStop);
    if(this.screen != 'Dashboard_trips'){
      this.httpService.getVehicleTripRoutes(data).subscribe((res: any) => {
        this.trackingRoute = res['trips'];
        this.updateTripRoutes();
      })
      this.httpService.getTrackingDetails(data).subscribe((res: any) => {
        this.loading = false;
        this.trackingDetails = res['details'];
        let restrictDate = res['tripDate'];
        this.minDate = new Date(restrictDate['date']);
        let [start, end] = res['summary']['activityTime'].split('-')
        res['summary']['totalTime'] = this.calculateTimeDifference(start,end)
        this.summary = res['summary'];
      })
    }else{
      this.httpService.getVehicleTripRoutes(data).subscribe((res:any)=>{
        this.trackingRoute = res['trips'];
        this.updateTripRoutes();
      })
      this.httpService.getTrackingDetails(data).subscribe((res:any) => {
        this.loading = false;
        this.trackingDetails = res['details'];
        let restrictDate = res['tripDate'];
        this.minDate = new Date(restrictDate['date']);
        let [start, end] = res['summary']['activityTime'].split('-')
        res['summary']['totalTime'] = this.calculateTimeDifference(start,end)
        this.summary = res['summary'];
      })
    }


  }

  calculateTimeDifference(startTime, endTime) {
    const start = moment(startTime, 'h:mm A');
    const end = moment(endTime, 'h:mm A');
    const duration = moment.duration(end.diff(start));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours} h ${minutes} min`;
  }

  setRoute(track) {
    if (track['type'] == 'trip') {
      if (this.selectedIndex != track['elementIndex']) {
        this.selectedIndex = track['elementIndex'];
      } else {
        this.selectedIndex = -1;
      }
      this.removeLayers();
      this.updateMaps(this.selectedIndex);
    }
  }

  changeDate(event) {
    let dateObject = new Date(this.selectedCustomDate);
    if (event === 'before') {
      dateObject.setDate(dateObject.getDate() - 1);
    } else {
      dateObject.setDate(dateObject.getDate() + 1);
    }
    this.selectedCustomDate = dateObject;
    let currentDate = new Date(this.selectedCustomDate).toDateString();
    let restrictDate = new Date(this.minDate).toDateString();
    if(currentDate == restrictDate){
      this.movePrevious = false;
    }else{
      this.movePrevious = true;
    }
    if(currentDate == this.todayDate){
      this.moveNext = true;
    }else this.moveNext = false;
    const backendDate = moment(this.selectedCustomDate).format('YYYY-MM-DD');

    let data = {
      date: backendDate,
      id: this.vehicleId
    };
    this.customSelectedDate = backendDate;
    this.getMap(data);
  }

  displayViewMode(data){

  }

  toggleViewMode(){
    this.viewMode = !this.viewMode;
  }

  getSelectedLocation(){
    if(this.selectedLayer !== false){
      this.updateMapBounds();
    }
  }

  setMapConfig(latitude, longitude) {
    let self = this;
    // Maps
    $(function () {
      self.config = {
        center: [latitude, longitude],
        zoom: 10,
        layers: [],
        inertia: true,
        inertiaDeceleration: 10000,
        inertiaMaxSpeed: 1000,
        bubblingMouseEvents: true,
        doubleClickZoom: false // disable default double-click zoom
      };
      if (self.map != undefined) self.map.remove();
      self.map = L.map('map', self.config);
      self.map.markerZoomAnimation
      self.map.keyboard.disable();
      self.map.scrollWheelZoom.enable();
      self.map.boxZoom.disable();
      self.map.on('zoom', function () {
        if (!self.mapZoomHappenedByFit) {
          self.mapZoomHappened = true;
        } else {
          self.mapZoomHappenedByFit = false;
        }
      });
      self.mapInitated = true;
      L.tileLayer('https://map.commusoft.net/styles/com-streets/{z}/{x}/{y}.png', { attribution: '' }).addTo(self.map);
        let clickTimeout: any;
        // self.map.on('moveend', function(e) {
        //     self.removeMarker();  // if you need dragend remove logic enable this
        // });
        self.map.on('click', (e: any) => {
            if (clickTimeout) {
                clearTimeout(clickTimeout);
            }
            clickTimeout = setTimeout(() => {
                clickTimeout = null;
                self.onMapClick(e);
            }, 250);
        });

        self.map.on('dblclick', (e: any) => {
            if (clickTimeout) {
                clearTimeout(clickTimeout);
                clickTimeout = null;
            }
            self.removeMarker();
            self.map.setZoom(self.map.getZoom() + 1);
        });
    });


  }

    private removeMarker(): void {
        if (this.currentMarker) {
            this.currentMarker.remove();
            this.currentMarker = undefined;
        }
        if (this.clickListener) {
            this.clickListener();
            this.clickListener = null;
        }
    }

    private onMapClick(e: any): void {
        const latlng = e.latlng;
        const zoomLevel = this.map.getZoom();
        const data = {
            latitude: latlng.lat,
            longitude: latlng.lng,
            zoomLevel: zoomLevel,
        };

        if (this.currentMarker) {
            this.currentMarker.remove();
        }

        this.currentMarker = this.createMarker(latlng);
        this.updatePopupContent(this.getLoadingPopupContent());

        this.httpService.getLiveAddress(data).subscribe(
            (response) => {
                if(response.hasOwnProperty('display_name')){
                    const address = response.display_name;
                    const properties = [
                        'name', 'amenity', 'building', 'office', 'tourism',
                        'place', 'road', 'suburb', 'village', 'town',
                        'city', 'shop', 'landuse', 'natural', 'historic',
                        'leisure', 'man_made', 'waterway', 'railway',
                        'aeroway', 'public_transport', 'boundary', 'military',
                        'healthcare'
                    ];
                    let placeName = 'Unknown Place';
                    for (const property of properties) {
                        if (response.address[property]) {
                            placeName = response.address[property];
                            break;
                        }
                    }
                    const capitalizeFirstLetter = (str: string): string => {
                        if (str.length === 0) return str;
                        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                    };
                    placeName = capitalizeFirstLetter(placeName);
                    this.updatePopupContent(this.getAddressPopupContent(placeName,address));
                    this.attachEventListeners(response);
                }else{
                    this.updatePopupContent(this.getAddressPopupContent('','<p>Something went wrong. Please try again.</p>'));
                }
            },
            (error) => {
                console.error('Error fetching address:', error);
                this.updatePopupContent(this.getAddressPopupContent('','<p>Something went wrong. Please try again.</p>'));
            }
        );

        if (this.clickListener) {
            this.clickListener();
        }
    }



    private createMarker(latlng: any): L.CircleMarker {
        return L.circleMarker(latlng, {
            radius: 8,
            fillColor: '#505B65',
            color: '#fff',
            weight: 2,
            opacity: 1,
            fillOpacity: 1,
        }).addTo(this.map);
    }

    private updatePopupContent(content: string): void {
        this.currentMarker.bindPopup(content, {
            className: 'live-map-custom-popup-class',
            closeButton : false,
        }).openPopup();
    }
    private getLoadingPopupContent(): string {
        return `
        <div class="map_search_result cursor-not-allowed pointer-events-none">
            <div class="selected_vehicle_card_box">
                <div class="card_tilte">
                    <div class="animated-background w-80 loading-height"></div>
                    <div class="animated-background w-20 loading-height"></div>
                    <div class="animated-background w-100 loading-height"></div>
                </div>
                <div class="card_action">
                    ${this.getActionSteps()}
                </div>
            </div>
        </div>
    `;
    }

    private getAddressPopupContent(PlaceName:string,address: string): string {
        return `
        <div class="map_search_result">
            <div class="selected_vehicle_card_box">
                <div class="card_tilte">
                    <p><strong>${PlaceName}</strong></p>
                    <p>${address}</p>
                </div>
                <div class="card_action">
                    ${this.getActionSteps()}
                </div>
            </div>
        </div>
    `;
    }

    private getActionSteps(): string {
        return `
        <div class="close-btn-section close-btn">
            <i id="fleet-modal-popup-close-btn" class="far fa-xmark close-icon" aria-hidden="true"></i>
        </div>
        <!--<div class="card_action_steps">
            <p><i class="action_icon_text fa-regular fa-swap-arrows"></i></p>
            <p class="action_desc_text">Check distance</p>
        </div>-->
        <div class="card_action_steps" id="save-location">
            <p><i class="action_icon_text fa-solid fa-bookmark"></i></p>
            <p class="action_desc_text">Save location</p>
        </div>
        <!--<div class="card_action_steps">
            <p><i class="action_icon_text fa-solid fa-user"></i></p>
            <p class="action_desc_text">Send to customer</p>
        </div>-->
       <!-- <div class="card_action_steps disabled">
            <p><i class="action_icon_text fa-solid fa-car-side"></i></p>
            <p class="action_desc_text">Send to driver</p>
        </div>-->
    `;
    }
    private attachEventListeners(data): void {
        // Add event listener and store the reference so it can be removed later
        this.clickListener = this.renderer.listen(this.el.nativeElement, 'click', (event) => {
            const target = event.target as HTMLElement;

            if (target && target.closest('#save-location')) {
                this.savedLocation(data);
            }

            if (target && target.closest('#close')) {
                this.removeMarker();
            }
        });
    }

    private savedLocation(locationData): void {
        if(!this.modalService.isOpen()){
            const initialState = {
                title : 'Save.location',
                addressDetails : locationData,
                defaultIconsList: this.defaultIconsList
            };
            this.bsModalRef = this.modalService.show(FleetLiveMapSidepanelComponent, {
                initialState: initialState
            });
            this.bsModalRef.content.closeBtnName = 'Close';
            this.bsModalRef.content.onClose.subscribe(result => {
                if(result){
                    this.removeMarker();
                    this.FleetMapViewComponent.getMapPinData();
                }
            });
        }
    }

    @HostListener('document:keydown', ['$event'])
    @HostListener('document:click', ['$event'])
    handleGlobalEvents(event: KeyboardEvent | MouseEvent) {
        if (event instanceof KeyboardEvent) {
            switch (event.key) {
                case 'Escape':
                case 'Esc':
                    this.removeMarker();
                    break;
                default:
                    // Handle other keyboard events if needed
                    break;
            }
        } else if (event instanceof MouseEvent) {
            const targetElement = event.target as HTMLElement;
            if (targetElement && targetElement.id === 'fleet-modal-popup-close-btn') {
                this.removeMarker();
            }
        }
    }

    ngOnDestroy() {
        if(this.map != undefined) {
            this.map.off();
            this.map.remove();
        }
    }

}


