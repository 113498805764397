import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {Subject} from "rxjs/index";
import {BsModalRef} from 'ngx-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {TimelineService} from "@app/shared/services/timeline.service";

@Component({
    selector: 'app-view-attach-file',
    templateUrl: './view-attach-files.component.html',
    styleUrls: ['./view-attach-files.component.scss'],
    styles: []
})
export class ViewAttachFilesComponent implements OnInit {
    public onClose: Subject<boolean>;
    data: any;
    createdDetails: any;
    Url: any;
    filesArray: any;
    index: any;
    clone: any;


    constructor(private ts: TimelineService,
                public bsModalRef: BsModalRef,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.filesArray;
        this.clone;
        this.Url = this.sanitizer.bypassSecurityTrustResourceUrl(this.clone.fileLocation);
        this.onClose = new Subject();
        this.clone['createdDateTime'] = this.ts.convertTimeLineDateTime(this.clone['createdDateTime']);
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode == 39 || event.keyCode == 40) { //right and down key
            this.fileRightArrow();
        }
        else if (event.keyCode == 37 || event.keyCode == 38) { //left and up key
            this.fileLeftArrow();
        }
    }

    fileRightArrow() {
        if (this.filesArray.length > 0 && this.filesArray.length - 1 > this.index) {
            this.index++;
            this.selectedIndex(this.index)
        }
    }

    fileLeftArrow() {
        if (this.filesArray.length > 0 && this.index > 0) {
            this.index--;
            this.selectedIndex(this.index)
        }
    }

    selectedIndex(currentIndex) {
        if (this.filesArray[currentIndex]) {
            let findNextImage = this.filesArray[currentIndex]
            this.clone['fileLocation'] = findNextImage['fileLocation'];
            this.clone['fileName'] = findNextImage['fileName'];
            this.clone['createdDateTime'] = this.ts.convertTimeLineDateTime(findNextImage['createdDateTime']);
        }
    }
    async attachFileDownload(imageSrc) {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = this.clone.fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

}
