import { OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { TimelineService } from '@app/shared/services/timeline.service';
var ViewJobPartsComponent = /** @class */ (function () {
    function ViewJobPartsComponent(bsModalRef, sanitizer, ts, cdr) {
        this.bsModalRef = bsModalRef;
        this.sanitizer = sanitizer;
        this.ts = ts;
        this.cdr = cdr;
        this.jobPartStatus = '';
        this.poDetails = [];
    }
    ViewJobPartsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.jobPartDetails.forEach(function (part) {
            if (part.fulFillmentType === "OrderFromSupplier") {
                _this.ts.getJobPartsPoDetails(part.jobPartId, part.fulFillmentId, _this.jobId).subscribe(function (data) {
                    part.poDetails = data && data.purchaseOrderDetails ? data.purchaseOrderDetails : [];
                    _this.cdr.detectChanges(); // Manually trigger change detection
                }, function (error) {
                    console.error("Error fetching purchase order details", error);
                    part.poDetails = []; // Set an empty array on error
                    _this.cdr.detectChanges(); // Ensure UI updates even on error
                });
            }
            else {
                part.poDetails = []; // Set default empty array if no API call needed
            }
            var matchingUser = _this.selectedUser.find(function (user) { return user.id === part.userId; }); // Find matching user by userid
            part.jobPartName = _this.data[part.jobPartId].jobPartName;
            part.requestedQty = (_this.jobPartStatus == 'Requested') ? part.qty : _this.data[part.jobPartId].requestedQty;
            part.profilePic = matchingUser ? matchingUser.profilepicture : null;
            part.requestBy = matchingUser ? matchingUser.username : '';
            part.createdDateTime = _this.ts.convertTimeLineDateTime(part.updated);
            part.quantity = _this.formatQty(part.qty);
        });
    };
    ViewJobPartsComponent.prototype.formatQty = function (qty) {
        var numberQty = parseFloat(qty);
        if (isNaN(numberQty)) {
            return '0';
        }
        if (Number.isInteger(numberQty)) {
            return numberQty.toFixed(0); // Show as 2
        }
        else {
            return numberQty.toFixed(2); // Show as 2.10 or 2.34
        }
    };
    ViewJobPartsComponent.prototype.dateFormate = function (date) {
        return this.ts.convertTimeLineDateTime(date);
    };
    return ViewJobPartsComponent;
}());
export { ViewJobPartsComponent };
