import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
import { StartSequenceBlockModel } from "@app/workflow-common/services/models/StartSequenceBlockModel";
var StartSequenceBlockWidgetComponent = /** @class */ (function () {
    function StartSequenceBlockWidgetComponent(formBuilder, workflowService) {
        this.formBuilder = formBuilder;
        this.workflowService = workflowService;
        this.close = new EventEmitter();
        this.contactsList = [];
        this.isLoading = true;
        this.blockData = {};
        this.usersList = [];
        this.sequenceListData = [];
        this.isFormSubmitted = false;
        this.inputModel = undefined;
        this.showDeleteBtn = false;
        this.select2Config = {};
        this.validation = false;
        this.activeDagSource = [];
        this.onChangeSequenceLoading = false;
        this.selectedWorkflowTemplateList = [];
        this.displayBlocks = {
            delayBlock: {
                text: 'Delay',
                iconClass: 'fas fa-history',
                textClass: null,
            },
            emailBlock: {
                text: 'Email',
                iconClass: 'fas fa-envelope',
                textClass: null,
            },
            smsBlock: {
                text: 'SMS',
                iconClass: 'fas fa-comment',
                textClass: null,
            },
            officeTaskBlock: {
                text: 'Office task',
                iconClass: 'fas fa-tasks',
                textClass: null,
            },
            notificationBlock: {
                text: 'Notification task',
                iconClass: 'fas fa-bell',
                textClass: null,
            },
            scheduledActivityBlock: {
                text: 'Schedule activity task',
                iconClass: 'fas fa-calendar-alt',
                textClass: null,
            }
        };
    }
    StartSequenceBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.contactsList = CommonDataSource.contactsList;
                        this.blockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                        blockObject = tslib_1.__assign({}, this.blockObject);
                        modelData = blockObject['model'] ? blockObject['model'] : undefined;
                        if (modelData) {
                            this.showDeleteBtn = true;
                        }
                        return [4 /*yield*/, this.getSequences()];
                    case 1:
                        _a.sent();
                        this.inputModel = new StartSequenceBlockModel(modelData);
                        this.form = this.formBuilder.group({
                            templateId: new FormControl(this.inputModel.templateId, [Validators.required]),
                            contactId: new FormControl(this.inputModel.contactId, [Validators.required]),
                            templateName: new FormControl(this.inputModel.templateName),
                        });
                        if (this.inputModel.templateId) {
                            this.onChangeSequence(this.inputModel.templateId);
                        }
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    StartSequenceBlockWidgetComponent.prototype.onChangeSequence = function (templateId) {
        var _this = this;
        this.form.get('templateId').setValue(templateId);
        this.onChangeSequenceLoading = true;
        if (!templateId) {
            this.activeDagSource = [];
            this.onChangeSequenceLoading = false;
            return;
        }
        var dags = [], emailSmsTemplatesId = [];
        var activeSequenceItem = this.sequenceListData.find(function (item) { return item.id == parseInt(templateId); });
        this.form.get('templateName').setValue(activeSequenceItem['text']);
        var dagSource = JSON.parse(activeSequenceItem['dagsource']);
        var initBlock = dagSource['dagObject']['cs_initializer'];
        var getChild = function (child) {
            child.forEach(function (x) {
                if (dagSource['blockObjects'].hasOwnProperty(x)) {
                    dags.push(dagSource['blockObjects'][x]);
                    if (dagSource['dagObject'][x]) {
                        getChild(dagSource['dagObject'][x]);
                    }
                }
            });
        };
        getChild(initBlock);
        dags.forEach(function (dag) {
            if (dag['name'] == 'emailBlock' || dag['name'] == 'smsBlock') {
                emailSmsTemplatesId.push(dag['model']['templateId']);
            }
        });
        this.workflowService.getEmailSmsTemplatesByIds({ ids: emailSmsTemplatesId })
            .subscribe({
            next: function (resp) {
                _this.selectedWorkflowTemplateList = resp['templates'];
                _this.activeDagSource = dags;
                _this.onChangeSequenceLoading = false;
            },
            error: function () {
                _this.onChangeSequenceLoading = false;
            }
        });
    };
    StartSequenceBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = new StartSequenceBlockModel(this.form.value);
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    StartSequenceBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    StartSequenceBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    StartSequenceBlockWidgetComponent.prototype.getSequences = function () {
        var _this = this;
        this.isLoading = true;
        return this.workflowService.getWorkflowSetupSequencesList({
            sequenceType: 'Opportunity'
        }).toPromise().then(function (resp) {
            _this.sequenceListData = resp['workflow_sequence'];
        });
    };
    StartSequenceBlockWidgetComponent.prototype.getDisplayText = function (block) {
        var model = block.model, name = block.name;
        if (name == 'delayBlock') {
            return model.interval + ' ' + model.unit;
        }
        else if (name == 'emailBlock' || name == 'smsBlock') {
            if (this.selectedWorkflowTemplateList[model['templateId']]) {
                return this.selectedWorkflowTemplateList[model['templateId']]['templateName'];
            }
        }
        else if (name == 'officeTaskBlock' || name == 'notificationBlock') {
            return model['textMessage'];
        }
        else if (name == 'scheduledActivityBlock') {
            return model['description'];
        }
        else {
            return '';
        }
    };
    return StartSequenceBlockWidgetComponent;
}());
export { StartSequenceBlockWidgetComponent };
