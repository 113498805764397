/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./directives/material-directives/cs-toast-box/cs-toast-box.component.ngfactory";
import * as i3 from "./directives/material-directives/cs-toast-box/cs-toast-box.component";
import * as i4 from "./services/utility-services/cs-toast-box.service";
import * as i5 from "./shared-modal/shared-modal.component.ngfactory";
import * as i6 from "./shared-modal/shared-modal.component";
import * as i7 from "./shared-modal/email-modal/email-listener.service";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "./app.component";
import * as i11 from "./core/settings/settings.service";
import * as i12 from "ngx-bootstrap/datepicker";
import * as i13 from "@ngx-translate/core";
import * as i14 from "./services/utility-services/cs-version.service";
import * as i15 from "./services/utility-services/session-log.service";
import * as i16 from "./shared-modal/service/shared-modal.service";
import * as i17 from "./features/jobs-tab/resolver/jobs-tab.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["csToastBox", ""]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onmouseover($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onmouseleave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CsToastBoxComponent_0, i2.RenderType_CsToastBoxComponent)), i1.ɵdid(1, 114688, null, 0, i3.CsToastBoxComponent, [i4.CsToastBoxService], { toastMessage: [0, "toastMessage"], showupDuration: [1, "showupDuration"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toastConfig.message; var currVal_1 = _co.toastConfig.duration; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-shared-modal", [], null, null, null, i5.View_SharedModalComponent_0, i5.RenderType_SharedModalComponent)), i1.ɵdid(2, 245760, null, 0, i6.SharedModalComponent, [i7.EmailListenerService, i1.ChangeDetectorRef, i4.CsToastBoxService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "a", [["id", "navigator"], ["style", "display: none"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.showToastMessage; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isAuthenticated; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "callAngular7Function"], ["window", "keyup"]], function (_v, en, $event) { var ad = true; if (("window:callAngular7Function" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).redirectToAngular7Module($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:keyup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onKeyup($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i10.AppComponent, [i1.LOCALE_ID, "$scope", i11.SettingsService, i4.CsToastBoxService, i9.PlatformLocation, i8.Router, i12.BsLocaleService, i13.TranslateService, i14.CsVersionService, i15.SessionLogService, i16.SharedModalService, i17.JobsTabService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
