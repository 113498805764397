import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { DagSourceModel } from "@app/workflow-common/services/DagSourceModel";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ScheduledActivityBlockModel } from "@app/workflow-common/services/models/ScheduledActivityBlockModel";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
import * as moment from "moment";
var ScheduledActivityBlockWidgetComponent = /** @class */ (function () {
    function ScheduledActivityBlockWidgetComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.close = new EventEmitter();
        this.usersList = [];
        this.scheduledActivityBlockData = {};
        this.isLoading = true;
        this.isFormSubmitted = false;
        this.showDeleteBtn = false;
        this.activityTypes = [];
        this.iconClassNames = {
            'Call': 'fa fa-phone',
            'Email': 'fa fa-envelope',
            'Meeting': 'fas fa-calendar-alt',
            'Task': 'fa fa-list-ul'
        };
        this.units = ['days', 'weeks', 'months'];
        this.timeModel = null;
    }
    ScheduledActivityBlockWidgetComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var blockObject, modelData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scheduledActivityBlockData = this.dagSourceModel.getBlocksSource().getBlockByKey(this.blockObject.key);
                        blockObject = tslib_1.__assign({}, this.blockObject);
                        modelData = blockObject['model'] ? blockObject['model'] : undefined;
                        this.inputModel = new ScheduledActivityBlockModel(modelData);
                        this.activityTypes = CommonDataSource.activityTypes;
                        if (modelData) {
                            this.showDeleteBtn = true;
                        }
                        return [4 /*yield*/, CommonDataSource.getUserData().then(function (userData) {
                                console.log('userData', userData);
                                var users = [];
                                var userRecords = userData.hasOwnProperty('userRecords') && Array.isArray(userData['userRecords']) ? userData['userRecords'] : [];
                                userRecords.forEach(function (user) {
                                    users.push({
                                        id: user.id,
                                        text: user.name
                                    });
                                });
                                _this.usersList = users;
                                _this.setSelect2AssigneeConfig();
                            })];
                    case 1:
                        _a.sent();
                        this.isLoading = false;
                        if (!this.inputModel.activityType) {
                            this.inputModel.activityType = this.activityTypes[0].id;
                        }
                        if (!this.inputModel.unit) {
                            this.inputModel.unit = this.units[0];
                        }
                        if (!this.inputModel.displaySelectedTime) {
                            this.timeModel = moment('08:00:00', 'HH:mm:ss').toDate();
                        }
                        else {
                            this.timeModel = moment(this.inputModel.displaySelectedTime, 'HH:mm:ss').toDate();
                        }
                        this.form = this.formBuilder.group({
                            'description': new FormControl(this.inputModel.description, [Validators.required]),
                            'activityType': new FormControl(this.inputModel.activityType),
                            'scheduleInterval': new FormControl(this.inputModel.scheduleInterval),
                            'unit': new FormControl(this.inputModel.unit),
                            'scheduleTime': new FormControl(this.inputModel.scheduleTime),
                            'displaySelectedTime': new FormControl(this.inputModel.displaySelectedTime),
                            'timeModel': new FormControl(this.timeModel),
                            'assigneeIds': new FormControl(this.inputModel.assigneeIds),
                            'notes': new FormControl(this.inputModel.notes)
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduledActivityBlockWidgetComponent.prototype.onChangeTime = function (time) {
        this.form.value['scheduleTime'] = moment(time, 'HH:mm:ss').toDate();
        console.log('time', time);
    };
    ScheduledActivityBlockWidgetComponent.prototype.selectedAssignee = function (event) {
        this.form.get('assigneeIds').setValue(event.map(function (i) { return parseInt(i); }));
    };
    ScheduledActivityBlockWidgetComponent.prototype.setSelect2AssigneeConfig = function () {
        this.select2AssigneeConfig = {
            width: '100%',
            data: this.usersList,
            tags: true,
            formatSelection: function (item) {
                return item.text;
            },
            formatResult: function (item) {
                return item.text;
            },
            multiple: true
        };
    };
    ScheduledActivityBlockWidgetComponent.prototype.setAssignedUserDisplayName = function () {
        var assigneeIds = this.inputModel.assigneeIds;
        if (assigneeIds.length == 0) {
            return [];
        }
        this.inputModel.assigneeDisplay = this.usersList.filter(function (user) {
            return assigneeIds.includes(user.id);
        }).map(function (item) { return item.text; });
    };
    ScheduledActivityBlockWidgetComponent.prototype.setActivityTypeDisplay = function () {
        var activityType = this.inputModel.activityType;
        this.inputModel.activityTypeDisplay = this.activityTypes.find(function (i) { return i.id == activityType; })['name'];
    };
    ScheduledActivityBlockWidgetComponent.prototype.save = function () {
        this.isFormSubmitted = true;
        this.inputModel = new ScheduledActivityBlockModel(this.form.value);
        if (this.form.value['displaySelectedTime']) {
            var time = moment(this.form.value['displaySelectedTime'], 'HH:mm:ss').toDate();
            // let isoDate = time.toISOString();
            this.inputModel.scheduleTime = moment.utc(time).format('HH:mm:ss');
        }
        else {
            var time = moment("08:00:00", 'HH:mm:ss').toDate();
            // let isoDate = time.toISOString(); this.settings._csLanguage
            this.inputModel.scheduleTime = moment.utc(time).format('HH:mm:ss');
        }
        this.inputModel.displayTime = moment(this.form.value['timeModel']).format('HH:mm');
        this.inputModel.displaySelectedTime = moment(this.form.value['timeModel']).format('HH:mm:ss');
        this.setAssignedUserDisplayName();
        this.setActivityTypeDisplay();
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'save_and_close',
            data: blockObject
        });
    };
    ScheduledActivityBlockWidgetComponent.prototype.closeModal = function () {
        this.close.emit({
            action: 'close',
            data: null
        });
    };
    ScheduledActivityBlockWidgetComponent.prototype.remove = function () {
        var blockObject = tslib_1.__assign({}, this.blockObject);
        blockObject.model = this.inputModel;
        this.close.emit({
            action: 'remove_block',
            data: blockObject
        });
    };
    return ScheduledActivityBlockWidgetComponent;
}());
export { ScheduledActivityBlockWidgetComponent };
