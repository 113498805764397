import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "@app/services";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-customfield-user',
  templateUrl: './customfield-user.component.html',
  styles: []
})
export class CustomfieldUserComponent implements OnInit {
    public userData : Array<object>=[];
    public showUserField = false;
    public userForm;
    public listIndex: string = '';
    @Input () parentForm;
    @Input () config;
    @Input () fieldData;
    @Input () pageIndex;
    @Input () isFormControlNeed = true;
    @Input () allowShowDeletedUser = false;
    @Output() valueEmitter: EventEmitter<any> = new EventEmitter();


    isDefaultUserId: boolean;

  constructor(private commonService: CommonService, private fb:FormBuilder) {}

    async ngOnInit() {
        let userId = '';

        if(this.pageIndex && this.pageIndex.hasOwnProperty('page') && this.pageIndex.hasOwnProperty('index'))
            this.listIndex = `_page${this.pageIndex.page + 1}_${this.pageIndex.index+1}`;

        if(this.fieldData && this.fieldData.hasOwnProperty('questionID') && this.parentForm){
            userId = this.parentForm.getRawValue()[this.fieldData['questionID']+this.listIndex];

            if((!userId) && this.fieldData.hasOwnProperty('defaultID') && this.fieldData['defaultID'])
                this.isDefaultUserId = this.fieldData.hasOwnProperty('defaultID') && this.fieldData['defaultID'] != userId;

            userId = userId ? userId : this.fieldData.hasOwnProperty('defaultID') ? this.fieldData['defaultID'] : '';
            this.getSelectedUser(userId);
        }
        else{
            userId = this.fieldData.hasOwnProperty('defaultID') ? this.fieldData['defaultID'] : '';
            this.isDefaultUserId = userId != '';
        }

        this.userForm = this.fb.group({
            [this.fieldData['questionID']+this.listIndex] : [userId]
        })

      await this.getUserDetails();
      if(userId)
        await this.checkUserIsActive(userId);

        this.showUserField = true;
    }

    getUserDetails() {
        return new Promise( (resolve, reject) => {
            this.commonService.getUsersList({sort: 'name:asc'})
            .toPromise()
            .then( (data) => {
                if (data) {
                    this.userData = data.userRecords;
                }
                resolve();
                },(err) => {
                    reject(err);
                })
        });
    }

    checkUserIsActive(userId) {
        return new Promise( (resolve, reject) => {
            if(userId == '') {
                resolve();
                return;
            }
            this.commonService.getIsUserActive(userId)
            .toPromise()
            .then( (data) => {
                    if (data.isDeleted && (this.allowShowDeletedUser || !this.isDefaultUserId)) {
                        this.userData.push(data);
                    }
                    else if(data.isDeleted && this.isDefaultUserId) {
                        let userFieldControl = this.userForm.get(this.fieldData['questionID']+this.listIndex);
                        if (userFieldControl) {
                            userFieldControl.setValue('');
                        }
                        this.getSelectedUser('');
                    }
                resolve();
            },(err) => {
                reject(err);
            })
        });
    }

    getSelectedUser(userId) {
        let userFieldControl = this.parentForm.get(this.fieldData['questionID']+this.listIndex);
        if (userFieldControl) {
            userFieldControl.setValue(userId);

            if(!this.listIndex){
                delete this.fieldData['defaults'];
                delete this.fieldData['defaultID'];
                delete this.fieldData['defaultValue'];
            }
        }
    }

    setDefaultUser (userId) {
        const user = userId === '' ? [] : this.userData.filter(user=> (user['id'] == userId));
        let changedValue = {};

        changedValue['defaultValue'] = user.length > 0 ? user[0]['name'] : null;
        changedValue['defaults'] = userId;
        changedValue['defaultID'] = userId;

        this.valueEmitter.emit(changedValue);
    }
}
