import { ComponentRef, TemplateRef, EventEmitter, Renderer2, RendererFactory2 } from '@angular/core';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { CLASS_NAME, modalConfigDefaults, SidepanelOptions, TRANSITION_DURATIONS } from './sidepanel-options';
import { BsModalRef } from 'ngx-bootstrap';
import { SidepanelComponent } from "@app/sidepanel/cs-sidepanel/sidepanel.component";
import { PopupPanelComponent } from "@app/sidepanel/cs-popup-panel/popup-panel.component";
import { CsModalBackdropComponent } from "@app/sidepanel/cs-backdrop/cs-modal-backdrop.component";
import { CsFullscreenPanelComponent } from "@app/sidepanel/cs-fullscreen-panel/cs-fullscreen-panel.component";
var SidepanelModalService = /** @class */ (function () {
    function SidepanelModalService(rendererFactory, clf) {
        this.clf = clf;
        // constructor props
        this.config = modalConfigDefaults;
        // tslint:disable-next-line:no-any
        this.onShow = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onShown = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onHide = new EventEmitter();
        // tslint:disable-next-line:no-any
        this.onHidden = new EventEmitter();
        this.isBodyOverflowing = false;
        this.originalBodyPadding = 0;
        this.scrollbarWidth = 0;
        this._backdropLoaderList = [];
        this.modalsCount = 0;
        this.lastDismissReason = '';
        this.loaders = [];
        this.modalVisibleStatus = false;
        //super(rendererFactory, clf);
        this._backdropLoader = this.initializeLoader();
        this._renderer = rendererFactory.createRenderer(null, null);
    }
    /** Shows a modal */
    // tslint:disable-next-line:no-any
    SidepanelModalService.prototype.show = function (content, config) {
        this.modalsCount++;
        this._createLoaders();
        this.config = Object.assign({}, modalConfigDefaults, config);
        this._showBackdrop();
        this.lastDismissReason = null;
        this.modalVisibleStatus = true;
        return this._showModal(content);
    };
    SidepanelModalService.prototype.hide = function (level) {
        var _this = this;
        if (this.modalsCount === 1 || this.config.backdrop && this.backdropRef) {
            this._hideBackdrop();
            this.resetScrollbar();
        }
        this.modalsCount = this.modalsCount >= 1 ? this.modalsCount - 1 : 0;
        setTimeout(function () {
            _this._hideModal(level);
            _this.removeLoaders(level);
        }, this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0);
        this.modalVisibleStatus = false;
    };
    SidepanelModalService.prototype.isOpen = function () {
        return this.modalVisibleStatus;
    };
    SidepanelModalService.prototype._showBackdrop = function () {
        var isBackdropEnabled = this.config.backdrop || this.config.backdrop === 'static';
        var isBackdropInDOM = !this.backdropRef || !this.backdropRef.instance.isShown || this.modalsCount > 1;
        if (this.modalsCount > 1) {
            console.log('back', isBackdropInDOM);
        }
        if (this.modalsCount >= 1 || this.config.backdrop && !this.backdropRef) {
            if (this.modalsCount === 1)
                this.removeBackdrop();
            if (isBackdropEnabled && isBackdropInDOM) {
                if (this.modalsCount > 1)
                    this._backdropLoader = this.initializeLoader();
                this._backdropLoader
                    .attach(CsModalBackdropComponent)
                    .to('body')
                    .show({ isAnimated: this.config.animated, modalsCount: this.modalsCount });
                this.backdropRef = this._backdropLoader._componentRef;
                //this._renderer.addClass(this.backdropRef.instance.element.nativeElement, 'modal-backdrop-leve-'+)
            }
            this._backdropLoaderList.push(this._backdropLoader);
        }
    };
    SidepanelModalService.prototype._hideBackdrop = function () {
        var _this = this;
        if (!this.backdropRef) {
            return;
        }
        this.backdropRef.instance.isShown = false;
        var duration = this.config.animated ? TRANSITION_DURATIONS.BACKDROP : 0;
        setTimeout(function () { return _this.removeBackdrop(); }, duration);
    };
    // tslint:disable-next-line:no-any
    SidepanelModalService.prototype._showModal = function (content) {
        var _this = this;
        var modalLoader = this.loaders[this.loaders.length - 1];
        if (this.config && this.config.providers) {
            for (var _i = 0, _a = this.config.providers; _i < _a.length; _i++) {
                var provider = _a[_i];
                modalLoader.provide(provider);
            }
        }
        var bsModalRef = new BsModalRef();
        var modalContainerRef = modalLoader
            .provide({ provide: SidepanelOptions, useValue: this.config })
            .provide({ provide: BsModalRef, useValue: bsModalRef })
            .attach(this.config.panelType === 'fullscreen' ? CsFullscreenPanelComponent : this.config.panelType === 'popup' ? PopupPanelComponent : SidepanelComponent)
            .to('body')
            .show({ content: content, isAnimated: this.config.animated, initialState: this.config.initialState, bsModalService: this });
        modalContainerRef.instance.level = this.getModalsCount();
        bsModalRef.hide = function () {
            var duration = _this.config.animated ? TRANSITION_DURATIONS.MODAL : 0;
            setTimeout(function () { return modalContainerRef.instance.hide(); }, duration);
        };
        bsModalRef.content = modalLoader.getInnerComponent() || null;
        bsModalRef.setClass = function (newClass) {
            modalContainerRef.instance.config.class = newClass;
        };
        return bsModalRef;
    };
    SidepanelModalService.prototype._hideModal = function (level) {
        var modalLoader = this.loaders[level - 1];
        if (modalLoader) {
            modalLoader.hide();
        }
    };
    SidepanelModalService.prototype.getModalsCount = function () {
        return this.modalsCount;
    };
    SidepanelModalService.prototype.setDismissReason = function (reason) {
        this.lastDismissReason = reason;
    };
    SidepanelModalService.prototype.removeBackdrop = function () {
        this._backdropLoader.hide();
        if (this.modalsCount === 0) {
            this.backdropRef = null;
            this._backdropLoaderList[this.modalsCount].hide();
        }
        this._backdropLoaderList.splice(this.modalsCount, 1);
    };
    /** Checks if the body is overflowing and sets scrollbar width */
    /** @internal */
    SidepanelModalService.prototype.checkScrollbar = function () {
        this.isBodyOverflowing = document.body.clientWidth < window.innerWidth;
        this.scrollbarWidth = this.getScrollbarWidth();
    };
    SidepanelModalService.prototype.setScrollbar = function () {
        if (!document) {
            return;
        }
        this.originalBodyPadding = parseInt(window
            .getComputedStyle(document.body)
            .getPropertyValue('padding-right') || '0', 10);
        if (this.isBodyOverflowing) {
            document.body.style.paddingRight = this.originalBodyPadding +
                this.scrollbarWidth + "px";
        }
    };
    SidepanelModalService.prototype.resetScrollbar = function () {
        document.body.style.paddingRight = this.originalBodyPadding + "px";
    };
    // thx d.walsh
    SidepanelModalService.prototype.getScrollbarWidth = function () {
        var scrollDiv = this._renderer.createElement('div');
        this._renderer.addClass(scrollDiv, CLASS_NAME.SCROLLBAR_MEASURER);
        this._renderer.appendChild(document.body, scrollDiv);
        var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        this._renderer.removeChild(document.body, scrollDiv);
        return scrollbarWidth;
    };
    SidepanelModalService.prototype._createLoaders = function () {
        var loader = this.clf.createLoader(null, null, null);
        this.copyEvent(loader.onBeforeShow, this.onShow);
        this.copyEvent(loader.onShown, this.onShown);
        this.copyEvent(loader.onBeforeHide, this.onHide);
        this.copyEvent(loader.onHidden, this.onHidden);
        this.loaders.push(loader);
    };
    SidepanelModalService.prototype.removeLoaders = function (level) {
        this.loaders.splice(level - 1, 1);
        this.loaders.forEach(function (loader, i) {
            loader.instance.level = i + 1;
        });
    };
    // tslint:disable-next-line:no-any
    SidepanelModalService.prototype.copyEvent = function (from, to) {
        var _this = this;
        from.subscribe(function () {
            to.emit(_this.lastDismissReason);
        });
    };
    SidepanelModalService.prototype.initializeLoader = function () {
        return this.clf.createLoader(null, null, null);
    };
    return SidepanelModalService;
}());
export { SidepanelModalService };
