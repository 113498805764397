import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {BsModalRef, window} from 'ngx-bootstrap';
import {Subject} from "rxjs/index";
import {DomSanitizer} from '@angular/platform-browser';
import {SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import {
    OpportunityTemplateService
} from '@app/features/system-settings/opportunity-template/services/opportunity-template.services';
import html2canvas from "html2canvas";
import {isHTMLElement} from "html2canvas/dist/types/dom/node-parser";
import {AuthService} from "@app/core";
import {
    ProposalTemplateService
} from "@app/features/system-settings/proposal-template/services/proposal-template.service";
import {AddOpportunityService} from '@app/features/customers/add-opportunity/service/add-opportunity.service';

declare var $: any;

@Component({
    selector: 'app-view-proposal',
    templateUrl: './view-proposal.component.html',
    styleUrls: ['./view-proposal.component.scss']
})
export class ViewProposalComponent implements OnInit {
    public onClose: Subject<boolean>;
    data: any;
    customerId: any;
    opportunityTemplateId: any;
    opportunityDetails: any;
    Url: any;
    url: any;
    // loading : boolean = true;
    opportunityId: any;
    sendButtonDisabled: boolean;
    opportunitiesPagesData: any;
    @ViewChild('iframe') iframe!: ElementRef;
    frameValue: HTMLDivElement;
    proposalPages: any;
    sendtoCustomerLoading: boolean;
    opportunityProposalId: any;
    PricingSectionAdvanceStyles: any;
    pricingSummaryAdvanceStyles: any;
    allSectionLoading: boolean;
    hasSMSModuleAccess: any;
    normalTableStyles: any = [];
    pagesDataList: any;
    captureScreenshotData: any = [];
    PdfFilesData: any;
    proposalVersion: any;
    proposalSendStatus: any;
    proposalPdfDetails: any;
    showAcceptBtn: any;
    proposalValueMismatch:boolean;
    proposalValueMismatchError:boolean;
    pricingSectionDetails: any;
    public discrepancyStatus :any;
    //new pdf make

    pdfDatamake: any = {
        htmldata: [],
        style: [],
        countryName: '',
        googleFontLink: [],
        pdfVersion: '',
        opportunitiesProposalID: '',
        proposalStatus: '',
    };

    constructor(public bsModalRef: BsModalRef,
                private modalService: SidepanelModalService,
                private sanitizer: DomSanitizer,
                private opportunityService: AddOpportunityService,
                private authService: AuthService,
                private templateService: OpportunityTemplateService) {
    }

    ngOnInit() {
        this.getGoogleFontList();
        this.opportunitiesPages();

        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        // this.url = this.data.url;
        this.onClose = new Subject();
        // if (this.url) {
        //   this.loading = false;
        // }
    }

    closeModal(type) {
        this.opportunityService.setOpportunityEmailModal(false);
        if (type == 'print') {
            window.open(this.Url, '_blank');
        }
        this.onClose.next(type);
        this.bsModalRef.hide();
    }

    openSidePanel(type) {
        if (type == 'email') {
            type = this.PdfFilesData;
        }
        this.sendButtonDisabled = true;
        this.opportunityService.setOpportunityEmailModal(true);
        this.onClose.next(type);
        this.bsModalRef.hide();
    }

    opportunitiesPages() {
        this.sendtoCustomerLoading = true;

        this.opportunityService.getOpportunityPages(this.opportunityId, this.opportunityProposalId, true).subscribe(responseData => {
            if (responseData) {
                this.proposalPages = responseData['opportunityPages'];
                this.PricingSectionAdvanceStyles = responseData['styles']
                this.pricingSummaryAdvanceStyles = responseData['summaryStyles'];
                this.hasSMSModuleAccess = responseData['hasSMSAccess'];
                this.pricingSectionDetails = responseData['pricingSection']
                /* for (let i = 0; i < this.pricingSummaryAdvanceStyles.length; i++) {
                     this.pricingSummaryAdvanceStyles[i] = JSON.parse(this.pricingSummaryAdvanceStyles[i]['value']);
                 }*/

                const collectionOfPages = this.proposalPages.flatMap(arr => {
                    if (arr.hasOwnProperty('pages')) {
                        arr.pages.forEach(page => page['option'] = arr['optionName']);
                        return arr.pages
                    } else {
                        return []
                    }
                });
                this.proposalPages = collectionOfPages;
                this.proposalPages.forEach((page, index) => {
                    this.pricingSectionDetails.forEach((ps) => {
                        const pricingSectionNameId = `pricingSectionName_${ps.sectionId}`;
                        if(page.value.includes(pricingSectionNameId)) {
                            const htmlElement = document.createElement('div');
                            htmlElement.innerHTML = page.value.trim();
                            const psJquery = $(htmlElement).find(`#${pricingSectionNameId}`)
                            if(psJquery && psJquery[0]) {
                                const regex2 = /^Pricing section {2}\d+$/;
                                const regex = /^Pricing section \d+$/;
                                if(regex.test(psJquery[0].innerText) || regex2.test(psJquery[0].innerText)) {
                                    psJquery.html('');
                                    page.value = htmlElement.innerHTML;
                                }
                            }
                        }
                    });
                });
                this.loadFrame();
            }
        });

    }

    loadFrame() {
        this.pdfDatamake.pdfVersion = this.proposalVersion;
        this.pdfDatamake.proposalStatus = this.proposalSendStatus;
        if (this.pdfDatamake.proposalStatus == 'sent' && this.proposalPdfDetails) {
            this.PdfFilesData = this.proposalPdfDetails;
            this.Url = this.proposalPdfDetails['url'];
        }
        let editorRef = $('.page-class > #customer_view_pdf').get(0);
        if (editorRef) {
            let editorRef = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
            this.frameValue = document.createElement('div');
            let iframedoc = editorRef;
            if (iframedoc && iframedoc.body) {
                let body = iframedoc.body.querySelector('div.cs_paper');
                $(iframedoc.body).css({
                    cursor: 'pointer',
                    zoom: '58%',
                    overflowX: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingtop: '15px'
                });
                this.proposalPages.forEach((element: any) => {
                    this.frameValue.append(element.value);
                });
                if (this.frameValue) {
                    body.innerHTML = this.frameValue.innerText;
                    $(body).children('div.cs_page');
                }

                let attrStyles = this.getAllOtherStyles();
                for (let i = 0; i < attrStyles.length; i++) {
                    this.normalTableStyles.push(attrStyles[i])
                }
                this.checkingPricingSectionsTotalGrants(body)
                this.findEmptyPages(body);
                // this.findReplaceIframeVideoElement(body);
                this.updateStylesToDocument(this.normalTableStyles, 'normalTable')
                this.updateStylesToDocument(this.PricingSectionAdvanceStyles, 'pricingSection');
                this.updateStylesToDocument(this.pricingSummaryAdvanceStyles, 'pricingSummary');
                if (this.pdfDatamake.proposalStatus != 'sent' || this.proposalPdfDetails == null) {
                    this.youtubeThumbnailRendering(body);
                } else {
                    this.sendButtonDisabled = false;
                    this.sendtoCustomerLoading = false;
                }


                // this.cloneEntireNode(body);
                // this.sendtoCustomerLoading = false;
                // this.capturePdfPages();
                // this.totalalignmentChecking(body)

            }
        }
    }

    findEmptyPages(body: any) {

        const child = body.querySelectorAll('.cs_page')

        child.forEach((page: HTMLElement) => {
            if (!page) {
                return;
            }

            const titleElements = page.querySelectorAll('[tag="title"]');
            const textareaElements = page.querySelectorAll('[tag="textarea"]');
            const imageElements = page.querySelectorAll('[tag="image"]');
            const videoElements = page.querySelectorAll('[tag="video"]');
            const tableElements = page.querySelectorAll('[tag="table"]');
            const pricingsectionElements = page.querySelectorAll('[tag="pricingsection"]');
            const optionspricingbreakupElements = page.querySelectorAll('[tag="optionspricingbreakup"]');

            function hasNonEmptyTextContent(element) {
                return element.textContent.trim() !== '';
            }

            function processTableElement(elem) {
                const tdElements = elem.querySelectorAll('td');
                const hasText = Array.from(tdElements).some(hasNonEmptyTextContent);
                if (!hasText) {
                    $(elem).addClass('editorHideShowElement');
                }
            }

            titleElements.forEach(elem => {
                if (!hasNonEmptyTextContent(elem)) {
                    $(elem).addClass('editorHideShowElement');
                }
            });

            textareaElements.forEach(elem => {
                if (!hasNonEmptyTextContent(elem)) {
                    $(elem).addClass('editorHideShowElement');
                }
            });

            imageElements.forEach(elem => {
                if ($(elem).find('.img-btn').length) {
                    $(elem).addClass('editorHideShowElement');
                }
            });

            videoElements.forEach(elem => {
                if ($(elem).find('.img-btn').length) {
                    $(elem).addClass('editorHideShowElement');
                }
            });

            tableElements.forEach(processTableElement);

            //pricing section table
            if (pricingsectionElements && pricingsectionElements.length) {
                for (let i = 0; i < pricingsectionElements.length; i++) {
                    let getTableElement = $(pricingsectionElements[i]).find('table');
                    if (getTableElement && getTableElement.length) {
                        let getPricingTable = $(pricingsectionElements[i]).find('tbody tr td');
                        if (getPricingTable && getPricingTable[0].innerHTML == '') {
                            $(getPricingTable).closest('.row-item').addClass('editorHideShowElement')
                        }
                    }
                }
            }
            if (optionspricingbreakupElements && optionspricingbreakupElements.length) {
                for (let i = 0; i < optionspricingbreakupElements.length; i++) {
                    let getTableSummary = $(optionspricingbreakupElements[i]).find('table');
                    if (getTableSummary && getTableSummary.length) {
                        let getTbody = $(getTableSummary).find('tbody tr');
                        if (getTbody && getTbody.length) {
                            let getPricingTable = $(getTbody).find('td');
                            if (getPricingTable && getPricingTable[0].innerHTML.trim() == '') {
                                $(optionspricingbreakupElements[i]).closest('.row-item').addClass('editorHideShowElement');
                            }
                        } else {
                            $(optionspricingbreakupElements[i]).closest('.row-item').addClass('editorHideShowElement');
                        }
                    }
                }
            }
            const editorHideShowElements = page.querySelectorAll('.editorHideShowElement');
            const totalElementsLength = titleElements.length + textareaElements.length + imageElements.length + videoElements.length + tableElements.length + pricingsectionElements.length + optionspricingbreakupElements.length;
            const editorHideShowLength = editorHideShowElements.length;

            if (totalElementsLength === editorHideShowLength) {
                let eleBackImage = $(page).css('background-image');
                let checkBackgroungColour = $(page).css('background-color');
                let findBackgroundHeader = $(page).find('div[id^="headerandfooter_header_"]').css('background-image') || '';
                let findBackgroundFooter = $(page).find('div[id^="headerandfooter_footer_"]').css('background-image') || '';

                if (
                    (eleBackImage === 'none' || !eleBackImage) &&
                    (checkBackgroungColour === 'rgb(255, 255, 255)' || !checkBackgroungColour) &&
                    (findBackgroundHeader === 'none' || !findBackgroundHeader) &&
                    (findBackgroundFooter === 'none' || !findBackgroundFooter)
                ) {
                    $(page).addClass('over-all-page-hide');
                }
            }

        });

        this.findTableEmptyColumn(body);
        this.findReplaceRow(body);
        // this.checkCoverBackPageBackground(body);
    }

    findReplaceRow(body: any) {
        const child: any = body.querySelectorAll('.cs_page')
        child.forEach((element: any) => {
            let textNode: any = $(element).find('.desc')
            if (textNode.length) {
                for (let i = 0; i < textNode.length; i++) {
                    let textValue: any = $(textNode[i]).val();
                    let tempDiv: any = `<div class="desc ignore-sorting">${textValue}</div>`
                    $(textNode[i]).replaceWith(tempDiv);
                }
            }
            let textarea: any = $(element).find('.notes')
            if (textarea.length) {
                for (let i = 0; i < textarea.length; i++) {
                    let textareaValue: any = $(textarea[i]).val();
                    let textareaValuetempDiv: any = `<div class="notes ignore-sorting">${textareaValue}</div>`
                    $(textarea[i]).replaceWith(textareaValuetempDiv);
                }
            }
        });
    }

    //find pricing section table empty element
    findTableEmptyColumn(body: any) {
        let findTableEmptyPage = body.getElementsByClassName('notes');
        for (let index = 0; index < findTableEmptyPage.length; index++) {
            let element = findTableEmptyPage[index];
            if (element.value == '') {
                $(element).addClass("contentPage-empty-notes-row");
            }
        }
    }


    checkCoverBackPageBackground(body: any) {
        const child = body.querySelectorAll('.cs_page')
        child.forEach((element: any) => {
            let checkImage = $(element).css('background-image');
            let checkBackgroungColour = $(element).css('background-color');
            if (checkImage != "none" || checkBackgroungColour != "rgb(255, 255, 255)") {
                $(element).removeClass('over-all-page-hide')
            }
        });
    }

    cloneEntireNode(body: any) {
        let CsPageNode = $(body);
        let cloneCsPageNode = $(body).clone();

        let createAnotherCsNodeElement = `<div class="sendtocustomer" id="sendcapture">
                                        </div>`
        $(CsPageNode).after(createAnotherCsNodeElement);
        $(body.parentElement).find('#sendcapture')
    }

    updateStylesToDocument(stylesList: any, tableType = '') {
        let dynamicStyle = '';

        if (tableType == 'pricingSummary') {
            if (stylesList && stylesList.length) {
                for (let i = 0; i < stylesList.length; i++) {
                    dynamicStyle += ' ' + this.getStyleFromJSON(stylesList[i], tableType);
                    this.findAndremoveWidth(stylesList[i], tableType)
                }
            }
        } else if (tableType == 'normalTable') {
            if (stylesList && stylesList.length) {
                for (let i = 0; i < stylesList.length; i++) {
                    dynamicStyle += ' ' + this.getStyleFromJSON(stylesList[i], tableType);
                }
            }
        } else {
            if (stylesList && stylesList.length) {
                for (let i = 0; i < stylesList.length; i++) {
                    dynamicStyle += ' ' + this.getStyleFromJSON(stylesList[i], tableType);
                    this.findAndremoveWidth(stylesList[i], tableType)
                }
            }
        }
        this.pdfDatamake.style.push(dynamicStyle)
        if (dynamicStyle != '') {
            this.updateStylesInCSEditor(dynamicStyle, tableType);
        }
    }


// Advanced style related code
    getStyleFromJSON(styleData: any, tableType = '') {
        var identifier = ''
        var totalSectionIdentifier = '';
        if (tableType == 'pricingSummary') {
            identifier = '#pricing-items-breakup-tpl_' + styleData['currentSectionId'];
            totalSectionIdentifier = '#pricing-summary_total-section_' + styleData['currentSectionId'];
        } else if (tableType == 'normalTable') {
            identifier = '#' + styleData['hashId'];
        } else {
            identifier = '#Pricing_' + styleData['sectionId'];
            totalSectionIdentifier = '#total_' + styleData['sectionId'];
        }

        let contentBlockBorderWidth = styleData['contentBlockBorderWidthIndividualSide'] ? (styleData.contentBlockBorderWidthTop + 'px ' + styleData.contentBlockBorderWidthRight + 'px ' + styleData.contentBlockBorderWidthBottom + 'px ' + styleData.contentBlockBorderWidthLeft + 'px') : (styleData['contentBlockBorderWidth'] != '' ? styleData['contentBlockBorderWidth'] + 'px' : 0);
        let contentBlockBorderColor = styleData['contentBlockBorderColor'] != 'No colour' ? styleData['contentBlockBorderColor'] : '#ccc';
        let contentBlockBorderRadius = styleData['contentBlockBorderRadiusIndividualSide'] ? (styleData['contentBlockBorderRadiusTopLeft'] + 'px ' + styleData['contentBlockBorderRadiusTopRight'] + 'px ' + styleData['contentBlockBorderRadiusBottomRight'] + 'px ' + styleData['contentBlockBorderRadiusBottomLeft'] + 'px') : (styleData['contentBlockBorderRadius'] != '' ? styleData['contentBlockBorderRadius'] + 'px' : 0);
        let contentBlockBackgroundColor = styleData['contentBlockBackgroundColor'] != 'No background' ? styleData['contentBlockBackgroundColor'] : 'transparent';
        let contentBlockPadding = styleData['contentBlockPaddingIndividualSide'] ? (styleData['contentBlockPaddingTop'] + 'px ' + styleData['contentBlockPaddingRight'] + 'px ' + styleData['contentBlockPaddingBottom'] + 'px ' + styleData['contentBlockPaddingLeft'] + 'px') : (styleData['contentBlockPadding'] != '' ? styleData['contentBlockPadding'] + 'px' : 0);
        let contentBlockMargin = styleData['contentBlockMarginIndividualSide'] ? (styleData['contentBlockMarginTop'] + 'px ' + styleData['contentBlockMarginRight'] + 'px ' + styleData['contentBlockMarginBottom'] + 'px ' + styleData['contentBlockMarginLeft'] + 'px') : (styleData['contentBlockMargin'] != '' ? styleData['contentBlockMargin'] + 'px' : 0);

        let tableBorderWidthToBeAppliedForHeader = styleData['tableBorderWidthIndividualSide'] ? (styleData['tableBorderWidthTop'] + 'px ' + styleData['tableBorderWidthRight'] + 'px ' + styleData['titleTextBorderWidth'] + 'px ' + styleData['tableBorderWidthLeft'] + 'px') : (styleData['tableBorderWidth'] != '' ? (styleData['tableBorderWidth'] + 'px ' + styleData['tableBorderWidth'] + 'px ' + styleData['titleTextBorderWidth'] + 'px ' + styleData['tableBorderWidth'] + 'px') : 0);
        let tableBorderWidthToBeAppliedForBody = styleData['tableBorderWidthIndividualSide'] ? (styleData['titleTextBorderWidth'] + 'px ' + styleData['tableBorderWidthRight'] + 'px ' + styleData['tableBorderWidthBottom'] + 'px ' + styleData['tableBorderWidthLeft'] + 'px') : (styleData['tableBorderWidth'] != '' ? (styleData['titleTextBorderWidth'] + 'px ' + styleData['tableBorderWidth'] + 'px ' + styleData['tableBorderWidth'] + 'px ' + styleData['tableBorderWidth'] + 'px') : 0);
        let tableBorderColor = styleData['tableBorderColor'] != 'No colour' ? styleData['tableBorderColor'] : '#ccc';
        let tableBorderVerticalBorderWidth = styleData['tableBorderVerticalBorderWidth'] != '' ? styleData['tableBorderVerticalBorderWidth'] + 'px' : 1;
        let tableBorderVerticalBorderColor = styleData['tableBorderVerticalBorderColor'] != 'No colour' ? styleData['tableBorderVerticalBorderColor'] : '#ccc'

        let titleHeaderFont = styleData['titleHeaderFont'] != '' ? styleData['titleHeaderFont'] : 'Arial';
        let titleHeaderFontWeight = styleData['titleHeaderFontWeight'] != '' ? styleData['titleHeaderFontWeight'] : 400;
        let titleTextSize = styleData['titleTextSize'] != '' ? styleData['titleTextSize'] : 14;
        let titleTextColor = styleData['titleTextColor'] != 'No colour' ? styleData['titleTextColor'] : '#505B65';
        let titleTextAlignment = styleData['titleTextAlignment'] != '' ? styleData['titleTextAlignment'] : 'left';
        let titleTextHeight = styleData['titleTextHeight'] != '' ? styleData['titleTextHeight'] : 27;
        let titleTextBackgroundColor = styleData['titleTextBackgroundColor'] != 'No background' ? styleData['titleTextBackgroundColor'] : 'transparent';
        let titleTextBorderWidth = styleData['titleTextBorderWidthIndividualSide'] ? (styleData['titleTextBorderWidthTop'] + 'px ' + styleData['titleTextBorderWidthRight'] + 'px ' + styleData['titleTextBorderWidthBottom'] + 'px ' + styleData['titleTextBorderWidthLeft'] + 'px') : (styleData['titleTextBorderWidth'] != '' ? styleData['titleTextBorderWidth'] + 'px' : 1);
        let titleTextBorderColor = styleData['titleTextBorderColor'] != 'No colour' ? styleData['titleTextBorderColor'] : '#ccc';

        let rowHeaderFont = styleData['rowHeaderFont'] != '' ? styleData['rowHeaderFont'] : 'Arial';
        let rowHeaderFontWeight = styleData['rowHeaderFontWeight'] != '' ? styleData['rowHeaderFontWeight'] : 400;
        let rowTextSize = styleData['rowTextSize'] != '' ? styleData['rowTextSize'] : 14;
        let rowTextColor = styleData['rowTextColor'] != 'No colour' ? styleData['rowTextColor'] : '#505B65'
        let rowTextAlignment = styleData['rowTextAlignment'] != '' ? styleData['rowTextAlignment'] : 'left'
        let rowTextHeight = styleData['rowTextHeight'] != '' ? styleData['rowTextHeight'] : 27;
        let rowTextBackgroundColor = styleData['rowTextBackgroundColor'] != 'No background' ? styleData['rowTextBackgroundColor'] : 'transparent';
        let rowTextAlternativeBackgroundColor = styleData['rowTextAlternativeBackgroundColor'] != 'No background' ? styleData['rowTextAlternativeBackgroundColor'] : 'transparent';
        let rowTextHorizontalBorderWidth = styleData['rowTextHorizontalBorderWidth'] != '' ? styleData['rowTextHorizontalBorderWidth'] + 'px' : 1;
        let rowTextHorizontalBorderColor = styleData['rowTextHorizontalBorderColor'] != '' ? styleData['rowTextHorizontalBorderColor'] : '#ccc';

        let totalSummaryBorderWidth = styleData['totalSummaryBorderWidthIndividualSide'] ? (styleData['totalSummaryBorderWidthTop'] + 'px ' + styleData['totalSummaryBorderWidthRight'] + 'px ' + styleData['totalSummaryBorderWidthBottom'] + 'px ' + styleData['totalSummaryBorderWidthLeft'] + 'px') : (styleData['totalSummaryBorderWidth'] != '' ? styleData['totalSummaryBorderWidth'] + 'px' : 0);
        let totalSummaryBorderColor = styleData['totalSummaryBorderColor'] != '' ? styleData['totalSummaryBorderColor'] : '#ccc';
        let totalSummaryBackgroundColor = styleData['totalSummaryBackgroundColor'] != 'No background' ? styleData['totalSummaryBackgroundColor'] : 'transparent';
        let totalSummaryPadding = styleData['totalSummaryPadding'] != '' ? styleData['totalSummaryPadding'] + 'px' : 0;
        let totalSummaryMargin = styleData['totalSummaryMargin'] != '' ? styleData['totalSummaryMargin'] + 'px' : 0;
        let totalSummarySubtotalHeaderFont = styleData['totalSummarySubtotalHeaderFont'] != '' ? styleData['totalSummarySubtotalHeaderFont'] : 'Arial'
        let totalSummarySubtotalHeaderFontWeight = styleData['totalSummarySubtotalHeaderFontWeight'] != '' ? styleData['totalSummarySubtotalHeaderFontWeight'] : 400;
        let totalSummarySubtotalTextSize = styleData['totalSummarySubtotalTextSize'] != '' ? styleData['totalSummarySubtotalTextSize'] : 14;
        let totalSummarySubtotalTextColor = styleData['totalSummarySubtotalTextColor'] != 'No colour' ? styleData['totalSummarySubtotalTextColor'] : '#505B65';
        let totalSummarySubtotalLineHeight = styleData['totalSummarySubtotalLineHeight'] != '' ? styleData['totalSummarySubtotalLineHeight'] : 14;
        let totalSummaryGrandTotalHeaderFont = styleData['totalSummaryGrandTotalHeaderFont'] != '' ? styleData['totalSummaryGrandTotalHeaderFont'] : 'Arial';
        let totalSummaryGrandTotalHeaderFontWeight = styleData['totalSummaryGrandTotalHeaderFontWeight'] != '' ? styleData['totalSummaryGrandTotalHeaderFontWeight'] : 400;
        let totalSummaryGrandTotalTextSize = styleData['totalSummaryGrandTotalTextSize'] != '' ? styleData['totalSummaryGrandTotalTextSize'] : 14;
        let totalSummaryGrandTotalTextColor = styleData['totalSummaryGrandTotalTextColor'] != 'No colour' ? styleData['totalSummaryGrandTotalTextColor'] : '#505B65';
        let totalSummaryGrandTotalLineHeight = styleData['totalSummaryGrandTotalLineHeight'] != '' ? styleData['totalSummaryGrandTotalLineHeight'] : 14;
        let financeFontStyle = (styleData['financeFontStyle'] && styleData['financeFontStyle'] != '') ? styleData['financeFontStyle'] : 'Arial';
        let financeFontSize = (styleData['financeFontSize'] && styleData['financeFontSize'] != '') ? styleData['financeFontSize'] : 14;
        let financeTextColor = (styleData['financeTextColor'] && styleData['financeTextColor'] != 'No colour') ? styleData['financeTextColor'] : '#505B65';
        let financeLineHeight = (styleData['financeLineHeight'] && styleData['financeLineHeight'] != '') ? styleData['financeLineHeight'] : 20;
        let grantsFontStyle = (styleData['grantsFontStyle'] && styleData['grantsFontStyle'] != '') ? styleData['grantsFontStyle'] : 'Arial';
        let grantsFontWeight = (styleData['grantsFontWeight'] && styleData['grantsFontWeight'] != '') ? styleData['grantsFontWeight'] : 400;
        let grantsFontSize = (styleData['grantsFontSize'] && styleData['grantsFontSize'] != '') ? styleData['grantsFontSize'] : 14;
        let grantsTextColor = (styleData['grantsTextColor'] && styleData['grantsTextColor'] != 'No colour') ? styleData['grantsTextColor'] : '#505B65';
        let grantsLineHeight = (styleData['grantsLineHeight'] && styleData['grantsLineHeight'] != '') ? styleData['grantsLineHeight'] : 14;


        let dynamicStyle = `${identifier} ${styleData.isNormalTable ? 'div.froala-table' : ''} {
            border-width: ${contentBlockBorderWidth};
            border-style: solid;
            border-color: ${contentBlockBorderColor};
            background: ${contentBlockBackgroundColor};
            padding: ${contentBlockPadding};
            border-radius: ${contentBlockBorderRadius};
            margin: ${contentBlockMargin};
    }

    ${identifier} table thead{
            border-width: ${tableBorderWidthToBeAppliedForHeader};
            border-right-style: solid;
            border-left-style: solid;
            border-top-style: solid;
            border-right-color: ${tableBorderColor};
            border-left-color: ${tableBorderColor};
            border-top-color: ${tableBorderColor};
    }
    ${identifier} table tbody{
            border-width: ${tableBorderWidthToBeAppliedForBody};
            border-right-style: solid;
            border-left-style: solid;
            border-bottom-style: solid;
            border-right-color: ${tableBorderColor};
            border-left-color: ${tableBorderColor};
            border-bottom-color: ${tableBorderColor};
    }
    ${identifier} table thead tr th,
    ${identifier} table tbody tr td {
        border-left-width: ${tableBorderVerticalBorderWidth};
        border-left-style: solid;
        border-left-color: ${tableBorderVerticalBorderColor};
        border-right-width: ${tableBorderVerticalBorderWidth};
        border-right-style: solid;
        border-right-color: ${tableBorderVerticalBorderColor};
    }
    ${identifier} table thead tr{
         border-width: ${titleTextBorderWidth};
         border-style: solid;
         border-color: ${titleTextBorderColor};
    }
    ${identifier} table thead tr th{
            font-family: ${titleHeaderFont};
            font-weight: ${titleHeaderFontWeight} !important;
            font-size: ${titleTextSize}px !important;
            text-align: ${titleTextAlignment};
            height: ${titleTextHeight}px;
            color: ${titleTextColor} !important;
            background-color: ${titleTextBackgroundColor};
    }
    ${identifier} table tbody tr:nth-child(odd) td{
            font-family: ${rowHeaderFont};
            font-weight: ${rowHeaderFontWeight};
            font-size: ${rowTextSize}px !important;
            text-align: ${rowTextAlignment};
            color: ${rowTextColor} !important;
            background-color: ${rowTextBackgroundColor};
        }
         ${identifier} tbody tr:nth-child(even) td{
            font-family: ${rowHeaderFont};
            font-weight: ${rowHeaderFontWeight};
            font-size: ${rowTextSize}px !important;
            text-align: ${rowTextAlignment};
            color: ${rowTextColor} !important;
            background-color: ${rowTextAlternativeBackgroundColor};
        }

        ${identifier} tbody tr td .desc{
            font-family: ${rowHeaderFont};
            font-weight: ${rowHeaderFontWeight};
            font-size: ${rowTextSize}px !important;
            text-align: ${rowTextAlignment};
            color: ${rowTextColor} !important;
            }
            ${identifier} table tr td .notes{
            font-family: ${rowHeaderFont};
            font-weight: ${rowHeaderFontWeight};
            font-size: ${rowTextSize}px !important;
            text-align: ${rowTextAlignment};
            color: ${rowTextColor} !important;
         }
         ${identifier} tbody tr td .pricing-section-part-no-images{
            text-align : ${rowTextAlignment};
            width: 92%;
         }
        ${identifier} table tbody tr.mousehoverNew{
            height: ${rowTextHeight}px;
        }
        ${identifier} table tbody tr {
            border-bottom-width : ${rowTextHorizontalBorderWidth};
            border-bottom-style: solid;
            border-bottom-color: ${rowTextHorizontalBorderColor};
        }
        ${identifier} table tfoot.pricingSection_subGrandTotalBlock{
            border-width: ${totalSummaryBorderWidth};
            border-style: solid;
            border-color: ${totalSummaryBorderColor};
        }
        ${identifier} table tfoot tr{
            background-color: ${totalSummaryBackgroundColor} !important;
        }

        ${identifier} table tfoot tr td div.subtotal-style-apply-here{
            font-family: ${totalSummarySubtotalHeaderFont};
            font-weight: ${totalSummarySubtotalHeaderFontWeight} !important;
            font-size: ${totalSummarySubtotalTextSize}px !important;
            color: ${totalSummarySubtotalTextColor} !important;
            line-height: ${totalSummarySubtotalLineHeight}px !important;
        }
        ${identifier} table tfoot tr td div.grand-total-style-apply-here{
            font-family: ${totalSummaryGrandTotalHeaderFont};
            font-weight: ${totalSummaryGrandTotalHeaderFontWeight} !important;
            font-size: ${totalSummaryGrandTotalTextSize}px !important;
            color: ${totalSummaryGrandTotalTextColor} !important;
            line-height: ${totalSummaryGrandTotalLineHeight}px !important;
        }
        ${identifier} table tfoot tr td.finance-section-new-improvement {
            font-family: ${financeFontStyle};
            font-size: ${financeFontSize}px !important;
            color: ${financeTextColor} !important;
            line-height: ${financeLineHeight}px !important;
        }
        ${identifier} table tfoot tr td div.grants-style-apply-here {
            font-family: ${grantsFontStyle};
            font-weight: ${grantsFontWeight} !important;
            font-size: ${grantsFontSize}px !important;
            color: ${grantsTextColor} !important;
            line-height: ${grantsLineHeight}px !important;
        }
        `

        // //pricing section image alignment css
        // if(rowAlignment == 'left' || rowAlignment == 'justify') {
        //     dynamicStyle += `${identifier} .show-image{
        //     transform: translateX(5px);
        //     }`
        // } else if(rowAlignment == 'center'){
        //     dynamicStyle += `${identifier} .show-image{
        //     transform: translateX(85px);
        //     }`
        // }else if(rowAlignment == 'right') {
        //     dynamicStyle += `${identifier} .show-image{
        //     transform: translateX(173px);
        //     }`
        // }

        if (tableType == 'normalTable') {
            dynamicStyle += `${identifier} div.froala-table{
            border-radius: ${contentBlockBorderRadius};
            overflow: hidden;
        }`
        } else {
            dynamicStyle += `${identifier} {
            border-radius: ${contentBlockBorderRadius};
            overflow: hidden;
        }`
        }

        return dynamicStyle;
    }


    findAndremoveWidth(styleData: any, tableType: any = '') {
        const sectionId = styleData['sectionId'];
        const identifier = tableType === 'pricingSummary'
            ? `#pricing-items-breakup-tpl_${sectionId}`
            : `#Pricing_${sectionId}`;

        const placeEverythingElement: any = document.getElementById('customer_view_pdf');
        if (placeEverythingElement) {
            const self = placeEverythingElement.contentWindow.document.getElementById('place_everything');
            if (self && identifier.startsWith('#')) {
                const findTable = self.querySelectorAll(identifier);
                if (findTable && findTable.length) {
                    $(findTable).css({'width': ''});
                }
            }
        }
    }

    updateStylesInCSEditor(dynamicStyle: any, tableType = '') {
        var style: any;
        //TODO: seperate to option summary style and option table style and create dynamic style
        let doc: any = document.getElementById('customer_view_pdf')
        if (tableType == 'pricingSummary') {
            style = document.getElementById('dynamic_styling_summary');
        } else if (tableType == 'normalTable') {
            style = document.getElementById('dynamic_styling_normal');
        } else {
            style = document.getElementById('dynamic_styling');
        }
        if (style && style.length) {
            return;
        }
        if (!style) {
            style = document.createElement('style');
            if (tableType == 'pricingSummary') {
                style.id = "dynamic_styling_summary";
            } else if (tableType == 'normalTable') {
                style.id = "dynamic_styling_normal";
            } else {
                style.id = "dynamic_styling";
            }
        }
        style.innerHTML = dynamicStyle;
        // Get the first script tag
        let ref: any = document.querySelector('script');
        let placeEverythingElement: any = document.getElementById('customer_view_pdf')
        let self = placeEverythingElement.contentWindow.document.getElementById('place_everything');
        if (self) {
            $(placeEverythingElement).contents().find("head")[0].appendChild(style);
        }
    }

    getAllOtherStyles() {
        let styles = [];
        let block = $("#customer_view_pdf").contents().find('.fr-element.froala-table.normal-table-width').find('table');
        if ($(block).length > 0) {
            $(block).each(function (i, value) {
                let x = $(value).attr('data-styling');
                if (x) {
                    let y = JSON.parse(x).advancedStyle;
                    y.isNormalTable = true;
                    styles.push(y);
                }
            });
        }
        return styles;
    }

    async capturePdfPages() {
        this.sendButtonDisabled = true;
        this.pagesDataList = [];
        var imageDataList = {
            'imageData': '',
            'pageId': ''
        };
        var placeEverythingElement: any = document.getElementById('customer_view_pdf');
        if (placeEverythingElement) {
            var findDivElement = placeEverythingElement.contentWindow.document.getElementById('place_everything');
            this.pagesDataList = $(findDivElement).find('.cs_paper').find('.cs_page');
        }
        var screenShotPage: HTMLElement;
        for (let i = 0; i < this.pagesDataList.length; i++) {
            $(this.pagesDataList[i]).css('border', '1px solid white !important');
            screenShotPage = this.pagesDataList[i]
            await html2canvas(screenShotPage, {
                scale: 0.9,
                useCORS: true,
            }).then((canvas: any) => {
                var screenShotBase64 = canvas.toDataURL('image/png', 0.1).replace("data:image/png;base64,", "");
                const contentType = 'image/png';
                let linkSource = `data:${contentType};base64,${screenShotBase64}`;
                imageDataList = {
                    'imageData': linkSource,
                    'pageId': this.pagesDataList[i].id
                };

                //image download purpose
                // var link = document.createElement('a');
                // link.download = "this index"+i;
                // link.href = linkSource;
                // link.click();
                this.captureScreenshotData.push(imageDataList);
                $(this.pagesDataList[i]).css('border', '');
            });
        }
        // this.makeImageToPdfConversation();
    }

    getGoogleFontList() {
        this.opportunityService.fetchAndPersistGoogleFontLinks('get', '').subscribe((res) => {
            if (res) {
                let FontfamilyLinkList = res;
                if (FontfamilyLinkList.hasOwnProperty('displayFontList') && FontfamilyLinkList['displayFontList'].length && FontfamilyLinkList.hasOwnProperty('googleLinks') && FontfamilyLinkList['googleLinks'].length) {
                    const googleLinksData = FontfamilyLinkList['googleLinks'];
                    const googleLink = [].concat(...googleLinksData);
                    this.appendGoogleLinks(googleLink)
                }
            }
        });
    }

    appendGoogleLinks(GoogleLink) {
        const customerViewPdf: any = document.getElementById('customer_view_pdf');
        if (customerViewPdf) {
            const headElement = $(customerViewPdf).contents().find('head');
            GoogleLink.forEach((link: any) => {
                if (link.startsWith('https://fonts.googleapis')) {
                    $('<link>', {
                        rel: 'stylesheet',
                        href: link
                    }).appendTo(headElement);
                    let googleLink = `<link rel='stylesheet' href='${link}'>`;
                    this.pdfDatamake.googleFontLink.push(googleLink);
                }
            });
        }


    }

    getCsPageList() {
        var placeEverythingElement: any = document.getElementById('customer_view_pdf');
        if (placeEverythingElement) {
            var findDivElement = placeEverythingElement.contentWindow.document.getElementById('place_everything');
            this.pagesDataList = $(findDivElement).find('.cs_paper').find('.cs_page');
            let userConfigDetails = this.authService;
            this.pdfDatamake['countryName'] = userConfigDetails['settings']['_csCountryCode']['country'].replaceAll(' ', '').toLowerCase();
            this.pdfDatamake['opportunitiesProposalID'] = this.opportunityProposalId;
            for (let i = 0; i < this.pagesDataList.length; i++) {
                this.pdfDatamake.htmldata.push(this.pagesDataList[i].outerHTML);
            }
            this.htmltoPdfConversation();
        }
    }

    htmltoPdfConversation() {
        this.opportunityService.generatePdfFiles(this.opportunityId, this.pdfDatamake).subscribe(responseData => {
            if (responseData && responseData.hasOwnProperty('pdfFilePath')) {
                let data = {
                    'type': 'email',
                    'fileName': responseData['pdfName'],
                    'url': responseData['pdfFilePath'],
                    'absolutePath': responseData['absolutePath'],
                    'filesData': {
                        'id': responseData['fileId'],
                        'name': responseData['pdfName'],
                        'location': responseData['pdfFilePath'],
                        'size': responseData['filesSize'],
                        'filetype': 'pdf',
                        'opportunityId': responseData['opportunityId'],
                        'selected': false,
                        'attached': false
                    },
                }
                this.Url = data.absolutePath;
                this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.absolutePath);
                this.PdfFilesData = data;
                this.sendButtonDisabled = false;
                this.sendtoCustomerLoading = false;
                // this.sendtoCustomerLoading = false;
                // this.openSidePanel(data);
            }
        });
    }

    async youtubeThumbnailRendering(body: any) {
        const child = body.querySelectorAll('.cs_page');
        const promises = [];
        for (const element of child) {
            const extractedData = await this.extractDataFromHTML(element);
            promises.push(this.youtubeAndLoomVideoRendering(extractedData, element));
        }
        await Promise.all(promises);
        this.sendButtonDisabled = true;
        this.sendtoCustomerLoading = false;
        setTimeout(() => {
            this.domRederingImagesElement();
        }, 2000);

        // this.pdfPageLoading = true;
    }


    domRederingImagesElement() {

        var placeEverythingElement: any = document.getElementById('customer_view_pdf');
        if (placeEverythingElement) {
            var findDivElement = placeEverythingElement.contentWindow.document.getElementById('place_everything');
            let body = findDivElement.querySelectorAll('.cs_page');
            const child = body;
            child.forEach((page: HTMLElement) => {
                const imageElements = page.querySelectorAll('[tag="image"]');
                const videoElements = page.querySelectorAll('[tag="video"]');
                const pricingsectionElements = page.querySelectorAll('[tag="pricingsection"]');
                const optionspricingbreakupElements = page.querySelectorAll('[tag="optionspricingbreakup"]');

                imageElements.forEach(elem => {
                    if ($(elem).find('.img-btn').length == 0) {
                        const imgDiv = $(elem).find('img').get(0);
                        const imgWidth = $(imgDiv).css('width');
                        const imgHeight = $(imgDiv).css('height');
                        $(imgDiv).css({'width': imgWidth, 'height': imgHeight})
                    }
                });

                videoElements.forEach(elem => {
                    if ($(elem).find('.img-btn').length == 0) {
                        let videoContainer = $(elem).find('.thumbnail-image').get(0);
                        const width = $(videoContainer).css('width');
                        const height = $(videoContainer).css('height');
                        $(videoContainer).css({'width': width, 'height': height});
                        $(videoContainer).css({'max-width': '', 'max-height': ''});

                        /* const styleAttr = elem.getAttribute('style') || '';
                         const styles = styleAttr.split(';').reduce((acc: any, style: any) => {
                             const [property, value] = style.split(':').map((s: any) => s.trim());
                             acc[property] = value;
                             return acc;
                         }, {});

                         if (!styles.hasOwnProperty('max-width') || styles['max-width'] === '100%') {
                             styles['max-width'] = '';
                             styles['width'] = 'auto';
                         }

                         for (var prop in styles) {
                             if (styles.hasOwnProperty(prop)) {
                                 // Only set width and height properties
                                 if (prop === 'width' || prop === 'height') {
                                     videoContainer.style[prop] = styles[prop];
                                 }
                             }
                         }*/

                        /*let styleList:any = elem;

                        Object.entries(styleList.style).forEach(([property, value]) => {
                            videoContainer.style[property] = value;
                        });*/

                    }
                });
                if (pricingsectionElements && pricingsectionElements.length) {
                    this.adjustFinanceSectionHeight(pricingsectionElements);
                }
                if (optionspricingbreakupElements && optionspricingbreakupElements.length) {
                    this.adjustFinanceSectionHeight(optionspricingbreakupElements);
                }
            })

        }

        if(!this.proposalValueMismatch && !this.proposalValueMismatchError){
            this.getCsPageList();
        }
    }


    adjustFinanceSectionHeight(elements) {
        if (elements && elements.length) {
            for (let i = 0; i < elements.length; i++) {
                let getTableElement = $(elements[i]).find('table');
                if (getTableElement && getTableElement.length) {
                    let getFinanceSection = $(getTableElement).find('.finance-section-new-improvement');
                    if (getFinanceSection && getFinanceSection.length && $(getFinanceSection).attr('breakdown-status') == 'horizontal') {
                        const financeHeight = $(getFinanceSection).height();
                        const parentElement = getFinanceSection.parent();
                        if (parentElement && parentElement.length) {
                            let firstTD = $(parentElement).find('.total-section-right-text-label').parent();
                            let secondTD = $(parentElement).find('.total-section-right-text-value').parent();
                            if (firstTD && firstTD.length && secondTD && secondTD.length) {
                                $(firstTD).css('height', financeHeight);
                                $(secondTD).css('height', financeHeight);
                            }
                        }
                    }
                }
            }
        }
    }

    async extractDataFromHTML(html: any): Promise<any[]> {
        const videoBlocks: any[] = [];
        const csBlocks = html.querySelectorAll('.cs_block_s[tag="video"]');
        csBlocks.forEach((csBlock: any) => {
            const dynamicId = csBlock.getAttribute('id') || '';
            const iframe = csBlock.querySelector('iframe');
            if (iframe == null) {
                return;
            }
            const styleAttr = iframe.getAttribute('style') || '';
            const styles = styleAttr.split(';').reduce((acc: any, style: any) => {
                const [property, value] = style.split(':').map((s: any) => s.trim());
                acc[property] = value;
                return acc;
            }, {});
            const youtubeUrl = iframe.getAttribute('src') || '';
            const youtubeURLorLoomURL = youtubeUrl.includes('youtube') ? 'youtube' : 'loom';
            videoBlocks.push({dynamicId: dynamicId, IframeImageType: youtubeURLorLoomURL, style: styles, youtubeUrl});
        });

        return videoBlocks;
    }

    async youtubeAndLoomVideoRendering(data: any, element: any) {
        for (const blockData of data) {
            if (blockData.IframeImageType === 'youtube') {
                await this.createVideoDivElements(blockData, element, 'youtube');
            } else {
                await this.createVideoDivElements(blockData, element, 'loom');
            }
        }
    }

    async createVideoDivElements(data: any, element: any, type: string) {
        const container = $(element).find(`#${data.dynamicId}`);
        const newDiv = document.createElement('div');
        newDiv.classList.add('new-video-block');
        newDiv.style.width = "-webkit-fill-available";
        if (type == 'loom') {
            newDiv.classList.add('loom-video-element-thumbnail');
        }

        const thumbnailImg: any = document.createElement('img');
        thumbnailImg.classList.add('thumbnail-image');
        thumbnailImg.src = type === 'youtube'
            ? `https://img.youtube.com/vi/${data.youtubeUrl.split('/').pop()}/mqdefault.jpg`
            : await this.fetchLoomThumbnail(data.youtubeUrl);

        thumbnailImg.alt = 'Video Thumbnail';

        Object.entries(data.style).forEach(([property, value]) => {
            thumbnailImg.style[property] = value;
        });

        const playButton = document.createElement('div');
        playButton.classList.add(`play-button-${type}`);

        var iconElement = document.createElement("i");

        iconElement.className = "fa fa-play";
        iconElement.classList.add(`play-icon-button-${type}`);
        iconElement.setAttribute("aria-hidden", "true");


        playButton.appendChild(iconElement);
        // playButton.setAttribute('onclick', `showIframe('${data.youtubeUrl+'?autoplay=1'}', '${data.dynamicId}','${type}')`);

        newDiv.appendChild(thumbnailImg);
        newDiv.appendChild(playButton);
        container.append(newDiv);

        const handleSectionSummary = (container: any) => {
            const removeIframe = container.find('iframe');
            removeIframe.remove();
        };

        handleSectionSummary(container);
    }


    async fetchLoomThumbnail(videoUrl: string): Promise<string> {
        try {
            const apiUrl = `https://www.loom.com/v1/oembed?url=https://www.loom.com/share/${videoUrl.split('/').pop()}`;
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error(`Error fetching Loom oEmbed data. Status: ${response.status}`);
            }
            const data = await response.json();
            return data.thumbnail_url;
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    checkingPricingSectionsTotalGrants(body) {
        if (!this.pricingSectionDetails.length) return;
        for (let i = 0; i < this.pricingSectionDetails.length; i++) {
            let items = this.pricingSectionDetails[i];
            let sectionId = items['sectionId'];
            let grandTotal = items['grandTotal'];
            if (!sectionId || grandTotal === undefined) return;
            let findTable = body.querySelector(`#cs_dynamic_styling_${sectionId}`);
            if (!findTable) return;
            const grandTotalElem = findTable.querySelector(`#grand_total_${sectionId}`);
            if (!grandTotalElem){
                this.proposalValueMismatch = true;
                this.proposalValueMismatchError = true;
                break;
            }
            const getValue = grandTotalElem.textContent.trim();
            if (getValue !== grandTotal) {
                this.proposalValueMismatch = true;
                this.proposalValueMismatchError = true;
                this.sendButtonDisabled = true;
                break;
            } else {
                this.proposalValueMismatch = false;
                this.proposalValueMismatchError = false;
            }
        }
    }

    warningPopup(status) {
        $('#pricing-section-value-mismatch').appendTo('body').modal(`${status}`);
    }

    fixDiscrepancy(type ){
        this.discrepancyStatus = true;
        this.onClose.next(type);
        this.bsModalRef.hide();
    }

}
