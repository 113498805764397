import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FolderService = /** @class */ (function (_super) {
    tslib_1.__extends(FolderService, _super);
    function FolderService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.triggerEvent = new BehaviorSubject(null);
        _this.triggerEvent$ = _this.triggerEvent.asObservable();
        _this.coreUrlPrefix = 'e5ec7051';
        return _this;
    }
    FolderService.prototype.getApiCall = function (url) {
        return this.http.post(this.getApiUrl(url), []);
    };
    FolderService.prototype.postApiCall = function (url, params) {
        return this.http.post(this.getApiUrl(url), params);
    };
    FolderService.prototype.addEditEmailTemplate = function (params) {
        return this.http.post(this.getApiUrl('save_templates'), params);
    };
    FolderService.prototype.getReplacedMessage = function (params) {
        return this.http.post(this.getApiUrl('replaced_email_message'), params);
    };
    FolderService.prototype.checkTemplateStatus = function (params) {
        return this.http.post(this.getApiUrl('check_template_status'), params);
    };
    FolderService.prototype.checkFolderStatus = function (params) {
        return this.http.post(this.getApiUrl('check_folder_status'), params);
    };
    FolderService.prototype.checkUniqueFolderName = function (params) {
        return this.http.post(this.getApiUrl('check_unique_folder_name'), params);
    };
    FolderService.prototype.checkUniqueTemplateName = function (params) {
        return this.http.post(this.getApiUrl('check_unique_template_name'), params);
    };
    FolderService.prototype.loadData = function (value) {
        this.triggerEvent.next(value);
    };
    FolderService.prototype.checkTemplateName = function (params) {
        return this.http.get(this.getApiUrl("checkSetupOptionsExists", params));
    };
    FolderService.prototype.checkProposalTemplateExists = function (value) {
        return this.http.get(this.getApiUrl("checkProposalTemplateExists", value));
    };
    FolderService.prototype.postApiV5Call = function (url, params) {
        return this.http.post(this.getFleetApiUrl(url), params);
    };
    FolderService.prototype.getApiV5Call = function (url) {
        return this.http.post(this.getFleetApiUrl(url), []);
    };
    FolderService.prototype.checkTemplateFormStatus = function (id) {
        return this.http.get(this.getFleetApiUrl(id + "/is_vehicle_form_deletable"));
    };
    // Common API methods :
    FolderService.prototype.getFolderList = function (urlFormatter, params) {
        return this.http.get(this[urlFormatter](this.coreUrlPrefix + "/folders", params));
    };
    FolderService.prototype.getChildFolderList = function (urlFormatter, params) {
        return this.http.get(this[urlFormatter](this.coreUrlPrefix + "/folders/" + params['id'], params['queryParam']));
    };
    FolderService.prototype.addOrEditFolder = function (urlFormatter, payload) {
        return this.http.post(this[urlFormatter](this.coreUrlPrefix + "/folder"), payload);
    };
    FolderService.prototype.deleteFolder = function (urlFormatter, params) {
        return this.http.delete(this[urlFormatter](this.coreUrlPrefix + "/folder/" + params['deleteId'] + "/delete", { tableType: params['tableType'] }));
    };
    FolderService.prototype.checkBeforeFolderDelete = function (urlFormatter, params) {
        return this.http.get(this[urlFormatter](this.coreUrlPrefix + "/" + params['folderId'] + "/check_folder_delete", params));
    };
    FolderService.prototype.checkIsFolderExists = function (urlFormatter, params) {
        return this.http.get(this[urlFormatter](this.coreUrlPrefix + "/is_folder_exists", params));
    };
    FolderService.prototype.moveFolderOrTemplate = function (urlFormatter, params) {
        return this.http.post(this[urlFormatter](this.coreUrlPrefix + "/move_templates"), params);
    };
    FolderService.ngInjectableDef = i0.defineInjectable({ factory: function FolderService_Factory() { return new FolderService(i0.inject(i1.HttpClient)); }, token: FolderService, providedIn: "root" });
    return FolderService;
}(InterfaceService));
export { FolderService };
