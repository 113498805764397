import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { conditions } from "@app/shared/cs-components/filter-configurator-1/conditions-metadata";
import {DatePipe} from "@app/pipes";
import {SettingsService} from "@app/core";
declare var $: any;

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, AfterViewInit {
    booleanOptionValues = [
        { key: true, label: 'True' },
        { key: false, label: 'False' },
    ]
    filterForm: FormGroup;
    select2Config: any;
    select2ConfigDynamicFilter: any;
    select2ConfigDynamicMultipleFilter: any;
    select2ConfigStaticMultipleFilter: any;
    select2SearchLabelConfig: any;
    select2ConfigDynamicMultiFilter: any;
    select2MultipleOwnFilterConfig: any;
    isPopupOpened = false;
    isMoreFilterPopupOpened = false;
    popUpElement: any;
    moreFilterElement: any;
    subscriptions: Object = {};
    bsDateConfig: Partial<BsDatepickerConfig> = {
        showWeekNumbers: false
    };
    filterValueType = 'text';
    viewableFilters: any[] = [];
    moreFilters: any[] = [];
    conditions = conditions;
    availableConditions: any[] = [];
    selectedFilter: any;
    selectedFilterValues: any = [];
    currentDate = new Date();
    onWidthChangeSubscription: Subscription;
    @Input() availableFields: any[];
    @Input() appliedFilters: any[];
    @Input() widthChangeEvent: Observable<any>;
    @Input() screenType: any = '';
    @Output() sendSelectedField = new EventEmitter<any>();
    @Output() applyFilter = new EventEmitter<any>();
    @Output() deleteFilter = new EventEmitter<any>();
    showDatePicker: boolean;
    showValueOption: boolean;
    showCondition = true;
    format: any;
    @Input() specificScreenType: any;
    constructor(private el: ElementRef,
                private datePipe: DatePipe,
                @Inject(forwardRef(() => SettingsService)) private settings) {
        const intlSettings = this.settings._csIntlSettings;
        this.format =  this.datePipe.formats.find(f => f.id == parseInt(intlSettings['short_date']));
        this.bsDateConfig.dateInputFormat = (String(this.format.format)).toUpperCase();
        this.select2Config = {
            width: '100%'
        };
        this.select2ConfigDynamicFilter = {
            width: '100%',
            minimumInputLength: 2,
        };
        this.select2ConfigDynamicMultipleFilter = {
            width: '100%',
            minimumInputLength: 2,
            multiple: true
        }
        this.select2ConfigStaticMultipleFilter = {
            width: '100%',
            multiple: true,
        }
        this.select2ConfigDynamicMultiFilter = {
            width: '100%',
            multiple: true,
            data: []
        }
        this.select2SearchLabelConfig = {
            width: '100%',
            minimumInputLength: 3,
            data: [],
            tags: true,
            createSearchChoice: function (term, data) {
                let exists = false;
                data.forEach((idText) => {
                    if (idText.text == term)
                        exists = true;
                });
                return term == ' ' || exists ? null :
                    { id: term, text: term };
            },
            formatSelection: format,
            multiple: true
        };
        this.select2MultipleOwnFilterConfig = {
            width: '100%',
            data: [],
            tags: true,
            createSearchChoice: function (term, data) {
                let exists = false;
                data.forEach((idText) => {
                    if (idText.text == term)
                        exists = true;
                });
                return term == ' ' || exists ? null :
                    { id: term, text: term };
            },
            dropdownCssClass: "filter-custom-dropdown",
            formatSelection: format,
            multiple: true
        }

        function format(item) {
            return item.text;
        }
        this.filterForm = new FormGroup({
            field: new FormControl('', Validators.required),
            condition: new FormControl('', Validators.required),
            value: new FormControl('', Validators.required),
        });
        this.conditions.date = this.conditions.date.filter((condition) => (condition.condition !== 'nextWeek' && condition.condition !== 'nextMonth' && condition.condition !== 'nextQuarter' && condition.condition !== 'nextYear' && condition.condition !== 'anytime' && condition.condition !== 'greaterThanToday'))
    }

    ngOnInit() {
        this.popUpElement = this.el.nativeElement.querySelector(".filter-popup");
        this.moreFilterElement = this.el.nativeElement.querySelector(".more-filter-list");
        let that = this;
        $(document).on('click', function (event) {
            that.clickEventListner(event, that, false);
        });
        $(document).on('click', 'a', function (event) {
            that.clickEventListner(event, that, true);
        });
        this.onWidthChangeSubscription = this.widthChangeEvent.subscribe((data) => {
            this.appliedFilters = data ? data : this.appliedFilters;
            this.calculateWidthOfFilter();
            if (data) {
                this.setSelectedFilter();
            }
        });
    }

    clickEventListner(event, that, isWatchAnchorTag) {
        let bodyScopeToCheck: any = $('body.ng-scope');
        bodyScopeToCheck = bodyScopeToCheck && bodyScopeToCheck.length ? bodyScopeToCheck[0] : null;
        if (event.target == bodyScopeToCheck) {
            return;
        }
        if (!$(event.target).closest('.dropdown-menu').length && !$(event.target).hasClass('modal-open') && $(".filter-popup").is(":visible")
            && (isWatchAnchorTag || event.target['id'] == '') && !$(event.target).closest('#add-filter-dropdown').length && !event.target.hasAttribute('bsdatepickerdaydecorator')) {
            that.closeFilterPopupUI();
        }
        if (!$(event.target).closest('.dropdown-menu').length && !$(event.target).hasClass('modal-open') && $(".more-filter-list").is(":visible")
            && (isWatchAnchorTag || event.target['id'] == '') && !$(event.target).closest('#more-filter-dropdown').length && !event.target.hasAttribute('bsdatepickerdaydecorator')) {
            that.closeMoreFilterPopup();
        }
    }

    ngAfterViewInit(): void {
        this.calculateWidthOfFilter();
    }

    calculateWidthOfFilter() {
        const parentWidth = $('.filter-comp-container').width();
        let widthOfTheFilters = 0;
        this.appliedFilters.map((filter, index) => {
            let textLengthOfTheFilter = 0;
            let text;
            if (Array.isArray(filter.valueLabel)) {
                text = filter.valueLabel.map(v => v.text).join(', ');
            } else if (filter.valueLabel.length) {
                text = filter.valueLabel;
            } else {
                const labels: any = Object.entries(Object.entries(filter.valueLabel));
                for (const [index, [key, val]] of labels) {
                    if (text === undefined) {
                        text = val;
                        if (labels.length > 1) {
                            text += ', ';
                        }
                    } else {
                        text += val;
                        if (index < (labels.length - 1)) {
                            text += ', ';
                        }
                    }
                }
            }
            const selectedField = this.availableFields.find((field) => field.ID === filter.ID);
            const selectedCondition = this.conditions[filter.type].find((condition) => condition.condition === filter.operator);
            if (selectedCondition) {
                selectedCondition.replaceText = selectedCondition.condition === 'range' ? 'Is between' : selectedCondition.replaceText;
            }
            textLengthOfTheFilter += selectedField.filterInterfaceLabel.length;
            textLengthOfTheFilter += selectedCondition ? selectedCondition.replaceText.length : 0;
            textLengthOfTheFilter += text.length;
            const flof = (textLengthOfTheFilter * 8) + 40;
            this.appliedFilters[index]['width'] = flof > 300 ? 300 : flof;
            widthOfTheFilters += this.appliedFilters[index]['width'];
            filter.fieldLabel = selectedField.filterInterfaceLabel;
            filter.conditionLabel = selectedCondition ? selectedCondition.replaceText : '';
            filter.valueLabelText = text;
            return filter;
        });
        this.viewableFilters = [];
        this.moreFilters = [];
        const avgWidthOfFilter = widthOfTheFilters / this.appliedFilters.length;
        const fwof = widthOfTheFilters + 125;
        if (parentWidth > fwof) {
            this.viewableFilters = this.appliedFilters;
        } else {
            let totalFiltersBlockSize = 0;
            this.appliedFilters.forEach((filter, index) => {
                totalFiltersBlockSize = totalFiltersBlockSize + filter.width;
                if (totalFiltersBlockSize < (parseInt(parentWidth) - 125)) {
                    this.viewableFilters.push(filter);
                } else {
                    this.moreFilters.push(filter);
                }
            });
        }
    }

    clearValueInput(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.filterForm.controls.value.setValue(null);
    }

    addFilter() {
        const data = this.filterForm.value;
        const selectedCondition = this.conditions[this.selectedFilter.filterType].find((cond) => cond.condition === data.condition);
        let value; let valueLabel;
        switch (this.selectedFilter.filterType) {
            case 'select':
                const chosedOption = this.selectedFilter.filterOptions.find((opt) => opt.id === data.value);
                value = valueLabel = chosedOption;
                break;
            case 'staticSingleSelect':
                value = data.value;
                const sssOption = this.selectedFilter.filterOptions.find((opt) => opt.id === data.value);
                valueLabel = sssOption.text;
                data.condition = 'relative';
                break;
            case 'staticMultiSelect':
                value = data.value;
                valueLabel = {};
                data.value.forEach((v) => {
                    const sfo = this.selectedFilter.filterOptions.find((opt) => opt.id === v);
                    value[v] = sfo.text;
                });
                break;
            case 'dynamic_filter':
                value = data.value;
                valueLabel = this.selectedFilterValues.find((sfv) => sfv.id === data.value);
                break;
            case 'dynamic_multiple_filter':
                value = data.value;
                valueLabel = {};
                data.value.forEach((v) => {
                    const fv = this.selectedFilterValues.find((sfv) => sfv.id == v)
                    valueLabel[v] = fv.text;
                });
                break;
            case 'dynamicFilterWithSearch':
                if (data.condition === 'logged_in_user') {
                    value = data.condition;
                    valueLabel = selectedCondition.replaceText;
                    data.condition = 'relative'
                } else {
                    value = data.value;
                    valueLabel = {};
                    data.value.forEach((v) => {
                        const fv = this.selectedFilterValues.find((sfv) => sfv.id == v)
                        valueLabel[v] = fv.text;
                    });
                }
                break;
            case 'dynamicMultipleSelect':
                value = data.value;
                valueLabel = {};
                data.value.forEach((v) => {
                    const fv = this.selectedFilter.filterOptions.find((sfv) => sfv.id == v)
                    valueLabel[v] = fv.text;
                });
                break;
            case 'subStringSearch':
                value = data.value;
                valueLabel = {};
                data.value.forEach((v) => {
                    valueLabel[v] = v;
                });
                break;
            case 'multipleOwnFilter':
                value = data.value;
                valueLabel = {};
                data.value.forEach((v) => {
                    valueLabel[v] = v;
                });
                break;
            case 'text':
                value = data.value;
                valueLabel = { id: data.value, text: data.value };
                break;
            case 'numeric':
                value = data.value;
                valueLabel = data.value;
                break;
            case 'date':
                let dateValue = {};
                let dateLabel;
                if (selectedCondition.showDatePicker) {
                    if (data.condition !== 'range') {
                            valueLabel = this.datePipe.transform(data.value);
                        dateValue['startDate'] = moment(data.value).format('YYYY-MM-DD') + ' 00:00:00';
                        value = [dateValue['startDate']];
                    } else {
                        dateLabel = {};
                        data.value.forEach((date, index) => {
                            if (index === 0) {
                                dateLabel['startDate'] = this.datePipe.transform(date);
                                dateValue['min'] = moment(date).format('YYYY-MM-DD') + ' 00:00:00';
                            } else {
                                dateLabel['endDate'] = this.datePipe.transform(date);
                                dateValue['max'] = moment(date).format('YYYY-MM-DD') + ' 23:59:59';
                            }
                        });
                        valueLabel = `${dateLabel.startDate} - ${dateLabel.endDate}`;
                        value = dateValue;
                    }
                } else {
                    value = data.condition;
                    valueLabel = selectedCondition.replaceText;
                    data.condition = 'relative'
                }
                break;
            default:
                value = data.value;
                valueLabel = data.value;
                break;
        }
        this.applyFilter.emit({
            deletedCheckboxSelected: false,
            ID: data.field,
            value,
            valueLabel,
            operator: data.condition,
            type: this.selectedFilter.filterType
        });
        this.closeFilterPopupUI();
        this.calculateWidthOfFilter();
        this.selectedFilterValues = [];
        this.setSelectedFilter();
        this.showCondition = true;
    }

    openFilterPopup() {
        this.clearForm();
        this.isPopupOpened = true;
        this.popUpElement.classList.add('popup-visible');
        if (this.screenType == 'workflow') {
            let pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({ left: pos.left + 12, top: '60px' });
            $(this.moreFilterElement).css({ top: '60px' });
            $('.aero').addClass('aero-less');
        } else if ((this.specificScreenType === 'driverIncidentDetails') && this.screenType === 'fleet') {
            let pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({position: 'fixed', left: pos.left + 12, top: '128px' });
            $(this.moreFilterElement).css({ top: '60px' });
            $('.aero').addClass('aero-less');
        } else if (this.specificScreenType === 'vehicleMileageHistory' && this.screenType === 'fleet') {
            let pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({position: 'fixed', left: pos.left + 12, top: '120px' });
            $(this.moreFilterElement).css({ top: '60px' });
            $('.aero').addClass('aero-less');
        } else {
            let pos = $('#add-filter-dropdown').position();
            $(this.popUpElement).css({ left: pos.left - 97 });
            $('.dropdown-menu.filter-popup').css({ top: pos.top + 36 });
        }        

        this.closeMoreFilterPopup();
    }

    closeFilterPopupUI() {
        if (this.screenType == 'workflow') {
            $('.aero').removeClass('aero-less');
        }
        this.isPopupOpened = false;
        this.popUpElement.classList.remove('popup-visible');
    }

    openMoreFilterPopup() {
        this.isMoreFilterPopupOpened = true;
        this.moreFilterElement.classList.add('popup-visible');
        let pos = $('#more-filter-dropdown').position();
        $(this.moreFilterElement).css({ left: pos.left - 138 });
        this.closeFilterPopupUI();
    }

    closeMoreFilterPopup() {
        this.isMoreFilterPopupOpened = false;
        this.moreFilterElement.classList.remove('popup-visible');
    }

    clearForm() {
        this.filterForm.reset();
        this.selectedFilter = null;
        this.showCondition = true;
        $("#filter_field_select").select2('val', '');
        $("#filter_condition_select").select2('val', '');
        $("#dynamic_filter_type").select2('val', '');
        $("#dynamic_multiple_filter_type").select2('val', '');
        $("#static_multi_select").select2('val', '')
        $("#static_single_select").select2('val', '')
        $("#multiple_select_search").select2('val', '');
        $("#dynamic_multi_select").select2('val', '');
        $('#multiple_own_filter').select2('val', '');
        $('#s2id_dynamic_filter_type').remove();
        $('#s2id_dynamic_multiple_filter_type').remove();
        $('#s2id_static_single_select').remove();
        $('#s2id_static_multi_select').remove();
        $('#s2id_multiple_select_search').remove();
        $('#s2id_multiple_own_filter').remove();
        $('#s2id_dynamic_multi_select').remove();
    }

    removeFilter(index: number, type: string) {
        this.deleteFilter.emit(type === 'more' ? index + this.viewableFilters.length : index);
        this.calculateWidthOfFilter();
        if (type === 'more') {
            this.closeMoreFilterPopup();
        }
        this.setSelectedFilter();
    }

    onSelectingField(field: any) {
        this.selectedFilter = this.availableFields.find(f => f.ID === field);
        if (this.selectedFilter.filterType === 'staticMultiSelect') {
            this.showCondition = true;
            this.select2ConfigStaticMultipleFilter['data'] = {
                results: this.selectedFilter.filterOptions
            }
            $('#s2id_static_single_select').remove();
        } else if (this.selectedFilter.filterType === 'dynamicMultipleSelect') {
            this.showCondition = true;
            this.select2ConfigDynamicMultiFilter['data'] = {
                results: this.selectedFilter.filterOptions
            };
            $('#s2id_static_single_select').remove();
        } else if (this.selectedFilter.filterType === 'staticSingleSelect') {
            this.showCondition = false;
            this.filterForm.controls.condition.clearValidators();
            this.filterForm.controls.condition.setErrors(null);
            $('#s2id_filter_condition_select').remove();
        } else if (this.selectedFilter.filterType === 'dynamicFilterWithSearch') {
            this.showCondition = true;
            this.showValueOption = false;
            $('#s2id_dynamic_multiple_filter_type').remove();
            $('#s2id_static_single_select').remove();
            setTimeout(() => { this.showValueOption = true; }, 100);
        } else {
            this.showCondition = true;
            $('#s2id_static_single_select').remove();
        }
        // For opportunity specific codes
        if (this.selectedFilter.ID === 'salesperson' || this.selectedFilter.ID === 'createdBy') {
            if (!this.conditions.dynamicFilterWithSearch.find((con) => con.condition === 'logged_in_user')) {
                this.conditions.dynamicFilterWithSearch.push({
                    condition: 'logged_in_user',
                    replaceText: 'Logged in user',
                    showValueOption: false
                })
            }
        } else {
            if (this.conditions.dynamicFilterWithSearch.find((con) => con.condition === 'logged_in_user')) {
                this.conditions.dynamicFilterWithSearch.splice(2, 1);
            }
        }

        this.filterForm.controls.condition.setValue(null);
        $("#filter_condition_select").select2('val', '');
        this.filterForm.controls.value.setValue(null);
        $("#dynamic_filter_type").select2('val', '');
        $("#dynamic_multiple_filter_type").select2('val', '');
        $("#static_multi_select").select2('val', '');
        $('#static_single_select').select2('val', '');
        $("#multiple_select_search").select2('val', '');
        $("#dynamic_multi_select").select2('val', '');
        $('#s2id_multiple_select_search').remove();
        $('#s2id_multiple_own_filter').remove();
        $('#s2id_dynamic_multiple_filter_type').remove();
        $('#s2id_dynamic_multi_select').remove()

        if (this.selectedFilter.filterDropDownService || this.selectedFilter['dropDownUrl']) {
            let url:any;
            if(this.screenType == 'fleet'){
                url = environment.apiHost + environment.fleet + this.selectedFilter.filterDropDownService;
            }else{
                url = this.selectedFilter['dropDownUrl'] || (environment.apiBasePath + this.selectedFilter.filterDropDownService);
            }
            this.select2ConfigDynamicFilter['ajax'] = this.select2ConfigDynamicMultipleFilter['ajax'] = {
                initSelection: () => { },
                url: url,
                data: (searchText) => {
                    if (searchText == '') {
                        return false;
                    }
                    let newQueries = {};
                    newQueries['screenType'] = this.screenType || '';
                    newQueries['q'] = encodeURIComponent(searchText);
                    return newQueries;
                },
                results: (data) => {
                    this.selectedFilterValues.push(...data);
                    return { results: data }
                },
            };
        } else {
            $('#s2id_dynamic_filter_type').remove();
            $('#s2id_dynamic_multiple_filter_type').remove();
        }
    }

    onSelectMultipleValues(ev) {
        this.filterForm.controls.value.setValue(ev);
    }

    onSelectingCondition(ev) {
        if (this.selectedFilter.filterType === 'date') {
            const selectedCondition = this.conditions['date'].find((cond) => cond.condition === ev);
            this.showDatePicker = selectedCondition.showDatePicker;
            if (selectedCondition.showDatePicker) {
                this.filterForm.controls.value.setValidators(Validators.required);
            } else {
                this.filterForm.controls.value.clearValidators();
                this.filterForm.controls.value.setErrors(null);
            }
        }
        // For oppt board specific
        if (ev === 'logged_in_user') {
            $("#dynamic_multiple_filter_type").select2('val', '');
            $('#s2id_dynamic_multiple_filter_type').remove();
            this.showValueOption = false;
            this.filterForm.controls.value.clearValidators();
            this.filterForm.controls.value.setErrors(null);
        } else {
            this.showValueOption = true;
            this.filterForm.controls.value.setValidators(Validators.required);
        }
    }

    setSelectedFilter() {
        this.availableFields = this.availableFields.map((field) => {
            const selectedFilter = this.appliedFilters.find((filter) => (field.ID === filter.ID));
            if (selectedFilter) {
                field.isSelected = true;
            } else {
                field.isSelected = false;
            }
            return field;
        }).sort((a,b) => a.isSelected - b.isSelected);
    }
}
