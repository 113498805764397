import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {SettingsService} from "@app/core";
import {BehaviorSubject, Observable, Subscription} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class SetupViewsService extends InterfaceService {

    private shareWithInformation = new BehaviorSubject('');
    shareWithInformation$ = this.shareWithInformation.asObservable();

    private shareWithMe = new BehaviorSubject('');
    shareWithMe$ = this.shareWithMe.asObservable();
    constructor(private http: HttpClient,
                private translate: TranslateService,
                private jwtToken: SettingsService) {
        super();
    }

    getProgressPanelData(screen) {
        const progressPanelData = [
            {
                stepText: this.translate.instant('Basic details'),
                id: 'basic_details',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: true,
                isStepDisable: false,
                stepPermission: true,
                sequence: 1
            },
            {
                stepText: this.translate.instant('share.with'),
                id: 'share_with',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 2
            }
        ];
        if(screen != 'opportunityViews') {
            progressPanelData.push({
                stepText: this.translate.instant('Card design'),
                id: 'card_design',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 3
            });
            progressPanelData.push({
                stepText: this.translate.instant('Filters'),
                id: 'filters',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 4
            })
        } else {
            progressPanelData.push({
                stepText: this.translate.instant('Filters'),
                id: 'filters',
                stepRouting: [],
                isStepRequired: true,
                sections: [],
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                stepPermission: true,
                sequence: 3
            })
        }
        return progressPanelData;
    }

    setShareWithInformationDetails(data){
        this.shareWithInformation.next(data);
    }

    getShareWithInformationDetails(){
        return this.shareWithInformation;
    }

    addNewViews(recordType: string, params: Object): Observable<any> {
        return this.http.post(this.getViewApiUrl(recordType, `add_views`), JSON.stringify(params));
    }

    reorderList(data: object, type: any): Observable<any> {
        return this.http.put(this.getApiUrl(`commonSettingsReorder?type=${type}`), data);
    }

    updateViewDetails(recordType: string, viewId?: any, params?: any): Observable<any> {
        return this.http.put(this.getViewApiUrl(recordType, `update_views/${viewId}`) , JSON.stringify(params));
    }

    getViewsDetails(recordType: string, viewId: any): Observable<any> {
        return this.http.get(this.getViewApiUrl(recordType, `get_views/${viewId}?recordType=${recordType}`));
    }

    getViewsObjects = function (recordType: string) {
        return this.http.get(this.getViewApiUrl(recordType, `get_views_metadata`));
    };

    previewViews(recordType: string, params: Object): Observable<any> {
        if(recordType === 'opportunity') {
            return this.http.post(this.getApiUrl('get_preview_opportunities'), params);
        } else {
            return this.http.post(this.getJobApiUrl(`preview_jobs`), JSON.stringify(params));
        }
    }

    deleteViews(recordType: string = 'job', params?: any): Observable<any> {
        return this.http.post(this.getViewApiUrl(recordType, `delete_views`), params);
    }

    checkViewTemplateExists(params?: any): Observable<any> {
        return this.http.get(this.getApiUrl('checkViewTemplateExists', params));
    }

    getViewApiUrl(recordType, path) {
        if(recordType === 'opportunity') {
            return this.getApiUrl(path);
        } else {
            return this.getJobApiUrl(path);
        }
    }

    getSettingsRolesList(recordType?: string, params?: any){
        if(recordType === 'opportunity') {
            return this.http.get(this.getApiUrl(`getSettingsRolesList`, params))
        } else {
            let httpOptions = new HttpHeaders().set('Authorization',params.v5Token);
            return this.http.get(this.getJobApiUrl(`getSettingsRolesList`, {}), {headers:httpOptions});
        }
    }
    setShareWithMe(data){
        this.shareWithMe.next(data);
    }
}
