import { Blocks } from "@app/workflow-common/services/Blocks";
import { CommonDataSource } from "@app/workflow-common/services/CommonDataSource";
var InitialBlock = /** @class */ (function () {
    function InitialBlock(id, parentId, name, conditions, rules, mainObjectName, dependentId) {
        if (dependentId === void 0) { dependentId = ''; }
        this.id = id;
        this.parentId = parentId;
        this.name = name;
        this.conditions = conditions;
        this.rules = rules;
        this.mainObjectName = mainObjectName;
        this.dependentId = dependentId;
    }
    return InitialBlock;
}());
export { InitialBlock };
var BlockModel = /** @class */ (function () {
    function BlockModel(data) {
        this.isConditional = false;
        if (data) {
            this.name = data['name'];
            this.parentId = data['parentId'];
            this.isConditional = data['isConditional'] ? data['isConditional'] : false;
            this.model = data['model'];
            this.key = data['key'];
            if (data['id']) {
                this.id = data['id'];
                this.blockId = this.name + '_' + this.id;
            }
            this.block_name = this.name;
        }
    }
    return BlockModel;
}());
export { BlockModel };
var DagSourceModel = /** @class */ (function () {
    function DagSourceModel(data) {
        if (data === void 0) { data = undefined; }
        this.dagObject = {};
        this.blockObjects = {};
        this.endBlocks = [];
        this.mainObjectName = '';
        this.eventName = '';
        this.initialBlockId = 'cs_initializer';
        this.rootID = 0;
        this.nextIncrementalID = 1;
        this._blocks = new Blocks();
        if (data) {
            this.blockObjects = data['blockObjects'];
            this.eventName = data['eventName'];
            this.mainObjectName = data['mainObjectName'];
            this._buildData();
        }
    }
    DagSourceModel.prototype.hasInitBlock = function () {
        return this.blockObjects.hasOwnProperty(this.initialBlockId);
    };
    DagSourceModel.prototype.hasActionBlock = function () {
        return this.endBlocks.length > 0;
    };
    DagSourceModel.prototype.setEventName = function (value) {
        this.eventName = value;
    };
    DagSourceModel.prototype.setMainObject = function (value) {
        this.mainObjectName = value.toLowerCase();
    };
    DagSourceModel.prototype.setFieldMetadata = function (data) {
        if (data.length == 0) {
            return;
        }
        var tmp = {};
        data.forEach(function (field) {
            tmp[field.col_name] = field;
        });
        this._fieldMetaData = tmp;
    };
    DagSourceModel.prototype.getFieldMetadata = function () {
        return this._fieldMetaData;
    };
    DagSourceModel.prototype.setEventsList = function (data) {
        if (data.length == 0) {
            return;
        }
        var tmp = {};
        data.forEach(function (item) {
            tmp[item.id] = item;
        });
        this._eventsList = tmp;
    };
    DagSourceModel.prototype.getEventsList = function () {
        return this._eventsList;
    };
    DagSourceModel.prototype.setTriggerCommonService = function (service) {
        this._triggerCommonService = service;
    };
    DagSourceModel.prototype.getInitialBlockId = function () {
        return this.initialBlockId;
    };
    DagSourceModel.prototype.getNextIncrementalID = function () {
        return this.nextIncrementalID;
    };
    DagSourceModel.prototype.getBlockById = function (id) {
        if (!this.blockObjects.hasOwnProperty(id)) {
            return null;
        }
        return this.blockObjects[id];
    };
    DagSourceModel.prototype.getBlockByParent = function (parentId) {
        var _a = this, dagObject = _a.dagObject, blockObjects = _a.blockObjects;
        if (!dagObject.hasOwnProperty(parentId)) {
            return [];
        }
        var childrenBlocksIds = dagObject[parentId];
        var blocks = [];
        childrenBlocksIds.forEach(function (blockId) {
            blocks.push(blockObjects[blockId]);
        });
        return blocks;
    };
    DagSourceModel.prototype.getBlocksSource = function () {
        return this._blocks;
    };
    DagSourceModel.prototype.addTask = function (data, parentId) {
        if (parentId === void 0) { parentId = 0; }
        this._generateNextBlockId();
        var id = this.nextIncrementalID;
        if (parentId == this.rootID) {
            var blockID = 'cs_initializer';
            var initBlock = new InitialBlock('cs_initializer', parentId, 'initializer', data['conditions'], data['rules'], this.mainObjectName, '');
            initBlock.block_name = 'initializer';
            this.blockObjects[blockID] = initBlock;
        }
        else {
            if (!data['id']) {
                data['id'] = id;
            }
            var blockID = data['name'] + '_' + data['id'];
            data['parentId'] = parentId;
            data['blockId'] = blockID;
            var taskBlock = new BlockModel(data);
            taskBlock.block_name = data['name'];
            this.blockObjects[blockID] = taskBlock;
            console.log('addTask', this.blockObjects);
        }
        this._buildData();
    };
    DagSourceModel.prototype.removeTask = function (blockId) {
        var blockObjects = this.blockObjects;
        console.log('blockObjects', blockObjects);
        if (!blockObjects.hasOwnProperty(blockId)) {
            return false;
        }
        delete blockObjects[blockId];
        this._buildData();
        return true;
    };
    DagSourceModel.prototype._getBlockObjectsByKey = function () {
        var blockObjects = this.blockObjects;
        var _tmpBlockObjects = {};
        for (var _i = 0, _a = Object.entries(blockObjects); _i < _a.length; _i++) {
            var _b = _a[_i], blockId = _b[0], node = _b[1];
            _tmpBlockObjects[node['id']] = node;
        }
        return _tmpBlockObjects;
    };
    DagSourceModel.prototype._generateNextBlockId = function () {
        var _this = this;
        if (this.isObjectEmpty(this.blockObjects) || Object.keys(this.blockObjects).length == 1) {
            this.nextIncrementalID = 1;
        }
        else {
            var blockIdBag_1 = [];
            Object.values(this.blockObjects).forEach(function (block) {
                if (block['id'] == _this.initialBlockId) {
                    return;
                }
                blockIdBag_1.push(parseInt(block['id']));
            });
            var lastId = Math.max.apply(Math, blockIdBag_1);
            this.nextIncrementalID = lastId + 1;
        }
    };
    DagSourceModel.prototype._buildData = function () {
        var _this = this;
        var blockObjects = this._getBlockObjectsByKey();
        var blockIds = Object.keys(blockObjects);
        var blocksCount = blockIds.length;
        if (blocksCount == 0) {
            return;
        }
        if (blocksCount == 1) {
            this.dagObject[this.initialBlockId] = [];
            this.endBlocks = [];
        }
        else {
            var _tmpDagObject_1 = {};
            _tmpDagObject_1[this.initialBlockId] = [];
            var firstChildren = Object.values(blockObjects).filter(function (item) { return item['parentId'] == _this.initialBlockId; });
            firstChildren.forEach(function (child) {
                _tmpDagObject_1[_this.initialBlockId].push(child['id']);
            });
            var flattened_1 = function (arr) { return [].concat.apply([], arr); };
            var _generateEndBlocks = function () {
                if (_this.isObjectEmpty(_this.dagObject)) {
                    return [];
                }
                var endBlocks = [];
                var valuesOfDagObjects = Object.values(_this.dagObject);
                var keysOfDagObject = Object.keys(_this.dagObject);
                var flattenDags = flattened_1(valuesOfDagObjects);
                endBlocks = flattenDags.filter(function (id) {
                    return !keysOfDagObject.includes(id);
                });
                _this.endBlocks = endBlocks;
            };
            var _generateDagObject_1 = function (blockId) {
                if (_tmpDagObject_1[blockId].length) {
                    _tmpDagObject_1[blockId].forEach(function (_id) {
                        var nodes = Object.values(blockObjects).filter(function (item) { return item['parentId'] == _id; });
                        if (nodes.length) {
                            _tmpDagObject_1[_id] = [];
                            nodes.forEach(function (node) {
                                _tmpDagObject_1[_id].push(node['id']);
                            });
                            _generateDagObject_1(_id);
                        }
                    });
                }
            };
            _generateDagObject_1(this.initialBlockId);
            var _ids = Object.keys(_tmpDagObject_1);
            _ids.forEach(function (_id) {
                var _blockId = _id == _this.initialBlockId ? _this.initialBlockId : blockObjects[_id]['name'] + '_' + _id;
                _this.dagObject[_blockId] = [];
                var _nodes = _tmpDagObject_1[_id];
                _nodes.forEach(function (nodeId) {
                    _this.dagObject[_blockId].push(blockObjects[nodeId]['name'] + '_' + nodeId);
                });
            });
            _generateEndBlocks();
            this._generateNextBlockId();
        }
    };
    DagSourceModel.prototype.getBlockDisplayMessageDelta = function (blockId) {
        if (blockId === void 0) { blockId = ''; }
        if (!blockId) {
            return [];
        }
        var blockObjects = this.blockObjects;
        if (!blockObjects[blockId]) {
            console.warn("Block does not exists...");
            return ['do something'];
        }
        var block = blockObjects[blockId];
        var blockSourceData = this.getBlocksSource().getBlockByKey(block['key']);
        var delta = blockSourceData['displayText'] ? blockSourceData['displayText'].slice() : [];
        var model = block.model;
        var sourceItem = undefined;
        if (block['name'] === 'smsBlock') {
            sourceItem = model['toSmsTag'];
        }
        else if (block['name'] === 'emailBlock' || block['name'] === 'sendProposalBlock' || block['name'] == 'sendJobReportBlock') {
            sourceItem = model['toEmailTag'];
        }
        else if (block['name'] === 'notificationBlock') {
            sourceItem = [];
            var rolesDisplay = Array.isArray(model['rolesDisplay']) && model['rolesDisplay'].length ? model['rolesDisplay'] : [];
            rolesDisplay.forEach(function (item) {
                sourceItem.push({ displayName: '"' + item + '"' });
            });
        }
        else if (block['name'] == 'changeStageBlock') {
            sourceItem = [
                '"' + model.stageDisplayName + '"'
            ];
        }
        else if (block['name'] == 'scheduledActivityBlock') {
            //Create email activity and schedule next 2 weeks at 8pm.
            var activityTypeDisplay = model.activityTypeDisplay, displayTime = model.displayTime, scheduleInterval = model.scheduleInterval, unit = model.unit;
            var a_or_an = activityTypeDisplay == 'Email' ? 'an' : 'a';
            var scheduleText = '';
            if (scheduleInterval == 0) {
                scheduleText = ' at ' + displayTime;
            }
            else if (scheduleInterval == 1) {
                scheduleText = ' to the next ' + unit.slice(0, -1) + ' at ' + displayTime;
            }
            else {
                scheduleText = ' to the next ' + scheduleInterval + ' ' + unit + ' at ' + displayTime;
            }
            var tmpDelta = delta[0]
                .replace('#activityTypeDisplay#', activityTypeDisplay.toLowerCase())
                .replace('#a_or_an#', a_or_an) + scheduleText;
            delta = [tmpDelta];
        }
        else if (block['name'] == 'startSequenceBlock') {
            var tmpDelta = delta[0]
                .toLowerCase()
                .replace('#templatename#', '"' + model['templateName'] + '"');
            delta = [tmpDelta, ' to the '];
            var contactItem = CommonDataSource.contactsList.find(function (item) { return model['contactId'] == item.id; });
            if (contactItem) {
                var contactName = '"' + contactItem['text'] + '"';
                sourceItem = [contactName];
            }
        }
        else if (block['name'] == 'setSalesPersonBlock') {
            var tmpDelta = delta[0]
                .toLowerCase()
                .replace('#salesperson#', model['displayValue']);
            delta = [tmpDelta];
        }
        else if (block['name'] == 'AddJobBlock') {
            var tmpDelta = delta[0]
                .toLowerCase()
                .replace('#jobdescription#', model['displayValue']);
            delta = [tmpDelta];
        }
        if (!sourceItem) {
            return delta;
        }
        if (Array.isArray(sourceItem)) {
            var beforeLastIndex_1 = sourceItem.length - 1;
            delta.push(' ');
            sourceItem.forEach(function (lineItem, index) {
                var cnt = index + 1;
                var displayName = typeof lineItem == 'string' ? lineItem : lineItem['displayName'];
                delta.push(displayName);
                if (beforeLastIndex_1 == cnt) {
                    delta.push(' ', 'and', ' ');
                }
                else if (beforeLastIndex_1 > 1 && cnt != (beforeLastIndex_1 + 1)) {
                    delta.push(',', ' ');
                }
            });
        }
        return delta;
    };
    DagSourceModel.prototype.getDisplayMessageDelta = function () {
        var _this = this;
        var blockObjects = this.blockObjects;
        var initBlock = blockObjects[this.initialBlockId];
        var eventsList = this.getEventsList();
        var rules = initBlock.rules;
        var displayDelta = {
            event: [],
            rules: []
        };
        if (this.eventName && eventsList.hasOwnProperty(this.eventName)) {
            displayDelta['event'].push(eventsList[this.eventName]['text'], 'with');
        }
        if (rules.length == 0) {
            return displayDelta;
        }
        var operatorsDisplayText = this._triggerCommonService.getOperatorsDisplayText();
        var beforeLastIndex = rules.length - 1;
        rules.forEach(function (rule, index) {
            var cnt = index + 1;
            var display = [];
            var fieldData = _this._fieldMetaData[rule['col_name']];
            var fieldDisplayName = fieldData['text'];
            var operatorDisplayName = operatorsDisplayText[rule['operator']];
            var valueDisplayText = rule['value'];
            if (fieldData.hasOwnProperty('options')) {
                if (Array.isArray(rule['value'])) {
                    var tmp_1 = [];
                    rule['value'].forEach(function (id) {
                        var activeOption = fieldData.options.find(function (item) {
                            return item.id == id;
                        });
                        if (activeOption) {
                            tmp_1.push(activeOption['text']);
                        }
                    });
                    valueDisplayText = tmp_1.join(',');
                }
                else {
                    var activeOption = fieldData.options.find(function (item) {
                        return item.id == rule['value'];
                    });
                    if (activeOption) {
                        valueDisplayText = activeOption['text'];
                    }
                }
            }
            display.push(fieldDisplayName.toLowerCase(), ' ', operatorDisplayName, ' ', '"', valueDisplayText, '"');
            displayDelta['rules'].push(display);
            if (beforeLastIndex == cnt) {
                displayDelta['rules'].push([' ', 'and', ' ']);
            }
            else if (beforeLastIndex > 1 && cnt != (beforeLastIndex + 1)) {
                displayDelta['rules'].push([',', ' ']);
            }
        });
        return displayDelta;
    };
    DagSourceModel.prototype.getModel = function () {
        this._buildData();
        var _a = this, dagObject = _a.dagObject, blockObjects = _a.blockObjects, endBlocks = _a.endBlocks, mainObjectName = _a.mainObjectName, eventName = _a.eventName;
        return {
            dagObject: dagObject,
            blockObjects: blockObjects,
            endBlocks: endBlocks,
            mainObjectName: mainObjectName,
            eventName: eventName
        };
    };
    DagSourceModel.prototype.isObjectEmpty = function (objectName) {
        return Object.keys(objectName).length === 0;
    };
    DagSourceModel.prototype.getSmsTagsByType = function (workflowType, objectName) {
        if (objectName === void 0) { objectName = 'all'; }
        var smsTags = {
            'Custom': [
                { 'id': 't_0', 'text': '[customer_mobile]', 'name': 'TAGS' },
                { 'id': 't_3', 'text': '[job_contact_mobile]', 'name': 'TAGS' },
                { 'id': 't_4', 'text': '[invoice_address_mobile]', 'name': 'TAGS' },
                { 'id': 't_2', 'text': '[job_address_primary_contact_mobile]', 'name': 'TAGS' },
            ],
            'Sequence': [
                { 'id': 't_0', 'text': '[sequence_contact_mobile]', 'name': 'TAGS' },
            ],
            'PipelineAutomation': [
                { 'id': 't_0', 'text': '[opportunity_customer_contact_mobile]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Customer contact mobile' },
                { 'id': 't_1', 'text': '[opportunity_address_mobile]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Opportunity mobile address' },
                { 'id': 't_2', 'text': '[customer_mobile]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Customer mobile number' },
                { 'id': 't_3', 'text': '[job_contact_mobile]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job contact mobile number' },
                { 'id': 't_4', 'text': '[job_address_primary_contact_mobile]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job primary contact mobile number' },
            ]
        };
        if (!smsTags[workflowType]) {
            return {};
        }
        var _selectedEmailTags = smsTags[workflowType];
        if (objectName === 'all') {
            return _selectedEmailTags;
        }
        return _selectedEmailTags.filter(function (tag) { return tag['objectName'] == objectName; });
    };
    DagSourceModel.prototype.getEmailAddressTagsByType = function (workflowType, objectName) {
        if (objectName === void 0) { objectName = 'all'; }
        var emailTags = {
            'Custom': [
                { 'id': 't_0', 'text': '[customer_email]', 'name': 'TAGS' },
                { 'id': 't_3', 'text': '[job_contact_email]', 'name': 'TAGS' },
                { 'id': 't_4', 'text': '[invoice_address_email]', 'name': 'TAGS' },
                { 'id': 't_2', 'text': '[job_address_primary_contact_email]', 'name': 'TAGS' },
            ],
            'Sequence': [
                { 'id': 't_0', 'text': '[sequence_contact_email]', 'name': 'TAGS' },
            ],
            'PipelineAutomation': [
                { 'id': 't_0', 'text': '[opportunity_customer_contact_email]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Customer contact email' },
                { 'id': 't_1', 'text': '[opportunity_address_email]', 'name': 'TAGS', 'objectName': 'opportunity', displayText: 'Opportunity email address' },
                { 'id': 't_2', 'text': '[customer_email]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Customer email address' },
                { 'id': 't_3', 'text': '[job_contact_email]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job contact email address' },
                { 'id': 't_4', 'text': '[job_address_primary_contact_email]', 'name': 'TAGS', 'objectName': 'job', displayText: 'Job primary contact email address' },
            ]
        };
        if (!emailTags[workflowType]) {
            return {};
        }
        var _selectedEmailTags = emailTags[workflowType];
        if (objectName === 'all') {
            return _selectedEmailTags;
        }
        return _selectedEmailTags.filter(function (tag) { return tag['objectName'] == objectName; });
    };
    DagSourceModel.prototype.getDisplayDescriptionContent = function (actionType, stageName, objectName, blockName, isSkipCommunication) {
        if (isSkipCommunication === void 0) { isSkipCommunication = false; }
        if (!blockName) {
            return [];
        }
        var blockObjects = this.blockObjects;
        if (!blockObjects[blockName]) {
            console.warn("Block does not exists...");
            return ['do something'];
        }
        var block = blockObjects[blockName];
        var blockSourceData = this.getBlocksSource().getBlockByKey(block['key']);
        var delta;
        if (!isSkipCommunication) {
            delta = blockSourceData['activityDescription'] ? blockSourceData['activityDescription'].slice() : [];
        }
        else {
            delta = blockSourceData['skipMessage'] ? blockSourceData['skipMessage'].slice() : [];
        }
        if (actionType == 1) {
            delta = delta[0]
                .replace('#actionType#', objectName + ' ' + 'enters')
                .replace('#stageName#', stageName);
        }
        else if (actionType == 2) {
            if (isSkipCommunication) {
                delta = delta[0]
                    .replace('#actionType#', objectName + ' ' + 'moved out of')
                    .replace('#stageName#', stageName);
            }
            else {
                delta = delta[0]
                    .replace('#actionType#', objectName + ' ' + 'moves out of')
                    .replace('#stageName#', stageName);
            }
        }
        else if (actionType == 4) {
            delta = delta[0]
                .replace('#actionType#', objectName + ' ' + 'is in')
                .replace('#stageName#', stageName);
        }
        else {
            delta = delta[0]
                .replace('#stageName#', stageName)
                .replace('#toStageName#', block.model.stageDisplayName);
        }
        return delta;
    };
    return DagSourceModel;
}());
export { DagSourceModel };
