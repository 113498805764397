import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from "@app/interfaces";
import * as moment from "moment";
import { TimelineDatePipe } from '@app/pipes/timeline-date.pipe';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../pipes/timeline-date.pipe";
var TimelineService = /** @class */ (function (_super) {
    tslib_1.__extends(TimelineService, _super);
    function TimelineService(http, timelineDateTime) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.timelineDateTime = timelineDateTime;
        _this.triggerEvent = new BehaviorSubject(null);
        _this.triggerEvent$ = _this.triggerEvent.asObservable();
        _this.vehicleSearchActivity = new BehaviorSubject('');
        _this.vehicleSearchActivity$ = _this.vehicleSearchActivity.asObservable();
        _this.reloadTimelineListData = new BehaviorSubject('');
        _this.reloadTimelineListData$ = _this.reloadTimelineListData.asObservable();
        _this.fileTypeGroups = {
            image: ['jpg', 'png', 'gif', 'jpeg', 'PNG', 'JPEG', 'JPG', 'GIF'],
            pdf: ['pdf'],
            csv: ['csv'],
            word_document: ['doc', 'docx'],
            excel: ['xls', 'xlsx', 'ods', 'xlr'],
            audio: ['mp3', 'mpa', 'ogg', 'mid', 'midi'],
            video: ['avi', 'flv', 'mov', 'mp4', 'mpg', 'mpeg'],
            compressed: ['7z', 'rar', 'zip', 'tar.gz'],
            power_point: ['odp', 'pps', 'ppt', 'pptx'],
        };
        return _this;
    }
    TimelineService.prototype.getTimelineData = function (screen, primaryId, filters, portalAccess) {
        return this.http.get(this.getApiUrl("shared/getTimelineData/" + screen + "/" + primaryId + "?portalAccess=" + portalAccess), filters);
    };
    TimelineService.prototype.checkTimeLineHeaderDate = function (date) {
        var value = moment(date).calendar(null, {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]'
        });
        if (value == 'Today' || value == 'Tomorrow' || value == 'Yesterday') {
            return value;
        }
        else {
            return null;
        }
    };
    TimelineService.prototype.convertTimeLineHeaderDate = function (date) {
        return this.timelineDateTime.transform(date, 'date');
    };
    TimelineService.prototype.convertTimeLineDateTime = function (date) {
        return this.timelineDateTime.transform(date, 'datetime');
    };
    TimelineService.prototype.getDateDifference = function (date) {
        var now = moment(new Date());
        var fromDate = moment(date);
        var duration = moment.duration(fromDate.diff(now));
        return duration.humanize(true);
    };
    TimelineService.prototype.diaryDateTimeRange = function (fromDateTime, toDateTime, allDay, isSpecialEvent, duration) {
        fromDateTime = moment.utc(fromDateTime);
        toDateTime = moment.utc(toDateTime);
        var value = '';
        if (allDay === false) {
            if (isSpecialEvent) {
                value = this.timelineDateTime.transform(fromDateTime, 'date') + ' ' + duration + ' (' + this.timelineDateTime.transform(fromDateTime, 'diarytime') + ' - ' + this.timelineDateTime.transform(toDateTime, 'diarytime') + ')';
            }
            else {
                value = this.timelineDateTime.transform(fromDateTime, 'date') + ' (' + this.timelineDateTime.transform(fromDateTime, 'diarytime') + ' - ' + this.timelineDateTime.transform(toDateTime, 'diarytime') + ')';
            }
        }
        else {
            value = this.timelineDateTime.transform(fromDateTime, 'date') + ' ' + allDay;
        }
        return value;
    };
    TimelineService.prototype.getFileGroup = function (fileType) {
        var fileGroup = 'unknown';
        fileType = fileType.toLowerCase(); // Convert fileType to lowercase
        for (var key in this.fileTypeGroups) {
            if (this.fileTypeGroups.hasOwnProperty(key)) {
                var value = this.fileTypeGroups[key];
                if (value.indexOf(fileType) != -1) {
                    fileGroup = key;
                    break;
                }
            }
        }
        return fileGroup;
    };
    TimelineService.prototype.convertTimeLineScheduledDateTime = function (date) {
        date = moment.utc(date);
        return this.timelineDateTime.transform(date, 'datetime');
    };
    TimelineService.prototype.getJobData = function () {
        return this.JobsTimelineData;
    };
    TimelineService.prototype.setJobData = function (data) {
        this.JobsTimelineData = data;
    };
    TimelineService.prototype.getVehicleTimeline = function (id) {
        return this.http.get(this.getFleetApiUrl("" + id + "/getVehicleTimeline"));
    };
    TimelineService.prototype.getJobPartsDetails = function (ids) {
        var url = this.getApiUrl("job_parts_details/" + ids);
        return this.http.get(url);
    };
    TimelineService.prototype.loadVehicleTimelineData = function () {
        this.triggerEvent.next(true);
    };
    TimelineService.prototype.timelineSearchActivity = function (data) {
        this.vehicleSearchActivity.next(data);
    };
    TimelineService.prototype.saveFilesData = function (jobId, files, shareOnMobile, deleteIds, id) {
        var formData = new FormData();
        if (files != undefined) {
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i]);
            }
        }
        formData.append("shareOnMobile", shareOnMobile);
        if (deleteIds) {
            formData.append("deleteIds", deleteIds);
        }
        if (id) {
            formData.append("id", id);
        }
        var url = this.getApiUrl("upload_job_files/" + jobId);
        return this.http.post(url, formData);
    };
    TimelineService.prototype.getJobStatusDetailsNew = function (jobId) {
        return this.http.get(this.getApiUrl("job_status_details/" + jobId));
    };
    TimelineService.prototype.setRefreshTimelineActivityList = function (data) {
        this.reloadTimelineListData.next(data);
    };
    TimelineService.prototype.getJobPartsPoDetails = function (partId, id, jobId) {
        var url = this.getApiUrl("getJobPartViewList?partId=" + partId + "&id=" + id + "&jobId=" + jobId + "&status=On_Order");
        return this.http.get(url);
    };
    TimelineService.ngInjectableDef = i0.defineInjectable({ factory: function TimelineService_Factory() { return new TimelineService(i0.inject(i1.HttpClient), i0.inject(i2.TimelineDatePipe)); }, token: TimelineService, providedIn: "root" });
    return TimelineService;
}(InterfaceService));
export { TimelineService };
