import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSidePanelComponent } from './progress-side-panel.component';
import {TranslateModule} from "@ngx-translate/core";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {CsScrollbarModule} from "@app/directives/cs-scrollbar/cs-scrollbar.module";
import { ProgressSideMenuComponent } from './progress-side-menu/progress-side-menu.component';

@NgModule({
    declarations: [ProgressSidePanelComponent, ProgressSideMenuComponent],
    exports: [
        ProgressSidePanelComponent,
        ProgressSideMenuComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TooltipModule.forRoot(),
        CsScrollbarModule
    ]
})
// export class ProgressSidePanelModule { }

export class ProgressSidePanelModule {
    static forRoot() {
        return {
            ngModule: ProgressSidePanelModule
        };
    }
}
