import {
    Component,
    Input,
    OnInit,
    ApplicationRef,
    ComponentFactoryResolver,
    ViewChild,
    AfterViewInit,
    Output,
    EventEmitter,
    HostListener,
    ViewEncapsulation,
    ElementRef, QueryList, OnChanges, SimpleChanges, Inject
} from '@angular/core';
import { DataTableDirective} from "angular-datatables";
import {Subject, from, noop, Observable, of} from "rxjs/index";
import { DomService } from "@app/smart_table/services/dom.service";
import { HttpClient } from '@angular/common/http';
import {pluck} from "rxjs/operators";
import { SmartTableInstanceService } from "@app/smart_table/services/smarttable.instance.service";
import {environment} from "../../../environments/environment";
import { SmartTableMetaDataService } from "@app/smart_table/services/metadata.service";
import {filter, map} from "rxjs/internal/operators";

declare var $;

class DataTablesResponse {
    data: any[];
    draw: number = 0;
    recordsFiltered: number;
    recordsTotal: number;
}

export interface ActionButtonsInterface {
    title: string,
    permissions: any[],
    callBack: Function,
    visible: Function
}

export interface MetadataInterface {
    title: string,
    columns: any[],
    sort: any,
    limit: number
}

@Component({
    selector: 'app-smart-table',
    templateUrl: './smart-table.component.html',
    styleUrls: ['./smart-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SmartTableComponent implements AfterViewInit, OnInit, OnChanges {

  _rowSelect:boolean = false;

  @Input() metadata: any;
  @Input() emptyMessage: string = 'No records found';
  @Input() dataUrl: string;
  @Input() settings: Object;
  @Input() actions: Array<ActionButtonsInterface>;
  @Input() isServerProcessing: boolean = true;
  @Input() sorting: boolean = true;
  @Input() columnEdit: any = true;
  // @Input() rowSelect: boolean = false;
  @Input() set rowSelect(value:boolean) {
    this._rowSelect = value;
    const isCheckbox = this.columns.findIndex( ( item ) => {
      return item.data === '@empty_select';
    });

    if(this.columns.length === 0) {
        //first time
        this.columns.push(this.checkBoxColumn(this._rowSelect));
        return;
    }
    if(isCheckbox > -1) {
      this.onShowHideColumn({
        columnIndex: 0,
        visible: this._rowSelect
      });
    }
  };
  @Input() params: object = {};
  @Input() category: string;
  @Input() subcategory: string ='';
  @Input() paramName: string = 'filter';

  @Output() $instance: EventEmitter<any> = new EventEmitter();
  @Output() onSelectCheckbox: EventEmitter<any> = new EventEmitter();
  @Output() onSelectAllCheckbox: EventEmitter<any> = new EventEmitter();
  @Output() afterRender: EventEmitter<any> = new EventEmitter<any>();
  @Output() $dataCount: EventEmitter<any> = new EventEmitter();

  @ViewChild(DataTableDirective, {static: false} as any) datatableElement: DataTableDirective;
  @ViewChild("containerId") container: ElementRef;

  noDisplay: boolean = false;
  loadingFirstTime:boolean = true;

  columns: any[] = [];
  limit: number = 20;
  dtOptions:any = {};
  dtTrigger: Subject<any> = new Subject();
  toggleData: object = {};
  actionType: any = null;
  columnsCount: number=0;
  loading: boolean = true;
  selectAllCheckbox: boolean = false;
  columnsSaveUrl: string = 'userColumnOrder';

  _headerHash:string;
  _headerOldHash:string;

  constructor(
      @Inject('$scope') private ajsScope,
      private appRef: ApplicationRef,
      private componentFactoryResolver: ComponentFactoryResolver,
      private domService: DomService,
      private http: HttpClient,
      public smartTableInstance: SmartTableInstanceService,
      private metadataService: SmartTableMetaDataService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.hasOwnProperty('rowSelect')) {
      this.columnsAdjust();
    }
  }

  ngOnInit() {
      this.metadataService.initiaze(this.metadata);
      if(this.columns.length) {
          this.columns = this.columns.concat( this.metadataService.getColumns() );
      }
      else {
          this.columns = this.metadataService.getColumns();
      }

      this.limit = this.metadataService.getLimit();
      this.renderTable();
  }

  private defaultProps() {
    let that = this;
    return {
      dom: 'rtipl',
      // dom: 'lrtip',
      initComplete: () => {
          //that.columnsAdjust(50);
      },
      lengthMenu: [[10, 20, 50, 100], [10, 20, 50, 100]],
      autoWidth: false,
      serverSide: true,
      sPaginationType: "bootstrap",
      processing: true,
      pagingType: 'full_numbers',
      bScrollCollapse: true,
      //sScrollX: "100%",
      scrollX: true,
      colReorder: true,
      aaSorting: [],
      destroy: true,
      select: true,
      //"order": [[ 1, "desc" ], [ 3, "asc" ]],
      responsive: false,
      ajax: (dataTablesParameters: any, callback) => {
        //console.log('params', that.paramsTransform(dataTablesParameters), dataTablesParameters);
        const params = that.paramsTransform(dataTablesParameters);
        that._headerOldHash = that.getHeaderHash();
        let headerData = {
          limit: dataTablesParameters.length,
          order: dataTablesParameters.order
        };
        that.setHeaderHash(headerData);
        if(!that._headerOldHash) {
            that._headerOldHash = that.getHeaderHash();
        }
        // console.log('hashOld', that._headerOldHash);
        // console.log('hashNew', that.getHeaderHash());
        that.loading=true;
        that.http.post<DataTablesResponse>(
            that.getApiUrl(that.dataUrl, params['smartTableParams']), params, {}
        ).subscribe(resp => {
            const _resonseDatas = resp[that.category];
            that.smartTableInstance.setDatas(_resonseDatas.data);
            that.loadingFirstTime=false;
            that.loading=false;
            callback({
                recordsTotal: parseInt(_resonseDatas.count),
                recordsFiltered: parseInt(_resonseDatas.count),
                data: []
            });
            this.$dataCount.emit(_resonseDatas.count);
            this.ajsScope.$emit('setTableRowCount', _resonseDatas.count);
        })
      },
      preDrawCallback: () => {
        this.columnsAdjust();
      },
      drawCallback: (settings) => {
        if(that._headerOldHash !== that.getHeaderHash()) {
            const { oAjaxData } = settings;
            that.metadata.limit = oAjaxData.length;
            that.onSaveColumns({columns:that._prepareSaveColumns()}, false);
            // console.log('update column save', that.metadata, that._prepareSaveColumns());
        }
        setTimeout( () => {
          this.selectAllCheckbox = false;
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.columns.adjust();
            $('.cs-smart-table-container thead th').off('mousedown');
          })
          this.afterRender.emit();
        },50);
      },
      language: {
          processing: '<div class="spreadsheet-loading-wrap">\n' +
          ' <span class="loading-spreadsheet">\n' +
          '  <span class="loading-x-axis"></span>\n'  +
          '  <span class="loading-y-axis"></span>\n'  +
          '  <div class="loading-row row-1"></div>\n' +
          '  <div class="loading-row row-2"></div>\n' +
          '  <div class="loading-row row-3"></div>\n' +
          '  <div class="loading-row row-4"></div>\n' +
          '  <div class="loading-row row-5"></div>\n' +
          ' </span>\n' +
          '</div>',
          lengthMenu: "Show _MENU_ entries"
      }
    };
  }

  setHeaderHash(params:any):void {
      let headerData = {
          limit: params.limit,
          order: params.order
      };
      this._headerHash = btoa(JSON.stringify(headerData));
  }

  getHeaderHash():string | null {
      return this._headerHash;
  }

  renderTable(): void {
    let options: any = this.defaultProps();
    options.pageLength = this.limit;
    options.columns = this.prepareColumns();
    options.order = this.prepareSorting();
    if(!options.columns.length) {
        throw new Error(`Columns must not be empty.`);
    }
    this.dtOptions = options;
  }

  toggleActions(event: any) {
    this.toggleData=event;
  }

  ngAfterViewInit(): void {
      //this.dtTrigger.next();
      this.smartTableInstance.datatableElement = this.datatableElement;
      this.smartTableInstance.dtTrigger = this.dtTrigger;
      this.$instance.emit(this.smartTableInstance);
      this.smartTableInstance.container = this.container;
      this.smartTableInstance.setComponent(this);

      this.datatableElement.dtInstance.then( (dtInstance: DataTables.Api) => {
          const table = dtInstance.table('#cs-smart-table-v2');
          table.on('draw.dt', () => {
             setTimeout( () => {
                $('.dataTables_scrollHead thead tr th').each((idx:any, ele:any)  => {
                let xPos = $(ele).textWidth() + 20;
                $(ele).css('background-position-x',  xPos + 'px')
              })
            },100);
          });
      });

      $(window).on('resize', () => {
          this.datatableElement.dtInstance.then((dtInstance: any) => {
              dtInstance.columns.adjust();
          });
      });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.smartTableInstance.empty();
  }

  onShowHideColumn(event:any) {
    this.datatableElement.dtInstance.then( (dtInstance: DataTables.Api) => {
      dtInstance.columns([event.columnIndex]).visible(event.visible);
      this.columns[event.columnIndex].visible = event.visible;
      setTimeout(() => {
          dtInstance.columns.adjust();
      },100);
    });
  }

  onReOrderColumn(cols: any) {
    this.datatableElement.dtInstance.then( (dtInstance: any) => {
      this.columns = cols;
      const source = from(cols);
      const example = source.pipe(pluck('columnIndex'));
      let colPos = [];
      example.subscribe(val => colPos.push(val));
      this.columns.forEach((col, index) => {
          col.columnIndex=index;
      });
      dtInstance.colReorder.order( colPos );
      setTimeout(() => {
          dtInstance.columns.adjust();
      },100);
    });
  }

  setParams(newParams:any) {
      this.params =  newParams;
  }

  onSelectRow(data: any, col:any) {
    data.selected = !data.selected;
    this.onSelectCheckbox.emit({
        data: data,
        isChecked: data.selected
    });
    if(data.selected && !this.selectAllCheckbox) {
        this.selectAllCheckbox=true;
    }

    if(!data.selected && this.smartTableInstance.getSelectedRows().length === 0) {
        this.selectAllCheckbox=false;
    }
  }

  onSelectAllRows():void {
      this.selectAllCheckbox = !this.selectAllCheckbox;
      this.smartTableInstance.checkUncheckAllRows(this.selectAllCheckbox);
      this.onSelectAllCheckbox.emit( this.selectAllCheckbox );
  }

  onSaveColumns(event: any, fromEvent:boolean = true):void {
    const {limit, sort} = this.metadata;
    let colsParams = {
      columns: event.columns,
      limit: limit,
      sort: sort
    },
    postParams = {
      columnValue: JSON.stringify(colsParams),
      screenName: (this.subcategory=='' || this.subcategory ==undefined)?this.category:this.subcategory,
    };
    this.http.get(this.getApiUrl(this.columnsSaveUrl, null),{
      params: postParams
    }).subscribe(resp => {
      if(fromEvent) {
        event.modal.hide();
        setTimeout( () => {
          this.columnsAdjust();
        },600)

      }
    });
  }

  onCloseColumnModal() {
    setTimeout( () => {
      this.columnsAdjust();
    },600);
  }

  private checkBoxColumn(visible:boolean = true) {
    return {
      data: '@empty_select',
      title: '',
      className: 'select-checkbox-all',
      orderable: false,
      visible: visible,
      width: '10px'
    };
  }

  private actionsBtnColumn() {
    return {
      data: '@empty_actions',
      title: '',
      className: 'st-last-column plus-actions',
      orderable: false,
      visible: true,
      width: '16px'
    };
  }

  prepareColumns() {
    //if(this._rowSelect) {
      //this.columns.unshift(this.checkBoxColumn());
    //}
    if(this.actions.length) {
        this.columns.push(this.actionsBtnColumn());
    }
    this.columns.forEach((col, index) => {
      col.columnIndex = index;
    });
    this.columnsCount = this.columns.length;
    return this.columns;
  }

  prepareSorting() {
      let sort = this.metadataService.getSorting(), newOrderList=[];
      if(sort && typeof sort === 'object') {
          //console.log('this.cc', this.columns)
        const result = from(this.columns)
          .pipe(map( (col, index) => {
            if(sort.hasOwnProperty(col.title) ) {
                col._orderby = [index, sort[col.title]];
            }
            return col;
          }))
          .pipe(filter(cols => cols.hasOwnProperty('_orderby')));
        result.subscribe(x => newOrderList.push(x._orderby));
        return newOrderList;
      }
  }

  renderActionButton(event: any) {
      const { data, buttonProp, index } = event;
      if(buttonProp.onClick && isFunction(buttonProp.onClick) ) {
          buttonProp.onClick(data, buttonProp, this.smartTableInstance, index);
      }
  }

  columnsAdjust() {
      if(! this.datatableElement) {
          return;
      }
      this.datatableElement.dtInstance.then((dtInstance: any) => {
          dtInstance.columns.adjust();
      });
  }

  getApiUrl(path: string, params: any) {
      let url = environment.apiHost + environment.apiBasePath + path;
      if (params instanceof Object) {
          url += '?page='+params.page+'&limit='+params.limit;
      }
      return url;
  }

    paramsTransform(params:any) {
        let tableParams = {}, page=1;
        let externalParams = {};
        externalParams[this.paramName] = typeof this.params === 'object' ? JSON.stringify(this.params) : this.params;
        if(params.length) {
            tableParams['limit'] = params.length;
        }
        tableParams['page'] = page + ( params.start/params.length );
        tableParams['order'] = this.getSortableData(params);
        tableParams['tableCategory'] = this.category;
        externalParams['smartTableParams'] = tableParams;
        return externalParams;
    }
    getSortableData(params:any) {
        if(!params.order) {
            return [];
        }
        const columnsOrder:Object = {};
        params.order.forEach( (item) => {
            const tmp:Object = {};
            columnsOrder[this.columns[item.column].data] = item.dir;
            //columnsOrder.push(tmp);
        });
        return columnsOrder;
    }

    _prepareSaveColumns() {
        let newCols = [];
        const filterColumns = from(Object.assign([],this.columns))
            .pipe(filter(col => !col.data.startsWith('@') && col.visible))
            .pipe(map( (col, index) => {
                return {
                    col_name: col.col_name,
                    pos: index+1,
                    active: col.visible
                }
            }));
        filterColumns.subscribe( col => newCols.push(col));
        return newCols;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.datatableElement.dtInstance.then((dtInstance: any) => {
        dtInstance.columns.adjust();
      });
    }

}

export function isFunction(x: any): x is Function {
    return typeof x === 'function';
}

// this.dtOptions = {
//   dom: 'lrtip',
//   initComplete: () => {
//   },
//   serverSide: true,
//   sPaginationType: "bootstrap",
//   processing: true,
//   //ajax: this.dataUrl,
//   ajax: (dataTablesParameters: any, callback) => {
//     that.http.post<DataTablesResponse>(
//       that.dataUrl,
//       dataTablesParameters,
//       {}
//     ).subscribe(resp => {
//       that.datas = that.oldDatas = resp.data;
//       callback({
//           recordsTotal: resp.recordsTotal,
//           recordsFiltered: resp.recordsFiltered,
//           data: []
//       });
//     })
//   },
//   columns: this.prepareColumns(),
//   drawCallback: (settings) => {
//       setTimeout( () => {
//           this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
//               dtInstance.columns([0]).order('No');
//               dtInstance.columns.adjust();
//           })
//       },100);
//   },
//   bSort: this.sorting,
//   pagingType: 'full_numbers',
//   pageLength: 10,
//   scrollCollapse: true,
//   scrollX: true,
//   colReorder: true,
//   aaSorting: [],
//   destroy: true,
//     select: true,
// fixedColumns: {
//   rightColumns:0,
//   leftColumns:0
// },
// columnDefs: [{
//   targets: -1,
//   className: 'st-last-column plus-actions',
//   width: '16px',
//   orderable: false,
//   // render: (data, type, full, meta ) => {
//   //     const $table = $(meta.settings.oInstance.api().table().node());
//   //     const $td = $table.find('tr:eq(' + meta.row + ') > td:eq(' + meta.col + ')');
//   //     // const componentRef = that.componentFactoryResolver
//   //     //     .resolveComponentFactory(ActionButtonsComponent).create();
//   //     // that.appRef.attachView(componentRef);
//   //     //return that.renderActions(data, type, full, meta);
//   //     that.viewContainerRef.clear();
//   //     const domElem = this.domService.createEmbeddedView(ActionButtonsComponent, {
//   //         type: type
//   //     });
//   //     return $(domElem).html();
//   // }
// },
// {
//     targets: this.columns.length-2,
//     className: 'st-last-before-column',
// }]
// };
