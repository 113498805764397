import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SetupPipelineService = /** @class */ (function (_super) {
    tslib_1.__extends(SetupPipelineService, _super);
    function SetupPipelineService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.$stageEmitted = new BehaviorSubject(null);
        _this.stageEmitted = _this.$stageEmitted.asObservable();
        _this.$stageAfterTracking = new BehaviorSubject(null);
        _this.stageAfterTracking = _this.$stageAfterTracking.asObservable();
        _this.$activeMenu = new BehaviorSubject(null);
        _this.menu = _this.$activeMenu.asObservable();
        return _this;
    }
    // Add or Edit Pipeline
    SetupPipelineService.prototype.addEditPipelines = function (data) {
        return this.http.post(this.getApiUrl("addOrEditPipelines"), data);
    };
    // Check Pipeline name exists
    SetupPipelineService.prototype.checkUniqueName = function (params) {
        return this.http
            .get(this.getApiUrl("checkSettingsPipelineExists", params));
    };
    // Confirmation Modal for Delete
    SetupPipelineService.prototype.beforeDelete = function (id, screen) {
        if (screen == 'job') {
            return this.http.get(this.getApiUrl("pipelineDeleteCheck/" + id + "?screenType=" + screen));
        }
        else {
            return this.http.get(this.getApiUrl("pipelineDeleteCheck/" + id));
        }
    };
    SetupPipelineService.prototype.beforeStageDelete = function (id, screen) {
        if (screen == 'job') {
            return this.http.get(this.getApiUrl("pipelineStageDeleteCheck/" + id + "?screenType=" + screen));
        }
        else {
            return this.http.get(this.getApiUrl("pipelineStageDeleteCheck/" + id));
        }
    };
    // Delete Pipeline
    SetupPipelineService.prototype.deletePipelineRequest = function (id, params) {
        return this.http
            .delete(this.getApiUrl(id + "/deleteSettingsPipeline", params));
    };
    // Reorder Settings Pipeline List
    SetupPipelineService.prototype.reOrderSettingsPipeline = function (data) {
        return this.http.put(this.getApiUrl("reorderPipeline"), data);
    };
    // Setup Stage List
    SetupPipelineService.prototype.getStageForPipeline = function (id, type) {
        if (type == 'job') {
            return this.http.get(this.getApiUrl(id + "/listSettingStages?screenType=" + type));
        }
        else {
            return this.http.get(this.getApiUrl(id + "/listSettingStages"));
        }
    };
    // Add or Edit Stages
    SetupPipelineService.prototype.addEditStages = function (data, screen) {
        if (screen === void 0) { screen = ''; }
        if (screen == 'stage') {
            if (data && data.hasOwnProperty('id') && data.id) {
                return this.http.put(this.getJobApiUrl("update_stage_views/" + data.id), data);
            }
            else {
                return this.http.post(this.getJobApiUrl("add_stage_views"), data);
            }
        }
        else {
            return this.http.post(this.getApiUrl("addOrEditSettingStages"), data);
        }
    };
    // Delete Stages
    SetupPipelineService.prototype.deleteStages = function (id, type, pipelineId) {
        if (type == 'stage') {
            return this.http.post(this.getJobApiUrl("delete_stage_views"), { id: id });
        }
        else {
            var path = id + "/deleteSettingStages?screenType=" + type;
            if (pipelineId)
                path = path + ("&pipelineId=" + pipelineId);
            return this.http.delete(this.getApiUrl(path));
        }
    };
    // Trigger Stages Modal
    SetupPipelineService.prototype.triggerStages = function (pipeline) {
        this.$stageEmitted.next(pipeline);
    };
    SetupPipelineService.prototype.setAfterTrackingDetails = function (afterTracaking) {
        this.$stageAfterTracking.next(afterTracaking);
    };
    SetupPipelineService.prototype.reorderList = function (data, type, pipelineId) {
        var path = "commonSettingsReorder?type=" + type;
        if (pipelineId) {
            path = path + ("&pipelineId=" + pipelineId);
        }
        return this.http.put(this.getApiUrl(path), data);
    };
    SetupPipelineService.prototype.setActiveMenu = function (menu) {
        this.$activeMenu.next(menu);
    };
    SetupPipelineService.prototype.getSideMenuStatus = function (id) {
        return this.http.get(this.getApiUrl("getPipelineSideMenuStatus/" + id));
    };
    SetupPipelineService.prototype.getPipeline = function (id) {
        return this.http.get(this.getApiUrl("getPipeline/" + id));
    };
    SetupPipelineService.prototype.getDropdownData = function () {
        return this.http.get(this.getApiUrl("sales/getPipelineDropdownData"));
    };
    SetupPipelineService.prototype.activatePipeline = function (id, data) {
        return this.http.post(this.getApiUrl("pipelines/" + id + "/activate"), data);
    };
    SetupPipelineService.prototype.getPipelineCustomTemplates = function (action, oppTemplateId, tablePKID, propertyID, stageID) {
        return this.http.get(this.getApiUrl("getPipelineCustomFields?screenMode=addCustomTemplates&action=" + action + "&objects=Pipelines&objectTypes=Pipeline&objectTypeID=" + oppTemplateId + "&propertyID=" + propertyID + "&tablePKID=" + tablePKID + "&stageID=" + stageID));
    };
    SetupPipelineService.prototype.getStages = function (id) {
        return this.http.get(this.getApiUrl("getStageCustomFields/" + id));
    };
    SetupPipelineService.prototype.addEditCustomFields = function (data) {
        return this.http.post(this.getApiUrl("addOrEditCustomFields"), data);
    };
    SetupPipelineService.ngInjectableDef = i0.defineInjectable({ factory: function SetupPipelineService_Factory() { return new SetupPipelineService(i0.inject(i1.HttpClient)); }, token: SetupPipelineService, providedIn: "root" });
    return SetupPipelineService;
}(InterfaceService));
export { SetupPipelineService };
