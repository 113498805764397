import * as tslib_1 from "tslib";
import { EventEmitter, NgZone, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { AddOpportunityService } from "@app/features/customers/add-opportunity/service/add-opportunity.service";
import { FolderService } from '@app/shared/common-folder-structure/service/folder.service';
import { CsActivityLogEventService } from "@app/shared/cs-activity-log/cs-activity-log-event.service";
import { SharedModalService } from '../service/shared-modal.service';
import { CsToastBoxService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';
import { CommonFilterService } from "@app/shared-modal/email-modal/common-filter.service";
import { PropertyViewService } from "@app/features/customers/customer/property-view/service/property-view.service";
import { CommonService } from "@app/services";
import { ViewVehicleService } from '@app/features/fleet-management/add-vehicle/services/view-vehicle-service';
import { TimelineService } from "@app/shared/services/timeline.service";
import { AuthService } from "@app/core";
import { EmailListenerService } from "@app/shared-modal/email-modal/email-listener.service";
var EmailModalComponent = /** @class */ (function () {
    function EmailModalComponent(sharedModalService, fb, confirmationBoxHelper, ajsScope, opportunityService, zone, folderService, alEvent, toast, translate, commonFilterService, propertyService, commonService, viewVehicleService, ts, authService, _emailEventService) {
        var _this = this;
        this.sharedModalService = sharedModalService;
        this.fb = fb;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.opportunityService = opportunityService;
        this.zone = zone;
        this.folderService = folderService;
        this.alEvent = alEvent;
        this.toast = toast;
        this.translate = translate;
        this.commonFilterService = commonFilterService;
        this.propertyService = propertyService;
        this.commonService = commonService;
        this.viewVehicleService = viewVehicleService;
        this.ts = ts;
        this.authService = authService;
        this._emailEventService = _emailEventService;
        this.attachedFiles = [];
        this.attachmentSearchValues = [];
        this.fromAddress = [];
        this.modalBack = false;
        this.attachNotFound = false;
        this.attachments = [];
        this.attachmentFiles = [];
        this.selectedFiles = [];
        this.showEmailError = false;
        this.emailErrorMessage = '';
        this.emailActivityShow = false;
        this.completed = true;
        this.select2Config = {};
        this.toAddresses = '';
        this.ccAddresses = '';
        this.customerContacts = [];
        this.ccContacts = [];
        this.notFound = false;
        this.scheduled = false;
        this.templateList = [];
        this.templateSearchList = [];
        this.templateData = {};
        this.attached_files_view_limit = 3;
        this.emailDraft = false;
        this.subscriptions = {};
        this.activityIsScheduled = [];
        this.totalSizeofAttachment = 0;
        this.sendButtonDisabled = true;
        this.pdfFiles = [];
        this.sendPdf = false;
        this.footerMessage = '';
        this.isLargeFileSize = false;
        this.isGetEmailTemplates = true;
        this.showCc = false;
        this.showAttachfileButton = false;
        this.showEmailModal = false;
        this.currentUserEmail = '';
        this.onSave = new EventEmitter();
        this.onClose = new EventEmitter();
        this.isCommonRoute = false;
        this.todayEmailActivity = [];
        this.screen = "email_template";
        this.screenLabel = "Email template";
        this.showTemplateList = false;
        this.showAddNewTemplate = true;
        this.isCallFromCsActivityLog = false; // Note: This is used for modification according to `cs-activity-log.component`.
        this.searchValue = '';
        this.timeDelay = "";
        this.filterParams = {
            screen: this.screen,
            parentId: '',
            search: ''
        };
        this.isFromJs = false;
        this.defaultMessage = '';
        this.isInitialied = false;
        this.proposal = '';
        this.showAttachment = false;
        this.completeTodayActivity = false;
        this.folderStructureActive = false;
        this.fileActive = false;
        this.emailHandleTagData = {};
        this.scheduledActivityAccess = true;
        this.classAdded = false;
        this.restrictUnwanted = true;
        this.beginCloseWidget = false;
        this.onChange = function (_) {
            _this.templateForm.patchValue({
                subject: tinymce.get('email-template-subject').getContent(),
                message: tinymce.get('email-template-message').getContent(),
            });
            _this.emailForm.get('content').setValue(_this.templateForm.get('message').value);
            _this.emailForm.get('subject').setValue(_this.templateForm.get('subject').value);
            _this.emailForm.updateValueAndValidity();
            _this.sendButtonDisabled = false;
            _this.templateForm.updateValueAndValidity();
        };
    }
    EmailModalComponent.prototype.clickout = function (event) {
        if ($(event.srcElement).closest('.dropdown').length == 0) {
            $('#drop-down-emails').removeClass("open");
        }
    };
    EmailModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.scheduledActivityAccess = this.authService.permitted(['ScheduleActivity.writeaccess']) === true;
        var addTemplatePermission = this.authService.permitted(['SetupEmailTemplates.readaccess']) === true;
        if (!addTemplatePermission) {
            this.showAddNewTemplate = false;
        }
        this.sharedModalService.emailModalDetails$.subscribe(function (data) {
            if (data) {
                if (data.showModal == true) {
                    if (_this.templateData) {
                        _this.templateData['message'] = '';
                        _this.templateData['subject'] = '';
                    }
                    _this.sendButtonDisabled = true;
                    _this.emailHandleTagData = _this.commonFilterService.filterData(data);
                    _this.isFromJs = data.isFromJs;
                    _this.showEmailModal = data.showModal;
                    _this.isCallFromCsActivityLog = data['isCallFromCsActivityLog'] || false;
                    _this.afterSave = data['afterSave'] || null;
                    _this.loadEmailDetails(data);
                }
                else {
                    _this.showEmailModal = data.showModal;
                }
            }
        });
        this.buildForm();
        this.getDefaultEmailFooter();
    };
    EmailModalComponent.prototype.loadEmailDetails = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attachmentUrl, fromEmailUrl, toEmailUrl, _a, attachmentData_1, fromAddress, customerContacts, attachmentKeys, activityParentId, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.emailModalData = data;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        attachmentUrl = this.validateURL(data.attachments_url);
                        fromEmailUrl = this.validateURL(data.from_addresses_url);
                        toEmailUrl = this.validateURL(data.recipient_addresses_url);
                        return [4 /*yield*/, Promise.all([
                                attachmentUrl ? this.getEmailDetails(attachmentUrl) : [],
                                fromEmailUrl ? this.getEmailDetails(fromEmailUrl) : [],
                                toEmailUrl ? this.getEmailDetails(toEmailUrl) : []
                            ])];
                    case 2:
                        _a = _b.sent(), attachmentData_1 = _a[0], fromAddress = _a[1], customerContacts = _a[2];
                        this.scheduled = false;
                        attachmentKeys = { file_name: "name", file_size: "size" };
                        if (data['attached_files'] && data['attached_files'].length) {
                            data['attached_files'].forEach(function (value) {
                                value.selected = true;
                                value.attached = true;
                                value.isRemovable = false;
                                attachmentData_1.push(value);
                            });
                        }
                        this.attachmentSearchValues = this.attachmentFiles = this.attachments = this.formatAttachmentData(attachmentData_1, attachmentKeys);
                        this.fromAddress = fromAddress;
                        this.currentUserEmail = (fromAddress.length > 0) ? fromAddress[0].description : '';
                        this.customerContacts = JSON.parse(JSON.stringify(customerContacts));
                        this.ccContacts = JSON.parse(JSON.stringify(customerContacts));
                        this.defaultMessage = data['message'];
                        this.selectedToMail = data.selectedToEmail || '';
                        this.tablePKId = data.relatedObjectId || '';
                        this.tableType = data.relatedObjectType || '';
                        this.screenType = data.screenType || '';
                        this.customerId = data.customerId || '';
                        this.scheduledActivityParams = data.scheduledActivityParams || '';
                        this.todayEmailActivity = data.todayEmailActivity || '';
                        this.proposal = data.proposal || '';
                        this.pdfData = data.pdfData || '';
                        if (this.pdfData['pdfData']) {
                            this.pdfData['pdfData']['size'] = parseFloat(this.pdfData['pdfData']['size']) / 1024;
                            this.attachments.push(this.pdfData['pdfData']);
                        }
                        if (data.completeTodayActivity) {
                            activityParentId = (['opportunity', 'contract', 'jobs'].includes(this.screenType)) ? this.tablePKId : this.customerId;
                            this.primaryData = data;
                            this.propertyService.getTodayScheduleActivity(activityParentId, 'Email', this.screenType).subscribe(function (data) {
                                if (data) {
                                    _this.todayEmailActivity = data;
                                }
                                _this.loadEmailRelated(_this.primaryData);
                            });
                        }
                        else {
                            this.loadEmailRelated(data);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _b.sent();
                        console.error('Error fetching data:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EmailModalComponent.prototype.loadEmailRelated = function (data) {
        var _this = this;
        this.resetFormValues();
        this.setFooterMessage();
        this.openEmailModal();
        this.getSelectedAttachments('save');
        this.setAttachedFileSize();
        this.setDefaultValues(data);
        setTimeout(function () {
            _this.updateTinyMCEValues();
        }, 500);
    };
    EmailModalComponent.prototype.resetFormValues = function () {
        $('.email-receipient').val('').trigger('change');
        $('#emailCc').val('').trigger('change');
        var defaultMessage = this.footerMessage;
        if (this.defaultMessage) {
            defaultMessage = this.defaultMessage + this.footerMessage;
        }
        if (this.ccAddresses) {
            this.ccAddresses = '';
        }
        if (this.toAddresses) {
            this.toAddresses = '';
        }
        this.emailForm.get('toAddresses').setValue('');
        this.emailForm.get('ccAddresses').setValue('');
        this.emailForm.get('content').setValue(defaultMessage);
        this.emailForm.get('subject').setValue('');
        this.showCc = false;
    };
    EmailModalComponent.prototype.setDefaultValues = function (data) {
        var _this = this;
        if (data && data.hasOwnProperty('subject')) {
            this.emailForm.get('subject').setValue(data['subject']);
        }
        this.interval = setInterval(function () {
            _this.setChangeDetector();
        }, 50);
        if (this.selectedToMail) {
            this.scheduled = false;
            var index_1 = this.customerContacts.findIndex(function (x) { return x.description == _this.selectedToMail; });
            if (index_1 === -1) {
                setTimeout(function () {
                    _this.addEmailAddress(_this.selectedToMail);
                }, 500);
            }
            else {
                setTimeout(function () {
                    _this.checkMailAddressStatus(_this.customerContacts[index_1].id);
                }, 300);
            }
        }
        if (data.screenType == 'opportunity' && data.proposal != 'proposal') {
            this.scheduled = true;
        }
        this.showAttachment = (data.proposal == 'proposal') ? true : false;
        if (this.todayEmailActivity.length > 0) {
            this.emailActivityShow = true;
        }
    };
    EmailModalComponent.prototype.checkMailAddressStatus = function (id) {
        $('.email-receipient option').each(function (i, element) {
            var optionValue = element.value;
            var value = optionValue.split(': ').pop();
            if (value == id) {
                $('.email-receipient').val(optionValue).trigger('change');
            }
        });
        this.getToAddress(true);
    };
    EmailModalComponent.prototype.validateURL = function (url) {
        if (url && url.startsWith('/')) {
            return url.substring(1);
        }
        return url;
    };
    EmailModalComponent.prototype.getEmailDetails = function (url) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, data, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!(this.emailModalData.screenType === 'vehicle' && this.emailModalData.recipient_addresses_url === url)) return [3 /*break*/, 1];
                        data = this.viewVehicleService.getEmailAddressList(url).toPromise();
                        if (data) {
                            return [2 /*return*/, data];
                        }
                        else {
                            throw new Error('No data received');
                        }
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.sharedModalService.getEmailModalDetails(url).toPromise()];
                    case 2:
                        data = _a.sent();
                        if (data) {
                            return [2 /*return*/, data];
                        }
                        else {
                            throw new Error('No data received');
                        }
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        console.error('Error fetching email details:', error_2);
                        throw error_2; // rethrow the error to be caught by the caller
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    EmailModalComponent.prototype.formatAttachmentData = function (attachmentData, attachmentKeys) {
        var data = attachmentData.map(function (obj) {
            var newObj = {};
            Object.keys(obj).forEach(function (key) {
                newObj[attachmentKeys[key] || key] = obj[key];
            });
            return newObj;
        });
        return data;
    };
    EmailModalComponent.prototype.openEmailModal = function () {
        this.emailDraft = false;
        setTimeout(function () {
            $(".modal-header").css("background", "#5E7F7D");
            $('.emailModal').modal('show');
            $(".modal-dialog").addClass("modal-dialog-open").css("display", "block");
        }, 500);
    };
    EmailModalComponent.prototype.setFooterMessage = function () {
        // this.tinyMCEIntervel = setInterval(() => {
        this.updateTinyMCEValues();
        // }, 50);
        var signatureMessage = this.footerMessage;
        if (this.defaultMessage) {
            signatureMessage = this.defaultMessage + this.footerMessage;
        }
        this.emailForm.get('content').setValue(signatureMessage);
    };
    EmailModalComponent.prototype.setChangeDetector = function () {
        var self = this;
        if ($('.email-receipient .select2-input').length) {
            $('.email-receipient .select2-input').on("input", function (e) {
                var inputtedValue = e.target.value;
                var inputtedValueWithoutTrailingCharacter = inputtedValue.substring(0, inputtedValue.length - 1);
                // Check if the input ends in a space
                var lastChar = inputtedValue.slice(-1);
                if ([" ", ","].indexOf(lastChar) > -1) {
                    self.addEmailAddress(inputtedValueWithoutTrailingCharacter);
                }
            });
            $('.email-receipient .select2-input').on("keyup", function (e) {
                if (e.keyCode == 13) {
                    var inputtedValue = e.target.value;
                    self.addEmailAddress(inputtedValue);
                }
            });
            if (this.interval)
                clearInterval(this.interval);
        }
    };
    EmailModalComponent.prototype.addNewRecipient = function (emailAddress, contactList, selectElement, addressGetter) {
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var isEmailAddress = emailRegex.test(emailAddress);
        var emailAddressAlreadyExists = contactList.find(function (contact) { return contact.description === emailAddress; });
        if (isEmailAddress && !emailAddressAlreadyExists) {
            var newRecipient = {
                id: contactList.length,
                description: emailAddress
            };
            contactList.push(newRecipient);
            var selectedVal_1 = $(selectElement).select2('val');
            setTimeout(function () {
                var newVal = $(selectElement).find('option:last').val();
                selectedVal_1.push(newVal);
                $(selectElement).select2('val', selectedVal_1);
                addressGetter(true);
            }, 200);
        }
    };
    EmailModalComponent.prototype.addNewCcAddress = function (emailAddress) {
        this.addNewRecipient(emailAddress, this.ccContacts, '#emailCc', this.getCcAddress.bind(this));
    };
    EmailModalComponent.prototype.addEmailAddress = function (emailAddress) {
        setTimeout(function () {
            $('.email-receipient').click().trigger('change');
        }, 200);
        this.addNewRecipient(emailAddress, this.customerContacts, '#emailTo', this.getToAddress.bind(this));
    };
    EmailModalComponent.prototype.buildForm = function () {
        if (this.emailForm) {
            this.emailForm.reset();
        }
        this.emailForm = this.fb.group({
            "toAddresses": [""],
            "fromAddress": [""],
            "ccAddresses": [""],
            "subject": ["", Validators.required],
            "activityType": [""],
            "content": [""],
            "attachments": [""]
        });
        this.templateForm = this.fb.group({
            subject: [''],
            message: ['']
        });
    };
    EmailModalComponent.prototype.showCcOption = function () {
        var _this = this;
        setTimeout(function () {
            var ccInput = $('.cc-mail .select2-input');
            if (ccInput.length) {
                ccInput.on("input", function (e) { return _this.handleCcInput(e); });
                ccInput.on("keyup", function (e) { return _this.handleCcKeyUp(e); });
                clearInterval(_this.interval);
            }
        }, 300);
        this.showCc = true;
    };
    EmailModalComponent.prototype.handleCcInput = function (event) {
        var inputtedValue = event.target.value.trim();
        var lastChar = inputtedValue.slice(-1);
        if ([" ", ","].includes(lastChar)) {
            var inputtedValueWithoutTrailingCharacter = inputtedValue.substring(0, inputtedValue.length - 1);
            this.addNewCcAddress(inputtedValueWithoutTrailingCharacter);
        }
    };
    EmailModalComponent.prototype.handleCcKeyUp = function (event) {
        if (event.keyCode === 13) {
            var inputtedValue = event.target.value.trim();
            this.addNewCcAddress(inputtedValue);
        }
    };
    EmailModalComponent.prototype.removeCcOption = function () {
        this.showCc = false;
        this.ccAddresses = '';
    };
    EmailModalComponent.prototype.closeModal = function (emitEvent) {
        if (emitEvent === void 0) { emitEvent = true; }
        if (this.templateData) {
            this.templateData['message'] = '';
            this.templateData['subject'] = '';
        }
        this.updateTinyMCEValues();
        $('.emailModal').modal('hide');
        $("#email-modal-dialog").removeClass("modal-dialog-open");
        $(".template-wrapper").removeClass("template-open");
        $(".attachment-container").removeClass("attachment-open");
        $("#emailcontent").removeClass("overlay");
        this.showTemplateList = false;
        this.activityIsScheduled = [];
        this.modalBack = false;
        this.emailDraft = false;
        this.emailActivityShow = false;
        this.pdfFiles = [];
        this.pdfData = null;
        this.showEmailModal = false;
        this.sharedModalService.setEmailEmittedDetails(false);
        this.sharedModalService.setCommonModalEmittedValue(false);
        this.commonService.setMailModalCase(true);
        this.sharedModalService.updateModalStatus('others', 'email', this.showEmailModal);
        if (emitEvent)
            this.onClose.emit(false);
    };
    EmailModalComponent.prototype.emailDraft_ = function (event) {
        if (!$(event.srcElement).closest('#email-close').length) {
            if ($("#email-modal-dialog").hasClass("modal-dialog-open")) {
                this.emailDraft = !this.emailDraft;
            }
        }
        else {
            this.closeModalConfirmation();
        }
    };
    EmailModalComponent.prototype.openFromAddress = function (event) {
        event.stopPropagation();
        this.toggleDropdown();
        // $('#fromEmailDropdown').dropdown('toggle');
    };
    EmailModalComponent.prototype.toggleDropdown = function () {
        if ($('#drop-down-emails').hasClass("open")) {
            $('#drop-down-emails').removeClass("open");
        }
        else {
            $('#drop-down-emails').addClass("open");
        }
    };
    EmailModalComponent.prototype.getFromAddress = function () {
        var _this = this;
        this.opportunityService.getFromEmailAddress().subscribe(function (data) {
            if (data) {
                data.forEach(function (email) {
                    _this.fromAddress.push(email);
                });
                if (_this.isCommonRoute) {
                    _this.currentUserEmail = (_this.fromAddress.length > 0) ? _this.fromAddress[0].description : '';
                }
            }
        });
        if (this.isCallFromCsActivityLog) {
            this.alEvent.onTimelinePreloadDataEvent$.subscribe(function (data) {
                _this.attachmentSearchValues = _this.attachmentFiles = _this.attachments = JSON.parse(JSON.stringify(data.attachedFiles));
            });
        }
        else {
            this.opportunityService.$attachedFilesList.subscribe(function (data) {
                if (data) {
                    _this.attachmentSearchValues = _this.attachmentFiles = _this.attachments = JSON.parse(JSON.stringify(data));
                }
            });
        }
    };
    EmailModalComponent.prototype.getDefaultEmailFooter = function () {
        var _this = this;
        this.opportunityService.getDefaultEmailFooter().subscribe(function (data) {
            if (data['message']) {
                _this.footerMessage = '<br />' + data['message'];
                _this.setFooterMessage();
            }
        });
    };
    EmailModalComponent.prototype.getSelectedFromAddress = function (selectedEmail, event) {
        event.stopPropagation();
        this.currentUserEmail = selectedEmail.description;
        this.toggleDropdown();
    };
    EmailModalComponent.prototype.cancelAttachments = function () {
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.searchFiles();
        this.getSelectedAttachments('cancel');
        this.setAttachedFileSize();
        $("#emailcontent").removeClass("overlay");
        this.modalBack = false;
        $(".attachment-container").removeClass("attachment-open");
        this.fileActive = false;
    };
    EmailModalComponent.prototype.searchFiles = function () {
        var _this = this;
        var attachmentValues = [];
        this.attachmentSearchValues.filter(function (value) {
            if (value["name"].toLowerCase().includes(_this.filterParams.search.toLowerCase())) {
                attachmentValues.push(value);
                _this.attachments = attachmentValues;
            }
            if (attachmentValues.length == 0) {
                _this.attachNotFound = true;
            }
            else {
                _this.attachNotFound = false;
            }
        });
    };
    EmailModalComponent.prototype.onNativeChange = function (event) {
        var selectedAttachment = this.attachments.find(function (data) { return data.id == event.target.value; });
        var attachmentIndex = this.attachments.findIndex(function (data) { return data.id == event.target.value; });
        if (selectedAttachment) {
            var size = this.attachments[attachmentIndex].size;
            if (selectedAttachment.hasOwnProperty('selected')) {
                if (selectedAttachment['selected'] == true) {
                    this.attachments[attachmentIndex]['selected'] = false;
                    this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) - size).toFixed(2);
                    this.totalSizeofAttachment = (this.totalSizeofAttachment > 0) ? this.totalSizeofAttachment : 0;
                }
                else {
                    this.attachments[attachmentIndex]['selected'] = true;
                    this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) + size).toFixed(2);
                }
            }
            else {
                this.attachments[attachmentIndex].selected = true;
                this.totalSizeofAttachment = (parseFloat(this.totalSizeofAttachment) + size).toFixed(2);
            }
        }
        this.getSelectedAttachments();
    };
    EmailModalComponent.prototype.showAttachedFiles = function () {
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.searchFiles();
        this.getSelectedAttachments('save');
        $(".attachment-container").removeClass("attachment-open");
        $("#emailcontent").removeClass("overlay");
        this.modalBack = false;
        this.fileActive = false;
    };
    EmailModalComponent.prototype.getSelectedAttachments = function (status) {
        var _this = this;
        if (status === void 0) { status = ''; }
        if (status == 'save') {
            this.attachedFiles = [];
            this.attachments.forEach(function (val, key) {
                if (val.selected) {
                    _this.attachedFiles.push(val);
                }
                else {
                    _this.attachments[key].attached = false;
                }
            });
        }
        else if (status == 'cancel') {
            this.attachments.forEach(function (val, key) {
                if (val.attached == true && val.selected == false) {
                    _this.attachments[key].selected = true;
                }
                if (val.selected && (val.attached == false || typeof val.attached == 'undefined')) {
                    _this.attachments[key].selected = false;
                    _this.attachments[key].attached = false;
                }
            });
        }
        if (this.attachments.findIndex(function (x) { return x.selected == true; }) !== -1) {
            this.showAttachfileButton = true;
        }
        else {
            this.showAttachfileButton = false;
        }
        if (this.attachedFiles.length > 0 && !this.classAdded) {
            $("#email-template-message_ifr").addClass("bottom-space-mail");
            this.classAdded = true;
        }
        if (this.attachedFiles.length == 0 && this.classAdded) {
            $("#email-template-message_ifr").removeClass("bottom-space-mail");
            this.classAdded = false;
        }
    };
    EmailModalComponent.prototype.returnEmail = function () {
        $(".email-error-message").removeClass("message-open");
        this.showEmailError = false;
        this.emailErrorMessage = '';
    };
    EmailModalComponent.prototype.completeActivity = function (event) {
        this.completed = event.target.checked;
    };
    EmailModalComponent.prototype.getToAddress = function (toAddresses) {
        if (toAddresses) {
            this.setAddresses('#emailTo', 'toAddresses');
        }
    };
    EmailModalComponent.prototype.getCcAddress = function (toAddresses) {
        if (toAddresses) {
            this.setAddresses('#emailCc', 'ccAddresses');
        }
    };
    EmailModalComponent.prototype.setAddresses = function (elementId, addressType) {
        this[addressType] = '';
        var selectedData = $(elementId).select2('data');
        if (selectedData && selectedData.length) {
            this[addressType] = selectedData.map(function (data) { return data.text.trim(); }).join(',');
            addressType == 'toAddresses' ? this.sendButtonDisabled = false : '';
        }
        else {
            addressType == 'toAddresses' ? this.sendButtonDisabled = true : '';
        }
    };
    EmailModalComponent.prototype.deleteAttachedFile = function (selectedFile) {
        var index = this.attachments.findIndex(function (x) { return x.id == selectedFile.id; });
        if (index !== -1) {
            this.attachments[index].selected = false;
            this.attachments[index].attached = false;
        }
        this.setAttachedFileSize();
        this.getSelectedAttachments('save');
    };
    EmailModalComponent.prototype.setAttachedFileSize = function () {
        var _this = this;
        this.totalSizeofAttachment = 0;
        this.attachedFiles.forEach(function (val, key) {
            if (val.selected == true) {
                _this.totalSizeofAttachment = (parseFloat(_this.totalSizeofAttachment) + val.size).toFixed(2);
            }
        });
    };
    EmailModalComponent.prototype.showTemplateOptions = function () {
        this.searchValue = '';
        this.notFound = false;
        this.modalBack = true;
        $(".template-wrapper").addClass("template-open");
        this.showTemplateList = true;
        $("#emailcontent").addClass("overlay");
        this.templateList = [];
        this.filterParams.search = '';
        this.filterParams.parentId = '';
        this.folderStructureActive = true;
    };
    EmailModalComponent.prototype.showAttachments = function () {
        var _this = this;
        this.attachments.forEach(function (data) {
            _this.attachmentSize = parseInt(_this.totalSizeofAttachment) + data.size;
            if (_this.attachmentSize > 16) {
                _this.isLargeFileSize = true;
            }
            else {
                _this.isLargeFileSize = false;
            }
        });
        this.attachNotFound = false;
        // this.selectedFiles = [];
        if (this.attachedFiles.length != 0) {
            for (var i = 0; i < this.attachedFiles.length; i++) {
                this.attachedFiles[i].attached = true;
            }
        }
        $("#attachmentId").val('');
        this.modalBack = true;
        $(".attachment-container").addClass("attachment-open");
        $("#emailcontent").addClass("overlay");
        this.fileActive = true;
    };
    EmailModalComponent.prototype.isScheduledActivity = function (event) {
        this.scheduled = event.target.checked;
    };
    EmailModalComponent.prototype.searchTemplates = function (event) {
        var _this = this;
        var templateValues = [];
        this.templateSearchList.filter(function (value) {
            if (value["templateName"].toLowerCase().includes(event.target.value.toLowerCase())) {
                templateValues.push(value);
                _this.templateList = templateValues;
            }
            _this.notFound = templateValues.length == 0;
        });
    };
    EmailModalComponent.prototype.selectedTemplate = function (id) {
        var selectedTemplate = this.templateList.find(function (data) { return data.id == id; });
        this.setTemplateDetails(selectedTemplate);
    };
    EmailModalComponent.prototype.setTemplateDetails = function (selectedTemplate) {
        var _this = this;
        this.emailHandleTagData['message'] = selectedTemplate['message'];
        this.emailHandleTagData['subject'] = selectedTemplate['subject'];
        this.folderService.getReplacedMessage(this.emailHandleTagData).subscribe(function (data) {
            if (data) {
                _this.emailForm.get('subject').setValue(data['subject']);
                data['message'] = data['message'] + _this.footerMessage;
                _this.emailForm.get('content').setValue(data['message']);
                _this.templateData = JSON.parse(JSON.stringify(data));
                _this.cancelTemplate();
                _this.updateTinyMCEValues();
            }
        });
        $(".template-wrapper").removeClass("template-open");
        $("#emailcontent").removeClass("overlay");
        this.showTemplateList = false;
        this.modalBack = false;
        this.folderStructureActive = false;
    };
    EmailModalComponent.prototype.sendEmail = function () {
        var _this = this;
        // Validate email modal
        this.emailForm.get('toAddresses').setValue(this.toAddresses);
        this.emailForm.get('ccAddresses').setValue(this.ccAddresses);
        if (!this.emailForm.value['toAddresses']) {
            $(".email-error-message").addClass("message-open");
            this.showEmailError = true;
            this.emailErrorMessage = 'Please choose a recipient';
        }
        else if (!this.emailForm.value['subject']) {
            $(".email-error-message").addClass("message-open");
            this.showEmailError = true;
            this.emailErrorMessage = 'You have not written a subject';
        }
        else {
            this._emailEventService.send(this);
            setTimeout(function () {
                _this.beginCloseWidget = true;
                setTimeout(function () {
                    _this.beginCloseWidget = false;
                    _this.closeModal(true);
                }, 300);
            }, 0);
        }
    };
    EmailModalComponent.prototype.processEmail = function () {
        var _this = this;
        this._emailEventService.beforeSend('hi before');
        this.sendButtonDisabled = true;
        this.showEmailError = false;
        var emailData;
        this.emailForm.controls['activityType'].setValue("Email");
        var attachedArray = [];
        for (var i_1 = 0; i_1 < this.attachedFiles.length; i_1++) {
            attachedArray.push(this.attachedFiles[i_1]['id']);
        }
        this.emailForm.controls['attachments'].setValue(attachedArray.join());
        this.emailForm.controls['fromAddress'].setValue(this.currentUserEmail);
        this.emailForm.controls['toAddresses'].setValue(this.toAddresses);
        emailData = this.emailForm.value;
        if (this.completed) {
            emailData = this.emailForm.value;
            var activityId = [];
            if (this.activityIsScheduled.length != 0) {
                for (var i = 0; i < this.activityIsScheduled.length; i++) {
                    activityId.push(this.activityIsScheduled[i]['activityId']);
                }
            }
            else {
                if (this.scheduledData) {
                    if (this.scheduledData.hasOwnProperty('activity_id')) {
                        activityId.push(this.scheduledData.activity_id);
                    }
                    else {
                        activityId.push(this.scheduledData.activityId);
                    }
                }
            }
            emailData.activityIds = activityId;
            if (this.todayActivityId) {
                emailData.activityIds = this.todayActivityId;
            }
        }
        var customerContact = (this.customerContacts.length > 0) ? this.customerContacts[0].id : '';
        var subject = this.emailForm.get('subject').value;
        var body = this.emailForm.get('content').value;
        var formData = new FormData();
        formData.append('contactId', customerContact);
        formData.append('tableType', this.tableType);
        formData.append('screenType', this.screenType);
        formData.append('tablePKID', this.tablePKId);
        formData.append('toAddresses', this.toAddresses);
        formData.append('fromAddress', this.currentUserEmail);
        if (this.ccAddresses) {
            formData.append('ccAddress', this.ccAddresses);
        }
        formData.append('body', body);
        formData.append('subject', subject);
        formData.append('attachments', attachedArray.join());
        formData.append('proposal', this.proposal);
        if (this.emailModalData.screenType === 'vehicle') {
            this.viewVehicleService.onSaveEmail(formData).subscribe(function (data) {
                if (data) {
                    // this.closeModal(true);
                    _this.showEmailModal = false;
                    _this.ts.loadVehicleTimelineData();
                    _this.emailForm.reset();
                }
            });
        }
        else {
            this.sharedModalService.saveEmail(formData, {
                '___noPageloading': '1'
            }).subscribe(function (data) {
                if (data) {
                    if (_this.isCallFromCsActivityLog) {
                        if (_this.afterSave)
                            _this.afterSave();
                    }
                    if (_this.isFromJs) {
                        _this.ajsScope.$broadcast('email:email_sent');
                        var data_1 = { 'id': _this.tablePKId, 'type': _this.tableType };
                        _this.ajsScope.$broadcast('event:sentStatus', data_1);
                    }
                    else {
                        _this.afterMailSend();
                    }
                    // this.closeModal(true);
                    // this.closeEmailWidget(true);
                    // this.showEmailModal = false;
                    if (_this.screenType == 'customer') {
                        _this.propertyService.setRefreshSliderScheduleActivity(true);
                    }
                    if (_this.screenType == 'opportunity') {
                        var refreshData = void 0;
                        if (_this.proposal == 'proposal') {
                            refreshData = {
                                data: {
                                    screen: 'addEmail',
                                    type: 'add',
                                    values: null,
                                    id: 'latest',
                                    proposal: 'sendProposal'
                                }
                            };
                        }
                        else {
                            _this.opportunityService.setRefreshActivityList(true);
                            refreshData = {
                                data: {
                                    screen: 'addEmail',
                                    type: 'add',
                                    values: null,
                                    id: 'latest'
                                }
                            };
                        }
                        _this.propertyService.setTimelineRefresh(refreshData);
                    }
                }
            });
        }
        this.sharedModalService.updateModalStatus('others', 'email', false);
    };
    EmailModalComponent.prototype.afterMailSend = function () {
        var _this = this;
        $("#emailTo").val(null).trigger("change");
        var responseData = true;
        if (this.completed && this.todayEmailActivity.length) {
            var activityData = { "activityIds": this.todayEmailActivity, 'screen': this.screenType };
            this.opportunityService.completeScheduledActivity(activityData).subscribe(function (data) {
                var dataSet = JSON.parse(JSON.stringify(_this.filterParams));
                dataSet.page = 1;
                dataSet.data = data;
                dataSet.isSearch = true;
                if (_this.isCallFromCsActivityLog) {
                    _this.alEvent.notifyScheduledActivityCompletion();
                }
                else {
                    _this.restrictUnwanted = false;
                    _this.propertyService.setTimelineRefresh(dataSet);
                    _this.propertyService.setRefreshSliderScheduleActivity(true);
                }
            }, function (error) {
                console.error('error', error);
            });
        }
        if (this.scheduled && this.scheduledActivityAccess) {
            this.sharedModalService.setScheduledActivityDetails(this.scheduledActivityParams);
        }
        this.propertyService.timelineRefresh$.subscribe(function (refreshData) {
            var scheduleList, filterActivityList = [];
            var userId, dateType = '';
            if (_this.filterParams && _this.filterParams.hasOwnProperty('dateType'))
                dateType = _this.filterParams.dateType;
            if (_this.filterParams && _this.filterParams.hasOwnProperty('scheduleList'))
                scheduleList = _this.filterParams.scheduleList;
            if (_this.filterParams && _this.filterParams.hasOwnProperty('filterActivityList'))
                filterActivityList = _this.filterParams.filterActivityList;
            if (_this.filterParams && _this.filterParams.hasOwnProperty('userId'))
                userId = _this.filterParams.userId;
            if (scheduleList != undefined || filterActivityList != undefined || dateType != '' || (userId != undefined && userId != '')) {
                _this.appliedFilterCondition = refreshData;
            }
        });
        setTimeout(function () {
            if (_this.restrictUnwanted) {
                _this.sharedModalService.setEmailSaveAction(responseData);
                _this.restrictUnwanted = true;
            }
            else {
                var newDataSet = JSON.parse(JSON.stringify(_this.filterParams));
                newDataSet.page = 1;
                newDataSet.isSearch = true;
                _this.restrictUnwanted = true;
                if (_this.completed && _this.todayEmailActivity.length && _this.screenType == 'customer') {
                }
                else if (_this.appliedFilterCondition) {
                    newDataSet = _this.appliedFilterCondition;
                }
                _this.propertyService.setTimelineRefresh(newDataSet);
            }
        }, 1500);
        var notificationMsg = this.translate.instant("Communication.mail.send");
        this.toast.show(notificationMsg, 1500);
        this.sendButtonDisabled = false;
        if (this.scheduledData) {
            if (this.scheduledData.hasOwnProperty('activityId')) {
                this.opportunityService.refreshCompleteActivity('complete');
            }
        }
    };
    EmailModalComponent.prototype.todayActivity = function () {
        var _this = this;
        if (!this.isCommonRoute) {
            this.opportunityService.getScheduledEmail(this.opportunityId, 'Email').subscribe(function (resp) {
                if (resp) {
                    if (resp == false) {
                        _this.emailActivityShow = false;
                        _this.completed = false;
                    }
                    else {
                        _this.emailActivityShow = true;
                        _this.completed = true;
                        _this.todayActivityId = resp;
                    }
                }
            });
        }
        else {
            if (this.todayEmailActivity.length > 0) {
                this.emailActivityShow = true;
            }
            else {
                this.emailActivityShow = false;
            }
        }
    };
    // for p tag remove for email template
    EmailModalComponent.prototype.updateTinyMCEValues = function () {
        var _this = this;
        if ($('#email-template-message').length) {
            tinymce.init({
                selector: "#email-template-message",
                menubar: false,
                toolbar: false,
                plugins: "paste,textcolor,autolink",
                placeholder: 'Subject',
                paste_as_text: true,
                browser_spellcheck: true,
                paste_block_drop: false,
                relative_urls: false,
                remove_script_host: false,
                content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
                setup: function (editor) {
                    editor.on('LoadContent', function () {
                        if (_this.templateData && _this.templateData['message'])
                            editor.setContent(_this.templateData['message']);
                        else {
                            if (_this.emailForm) {
                                editor.setContent(_this.emailForm.get('content').value);
                            }
                        }
                    });
                    _this.editor = editor;
                    editor.on('keyup', function () {
                        var content = editor.getContent();
                        _this.zone.run(function () { return _this.onChange(content); });
                    });
                    editor.on("click", function () {
                        $('#drop-down-emails').removeClass("open");
                    });
                }
            });
            tinymce.init({
                selector: "#email-template-subject",
                menubar: false,
                toolbar: false,
                statusbar: false,
                plugins: "paste",
                browser_spellcheck: true,
                paste_as_text: true,
                paste_block_drop: false,
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                content_style: "p { white-space: nowrap; } body {margin-top: 14px;}",
                setup: function (editor) {
                    editor.on('init', function () {
                        if (editor.getContent() == '') {
                            editor.setContent("<p class='sub-placeholder' style='color:#999;'>Subject</p>");
                        }
                    }),
                        editor.on('LoadContent', function () {
                            if (_this.templateData && _this.templateData['subject'])
                                editor.setContent(_this.templateData['subject']);
                        });
                    _this.editor = editor;
                    editor.on('keyup', function () {
                        var content = editor.getContent();
                        _this.zone.run(function () { return _this.onChange(content); });
                    });
                    editor.on("click", function () {
                        $('#drop-down-emails').removeClass("open");
                    });
                    editor.on("blur", function () {
                        if (editor.getContent() == '') {
                            editor.setContent("<p class='sub-placeholder' style='color:#999;'>Subject</p>");
                        }
                    });
                    editor.on("focus", function () {
                        $('#email-template-subject_ifr').contents().find('.sub-placeholder').remove();
                    });
                }
            });
            if (this.tinyMCEIntervel)
                clearInterval(this.tinyMCEIntervel);
        }
    };
    EmailModalComponent.prototype.closeModalConfirmation = function () {
        var _this = this;
        this.confirmationBoxHelper.getConfirmation('Are you sure you want to discard the email?', this.ajsScope)
            .then(function () {
            _this.closeModal(true);
        }).catch(function () { });
    };
    EmailModalComponent.prototype.sendProposalPdf = function () {
        var _this = this;
        this.sendButtonDisabled = true;
        var emailData = this.emailForm.value;
        var messageData = {
            "body": emailData['content'],
            "subject": emailData['subject'],
            "attachments": this.pdfData['url'],
            "contactId": this.toAddresses['id'],
            "toAddresses": this.toAddresses,
            "fromAddress": this.currentUserEmail,
            "ccAddress": "",
            "tablePKID": this.opportunityId,
        };
        this.opportunityService.sendProposalEmailToCustomer(messageData, this.opportunityId)
            .subscribe(function (resp) {
            // this.onSave.emit(this.scheduled);
            _this.closeModal(true);
            _this.sendButtonDisabled = false;
            _this.sendPdf = false;
            _this.pdfFiles = [];
        }, function (error) {
            console.log('err', error);
        });
    };
    EmailModalComponent.prototype.showAttachmentName = function (name, size) {
        var imageSize = '(' + (size).toFixed(2) + 'MB' + ')';
        return name.concat(" ", imageSize);
    };
    EmailModalComponent.prototype.closeDropdown = function () {
        $('#drop-down-emails').removeClass("open");
    };
    EmailModalComponent.prototype.cancelTemplate = function () {
        $("#emailcontent").removeClass("overlay");
        this.modalBack = false;
        $(".template-wrapper").removeClass("template-open");
        this.showTemplateList = false;
        this.folderStructureActive = false;
    };
    return EmailModalComponent;
}());
export { EmailModalComponent };
