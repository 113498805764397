<!---Modal header-->
<div class="modal-header">
    <h4 translate>Send.Proposal</h4>
</div>
<div class="modal-body">
    <ng-container *ngIf="sendtoCustomerLoading">
        <div class="overall-animation" >
            <!--  <iframe class="pdf-width" style="height:98%;width:528px;" [src]="Url"></iframe>-->
            <div class="loading-animation"  [ngStyle]="{'display': sendtoCustomerLoading ? 'block' : 'none'}">
                <!--        <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>-->
                <!--        <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>-->
                <!--        <div class="text-layout animated-background small div1" style="margin-top: 10px;"></div>-->

                <div class="loading" style="border: 1px solid #2286cee6;zoom:88%;margin-bottom: 10px">
                    <div class="row-align" style="display: flex;margin: 10px ;gap: 15px;">
                        <div class="col-first">
                            <div class="ssc-card card-send-customer">
                                <div class="text-layout animated-background small div1"></div>
                                <div class="text-layout animated-background small div2"></div>
                                <div class="text-layout animated-background small div3"></div>
                                <div class="text-layout animated-background small div4"></div>
                            </div>
                        </div>
                        <div class="col-first">
                            <div class="ssc-card card-send-customer">
                                <div class="img-container animated-background">
                                    <img src="images/noImage.png" class="image-block addPartSildeImages" />
                                    <div style="text-align: center">
                                        <label class="part-overflow"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="third-animation">
                        <div class="search-ssc-lg ssc">
                            <div class="ssc-card ssc-wrapper">
                                <div class="flex mbs">
                                    <div class="tag ssc-square"></div>
                                    <div class="tag ssc-square"></div>
                                    <div class="tag ssc-square"></div>
                                </div>
                            </div>
                            <br />
                            <div class="ssc-hr"></div>
                            <br />
                            <div class="ssc-card ssc-wrapper pricing-section-table">
                                <table>
                                    <thead class="tablehead-width">
                                    <tr>
                                        <th> <div class="text-layout animated-background small first-lading loading-animation-line3"></div></th>
                                        <th> <div class="text-layout animated-background small sec-loading loading-animation-line3"></div></th>
                                        <th><div class="text-layout animated-background small third-loading loading-animation-line3"></div></th>
                                        <th> <div class="text-layout animated-background small first-lading loading-animation-line3"></div></th>
                                        <th><div class="text-layout animated-background small sec-loading loading-animation-line3"></div></th>
                                    </tr>
                                    </thead>
                                    <tbody class="table-border">
                                    <ng-container>
                                        <tr *ngFor="let row of [0,1,2]" class="table-border">
                                            <td class="row-column-loading-animation checkbox-column">
                                                <div class="text-layout animated-background small first-lading loading-animation-line3"></div>
                                            </td>
                                            <td class="row-column-loading-animation checkbox-column">
                                                <div class="text-layout animated-background small sec-loading loading-animation-line3"></div>
                                            </td>
                                            <td class="row-column-loading-animation checkbox-column">
                                                <div class="text-layout animated-background small third-loading loading-animation-line3"></div>
                                            </td>
                                            <td class="row-column-loading-animation checkbox-column">
                                                <div class="text-layout animated-background small first-lading loading-animation-line3"></div>
                                            </td>
                                            <td class="row-column-loading-animation checkbox-column">
                                                <div class="text-layout animated-background small third-loading loading-animation-line3"></div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div class="ssc-hr"></div>
                            <br />
                            <div class="flex align-start" style="gap: 15px">
                                <div class="ssc-card w-50 mr">
                                    <div class="ssc-wrapper flex justify-between">
                                        <div class="w-60 mr">
                                            <div class="ssc-line mb w-50"></div>
                                            <div class="ssc-line mb w-70"></div>
                                            <div class="ssc-line w-90"></div>
                                        </div>
                                        <div class="ssc-circle" style="width: 70px; height: 70px"></div>
                                    </div>
                                </div>
                                <div class="w-100">
                                    <div class="ssc-card ssc-wrapper">
                                        <div class="ssc-head-line"></div>
                                        <div class="ssc-square video-center-align">
                                            <img class="video-icon-center" src="images/videoloading.png">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div class="ssc-card ssc-wrapper">
                                <div class="flex mbs">
                                    <div class="tag ssc-square"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="loading" style="border: 1px solid #2286cee6;zoom:88%">
                    <div class="row-align" style="display: flex;margin: 10px ;gap: 15px;flex-direction: row-reverse;">
                        <div class="col-first">
                            <div class="ssc-card card-send-customer">
                                <div class="text-layout animated-background small div1"></div>
                                <div class="text-layout animated-background small div2"></div>
                                <div class="text-layout animated-background small div3"></div>
                                <div class="text-layout animated-background small div4"></div>
                            </div>
                        </div>
                        <div class="col-first">
                            <div class="ssc-card card-send-customer">
                                <div class="img-container animated-background">
                                    <img src="images/noImage.png" class="image-block addPartSildeImages" />
                                    <div style="text-align: center">
                                        <label class="part-overflow"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="third-animation">
                        <div class="search-ssc-lg ssc">
                            <div class="ssc-card ssc-wrapper">
                                <div class="flex mbs">
                                    <div class="tag ssc-square"></div>
                                    <div class="tag ssc-square"></div>
                                    <div class="tag ssc-square"></div>
                                </div>
                            </div>
                            <br />
                            <div class="ssc-hr"></div>
                            <br />
                        </div>
                    </div>
                    <br><br><br><br><br><br><br><br><br><br>
                </div>

            </div>
        </div>
    </ng-container>

    <div class="content-section manidisp" [ngStyle]="{'display': sendtoCustomerLoading ? 'none' : 'block'}">
        <div class="page-class" style="height: 87vh">
            <iframe frameBorder="0"  #iframe id="customer_view_pdf"
                    src="angularES/src/app/article-editor/froala-editor/css_js/sendToCustomer.html" width="100%" style="height: 100%;">
                Sorry your browser does not support inline frames.
            </iframe>
        </div>
    </div>
</div>
<div class="modal-footer">

    <div class="loading-section" *ngIf="sendtoCustomerLoading">
        <div class="text-layout animated-background small sec-loading loading-animation-line3"></div>
        <div class="text-layout animated-background small sec-loading loading-animation-line3"></div>
        <div class="text-layout animated-background small sec-loading loading-animation-line3"></div>
    </div>

    <ng-container *ngIf="showAcceptBtn === 'showProposalAcceptBtn' || showAcceptBtn === 'sendToCustomer'">
        <!-- Proposal mismatch warning -->
        <ng-container *ngIf="proposalValueMismatch && proposalValueMismatchError">
            <div class="proposal-mismatch-value">
            <span class="error-text-warning">
                <svg class="svg-icon-alignment" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14.8424 12.6948C15.2911 13.4724 14.7279 14.4444 13.8319 14.4444H2.16799C1.27021 14.4444 0.709677 13.4709 1.15743 12.6948L6.98945 2.58297C7.4383 1.80497 8.56251 1.80638 9.01056 2.58297L14.8424 12.6948ZM8 10.6042C7.38252 10.6042 6.88195 11.1047 6.88195 11.7222C6.88195 12.3397 7.38252 12.8403 8 12.8403C8.61749 12.8403 9.11806 12.3397 9.11806 11.7222C9.11806 11.1047 8.61749 10.6042 8 10.6042ZM6.93851 6.58534L7.11881 9.89089C7.12724 10.0456 7.25514 10.1667 7.41004 10.1667H8.58997C8.74487 10.1667 8.87277 10.0456 8.8812 9.89089L9.0615 6.58534C9.07062 6.41826 8.93759 6.27778 8.77027 6.27778H7.22971C7.06239 6.27778 6.92939 6.41826 6.93851 6.58534Z" fill="#BE6D6D"/>
                </svg>
                <span class="warring-message-alignment">Please check the proposal values, as a discrepancy has been identified.</span>
            </span>
                <span class="proposal-value-discrepancy-text" (click)="fixDiscrepancy('openEditor')">
                Fix this discrepancy
            </span>
            </div>
        </ng-container>

        <!-- Proposal accept buttons -->
        <ng-container *ngIf="proposalValueMismatch === false && proposalValueMismatchError === false">
            <div class="buttons">
                <a class="btn" (click)="closeModal('close')">Close</a>

                <!-- Accept proposal button -->
                <ng-container *ngIf="showAcceptBtn === 'showProposalAcceptBtn'">
                    <button class="btn btn-primary primary-button" (click)="fixDiscrepancy('acceptProposalModal')">Accept proposal</button>
                </ng-container>

                <!-- Send to customer buttons -->
                <ng-container *ngIf="showAcceptBtn === 'sendToCustomer'">
                    <ng-container *ngIf="hasSMSModuleAccess?.Sms == 1">
                        <button  *access = "['SendSMS.writeaccess']" class="btn btn-primary primary-button" [disabled]="sendButtonDisabled ||sendtoCustomerLoading" (click)="openSidePanel('sms')" translate>Sms</button>
                    </ng-container>
                    <button  *access = "['SendEmail.writeaccess']" class="btn btn-primary primary-button" [disabled]="sendButtonDisabled || sendtoCustomerLoading" (click)="openSidePanel('email')" translate>Email</button>
                    <!--    <a class="btn btn-primary primary-button" (click)="closeModal('print')" [href]="url" target="_blank" translate>Print</a>-->
                    <ng-container *access = "['SendEmail.writeaccess']">
                        <button class="btn btn-primary primary-button" [disabled]="sendButtonDisabled || sendtoCustomerLoading" (click)="bsModalRef.hide();closeModal('print')" translate>Print (beta)</button>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>



</div>

