import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { InterfaceService } from "@app/interfaces";
import { Subject } from "rxjs/internal/Subject";
import { of } from 'rxjs';
var ProposalTemplateService = /** @class */ (function (_super) {
    tslib_1.__extends(ProposalTemplateService, _super);
    // discrepancy check total value on pricing section table end
    function ProposalTemplateService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.pageBreakContent = new BehaviorSubject(null);
        _this.pageBreakContent$ = _this.pageBreakContent.asObservable();
        _this.templateName = new BehaviorSubject(null);
        _this.templateName$ = _this.templateName.asObservable();
        _this.advanceStyleUpdates = new BehaviorSubject(null);
        _this.advanceStyleUpdates$ = _this.advanceStyleUpdates.asObservable();
        _this.pagePropertiesUpdates = new BehaviorSubject(null);
        _this.pagePropertiesUpdates$ = _this.pagePropertiesUpdates.asObservable();
        _this.entireEditorLoading = new BehaviorSubject(null);
        _this.entireEditorLoading$ = _this.entireEditorLoading.asObservable();
        _this.updatePricingSummaryStyles = new BehaviorSubject(null);
        _this.updatePricingSummaryStyles$ = _this.updatePricingSummaryStyles.asObservable();
        _this.coverPage = new BehaviorSubject(null);
        _this.coverPage$ = _this.coverPage.asObservable();
        _this.optionSummary = new BehaviorSubject(null);
        _this.optionSummary$ = _this.optionSummary.asObservable();
        _this.contentEditorStatus = new BehaviorSubject(null);
        _this.contentEditorStatus$ = _this.contentEditorStatus.asObservable();
        _this.opportunitycoverPage = new BehaviorSubject(null);
        _this.opportunitycoverPage$ = _this.opportunitycoverPage.asObservable();
        _this.hottableInstance = new Subject();
        _this.hotTableInstance$ = _this.hottableInstance.asObservable();
        _this.pricingSectionType = new BehaviorSubject('');
        _this.pricingSectionType$ = _this.pricingSectionType.asObservable();
        _this.pageNumber = new BehaviorSubject('');
        _this.pageNumber$ = _this.pageNumber.asObservable();
        _this.pageList = new BehaviorSubject('');
        _this.pageList$ = _this.pageList.asObservable();
        _this.addOnBlocks = new BehaviorSubject('');
        _this.addOnBlocks$ = _this.addOnBlocks.asObservable();
        _this.opportunitypageList = new BehaviorSubject('');
        _this.opportunitypageList$ = _this.opportunitypageList.asObservable();
        _this.labourHotTableInstance = new Subject();
        _this.labourHotTableInstance$ = _this.labourHotTableInstance.asObservable();
        _this.expensesHotTableInstance = new Subject();
        _this.expensesHotTableInstance$ = _this.expensesHotTableInstance.asObservable();
        _this.depositHotTableInstance = new BehaviorSubject('');
        _this.depositHotTableInstance$ = _this.depositHotTableInstance.asObservable();
        _this.refreshPage = new BehaviorSubject('');
        _this.refreshPage$ = _this.refreshPage.asObservable();
        _this.refreshEditorThumbnailUrl = new BehaviorSubject('');
        _this.refreshEditorThumbnailUrl$ = _this.refreshEditorThumbnailUrl.asObservable();
        _this.clearData = new BehaviorSubject('');
        _this.clearData$ = _this.clearData.asObservable();
        _this.selectedAddon = new BehaviorSubject('');
        _this.selectedAddon$ = _this.selectedAddon.asObservable();
        _this.refreshAddonSidebar = new BehaviorSubject('');
        _this.refreshAddonSidebar$ = _this.refreshAddonSidebar.asObservable();
        _this.savaData = new BehaviorSubject('');
        _this.savaData$ = _this.savaData.asObservable();
        _this.savaEditorThumbnail = new BehaviorSubject(null);
        _this.savaEditorThumbnail$ = _this.savaEditorThumbnail.asObservable();
        _this.savePreviousPageData = new BehaviorSubject('');
        _this.savePreviousPageData$ = _this.savePreviousPageData.asObservable();
        _this.closeModal = new BehaviorSubject('');
        _this.closeModal$ = _this.closeModal.asObservable();
        _this.setOpenPricing = new BehaviorSubject('');
        _this.setOpenPricing$ = _this.setOpenPricing.asObservable();
        _this.zoomValue = new BehaviorSubject('');
        _this.zoomValue$ = _this.zoomValue.asObservable();
        _this.addCustomerPricingTable = new BehaviorSubject(false);
        _this.addCustomerPricing$ = _this.addCustomerPricingTable.asObservable();
        _this.loadData = new BehaviorSubject(false);
        _this.loadData$ = _this.loadData.asObservable();
        _this.pricingSectionData = new BehaviorSubject('');
        _this.pricingSectionData$ = _this.pricingSectionData.asObservable();
        _this.sectionDetails = new BehaviorSubject('');
        _this.sectionDetails$ = _this.sectionDetails.asObservable();
        _this.sidebarContent = new BehaviorSubject(null);
        _this.sidebarContent$ = _this.sidebarContent.asObservable();
        _this.sectionData = new BehaviorSubject(null);
        _this.sectionData$ = _this.sectionData.asObservable();
        _this.optionTypeWatch = new BehaviorSubject(null);
        _this.optionTypeWatch$ = _this.optionTypeWatch.asObservable();
        _this.proposalVersion = new BehaviorSubject(null);
        _this.proposalVersion$ = _this.proposalVersion.asObservable();
        _this.proposalStatus = new BehaviorSubject(null);
        _this.proposalStatus$ = _this.proposalStatus.asObservable();
        _this.addRemoveSectionData = new BehaviorSubject(null);
        _this.addRemoveSectionData$ = _this.addRemoveSectionData.asObservable();
        _this.SelectedFinanceDetails = new BehaviorSubject(null);
        _this.SelectedFinanceDetails$ = _this.SelectedFinanceDetails.asObservable();
        _this.SelectedFinanceChange = new BehaviorSubject(null);
        _this.SelectedFinanceChange$ = _this.SelectedFinanceChange.asObservable();
        _this.SelectedGrantChange = new BehaviorSubject(null);
        _this.SelectedGrantChange$ = _this.SelectedGrantChange.asObservable();
        _this.$formDataEmitted = new BehaviorSubject(null);
        _this.formDataEmitted = _this.$formDataEmitted.asObservable();
        _this.refreshInvoiceSchedule = new BehaviorSubject(null);
        _this.refreshInvoiceSchedule$ = _this.refreshInvoiceSchedule.asObservable();
        _this.updateInvoiceSchedulePopup = new BehaviorSubject(null);
        _this.updateInvoiceSchedulePopup$ = _this.updateInvoiceSchedulePopup.asObservable();
        _this.$invoiceFormEmitted = new BehaviorSubject(null);
        _this.invoiceFormEmitted = _this.$invoiceFormEmitted.asObservable();
        _this.pricingSectionPartsData = new BehaviorSubject('');
        _this.pricingSectionPartsData$ = _this.pricingSectionPartsData.asObservable();
        _this.invoiceStatus = new BehaviorSubject(null);
        _this.invoiceStatus$ = _this.invoiceStatus.asObservable();
        _this.tableTemplateId = new BehaviorSubject(null);
        _this.tableTemplateId$ = _this.tableTemplateId.asObservable();
        _this.refreshPricingBreakdownSidebar = new BehaviorSubject('');
        _this.refreshPricingBreakdownSidebar$ = _this.refreshPricingBreakdownSidebar.asObservable();
        _this.saveContentLibraryTemplate = new BehaviorSubject(null);
        _this.saveContentLibraryTemplate$ = _this.saveContentLibraryTemplate.asObservable();
        _this.proposalEmailStatus = new BehaviorSubject(null);
        _this.proposalEmailStatus$ = _this.proposalEmailStatus.asObservable();
        _this.closeAllModal = new BehaviorSubject(null);
        _this.closeAllModal$ = _this.closeAllModal.asObservable();
        _this.closePreviousVersion = new BehaviorSubject(null);
        _this.closePreviousVersion$ = _this.closePreviousVersion.asObservable();
        // discrepancy check total value on pricing section table start
        _this.moveDiscrepancyNextPage = new BehaviorSubject(null);
        _this.moveDiscrepancyNextPage$ = _this.moveDiscrepancyNextPage.asObservable();
        return _this;
    }
    ProposalTemplateService.prototype.setDiscrepancyNextPage = function (nextPage) {
        this.moveDiscrepancyNextPage.next(nextPage);
    };
    ProposalTemplateService.prototype.updateAdvancedStyles = function (styles) {
        this.advanceStyleUpdates.next(styles);
    };
    ProposalTemplateService.prototype.updatePagePropertiesUpdates = function (pageProperties) {
        this.pagePropertiesUpdates.next(pageProperties);
    };
    ProposalTemplateService.prototype.setEntireEditorLoading = function (data) {
        this.entireEditorLoading.next(data);
    };
    ProposalTemplateService.prototype.updatePricingSummaryStyle = function (styles) {
        this.updatePricingSummaryStyles.next(styles);
    };
    ProposalTemplateService.prototype.publishInvoiceScheduleRefresh = function (content) {
        this.refreshInvoiceSchedule.next(content);
    };
    ProposalTemplateService.prototype.publishInvoiceOpenClose = function (content) {
        this.updateInvoiceSchedulePopup.next(content);
    };
    ProposalTemplateService.prototype.setPageBreakContent = function (content) {
        this.pageBreakContent.next(content);
    };
    ProposalTemplateService.prototype.DisplayzoomValue = function (value) {
        this.zoomValue.next(value);
    };
    ProposalTemplateService.prototype.setOptionTypeWatch = function (data) {
        this.optionTypeWatch.next(data);
    };
    ProposalTemplateService.prototype.setAddRemoveSectionData = function (data) {
        this.addRemoveSectionData.next(data);
    };
    ProposalTemplateService.prototype.setSectionData = function (data) {
        this.sectionData.next(data);
    };
    ProposalTemplateService.prototype.setProposalVersion = function (data) {
        this.proposalVersion.next(data);
    };
    ProposalTemplateService.prototype.setProposalStatus = function (data) {
        this.proposalStatus.next(data);
    };
    ProposalTemplateService.prototype.addCustomerPricingsection = function (value) {
        this.addCustomerPricingTable.next(value);
    };
    ProposalTemplateService.prototype.checkSectionExists = function (id, params) {
        return this.http.get(this.getApiUrl("checkPricingSectionExists/" + id, params));
    };
    //Add or Edit Proposal Template API Call
    ProposalTemplateService.prototype.addOrEditProposalTemplates = function (data) {
        return this.http.post(this.getApiUrl("addOrEditProposalTemplate"), data);
    };
    //Delete Proposal Template API Call
    ProposalTemplateService.prototype.deleteProposalTemplate = function (id, params) {
        return this.http
            .delete(this.getApiUrl(id + "/deleteProposalTemplates", params));
    };
    // Delete Editor Pages
    ProposalTemplateService.prototype.deleteEditorPages = function (id, data) {
        return this.http.post(this.getApiUrl("deleteTemplatePages/" + id), data);
    };
    // Delete Editor Pages
    ProposalTemplateService.prototype.deletePagePricingSections = function (id) {
        return this.http.get(this.getApiUrl("deletePagePricingSections/" + id));
    };
    // Delete pricing section from editor
    ProposalTemplateService.prototype.deletePricingSection = function (id, data) {
        return this.http.post(this.getApiUrl("deletePricingSection/" + id), data);
    };
    // Delete pricing section from editor
    ProposalTemplateService.prototype.checkIsAddOn = function (optionId) {
        return this.http.get(this.getApiUrl("checkIsAddOn/" + optionId));
    };
    //Row Reorder API Call
    ProposalTemplateService.prototype.rowReorder = function (data) {
        return this.http
            .put(this.getApiUrl("reorderProposalTemplates"), data);
    };
    //Check proposal template already exists
    ProposalTemplateService.prototype.checkProposalTemplateExists = function (value) {
        return this.http.get(this.getApiUrl("checkProposalTemplateExists", value));
    };
    //Get Optional Template
    ProposalTemplateService.prototype.getSetupOption = function () {
        return this.http.get(this.getApiUrl("listSetupOptions"));
    };
    ProposalTemplateService.prototype.getAddonList = function (id, type) {
        return this.http.get(this.getApiUrl("getAddOnOptionTemplates/" + id + "/" + type));
    };
    // Set contentType
    ProposalTemplateService.prototype.setTemplateName = function (data) {
        this.templateName.next(data);
    };
    ProposalTemplateService.prototype.setCoverPage = function (data) {
        this.coverPage.next(data);
    };
    ProposalTemplateService.prototype.setOptionSummary = function (data) {
        this.optionSummary.next(data);
    };
    ProposalTemplateService.prototype.setPagesList = function (data) {
        this.pageList.next(data);
    };
    ProposalTemplateService.prototype.setAddOnBlocks = function (data) {
        this.addOnBlocks.next(data);
    };
    ProposalTemplateService.prototype.setOpportunityCoverPage = function (data) {
        this.opportunitycoverPage.next(data);
    };
    ProposalTemplateService.prototype.setOpportunityPagesList = function (data) {
        this.opportunitypageList.next(data);
    };
    ProposalTemplateService.prototype.savePages = function (data) {
        this.savaData.next(data);
    };
    ProposalTemplateService.prototype.setEditorThumbnail = function (data) {
        this.savaEditorThumbnail.next(data);
    };
    ProposalTemplateService.prototype.setPreviousPageData = function (data) {
        this.savePreviousPageData.next(data);
    };
    ProposalTemplateService.prototype.callCloseModalFuntion = function (data) {
        this.closeModal.next(data);
    };
    ProposalTemplateService.prototype.openPricingModal = function (data) {
        this.setOpenPricing.next(data);
    };
    ProposalTemplateService.prototype.setHottableInstance = function (data) {
        this.hottableInstance.next(data);
    };
    ProposalTemplateService.prototype.setTableTemplateId = function (data) {
        this.tableTemplateId.next(data);
    };
    ProposalTemplateService.prototype.getProposalPages = function (id) {
        var url = this.getApiUrl(id + "/getProposalTemplatePages");
        return this.http.get(url);
    };
    // Pricing section Parts save
    ProposalTemplateService.prototype.addOrEditPricingSectionParts = function (data, type) {
        return this.http.post(this.getApiUrl("addProposalCostPart?type=" + type), data);
    };
    //Pricing section get parts data
    ProposalTemplateService.prototype.getPricingSectionParts = function (data, type) {
        return this.http.post(this.getApiUrl("getProposalCostParts?type=" + type), data);
    };
    // Pricing section labour save
    ProposalTemplateService.prototype.addOrEditPricingSectionLabour = function (data, type) {
        return this.http.post(this.getApiUrl("addProposalCostLabour?type=" + type), data);
    };
    //Pricing section get labour data
    ProposalTemplateService.prototype.getPricingSectionLabour = function (data, type) {
        return this.http.post(this.getApiUrl("getProposalCostLabour?type=" + type), data);
    };
    // Pricing section expenses save
    ProposalTemplateService.prototype.addOrEditPricingSectionExpenses = function (data, type) {
        return this.http.post(this.getApiUrl("addProposalCostExpenses?type=" + type), data);
    };
    //Pricing section get expenses data
    ProposalTemplateService.prototype.getPricingSectionExpenses = function (data, type) {
        return this.http.post(this.getApiUrl("getProposalCostExpenses?type=" + type), data);
    };
    ProposalTemplateService.prototype.getPermissions = function () {
        return this.http.get(this.getApiUrl('get_access_details'));
    };
    ProposalTemplateService.prototype.setPricingSectionType = function (data) {
        this.pricingSectionType.next(data);
    };
    ProposalTemplateService.prototype.setPage = function (data) {
        this.pageNumber.next(data);
    };
    ProposalTemplateService.prototype.getPartsDropDownData = function (sectionIdList, viewMode) {
        return this.http.get(this.getApiUrl("getPartsSupplierData", { sections: sectionIdList, viewMode: viewMode }));
    };
    ProposalTemplateService.prototype.getSelectivePartsData = function (parts, listId, viewMode) {
        return this.http.get(this.getApiUrl("getPartsSupplierData", { parts: parts, listId: listId, viewMode: viewMode }));
    };
    ProposalTemplateService.prototype.setLabourHotInstance = function (data) {
        this.labourHotTableInstance.next(data);
    };
    ProposalTemplateService.prototype.setExpensesInstance = function (data) {
        this.expensesHotTableInstance.next(data);
    };
    ProposalTemplateService.prototype.setDepositInstance = function (data) {
        this.depositHotTableInstance.next(data);
    };
    ProposalTemplateService.prototype.getSuppliers = function () {
        return this.http.get(this.getApiUrl("get_all_suppliers"));
    };
    ProposalTemplateService.prototype.refreshPages = function (data) {
        this.refreshPage.next(data);
    };
    ProposalTemplateService.prototype.refreshEditorThumbnailUrls = function (data) {
        this.refreshEditorThumbnailUrl.next(data);
    };
    ProposalTemplateService.prototype.clearSubscriptions = function (data) {
        this.clearData.next(data);
    };
    ProposalTemplateService.prototype.setSelectedAddon = function (data) {
        this.selectedAddon.next(data);
    };
    ProposalTemplateService.prototype.setRefreshAddonSidebar = function (data) {
        this.refreshAddonSidebar.next(data);
    };
    ProposalTemplateService.prototype.addPricingOptionTemplateList = function (data) {
        return this.http.post(this.getApiUrl("addOrLinkProposalOptions"), data);
    };
    ProposalTemplateService.prototype.checkPricingOptionExists = function (optionId, pageNumber) {
        return this.http.get(this.getApiUrl(optionId + "/checkOptionPricingExists?pageNumber=" + pageNumber));
    };
    ProposalTemplateService.prototype.getPartsKitList = function () {
        return this.http.get(this.getApiUrl("listPartKits?page=" + 1 + "&limit=" + 1000));
    };
    ProposalTemplateService.prototype.deletePrposalTemplateCost = function (id, partLabourId) {
        if (partLabourId) {
            return this.http.delete(this.getApiUrl(id + "/deleteProposalTemplateCost?partLabourCostId=" + partLabourId));
        }
        else {
            return this.http.delete(this.getApiUrl(id + "/deleteProposalTemplateCost"));
        }
    };
    // Delete options
    ProposalTemplateService.prototype.deleteOptions = function (id, proposal_id, params) {
        return this.http
            .get(this.getApiUrl("deleteProposalOption/" + id + "?proposalId=" + proposal_id));
    };
    ProposalTemplateService.prototype.getTotalCost = function (params) {
        return this.http.get(this.getApiUrl("getPricingOptionBreakdowns", params));
    };
    ProposalTemplateService.prototype.getInvoiceScheduleData = function (params) {
        return this.http.post(this.getApiUrl("sales/getInvoiceScheduleData"), params);
    };
    ProposalTemplateService.prototype.editSectionOrListName = function (data, id, type) {
        return this.http
            .post(this.getApiUrl("updateSectionOrList/" + id + "?type=" + type), data);
    };
    ProposalTemplateService.prototype.getSelectedPart = function (partId) {
        return this.http.get(this.getApiUrl("getSelectedValuePart/" + partId));
    };
    ProposalTemplateService.prototype.getPartList = function (category, searchText, limit, page) {
        return this.http.get(this.getApiUrl("listPartsAndPrices?limit=" + limit + "&page=" + page + "&category=" + category + "&searchText=" + searchText));
    };
    ProposalTemplateService.prototype.checkPricingProposalExists = function (proposalId, optionId, pageNumber) {
        return this.http.get(this.getApiUrl(proposalId + "/checkProposalPricingExists?pageNumber=" + pageNumber + "&optionId=" + optionId));
    };
    ProposalTemplateService.prototype.saveUpsellPartDeatils = function (data) {
        return this.http.post(this.getApiUrl("addPricingSectionPartsUpsell"), data);
    };
    ProposalTemplateService.prototype.addCustomerPricing = function (data, template, merge) {
        return this.http.post(this.getApiUrl("addCustomerPricing?type=" + template + "&merge=" + merge), data);
    };
    ProposalTemplateService.prototype.updateTemplateSectionDetails = function (data, sectionId) {
        return this.http.post(this.getApiUrl("sales/updateTemplateSectionDetails/" + sectionId), data);
    };
    ProposalTemplateService.prototype.addCustomerPricingItem = function (data, sectionId, type) {
        return this.http.post(this.getApiUrl("sales/addCustomerPricingItem/" + sectionId + "?type=" + type), data);
    };
    ProposalTemplateService.prototype.getCustomerPricing = function (data, template) {
        return this.http.post(this.getApiUrl("sales/getCustomerPricingItems?type=" + template), data);
    };
    ProposalTemplateService.prototype.editCustomerPricing = function (data, id) {
        return this.http.post(this.getApiUrl("sales/editCustomerPricingItem/" + id), data);
    };
    ProposalTemplateService.prototype.getProposalOptionLinkedPricing = function (data) {
        return this.http.get(this.getApiUrl("sales/getCustomerLineItems/" + data['sectionId']), data);
    };
    ProposalTemplateService.prototype.addOrEditPricingFormattingOptions = function (data) {
        return this.http.post(this.getApiUrl("addOrEditPricingFormattingOptions"), data);
    };
    ProposalTemplateService.prototype.updatetooltip = function (id) {
        return this.http
            .get(this.getApiUrl(id + "/updateProposalTemplateToolTip"));
    };
    ProposalTemplateService.prototype.getAttachedFiles = function (id) {
        return this.http.get(this.getApiUrl("getProposalAttatchedFiles/" + id));
    };
    ProposalTemplateService.prototype.getCustomerImages = function (id) {
        if (id === void 0) { id = null; }
        return this.http.get(this.getApiUrl("sales/get_customer_images?templateId=" + id));
    };
    ProposalTemplateService.prototype.addPageImages = function (files, pageId) {
        return this.http.post(this.getApiUrl("sales/upload_editor_images/" + pageId), files);
    };
    ProposalTemplateService.prototype.addPricingImages = function (files, pricingId) {
        return this.http.post(this.getApiUrl("sales/upload_customer_pricing_images/" + pricingId), files);
    };
    ProposalTemplateService.prototype.addPageVideo = function (files, pageId) {
        if (files && files.length) {
            var formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                formData.append("file" + i, files[i]);
            }
            return this.http.post(this.getApiUrl("sales/upload_editor_video/" + pageId), formData);
        }
    };
    ProposalTemplateService.prototype.getEstimatedSuppliers = function () {
        return this.http
            .get(this.getApiUrl("get_all_estimate_suppliers"));
    };
    ProposalTemplateService.prototype.createPartsList = function (data) {
        return this.http.post(this.getApiUrl("createOrEditPartsList"), data);
    };
    ProposalTemplateService.prototype.getlabourRate = function () {
        return this.http.get(this.getApiUrl("sales/get_labour_rates"));
    };
    //Add or Edit Section Name
    ProposalTemplateService.prototype.addOrEditPricingSection = function (data, params) {
        return this.http.post(this.getApiUrl("addOrEditPricingSection", params), data);
    };
    ProposalTemplateService.prototype.updatePricingSection = function (data, params) {
        return this.http.post(this.getApiUrl("updatePricingSection", params), data);
    };
    ProposalTemplateService.prototype.getPricingSummary = function (data, params) {
        return this.http.post(this.getApiUrl("getPricingSummary", params), data);
    };
    ProposalTemplateService.prototype.addOrEditUpsellParts = function (data) {
        return this.http.post(this.getApiUrl("addPricingSectionPartsUpsell"), data);
    };
    ProposalTemplateService.prototype.saveColumn = function (data) {
        var url = this.getApiUrl("userColumnOrder");
        return this.http.post(url, data);
    };
    ProposalTemplateService.prototype.getZoomValue = function () {
        return this.http.get(this.getApiUrl("getEditorPagesZoomLevel"));
    };
    ProposalTemplateService.prototype.getPartsListData = function (listId) {
        return this.http.get(this.getApiUrl("getPartsListData/" + listId));
    };
    ProposalTemplateService.prototype.deleteList = function (sectionId, listId, labourPricingIds) {
        if (labourPricingIds === void 0) { labourPricingIds = []; }
        var data = {
            "labourPricingIds": labourPricingIds
        };
        return this.http.post(this.getApiUrl("deletePricingList/" + listId + "?sectionId=" + sectionId), data);
    };
    ProposalTemplateService.prototype.setLoadData = function (data) {
        this.loadData.next(data);
    };
    ProposalTemplateService.prototype.setPricingSectionData = function (data) {
        this.pricingSectionData.next(data);
    };
    ProposalTemplateService.prototype.setSidebarContent = function (data) {
        this.sidebarContent.next(data);
    };
    ProposalTemplateService.prototype.setSectionDetails = function (data) {
        this.sectionDetails.next(data);
    };
    ProposalTemplateService.prototype.saveContentLibrary = function (data) {
        this.saveContentLibraryTemplate.next(data);
    };
    ProposalTemplateService.prototype.getNominalCodeList = function () {
        return this.http.get(this.getApiUrl("listExpensesNominalAccounts"));
    };
    ProposalTemplateService.prototype.reorderOptionPages = function (id, data, type) {
        return this.http.post(this.getApiUrl("reOrderTemplatePages/" + id + "?type=" + type), data);
    };
    ProposalTemplateService.prototype.getPartsListImage = function (screen, templateid, proposalTemplateid) {
        return this.http.get(this.getApiUrl("listPartImages?screenType=" + screen + "&templateId=" + templateid + "&proposalTemplateId=" + proposalTemplateid));
    };
    ProposalTemplateService.prototype.addInvoiceSchedule = function (data, params) {
        return this.http.post(this.getApiUrl("sales/addEditInvoiceSchedule?invoiceType=" + params), data);
    };
    ProposalTemplateService.prototype.getInvoiceSchedule = function (id) {
        return this.http.get(this.getApiUrl("sales/getInvoiceScheduleDetails/" + id));
    };
    ProposalTemplateService.prototype.uploadPricingCustomerImage = function (data, id) {
        return this.http.post(this.getApiUrl("sales/upload_customer_pricing_images/" + id), data);
    };
    ProposalTemplateService.prototype.getInvoiceScheduleTax = function (params) {
        return this.http.get(this.getApiUrl("sales/getInvoiceScheduleTaxDetails", params));
    };
    ProposalTemplateService.prototype.getInvoiceVatGroup = function (data) {
        return this.http.post(this.getApiUrl("sales/getPartsVatRateGroup"), data);
    };
    ProposalTemplateService.prototype.getFinanceTemplate = function (optionId, tableType, tablePkId) {
        var data = {
            "tableType": tableType,
            "tablePkId": tablePkId,
            "optionId": optionId
        };
        return this.http.post(this.getApiUrl("sales/getFinanceTemplate"), data);
    };
    ProposalTemplateService.prototype.getAddOnData = function (optionId, tableType, tablePkId) {
        var data = {
            "tableType": tableType,
            "tablePkId": tablePkId,
            "optionId": optionId
        };
        return this.http.post(this.getApiUrl("getAddOnData"), data);
    };
    ProposalTemplateService.prototype.getPartUpsellData = function (id) {
        return this.http.get(this.getApiUrl("getPartUpsellData/" + id));
    };
    ProposalTemplateService.prototype.removePartUpsell = function (id) {
        return this.http.get(this.getApiUrl("removePartUpsell/" + id));
    };
    ProposalTemplateService.prototype.getSelectedFinanceDetails = function (res) {
        this.SelectedFinanceDetails.next(res);
    };
    ProposalTemplateService.prototype.getSelectedFinanceChange = function (res) {
        this.SelectedFinanceChange.next(res);
    };
    ProposalTemplateService.prototype.getSelectedGrantChange = function (res) {
        this.SelectedGrantChange.next(res);
    };
    ProposalTemplateService.prototype.setContentEditorStatus = function (data) {
        this.contentEditorStatus.next(data);
    };
    ProposalTemplateService.prototype.getInvoiceDropDownData = function (data) {
        return this.http.post(this.getApiUrl("sales/getInvoiceScheduleDropdown"), data);
    };
    ProposalTemplateService.prototype.addInvoiceSchedulePricing = function (data, id) {
        return this.http.post(this.getApiUrl("sales/addOrEditInvoicePricing/" + id), data);
    };
    ProposalTemplateService.prototype.getInvoiceRelatedData = function (data) {
        return this.http.post(this.getApiUrl("sales/getInvoiceRelatedData"), data);
    };
    ProposalTemplateService.prototype.getInvoiceSchedulePricing = function (id) {
        return this.http.get(this.getApiUrl("sales/getInvoiceSchedulePricing/" + id));
    };
    ProposalTemplateService.prototype.triggerInvoice = function (data) {
        this.$formDataEmitted.next(data);
    };
    ProposalTemplateService.prototype.getNominalList = function () {
        return this.http.get(this.getApiUrl("listInvoiceNominalAccounts"));
    };
    ProposalTemplateService.prototype.deleteInvoiceSchedule = function (id) {
        return this.http.delete(this.getApiUrl("sales/deleteInvoicePricing/" + id));
    };
    ProposalTemplateService.prototype.getInvoiceScheduleExists = function (data) {
        return this.http.post(this.getApiUrl("sales/getInvoiceScheduleExists/"), data);
    };
    ProposalTemplateService.prototype.setchangeValues = function (data) {
        this.$invoiceFormEmitted.next(data);
    };
    ProposalTemplateService.prototype.getTaxType = function (data) {
        return this.http.post(this.getApiUrl("sales/getInvoiceTaxType"), data);
    };
    ProposalTemplateService.prototype.updateProposalOptionPrices = function (opportunityId) {
        return this.http.get(this.getApiUrl("sales/addUpdateProposalPrices/" + opportunityId));
    };
    ProposalTemplateService.prototype.opportunityTemplateLinkedProposal = function (id) {
        return this.http.get(this.getApiUrl("checkIsProposalLinkOpportunityTemplate/" + id));
    };
    ProposalTemplateService.prototype.getProposalPdf = function (id) {
        return this.http.get(this.getApiUrl("getProposalPDF/" + id));
    };
    // Clone proposal template
    ProposalTemplateService.prototype.cloneProposalTemplate = function (id, parentId) {
        return this.http.get(this.getApiUrl("cloneProposalTemplate/" + id + "/" + parentId));
    };
    ProposalTemplateService.prototype.getPricingStyles = function (data) {
        return this.http.post(this.getApiUrl("sales/get_pricing_styles"), data);
    };
    ProposalTemplateService.prototype.editCustomerPricingCost = function (data, id) {
        return this.http.post(this.getApiUrl("editCustomerPricing/" + id), data);
    };
    ProposalTemplateService.prototype.getDiaryFeedback = function (opportunityId, diaryId, screenType) {
        return this.http.get(this.getApiUrl("get_diary_event_feedback/" + opportunityId + "/" + diaryId + "/" + screenType));
    };
    ProposalTemplateService.prototype.getDiary = function (opportunityId) {
        return this.http.get(this.getApiUrl("getDiaryDetails/" + opportunityId));
    };
    ProposalTemplateService.prototype.editCustomerOptionName = function (Data) {
        return this.http.post(this.getApiUrl("sales/editCustomerOptionName"), Data);
    };
    ProposalTemplateService.prototype.getCustomerOptionName = function (type, optionId, templateId) {
        return this.http.get(this.getApiUrl("sales/getCustomerOptionName/" + type + "/" + optionId + "/" + templateId));
    };
    ProposalTemplateService.prototype.getTaxDetails = function () {
        return this.http.get(this.getApiUrl("getTaxDetails"));
    };
    ProposalTemplateService.prototype.checkFinanceTemplateExists = function (params) {
        return this.http.get(this.getApiUrl("sales/checkFinanceTemplateExists", params));
    };
    ProposalTemplateService.prototype.addOrEditFinance = function (data) {
        return this.http.post(this.getApiUrl("sales/addOrEditFinanceTemplates"), data);
    };
    ProposalTemplateService.prototype.setInvoiceStatus = function (data) {
        this.invoiceStatus.next(data);
    };
    ProposalTemplateService.prototype.downloadProposalTemplate = function (id) {
        return this.http.get(this.getApiUrl("downloadProposalTemplate?id=" + id));
    };
    ProposalTemplateService.prototype.uploadProposalTemplate = function (params) {
        return this.http.post(this.getApiUrl("uploadProposalTemplate"), params);
    };
    ProposalTemplateService.prototype.setPricingSectionPartsData = function (data) {
        this.pricingSectionPartsData.next(data);
    };
    ProposalTemplateService.prototype.getOptionAddonsDetails = function (screenName, templateId, optionId) {
        return this.http.get(this.getApiUrl("sales/getOptionAddonsDetails/" + screenName + "/" + templateId + "/" + optionId));
    };
    ProposalTemplateService.prototype.pagePropertiesBackgroundImage = function (files, pageId, saveHeaderFooter, uploadType) {
        if (saveHeaderFooter === void 0) { saveHeaderFooter = 'tmpshowing'; }
        return this.http.post(this.getApiUrl("sales/pagePropertiesBackgroundImage/" + pageId + "?saveHeaderFooter=" + saveHeaderFooter + "&uploadType=" + uploadType), files);
    };
    ProposalTemplateService.prototype.saveBackgroundColor = function (backgroundColor) {
        return of({ 'backgroundColor': backgroundColor, 'status': 'saved', });
    };
    ProposalTemplateService.prototype.saveJobNotes = function (params) {
        return this.http.post(this.getApiUrl("sales/saveJobNotes"), params);
    };
    ProposalTemplateService.prototype.deleteJobNotes = function (data) {
        return this.http.post(this.getApiUrl("sales/deleteJobNotes"), data);
    };
    ProposalTemplateService.prototype.updateJobNotes = function (data) {
        return this.http.post(this.getApiUrl("sales/updateJobNotes"), data);
    };
    ProposalTemplateService.prototype.getJobNotesOptionAddonDetails = function (data) {
        return this.http.post(this.getApiUrl("sales/getJobNotesOptionAddonDetails"), data);
    };
    ProposalTemplateService.prototype.getSurveyTemplateList = function () {
        return this.http.get(this.getApiUrl("list_LeaveJobQuestions", { screen: 'opportunity', limit: 100 }));
    };
    ProposalTemplateService.prototype.addOrEditCustomTags = function (data) {
        return this.http.post(this.getApiUrl("sales/addOrEditCustomTags"), data);
    };
    ProposalTemplateService.prototype.getCustomTags = function (templateId, screenName) {
        return this.http.get(this.getApiUrl("sales/getCustomTags/" + templateId + "/" + screenName));
    };
    ProposalTemplateService.prototype.changeJobNotesPageId = function (data) {
        return this.http.post(this.getApiUrl('sales/changeJobNotesPageId'), data);
    };
    ProposalTemplateService.prototype.updateCustomTagStatus = function (data) {
        return this.http.post(this.getApiUrl("sales/updateCustomTagStatus"), data);
    };
    ProposalTemplateService.prototype.setRefreshPricingBreakdownSidebar = function (data) {
        this.refreshPricingBreakdownSidebar.next(data);
    };
    ProposalTemplateService.prototype.checkFinanceAvail = function () {
        return this.http.get(this.getApiUrl("sales/checkNovunaAvail"));
    };
    ProposalTemplateService.prototype.getMarketplaceTemplatesList = function (templatesType) {
        if (templatesType === void 0) { templatesType = ''; }
        if (templatesType != '') {
            return this.http.get(this.getApiUrl("getMarketplaceTemplates?templatesname=" + templatesType));
        }
    };
    ProposalTemplateService.prototype.manipulatemarketTemplates = function (data) {
        return this.http.post(this.getApiUrl("addMarketPlaceTemplates"), data);
    };
    ProposalTemplateService.prototype.addOrEditPricingSectionOptions = function (data) {
        return this.http.post(this.getApiUrl("addProposalCostSections"), data);
    };
    ProposalTemplateService.prototype.manipulateMarketplace = function (data) {
        return this.http.post(this.getJobApiUrl("custom_forms/market_place/add"), data);
    };
    ProposalTemplateService.prototype.getPageType = function (id) {
        return this.http.get(this.getApiUrl("sales/getPageType/" + id));
    };
    ProposalTemplateService.prototype.pageTypes = function () {
        return this.http.get(this.getApiUrl("sales/pageTypes"));
    };
    ProposalTemplateService.prototype.getFreemiumBlocks = function () {
        return this.http.get(this.getApiUrl('sales/getFreemiumBlocks'));
    };
    ProposalTemplateService.prototype.upgradeDowngradeFreemiumBlocks = function (data) {
        return this.http.post(this.getApiUrl('sales/upgradeDowngradeFreemiumBlocks'), data);
    };
    ProposalTemplateService.prototype.addOrEditPageHeaderFooter = function (data) {
        var url = this.getApiUrl("sales/addOrEditPageHeaderFooter");
        return this.http.post(url, data);
    };
    ProposalTemplateService.prototype.setProposalEmailStatus = function (data) {
        this.proposalEmailStatus.next(data);
    };
    ProposalTemplateService.prototype.setCloseAllModal = function (data) {
        this.closeAllModal.next(data);
    };
    ProposalTemplateService.prototype.setClosePreviousVersion = function (data) {
        this.closePreviousVersion.next(data);
    };
    ProposalTemplateService.prototype.setDiscrepancyData = function (data) {
        this.discrepancy = data;
    };
    ProposalTemplateService.prototype.getDiscrepancyData = function () {
        return this.discrepancy;
    };
    ProposalTemplateService.prototype.setCurrentIndex = function (data) {
        this.currentIndex = data;
    };
    ProposalTemplateService.prototype.getCurrentIndex = function () {
        return this.currentIndex;
    };
    ProposalTemplateService.prototype.isLastIndex = function (lastIndex) {
        this.lastIndex = lastIndex;
    };
    ProposalTemplateService.prototype.checkLastIndex = function () {
        return this.lastIndex;
    };
    return ProposalTemplateService;
}(InterfaceService));
export { ProposalTemplateService };
