import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
var CalculationFieldParserComponent = /** @class */ (function () {
    function CalculationFieldParserComponent() {
        this.expression = [];
        this.questionId = '';
        this.Values = {};
        this.calculatedResult = new EventEmitter();
        this.calculatedMath = undefined;
    }
    CalculationFieldParserComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var _a;
        if (changes) {
            var expressionField = this.expression['fields'];
            var decimalPoint = this.expression && this.expression.hasOwnProperty('field_data') && this.expression['field_data'].hasOwnProperty('decimalPoint')
                && this.expression['field_data'].decimalPoint !== ''
                ? Math.min(parseInt(this.expression['field_data'].decimalPoint), 10) : 4;
            var mathExpression = expressionField.map(function (item) {
                var answer = _this.listValues && _this.listValues.hasOwnProperty(item + _this.listIndex) ? _this.listValues[item + _this.listIndex] :
                    _this.Values.hasOwnProperty(item) ? _this.Values[item] :
                        _this.Values.hasOwnProperty(item + _this.listIndex) ? _this.Values[item + _this.listIndex] : item;
                return answer == null || answer + '' == '' ? 'undefined' : answer;
            }).join('');
            try {
                var mathResult = this.convertExponentialDigit(eval(mathExpression));
                this.calculatedMath = isNaN(mathResult) ? undefined : decimalPoint !== 0 &&
                    mathResult % 1 !== 0 ? mathResult.toFixed(decimalPoint) : mathResult;
            }
            catch (error) {
                this.calculatedMath = undefined;
            }
            if (!(this.Values[this.questionId] === this.calculatedMath || this.listValues && this.listValues[this.questionId] === this.calculatedMath))
                this.calculatedResult.emit((_a = {}, _a[this.questionId] = this.calculatedMath, _a));
        }
    };
    CalculationFieldParserComponent.prototype.convertExponentialDigit = function (inputNumber) {
        var sign = +inputNumber < 0 ? "-" : "", inputString = inputNumber.toString();
        if (!/e/i.test(inputString)) {
            return inputNumber; // if number doesn't contain exponential.
        }
        var _a = inputString.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./), lead = _a[0], decimal = _a[1], power = _a[2];
        return +power < 0 ?
            sign + "0." + "0".repeat(Math.max(Math.abs(power) - 1 || 0, 0)) + lead + decimal :
            sign + lead + (+power >= decimal.length ? (decimal + "0".repeat(Math.max(+power - decimal.length || 0, 0))) :
                (decimal.slice(0, +power) + "." + decimal.slice(+power)));
    };
    return CalculationFieldParserComponent;
}());
export { CalculationFieldParserComponent };
