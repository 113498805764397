import {TranslateService} from "@ngx-translate/core";

export class Blocks {

    private _cacheBlockKey:any = {};
    private translate: TranslateService;

    static WORKFLOW_TYPE_CONSTANTS: Object = {
        'custom': 'Custom',
        'sequence': 'Sequence',
        'pipeline_automation': 'PipelineAutomation'
    };

    private _groups: object = {
        'externalCommunications': {
            title: 'External communications',
        },
        'internalCommunications': {
            title: 'Internal communications',
        },
        'actionBlocks': {
            title: 'Actions blocks'
        }
    };

    private _blocks:any[] = [
        /* external communications */
        {
            name: 'emailBlock',
            title: 'Email',
            description: 'Send an email to one or more external recipients',
            iconClass: 'fal fa-envelope',
            groupId: 'externalCommunications',
            displayText: ['send an email to '],
            visible: true,
            access: [],
            key: 'externalCommunications_emailBlock',
            inputLabelText: 'External recipient\'s email address',
            activityDescription: ['An Email will be sent instantly when this #actionType# #stageName#'],
            skipMessage: ['An Email was not sent due to no preference or an empty/ invalid recipient when this #actionType# #stageName#']
        },
        {
            name: 'smsBlock',
            title: 'SMS',
            description: 'Send a SMS to one or more external recipients',
            iconClass: 'fa-light fa-comment-sms',
            groupId: 'externalCommunications',
            displayText: ['send a SMS to '],
            visible: true,
            access: [],
            key: 'externalCommunications_smsBlock',
            inputLabelText: 'External recipient\'s mobile',
            activityDescription: ['A SMS will be sent instantly when this #actionType# #stageName#'],
            skipMessage: ['A SMS was not sent due to no preference or an empty/ invalid recipient when this #actionType# #stageName#']
        },
        {
            name: 'sendProposalBlock',
            title: 'Send proposal',
            description: 'Send a proposal via email to one or more external recipients.',
            iconClass: 'fal fa-envelope',
            groupId: 'externalCommunications',
            displayText: ['send a proposal to the'],
            visible: true,
            access: [],
            key: 'externalCommunications_sendProposalBlock',
            inputLabelText: 'External recipient\'s email address',
            activityDescription: ['A proposal will be sent instantly when this #actionType# #stageName#'],
            skipMessage: ['A proposal was not sent due to no preference or an empty/ invalid recipient when this #actionType# #stageName#']
        },
        /* internal communications */
        {
            name: 'emailBlock',
            title: 'Email',
            description: 'Send an email to one or more users',
            iconClass: 'fal fa-envelope',
            groupId: 'internalCommunications',
            visible: true,
            access: [],
            key: 'internalCommunications_emailBlock',
            inputLabelText: 'To email address',
            displayText: ['send an email to '],
            activityDescription: ['An email will be sent instantly for internal communication when this #actionType# #stageName#'],
            skipMessage: ['An Email was not sent for internal communication due to no preference or an empty/ invalid recipient when this #actionType# #stageName#']
        },
        {
            name: 'smsBlock',
            title: 'SMS',
            description: 'Send a SMS to one or more recipients',
            iconClass: 'fa-light fa-comment-sms',
            groupId: 'internalCommunications',
            displayText: ['send a SMS to'],
            visible: true,
            access: [],
            key: 'internalCommunications_smsBlock',
            inputLabelText: 'Recipient\'s mobile',
            activityDescription: ['A SMS will be sent instantly for internal communication when this #actionType# #stageName#'],
            skipMessage: ['A SMS was not sent for internal communication due to no preference or an empty/ invalid recipient when this #actionType# #stageName#']
        },
        {
            name: 'sendProposalBlock',
            title: 'Send proposal',
            description: 'Send a proposal via email to one or more users.',
            iconClass: 'fal fa-envelope',
            groupId: 'internalCommunications',
            displayText: ['send a proposal to'],
            visible: true,
            access: [],
            key: 'internalCommunications_sendProposalBlock',
            inputLabelText: 'To email address',
            activityDescription: ['A proposal will be sent instantly for internal communication when this #actionType# #stageName#'],
            skipMessage: ["A proposal was not sent for internal communication due to no preference or an empty/ invalid recipient when this #actionType# #stageName#"]
        },
        {
            name: 'notificationBlock',
            title: 'Notification',
            description: 'Send an in-app notification to one or more roles',
            iconClass: 'fa-light fa-earth-americas',
            groupId: 'internalCommunications',
            visible: true,
            access: [],
            key: 'internalCommunications_notificationBlock',
            inputLabelText: 'Roles',
            displayText: ['send an in-app notification to'],
            activityDescription: ['A notification will be sent instantly when this #actionType# #stageName#'],
        },
        {
            name: 'scheduledActivityBlock',
            title: 'Scheduled activity',
            description: 'Create and assign a scheduled activity',
            iconClass: 'fal fa-clipboard-list-check',
            groupId: 'internalCommunications',
            visible: true,
            access: [],
            key: 'internalCommunications_scheduledActivityBlock',
            displayText: ['create #a_or_an# #activityTypeDisplay# activity and schedule it from the automation start date'],
            activityDescription: ['An activity will be scheduled instantly when this #actionType# #stageName#'],
        },
        {
            name: 'changeStageBlock',
            title: 'Change stage action',
            description: 'Move the stage from another stage',
            iconClass: 'fal fa-exchange',
            groupId: 'actionBlocks',
            visible: true,
            access: [],
            key: 'actionBlocks_changeStageBlock',
            displayText: [' '],
            activityDescription: ['A stage will be changed from #stageName# to #toStageName#'],
        },
        {
            name: 'startSequenceBlock',
            title: 'Start sequence',
            description: 'Execute the "Start sequence"',
            iconClass: 'fa-light fa-envelopes-bulk',
            groupId: 'actionBlocks',
            visible: true,
            access: [],
            key: 'actionBlocks_startSequenceBlock',
            inputLabelText: 'Sequence template',
            displayText: ['Start the #templatename# sequence and send'],
            activityDescription: ['A sequence will be started instantly when this #actionType# #stageName#'],
            skipMessage: ["A sequence was not start due to the dag file generation delay when this #actionType# #stageName#"]
        },
        {
            name: 'setSalesPersonBlock',
            title: 'Set sales person',
            description: 'Assign the sales person',
            iconClass: 'fa-light fa-envelopes-bulk',
            groupId: 'actionBlocks',
            visible: true,
            access: [],
            key: 'actionBlocks_setSalesPersonBlock',
            inputLabelText: 'Assign to',
            displayText: ['Set sales person as #salesPerson# for opportunity'],
            activityDescription: ['A sales person is assigned once this #actionType# #stageName#'],
            skipMessage: ["A sales person was not update due to the opportunity assigned by this sales person when this #actionType# #stageName#"]
        },
        {
            name: 'AddJobBlock',
            title: 'Add new job',
            description: 'Add new job for this customer',
            iconClass: 'fa-light fa-truck',
            groupId: 'actionBlocks',
            visible: true,
            access: [],
            key: 'actionBlocks_addJobBlock',
            inputLabelText: 'Job description',
            displayText: ['add an new job with #jobDescription# description'],
            activityDescription: ['A job was added automatically when this #actionType# #stageName#'],
        },
        {
            name: 'sendJobReportBlock',
            title: 'Send job report',
            description: 'Send job report via email to one or more users.',
            iconClass: 'fa-light fa-receipt',
            groupId: 'actionBlocks',
            visible: true,
            access: [],
            key: 'actionBlocks_sendJobReportBlock',
            inputLabelText: 'To email address',
            displayText: ['send a job report to'],
            activityDescription: ['A job report was sent automatically when this #actionType# #stageName#'],
        }
    ];

    constructor() {
    }

    getGroupMapping(workflowType: string, objectName: string = 'all'):any[] {
        let groups:any[] = [];
        if(workflowType == Blocks.WORKFLOW_TYPE_CONSTANTS['pipeline_automation'] && objectName == 'opportunity') {
            groups.push({
                groupId: 'externalCommunications',
                blocks: ['emailBlock', 'smsBlock', 'sendProposalBlock']
            }, {
                groupId: 'internalCommunications',
                blocks: ['emailBlock', 'smsBlock', 'notificationBlock', 'scheduledActivityBlock']
            }, {
                groupId: 'actionBlocks',
                blocks: ['startSequenceBlock', 'setSalesPersonBlock']
            });
        }
        else if(workflowType == Blocks.WORKFLOW_TYPE_CONSTANTS['pipeline_automation'] && objectName == 'job') {
            groups.push({
                groupId: 'externalCommunications',
                blocks: ['emailBlock', 'smsBlock']
            }, {
                groupId: 'internalCommunications',
                blocks: ['emailBlock', 'smsBlock', 'notificationBlock', 'scheduledActivityBlock']
            },{
                groupId: 'actionBlocks',
                blocks: ['AddJobBlock', 'sendJobReportBlock']
            });
        }
        return groups;
    }

    getBlockList(workflowType: string, objectName: string = 'all') {
        const groupsMapping:any[] = this.getGroupMapping(workflowType, objectName);
        const groupData = this._groups;
        const blockData = this._blocks;
        let blocksList:any[] = [];
        groupsMapping.forEach( (item:{groupId:string, blocks:any[]}) => {
            let _groupItem = groupData[item.groupId]
            _groupItem['id'] = item.groupId;
            _groupItem['blocks'] = [];
            item.blocks.forEach( (blockName:string) => {
                const matchedBlock = blockData.find( (block) => {
                    return item['groupId'] == block.groupId && block.name == blockName;
                });
                if(matchedBlock) {
                    _groupItem['blocks'].push(matchedBlock);
                }
            });
            blocksList.push(_groupItem);
        });
        return [...blocksList];
    }

    getBlockByKey(keyName:string) {
        if(this._cacheBlockKey[keyName]) {
            return this._cacheBlockKey[keyName];
        }
        const block = this._blocks.find( b => b.key == keyName);
        if(!block) {
            return {};
        }
        this._cacheBlockKey[keyName] = block;
        return block;
    }
}
