import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "@app/core";

@Injectable({
  providedIn: 'root'
})
export class PipelineSideMenuModalService {

    progressPanelData = [];
    baseUrlPath = ['/system_settings', 'set_up_pipelines'];
    jobPermission: boolean = false;
    financePermission: boolean = false;
    screenType:string = 'opportunity';
    appliedPermission = 'SetupPipelines.updateaccess';
    automationPermission = '';

  constructor(
      private translate: TranslateService,
      private authService: AuthService
  ) {

  }
    getProgressPanelData(screen) {
        if(screen == 'job'){
            this.appliedPermission = 'SetupJobPipelines.updateaccess';
            this.automationPermission = 'SetupJobPipelineAutomation.updateaccess'
        }else {
            this.appliedPermission = 'SetupPipelines.updateaccess';
            this.automationPermission = 'SetupSalesPipelineAutomation.updateaccess'
        }
        this.progressPanelData = [
            {
                stepText: this.translate.instant('Basic.Details.Title'),
                id: 'basic_details',
                stepRouting: ['basic_details'],
                isStepRequired: true,
                stepPermission: this.authService.permitted([this.appliedPermission]) === true,
                showSection: false,
                isStepCompleted: false,
                stepActive: true,
                isStepDisable: false,
                sequence: 1,
                /*sections: [
                    {
                        sectionText: this.translate.instant('Basic.Details.Title'),
                        sectionId: 'tabId_basic_details',
                        sectionActive: true,
                        isSectionCompleted: false,
                        tabRouting: ['basic_details', 'basic'],
                        isSectionRequired: true,
                        tabPermission: true,
                        isTabDisable: false,
                        tabSequence: 1
                    }
                ]*/
            },

            {
                stepText: this.translate.instant('Pipeline.Stages'),
                id: 'pipeline_stages',
                stepRouting: ['pipeline_stages'],
                isStepRequired: true,
                stepPermission: this.authService.permitted([this.appliedPermission]) === true,
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                sequence: 2,
                /*sections: [
                    {
                        sectionText: this.translate.instant('Pipeline.Stages'),
                        sectionId: 'tabId_pipeline_stages',
                        sectionActive: true,
                        isSectionCompleted: false,
                        tabRouting: ['pipeline_stages', 'stages'],
                        isSectionRequired: true,
                        tabPermission: true,
                        isTabDisable: false,
                        tabSequence: 1
                    }
                ]*/
            },

            {
                stepText: this.translate.instant('Automations'),
                id: 'automations',
                stepRouting: ['automations'],
                isStepRequired: false,
                stepPermission:this.authService.permitted([this.automationPermission]) === true,
                showSection: false,
                isStepCompleted: false,
                stepActive: false,
                isStepDisable: false,
                sequence: 3,
                /*sections: [
                    {
                        sectionText: this.translate.instant('Default.automations'),
                        sectionId: 'tabId_default_automations',
                        sectionActive: false,
                        isSectionCompleted: false,
                        tabRouting: ['automations', 'default'],
                        isSectionRequired: true,
                        tabPermission: true,
                        isTabDisable: false,
                        tabSequence: 1
                    }
                    /!*{
                        sectionText: this.translate.instant('Custom.automations'),
                        sectionId: 'tabId_custom_automations',
                        sectionActive: false,
                        isSectionCompleted: false,
                        tabRouting: ['automations', 'custom'],
                        isSectionRequired: true,
                        tabPermission: true,
                        isTabDisable: false,
                        tabSequence: 2
                    }*!/
                ]*/
            }
        ];
        this.jobPermission = this.authService.permitted(['Jobs.readaccess']) === true;
        this.financePermission = this.authService.permitted(['SetupFinance.writeaccess']) === true;
        if(screen == 'job') {
            this.baseUrlPath = ['/system_settings', 'jobs_pipelines'];
            this.screenType = 'job';
        } else if (screen == 'stage') {
            this.baseUrlPath = ['/system_settings', 'job_stages'];
            this.screenType = 'stage';
        } else {
            this.baseUrlPath = ['/system_settings', 'set_up_pipelines'];
            this.screenType = 'opportunity';
        }
        // need to change the condition once the finance enabled
        if ((this.jobPermission || this.financePermission ) && (screen != 'job')) {
            this.progressPanelData.push({
                stepText: this.translate.instant('After.acceptance.tracking'),
                id: 'after_acceptance_tracking',
                stepRouting: ['acceptance_tracking'],
                isStepRequired: true,
                stepPermission: this.authService.permitted(['SetupPipelines.updateaccess']) === true,
                showSection: false,
                isStepCompleted: false,
                sequence: 4,
                /*sections: [
                    {
                        sectionText: this.translate.instant('After.acceptance.tracking'),
                        sectionId: 'tabId_after_acceptance',
                        sectionActive: false,
                        isSectionCompleted: false,
                        tabRouting: ['acceptance_tracking', 'tracking'],
                        isSectionRequired: true,
                        tabPermission: true,
                        isTabDisable: false,
                        tabSequence: 1
                    },
                    ]*/

            });
        }
        return this.progressPanelData;

    }
}
