import {Component, HostListener, Inject, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {Subject} from "rxjs/index";
import {BsModalRef} from 'ngx-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import { TimelineService } from '@app/shared/services/timeline.service';

@Component({
    selector: 'app-view-job-parts',
    templateUrl: './view-job-parts.component.html',
    styleUrls: ['./view-job-parts.component.scss'],
    styles: []
})
export class ViewJobPartsComponent implements OnInit {
    public onClose: Subject<boolean>;
    data: any;
    createdDetails: any;
    Url: any;
    index: any;
    ids: any;
    jobPartDetails: any;
    jobPartStatus: string = '';
    selectedUser: any;
    oneHourTime: any;
    jobId: any;
    poDetails: Array<any> = [];

    constructor(public bsModalRef: BsModalRef,
                private sanitizer: DomSanitizer,private ts: TimelineService,private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.jobPartDetails.forEach(part => {
            if (part.fulFillmentType === "OrderFromSupplier") {
                this.ts.getJobPartsPoDetails(part.jobPartId, part.fulFillmentId, this.jobId).subscribe(
                    (data) => {
                        part.poDetails = data && data.purchaseOrderDetails ? data.purchaseOrderDetails : [];
                        this.cdr.detectChanges(); // Manually trigger change detection
                    },
                    (error) => {
                        console.error("Error fetching purchase order details", error);
                        part.poDetails = []; // Set an empty array on error
                        this.cdr.detectChanges(); // Ensure UI updates even on error
                    }
                );
            } else {
                part.poDetails = []; // Set default empty array if no API call needed
            }
            const matchingUser = this.selectedUser.find(user => user.id === part.userId); // Find matching user by userid
            part.jobPartName = this.data[part.jobPartId].jobPartName;
            part.requestedQty = (this.jobPartStatus == 'Requested') ? part.qty : this.data[part.jobPartId].requestedQty;
            part.profilePic = matchingUser ? matchingUser.profilepicture : null;
            part.requestBy = matchingUser ? matchingUser.username : '';
            part.createdDateTime = this.ts.convertTimeLineDateTime(part.updated);
            part.quantity = this.formatQty(part.qty);
        });

    }
    formatQty(qty) {
        const numberQty = parseFloat(qty);
        if (isNaN(numberQty)) {
            return '0';
        }
        if (Number.isInteger(numberQty)) {
            return numberQty.toFixed(0); // Show as 2
        } else {
            return numberQty.toFixed(2); // Show as 2.10 or 2.34
        }
    }
    dateFormate(date){
        return this.ts.convertTimeLineDateTime(date);
    }



}
