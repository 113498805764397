
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, Injector, DoBootstrap } from '@angular/core';
import {UpgradeModule, downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import {setUpLocationSync} from "@angular/router/upgrade";

// Third party
import { HotTableModule } from '@handsontable/angular';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';

// Custom plugins
// import '@app/plugins';
import { SmartTableModule } from "@app/smart_table/smarttable.module";

//--
import {HybridFactory, V5AuthService} from '@app/core';
import { CoreModule, SettingsService, setUpLocationSync } from "@app/core";
import { FeaturesModule } from './features/features.module';
import { AppComponent } from '@app/app.component';
import { ServicesModule } from '@app/services';
import { AppCommonModule } from '@app/shared';
import { DataResolver } from './resolvers';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { getDatepickerConfig } from '@app/directives';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, 'resources/i18n/locale_', '.json');
}
//import {downgradeComponent} from "@angular/upgrade/src/common/downgrade_component";
import {SmartTableComponent} from "@app/smart_table/smart-table/smart-table.component";
import {ModalModule, SortableModule} from "ngx-bootstrap";
import {SmartTableMetaDataService} from "@app/smart_table/services/metadata.service";
import {HandsontableModule} from "@app/handsontable/handsontable.module";
import {ConfigKBUrlService} from "@app/core/config/ConfigKBUrlService";
import {AddEditCertificateComponent} from "@app/features/customers/jobs/add-edit-certificate/add-edit-certificate.component";
import {AddEditCertificateModule} from "@app/features/customers/jobs/add-edit-certificate/add-edit-certificate.module";
import {CsSignatureGeneratorComponent} from "@app/directives/material-directives/cs-signature-generator/cs-signature-generator.component";
import {CsFullScreenModalComponent} from "@app/directives/material-directives/cs-full-screen-modal/cs-full-screen-modal.component";
import {environment} from "../environments/environment";
import {
    DynamicCertificateFormParserComponent
} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/dynamic-certificate-form-parser.component";
import {
    CsToggleuserpreferenceComponent
} from "@app/directives/input-directives/cs-toggleuserpreference/cs-toggleuserpreference.component";
import {ArticleEditorModule} from '@app/article-editor/article-editor.module';
import {MicroserviceHelperService} from '@app/shared/microservice/helper/microservice-helper.service';
import { QuillModule } from 'ngx-quill'
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import {TimePipe} from "@app/pipes/time.pipe";
import {WorkflowCommonModule} from "@app/workflow-common/workflow-common.module";
import {AgGridModule} from "ag-grid-angular";
import {AiDocumentParsingModule} from "@app/features/ai-document-parsing/ai-document-parsing.module";
import {UploadDocumentComponent} from "@app/features/ai-document-parsing/upload-document/upload-document.component";
import {RenewalTokenService} from "@app/core/auth/renewal-token-service";
import {CsDatepickerComponent} from "@app/directives/input-directives/cs-datepicker/cs-datepicker.component";
import { SharedModalModule } from './shared-modal/shared-modal.module';
import { SharedModalService } from './shared-modal/service/shared-modal.service';
import {CommonFilterService} from "@app/shared-modal/email-modal/common-filter.service";


declare var angular;
declare var SetRootScopeValues;
declare var window;

export let InjectorInstance: Injector;

registerLocaleData(localeES);
registerLocaleData(localeFr);
registerLocaleData(localeDe);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        // Core
        BrowserModule,
        BrowserAnimationsModule,
        UpgradeModule,
        HttpClientModule,

        // Application elements
        CoreModule,
        RouterModule.forRoot([]), // The routes are imported by modules
        ServicesModule,
        FeaturesModule,
        SmartTableModule.forRoot(),
        AppCommonModule,
        HandsontableModule,
        ArticleEditorModule,
        // WorkflowCommonModule,
        SharedModalModule,

        // Third party
        HotTableModule.forRoot(),
        CarouselModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AccordionModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        AddEditCertificateModule,
        QuillModule.forRoot(),
        AgGridModule.withComponents([])
    ],
    providers: [
        DataResolver,
        TimePipe,
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        {
            provide: LOCALE_ID,
            deps: [SettingsService],
            useFactory: (SettingsService) => SettingsService.getCsLanguage()
        },
        CommonFilterService
    ],
    entryComponents: [
        AppComponent,
        AddEditCertificateComponent,
        CsFullScreenModalComponent,
        DynamicCertificateFormParserComponent,
        CsToggleuserpreferenceComponent,
        UploadDocumentComponent

    ],
    exports: [
        ModalModule,
        SmartTableModule,
        HandsontableModule,
        ArticleEditorModule,
        AiDocumentParsingModule,
        SharedModalModule
    ],

})
export class AppModule  implements DoBootstrap {
    window
    constructor(
      private upgrade: UpgradeModule,
      private settings: SettingsService,
      private injector: Injector
    ) {
        InjectorInstance = this.injector;
    }

    // Bootstrapping angularJs application first and then Angular application, this helps to upgrade components into Angular application
    ngDoBootstrap(app) {
        const locale = this.settings.getCsLanguage();
        console.log('Using locale : ', locale);
        let angularJsModule = angular.module('commusoft');
        angularJsModule.constant('ALL_PERMISSIONS', this.settings.getAllPermissions());
        angularJsModule.constant('MODULE_PERMISSIONS', this.settings.getModulePermissions());
        angularJsModule.constant('CS_VERSION', this.settings.getCsVersion());
        angularJsModule.constant('CS_LANGUAGE', this.settings.getCsLanguage());
        angularJsModule.constant('LOCALE_ID', locale);
        angularJsModule.constant('CS_COUNTRY', this.settings.getCountryCode());
        angularJsModule.constant('CS_Intl', this.settings.getIntlSettings());
        angularJsModule.constant('HybridFactory', HybridFactory);
        angularJsModule.constant('prefix',environment.apiPrefixPath);
        angularJsModule.directive('appSmartTable', downgradeComponent({component: SmartTableComponent}));
        angularJsModule.directive('appSmartTable', downgradeComponent({component: SmartTableComponent}));
        //angularJsModule.factory('NgSmartTableMetaDataService', downgradeInjectable(SmartTableMetaDataService) as any);
        angularJsModule.factory('NgConfigKBUrlService', downgradeInjectable(ConfigKBUrlService) as any);
        angularJsModule.factory('NgSettingsService', downgradeInjectable(SettingsService) as any);
        angularJsModule.directive('appAddEditCertificate',
            downgradeComponent({component: AddEditCertificateComponent, inputs: ['data'],  outputs: ['onSidePanelClose', 'onReloadSmartTable']}));
        angularJsModule.directive('appCsSignatureGenerator', downgradeComponent({component: CsSignatureGeneratorComponent}));
        angularJsModule.directive('ngFsModal', downgradeComponent({component: CsFullScreenModalComponent}));
        angularJsModule.constant('COUNTRY_LIST', this.settings.getCountryMasterData());
        HybridFactory.ngUpgrade = this.upgrade;
        window.prefixVal=environment.apiPrefixPath;
        angularJsModule.constant('webservicePrefix',environment.webservicePrefix);
        angularJsModule.constant('adminPrefix',environment.adminPrefix);
        angularJsModule.constant('mapDetails', environment.mapDetails);
        angularJsModule.directive('csFormParser', downgradeComponent({component: DynamicCertificateFormParserComponent}));
        angularJsModule.directive('csUserPreferenceToggle', downgradeComponent({component: CsToggleuserpreferenceComponent}));
        angularJsModule.factory('NgMicroserviceHelperService', downgradeInjectable(MicroserviceHelperService) as any);
        angularJsModule.factory('NgRenewalTokenService', downgradeInjectable(RenewalTokenService) as any);
        angularJsModule.factory('NgEmailService', downgradeInjectable(SharedModalService) as any);
        angularJsModule.directive('appDatepickerComponent', downgradeComponent({component: CsDatepickerComponent}));
        angularJsModule.factory('V5AuthService', downgradeInjectable(V5AuthService) as any);
        angularJsModule.directive('appUploadDocument', downgradeComponent({component: UploadDocumentComponent}));

        // AngularJs bootstrap
        this.upgrade.bootstrap(document.body, ['commusoft'], { strictDi: true });
        setUpLocationSync(this.upgrade);

        // Angular bootstrap
        app.bootstrap(AppComponent);
    }

}
