import { NgModule } from '@angular/core';
import { AppCommonModule } from "@app/shared";
import { FileUploadModule } from 'ng2-file-upload';

//--
import {ScheduleActivityModalComponent} from "@app/shared/opportunity-modals/schedule-activity-modal/schedule-activity-modal.component";
import { CallLogModalComponent } from './call-log-modal/call-log-modal.component';
import { OpportunityEmailModalComponent } from './opportunity-email-modal/opportunity-email-modal.component';
import { FileModalComponent } from './file-modal/file-modal.component';
import { NoteModalComponent } from './note-modal/note-modal.component';
import { SmsModalComponent } from './sms-modal/sms-modal.component';
import { AcceptProposalModalWebComponent } from "@app/shared/opportunity-modals/accept-proposal-modal-web/accept-proposal-modal-web.component";
import {CardModule} from "ngx-card";
import {ReactiveFormsModule} from "@angular/forms";
import {DirectivesModule} from "@app/directives";
import {TranslateModule} from "@ngx-translate/core";
import {TimepickerModule} from "ngx-bootstrap";
import { PipesModule } from '@app/pipes';
import {CommonFolderStructureModule} from "@app/shared/common-folder-structure/common-folder-structure.module";
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";
import {ViewProposalComponent} from "@app/shared/opportunity-modals/view-proposal/view-proposal.component";


@NgModule({
    declarations: [
        ScheduleActivityModalComponent,
        CallLogModalComponent,
        OpportunityEmailModalComponent,
        FileModalComponent,
        NoteModalComponent,
        SmsModalComponent,
        AcceptProposalModalWebComponent,
        ViewProposalComponent
    ],
    exports:[
        ScheduleActivityModalComponent,
        CallLogModalComponent,
        OpportunityEmailModalComponent,
        FileModalComponent,
        NoteModalComponent,
        SmsModalComponent,
        AcceptProposalModalWebComponent,
        ViewProposalComponent
    ],
    imports: [
        // AppCommonModule,
        FileUploadModule,
        CardModule,
        ReactiveFormsModule,
        DirectivesModule,
        TranslateModule,
        TimepickerModule.forRoot(),
        PipesModule,
        CommonFolderStructureModule,
        CsComponentsModule
    ],
    entryComponents: [
        ViewProposalComponent
    ],
})
export class OpportunityModalsModule { }
