import { NgModule } from '@angular/core';
import { DynamicCertificateFormParserComponent } from './dynamic-certificate-form-parser/dynamic-certificate-form-parser.component';
import {AppCommonModule} from "../index";
import {FileUploadModule} from "ng2-file-upload";
import {SmartTableModule} from "@app/smart_table/smarttable.module";
import { DynamicCertificateSignatureSidePanelComponent } from './dynamic-certificate-form-parser/dynamic-certificate-signature-side-panel/dynamic-certificate-signature-side-panel.component';
import {
    DynamicCertificateFormViewComponent
} from "@app/shared/dynamic-certificate-form-parser/dynamic-certificate-form-parser/view-dynamic-form/dynamic-certificate-form-view.component";
import {DirectivesModule} from "@app/directives";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PipesModule} from "@app/pipes";
import {TranslateModule} from "@ngx-translate/core";
import { CustomfieldUserComponent } from './dynamic-certificate-form-parser/customfield-user/customfield-user.component';
import { CalculationFieldParserComponent } from './dynamic-certificate-form-parser/calculation-field-parser/calculation-field-parser.component';

@NgModule({
    declarations: [DynamicCertificateFormParserComponent, DynamicCertificateSignatureSidePanelComponent, DynamicCertificateFormViewComponent, CustomfieldUserComponent, CalculationFieldParserComponent],
    exports: [
        DynamicCertificateFormParserComponent,
        DynamicCertificateFormViewComponent,
        CustomfieldUserComponent
    ],
    imports: [
        // AppCommonModule,
        FileUploadModule,
        SmartTableModule,
        DirectivesModule,
        ReactiveFormsModule,
        FormsModule,
        PipesModule,
        TranslateModule
    ]
})
export class DynamicCertificateFormParserModule { }
