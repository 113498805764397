import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap";
import {SetupPipelineService} from "@app/features/system-settings/set-up-pipelines/services/set-up-pipeline.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {
    PipelineSideMenuModalService
} from "@app/features/system-settings/set-up-pipelines/services/pipeline-side-menu-modal.service";
import { ChangeDetectorRef } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {SetupViewsService} from "@app/shared/views-layouts/services/setup-views.service";

declare var $: any;
@Component({
  selector: 'app-pipeline-stages-modal',
  templateUrl: './pipeline-stages-modal.component.html',
  styleUrls: ['./pipeline-stages-modal.component.scss']
})
export class PipelineStagesModalComponent implements OnInit {
    public onClose: Subject<boolean>;
    statusForm: FormGroup;
    statusFormData = {
        name: '',
        color:'',
        rotting:'',
        rules:'',
        outRoles:'',
        outUsers:'',
        inRoles:'',
        inUsers:'',
        isJobComplete: '',
        viewIcon: '',
        iconName: '',
        iconId: '',
        iconType: ''
    }
    uniqueName:boolean = false;
    myValue: string = '#C5A638'
    changedColor= '#C5A638';
    stageRules: any = [{'value':'','checked':false,'radioOptions':[{'value':'','selected':false},{'value':'','selected':false},{'value':'','selected':false}]},{'value':'','checked':false,'radioOptions':[{'value':'','selected':false},{'value':'','selected':false},{'value':'','selected':false}]},{'value':'','checked':false,'radioOptions':[{}]},{'value':'','checked':false,'radioOptions':[{}]}];
    showInput:boolean = false;
    showInStageInput:boolean = false;

    title: string;
    data:any;
    roles:any
    users:any;
    pipelineId:any;
    isEdit: boolean = false;
    select2rolesConfig: {};
    select2usersConfig:{};
    select2stagesConfig:{};
    rulesDataList:any;
    outSeletectedRoles:any=[];
    outSelectedUsers:any=[];
    inSeletectedRoles:any=[];
    inSelectedUsers:any=[];
    outUsers:any;
    inUsers:any;
    selectedOutUsers:any;
    hideSave:boolean = false;
    screen:string ='opportunity';
    outStageCheckBox: boolean = false;
    inStageCheckBok: boolean = false;
    previousStageCheck: boolean = false;
    sendCustomerCheck: boolean = false;
    stageData:any;
    stageDropDownData: any;
    selectedStageId: any = "";
    selectedStageName: any;
    previousStageEnable: boolean = false;
    saving: boolean = false;
    isJobComplete: any = false;
    selectedIcon: any = '';
    selectedIconType: any = '';
    iconName = '';
    iconId: boolean = true;
    @Input() uploadedIconsListUrls = [];
    defaultIconsListUrls = [];
    @Input() defaultIconsList = [];


    constructor(
        private formBuilder: FormBuilder,
        public bsModalRef: BsModalRef,
        private httpService: SetupPipelineService,
        private route: ActivatedRoute,
        private router: Router,
        private configService: PipelineSideMenuModalService,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private viewsService: SetupViewsService,
    ) {
        this.route.params.subscribe(params => {
            this.pipelineId = this.router.url.split('/')[3];
        });

    }

    ngOnInit() {
        this.screen = this.configService.screenType;
        this.select2rolesConfig = {
            width: '100%'
        }
        this.select2usersConfig = {
            width: '100%'
        }
        this.onClose = new Subject();
        /*if(this.data){
            this.stageDropDownData = this.getPreviousStageById(this.data.id);
            console.log(this.stageDropDownData);
        } else {
            this.stageDropDownData = this.stageData;
        }*/
        this.select2stagesConfig = {
            width: '100%',
            data: this.stageData
        }
        if (this.stageData) {
            if ((!this.data && this.stageData.length < 1)  || (this.data && this.stageData.length < 2 )) {
                this.previousStageEnable = true;
            } else {
                this.previousStageEnable = false;
            }
        }

        if (this.data) {
            this.isEdit = true;
            this.statusFormData.name = this.data.hasOwnProperty('name') ? this.data.name : '';
            this.statusFormData.color = this.data.hasOwnProperty('color') ? this.data.color : '#FFFFFF';
            this.statusFormData.rotting = this.data.hasOwnProperty('rotting') ? this.data.rotting : '';
            this.statusFormData.rules = this.data.hasOwnProperty('rules') && this.data.rules ? JSON.parse(this.data.rules) : this.stageRules;
            this.statusFormData.isJobComplete = this.data.hasOwnProperty('isJobComplete') ? this.data.isJobComplete : '';
            this.statusFormData.viewIcon = this.data.hasOwnProperty('viewIcon') ? this.data.viewIcon : '';
            this.statusFormData.iconType = this.data.hasOwnProperty('iconType') ? this.data.iconType : '';
            this.myValue = this.statusFormData.color;
            this.changedColor = this.myValue;
            this.stageRules = this.statusFormData.rules;
            this.isJobComplete = this.statusFormData.isJobComplete;

            this.selectedIcon = this.data.viewIcon
            this.selectedIconType = this.data.iconType || 'FA'
            this.stageData = this.stageData.filter(item => item.id !== this.data.id);
            if (this.stageData) {
                if (this.stageData.length < 1) {
                    this.previousStageEnable = true;
                } else {
                    this.previousStageEnable = false;
                }
            }

            if (this.stageRules) {
                const stageUsersOptions = (this.stageRules[1] && this.stageRules[1]['radioOptions']) || null;
                const stageRolesOptions = (this.stageRules[0] && this.stageRules[0]['radioOptions']) || null;

                if (stageUsersOptions && (stageUsersOptions[1] && stageUsersOptions[1]['selected'] || stageUsersOptions[2] && stageUsersOptions[2]['selected'])) {
                    this.showInStageInput = true;

                    if (stageUsersOptions[1] && stageUsersOptions[1]['selected']) {
                        const inRoleValues = stageUsersOptions[1]['value'];
                        if (Array.isArray(inRoleValues)) {
                            inRoleValues.forEach(element => {
                                this.inSeletectedRoles.push(parseInt(element, 10));
                            });
                            this.statusFormData.inRoles = this.inSeletectedRoles;
                            stageUsersOptions[1]['value'] = this.inSeletectedRoles;
                        }
                    }

                    if (stageUsersOptions[2] && stageUsersOptions[2]['selected']) {
                        const inUserValues = stageUsersOptions[2]['value'];
                        if (Array.isArray(inUserValues)) {
                            inUserValues.forEach(element1 => {
                                this.inSelectedUsers.push(parseInt(element1, 10));
                            });
                            this.statusFormData.inUsers = this.inSelectedUsers;
                            stageUsersOptions[2]['value'] = this.inSelectedUsers;
                        }
                    }
                }

                if (stageRolesOptions && (stageRolesOptions[1] && stageRolesOptions[1]['selected'] || stageRolesOptions[2] && stageRolesOptions[2]['selected'])) {
                    this.showInput = true;

                    if (stageRolesOptions[1] && stageRolesOptions[1]['selected']) {
                        const outRoleValues = stageRolesOptions[1]['value'];
                        if (Array.isArray(outRoleValues)) {
                            outRoleValues.forEach(element => {
                                this.outSeletectedRoles.push(parseInt(element, 10));
                            });
                            this.statusFormData.outRoles = this.outSeletectedRoles;
                            stageRolesOptions[1]['value'] = this.outSeletectedRoles;
                        }
                    }

                    if (stageRolesOptions[2] && Array.isArray(stageRolesOptions[2]['value'])) {
                        stageRolesOptions[2]['value'].forEach(element1 => {
                            this.outSelectedUsers.push(parseInt(element1, 10));
                        });
                        this.statusFormData.outUsers = this.outSelectedUsers;
                        stageRolesOptions[2]['value'] = this.outSelectedUsers;
                    }
                }

                if (this.stageRules[2] && this.stageRules[2].value && this.stageData ) {
                    const selectedStage = this.stageData.find(stage => stage.id === +this.stageRules[2].value);
                    if (selectedStage) {
                        this.previousStageCheck = false;
                        this.selectedStageId = selectedStage.id;
                        this.selectedStageName = selectedStage.name;
                        setTimeout(() => {
                            $('#cs-select-previous-stage').select2('data', {
                                id: this.selectedStageId,
                                text: this.selectedStageName
                            });
                        }, 300);
                    }
                }
            }
        }
        if(this.statusFormData.isJobComplete == ''){
            this.statusFormData.isJobComplete = 'false';
            this.isJobComplete = 'false';
        }
        this.buildForm();
        if (this.screen == 'stage') {
            this.loadIcons();
        }
    }

    buildForm() {
        this.statusForm = this.formBuilder.group({
            'name': [this.statusFormData.name, Validators.required],
            'color': [this.statusFormData.color, Validators.required],
            'rotting': [this.statusFormData.rotting],
            'rules': [this.statusFormData.rules],
            'outRoles': [this.statusFormData.outRoles],
            'outUsers': [this.statusFormData.outUsers],
            'inRoles': [this.statusFormData.inRoles],
            'inUsers': [this.statusFormData.inUsers],
            'isJobComplete': [this.statusFormData.isJobComplete],
            'viewIcon': [this.statusFormData.viewIcon, this.screen == 'stage' ? Validators.required : ''],
            'iconName': [this.statusFormData.iconName],
            'iconType': [this.statusFormData.iconType],
            'iconId': [this.statusFormData.iconId],
        });
        // Use setTimeout to delay the updates to ensure elements are loaded before triggering changes.
            // After values are set, trigger validation manually
            this.statusForm.controls['name'].updateValueAndValidity();
            this.statusForm.controls['color'].updateValueAndValidity();
            this.statusForm.controls['rotting'].updateValueAndValidity();
            this.statusForm.controls['rules'].updateValueAndValidity();
            this.statusForm.controls['outRoles'].updateValueAndValidity();
            this.statusForm.controls['outUsers'].updateValueAndValidity();
            this.statusForm.controls['inRoles'].updateValueAndValidity();
            this.statusForm.controls['inUsers'].updateValueAndValidity();
        this.statusForm.controls['viewIcon'].updateValueAndValidity();
        this.statusForm.controls['iconName'].updateValueAndValidity();
        this.statusForm.controls['iconId'].updateValueAndValidity();
        this.statusForm.controls['iconType'].updateValueAndValidity();

        setTimeout(() => {
            if (this.statusFormData.outUsers) {
                $('.outUsers').val(this.outSelectedUsers).trigger('change');
            }
            if (this.statusFormData.outRoles) {
                $('.outRoles').val(this.outSeletectedRoles).trigger('change');
            }
            if (this.statusFormData.inRoles) {
                $('.inRoles').val(this.inSeletectedRoles).trigger('change');
            }
            if (this.statusFormData.inUsers) {
                $('.inUsers').val(this.inSelectedUsers).trigger('change');
            }
            }, 100);


    }

    get f() {
        return this.statusForm.controls;
    }

    getColor(data){
        this.changedColor = data;
    }

    checkboxChecked(index){
        this.updateStageCheck(index);
        this.stageRules[index].checked = !this.stageRules[index].checked;
        if(!this.stageRules[index].checked){
            this.stageRules[index]['radioOptions'].forEach((element,ind) => {
                if(element.selected){
                    if (index == 0) {
                        this.showInput = false;
                        this.outStageCheckBox = false;
                    }
                    if (index == 1) {
                        this.showInStageInput = false;
                        this.inStageCheckBok = false;
                    }
                    this.stageRules[index]['radioOptions'][ind]['selected'] = false;
                    this.stageRules[index]['radioOptions'][ind]['value'] = '';
                }
            })
            if (index == 2) {
                this.previousStageCheck = false;
                this.selectedStageId = '';
            } else if (index == 1){
                this.outStageCheckBox = false;
            } else if (index == 0) {
                this.inStageCheckBok = false;
            }
        } else {
            this.optionChange(index, 0);
        }
    }

    optionChange(i,j){
        this.stageRules[i]['radioOptions'][j].selected = !this.stageRules[i]['radioOptions'][j].selected;
        if(i ==0 && (j == 1 || j ==2)){
            this.showInput = true;
            this.inStageCheckBok = true;
            if(j == 1){
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            } else{
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            }
        }
        else if(i == 1 && (j == 1 || j==2)){
            this.showInStageInput = true;
            this.outStageCheckBox = true;
            if(j == 1){
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            } else{
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][0].selected = false;
            }
        }
        else{
            if(i == 0) {
                this.inStageCheckBok = false;
                this.showInput = false;
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][2].value = '';
                this.stageRules[i]['radioOptions'][1].value = '';
            }else if(i == 1) {
                // this.showInStageInput = false;
                this.outStageCheckBox = false;
                this.showInStageInput = false;
                this.stageRules[i]['radioOptions'][1].selected = false;
                this.stageRules[i]['radioOptions'][2].selected = false;
                this.stageRules[i]['radioOptions'][2].value = '';
                this.stageRules[i]['radioOptions'][1].value = '';
            }
        }
    }

    validateInput(event, i , j){
        if(event){
            let data = [];
            this.selectedOutUsers = event;
            this.hideSave = false;
            data = event;
            this.stageRules[i]['radioOptions'][j].value = event;
            if (i == 0) {
                if (event && event.length > 0 ) {
                    this.inStageCheckBok = false;
                } else {
                    this.inStageCheckBok = true;
                }
            } else if (i == 1 ) {
                if (event && event.length > 0) {
                    this.outStageCheckBox = false;
                } else {
                    this.outStageCheckBox = true;
                }
            }
        }
    }

    savePipelineStatus(){
        this.saving = true;
        this.statusForm.get('rules').setValue(JSON.stringify(this.stageRules));
        let pipelineStatus:any = this.statusForm.value;
        pipelineStatus['pipelineId'] = this.pipelineId;
        if(this.data && this.data.hasOwnProperty('rotting')){
            pipelineStatus['rotting'] = this.statusForm.get('rotting').value;
        }
        if(this.isEdit){
            pipelineStatus['id'] = this.data['id'];
        }
        this.statusForm.updateValueAndValidity();
        if (this.screen == 'job' || this.screen == 'stage') {
            pipelineStatus['screenType'] = this.screen;
            pipelineStatus['isJobComplete'] = JSON.parse(this.isJobComplete.toLowerCase());
        }
        this.cdr.detectChanges();
        this.httpService.addEditStages(pipelineStatus, this.screen)
            .subscribe(resp => {
                if (resp) {
                    this.onClose.next(true);
                    this.bsModalRef.hide();
                    setTimeout(()=>{
                        this.saving = false;
                    }, 200)
                }
            });
    }
    nextStage(event){
        this.stageRules[2].value = event.target.value;
    }

    updateStageCheck(index: number): void {
        switch (index) {
            case 0:
                this.inStageCheckBok = !this.inStageCheckBok;
                if (!this.inStageCheckBok) {
                    this.showInput = false;
                }
                break;
            case 1:
                this.outStageCheckBox = !this.outStageCheckBox;
                if (!this.outStageCheckBox) {
                    this.showInStageInput = false;
                }
                break;
            case 2:
                this.previousStageCheck = !this.previousStageCheck;
                break;
            default:
                break;
        }
    }

    onStageSelect(value: any) {
        const selectedStage = this.stageData.find(stage => stage.id === +value);

        if (selectedStage) {
            this.selectedStageId = selectedStage.id;
            this.selectedStageName = selectedStage.name;

            // Update the corresponding value in stageRules
            if (this.stageRules[2]) {
                this.stageRules[2].value = this.selectedStageId;
                this.previousStageCheck = false;
            }
        }
    }
    stageCompleteChecked(){
        this.isJobComplete = this.isJobComplete == 'true' ? 'false' : 'true';
    }

    getTransparencyLevel(val: string) {
        if (val) {
            let transparencyLevel = val.slice(7)
            if (transparencyLevel != '' && transparencyLevel.length < 3) {
                let decimal = parseInt(val.slice(7), 16)
                let percentage = Math.round(decimal * (100 / 255))
                return percentage + '%';
            }
        }
        return '100%';
    }

    removeIcon() {
        this.selectedIcon = '';
        this.iconName = '';
        this.statusForm.controls['viewIcon'].setValue('');
    }

    stopEventPropagation(eve) {
        eve.stopPropagation();
    }

    setSelectedIcon(iconData) {
        this.selectedIconType = null;
        setTimeout(() => {
            if (iconData.category === 'FA') {
                this.selectedIconType = iconData.category;
                this.selectedIcon = iconData.icon.className;
                this.statusForm.controls['viewIcon'].setValue(iconData.icon.className);
                this.iconName = iconData.icon.label;
                this.iconId = true;
            } else if (iconData.category === 'image') {
                this.selectedIconType = iconData.category;
                if (typeof iconData.icon === 'string') {
                    this.selectedIcon = this.sanitizer.bypassSecurityTrustUrl(iconData.icon);
                    const iconUrl = this.selectedIcon['changingThisBreaksApplicationSecurity'] ? this.selectedIcon['changingThisBreaksApplicationSecurity'] : this.selectedIcon;
                    this.statusForm.controls['viewIcon'].setValue(iconUrl);
                    // if (element[1]) this.iconName = element[1];
                    this.iconId = (this.uploadedIconsListUrls.includes(iconUrl) || this.defaultIconsListUrls.includes(iconUrl)) ? true : false;
                } else {
                    this.selectedIcon = this.sanitizer.bypassSecurityTrustUrl(iconData.icon.url);
                    const iconUrl = this.selectedIcon['changingThisBreaksApplicationSecurity'] ? this.selectedIcon['changingThisBreaksApplicationSecurity'] : this.selectedIcon;
                    this.statusForm.controls['viewIcon'].setValue(iconUrl);
                    this.iconName = iconData.icon.name;
                    this.iconId = (this.uploadedIconsListUrls.includes(iconUrl) || this.defaultIconsListUrls.includes(iconUrl)) ? true : false;
                }
            }
            this.statusForm.controls['iconType'].setValue(this.selectedIconType);
            this.statusForm.controls['iconName'].setValue(this.iconName);
            this.statusForm.controls['iconId'].setValue(this.iconId);
        }, 200)
    }

    loadIcons() {
        this.viewsService.getViewsObjects('job').subscribe((data: any) => {
            if (data['defaultIconsList']) {
                this.defaultIconsList = data['defaultIconsList'];
            }
            if (data['uploadedIconList']) {
                let uploadedIconsList = data['uploadedIconList'];
                this.uploadedIconsListUrls = uploadedIconsList.map(iconData => iconData['iconUrl']);
            }
        }, (error: any) => {
            console.error('error', error);
        });
    }
}
