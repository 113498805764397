import {data} from "jquery";

export interface ScheduledActivityBlockModelInterface {
    assigneeIds: any[];
    assigneeDisplay: any[];
    unit: string;
    notes: string;
    description: string;
    activityType: number;
    activityTypeDisplay: string;
    scheduleInterval: number;
    scheduleTime: string;
    displayTime: string;
}

export class ScheduledActivityBlockModel implements ScheduledActivityBlockModelInterface {
    activityType: number = undefined;
    activityTypeDisplay: string;
    assigneeDisplay: any[] = [];
    assigneeIds: any[] = [];
    description: string = '';
    displayTime: string = '';
    displaySelectedTime: string = '';
    notes: string = '';
    scheduleInterval: number = 0;
    scheduleTime: string = undefined;
    unit: string = '';

    constructor(data:{} = undefined) {
        if(data) {
            this.activityType = data['activityType'] ? parseInt(data['activityType']) : undefined;
            this.activityTypeDisplay = data['activityTypeDisplay'] ? data['activityTypeDisplay'] : '';
            this.assigneeDisplay = data['assigneeDisplay'] ? data['assigneeDisplay'] : [];
            this.assigneeIds = data['assigneeIds'] ? data['assigneeIds'] : [];
            this.description = data['description'] ? data['description'] : '';
            this.displayTime = data['displayTime'] ? data['displayTime'] : '';
            this.displaySelectedTime = data['displaySelectedTime'] ? data['displaySelectedTime'] : '';
            this.notes = data['notes'] ? data['notes'] : '';
            this.scheduleInterval = data.hasOwnProperty('scheduleInterval') ? parseInt(data['scheduleInterval']) : 0;
            this.scheduleTime = data['scheduleTime'] ? data['scheduleTime'] : undefined;
            this.unit = data['unit'] ? data['unit'] : '';
        }
    }
}
